import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import { ThemeContextElement } from './contexts/ThemeContext';
import { UserContextElement } from './contexts/UserContext';
import { ChatContextElement } from './contexts/ChatContext';
import { LoadingIndicatorContextElement } from './contexts/LoadingIndicatorContext';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from './components/utilities/AlertTemplate';
import { Router } from './Router';
import { WalkthroughContextElement } from './contexts/WalkthroughContext';
import { MenuContextElement } from './contexts/DrawerContext';
import { InstallPwaContextElement } from './contexts/InstallPwaContext';
import { FirebaseContextElement } from './contexts/FirebaseContext';

// NOTE: This is here to ensure that css from the index.html is picked up by tailwindcss when generating builds. Feel free to add more as necessary:
// flex flex-col min-h-screen

const alertOptions = {
	position: positions.TOP_CENTER,
	timeout: 5000,
	offset: '30px',
	transition: transitions.FADE,
};

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			<Auth0ProviderWithHistory>
				<AlertProvider template={AlertTemplate} {...alertOptions}>
					<UserContextElement>
						<ThemeContextElement>
							<MenuContextElement>
								<WalkthroughContextElement>
									<LoadingIndicatorContextElement>
										<InstallPwaContextElement>
											<FirebaseContextElement>
												<ChatContextElement>
													<RouterProvider router={Router} />
												</ChatContextElement>
											</FirebaseContextElement>
										</InstallPwaContextElement>
									</LoadingIndicatorContextElement>
								</WalkthroughContextElement>
							</MenuContextElement>
						</ThemeContextElement>
					</UserContextElement>
				</AlertProvider>
			</Auth0ProviderWithHistory>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
