import React, { useCallback, useEffect, useState } from 'react';
import {
	TalentEducationDto,
	OrganizationsService,
	OrganizationSummaryDto,
} from '../../api';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Box,
} from '@mui/material';
import { AutocompleteInput, TextFieldInput } from '../utilities/Input';
import { useForm } from 'react-hook-form';
import useApi from '../../services/useApi';
import { OrganizationModal } from '../Organizations/OrganizationModal';
import { v4 as uuidv4 } from 'uuid';
import { FaPlus } from 'react-icons/fa';
import { setValueAsUserInputOptions } from '../../services/helper';

type EducationModalEducation = Omit<
	TalentEducationDto,
	'id' | 'talentProfileId'
> & {
	educationalInstitution?: OrganizationSummaryDto;
};

type EducationModalProps = {
	id: string;
	talentProfileId: string;
	education: EducationModalEducation | null;
	visible: boolean;
	onSave: (education: TalentEducationDto) => void;
	onCancel: () => void;
};

const defaultValues: EducationModalEducation = {
	educationalInstitutionId: undefined!,
	educationalInstitution: undefined!,
	degree: undefined,
	major: undefined,
	startDate: undefined,
	endDate: undefined,
	description: undefined,
	deleted: false,
};

const EducationModal: React.FC<EducationModalProps> = ({
	id,
	talentProfileId,
	education,
	visible,
	onSave,
	onCancel,
}) => {
	const { control, setValue, handleSubmit, reset } = useForm({
		mode: 'onBlur',
		defaultValues: education ?? defaultValues,
		shouldFocusError: false,
	});
	const { callApi, emptyResponse } = useApi();

	useEffect(() => {
		if (education) {
			reset(education);
		} else {
			reset(defaultValues);
		}
	}, [education, reset]);

	const handleSave = (data: EducationModalEducation) => {
		onSave({
			...data,
			id,
			talentProfileId,
		});
	};

	const searchEducationalInstitutions = useCallback(
		async (query?: string) => {
			if (query) {
				const response = await callApi(
					OrganizationsService.searchOrganizations(query, true)
				);
				return response;
			} else {
				return emptyResponse;
			}
		},
		[callApi, emptyResponse]
	);

	const [
		addEducationalInstitutionModalOpen,
		setAddEducationalInstitutionModalOpen,
	] = useState(false);

	const handleAddEducationalInstitution = (
		newEducationalInstitution: OrganizationSummaryDto
	) => {
		setAddEducationalInstitutionModalOpen(false);
		setValue(
			'educationalInstitutionId',
			newEducationalInstitution.id,
			setValueAsUserInputOptions
		);
		setValue(
			'educationalInstitution',
			newEducationalInstitution,
			setValueAsUserInputOptions
		);
	};

	return (
		<>
			<Dialog open={visible} onClose={onCancel}>
				<DialogTitle>
					{education ? 'Edit Education' : 'Add Education'}
				</DialogTitle>
				<form onSubmit={handleSubmit(handleSave)}>
					<DialogContent>
						<TextFieldInput
							autoFocus
							margin="dense"
							name="degree"
							label="Degree"
							type="text"
							fullWidth
							control={control}
						/>
						<TextFieldInput
							margin="dense"
							name="major"
							label="Major"
							type="text"
							fullWidth
							control={control}
						/>
						<Box
							display="flex"
							className="gap-4 justify-between mb-4"
							alignItems="center">
							<AutocompleteInput
								className="flex-auto"
								name="educationalInstitution"
								valueName="educationalInstitutionId"
								control={control}
								label="Educational Institution"
								optionApiService={searchEducationalInstitutions}
								apiFiltering
								rules={{
									required: 'Educational Institution is required',
								}}
							/>
							<Button
								className="flex-none"
								variant="outlined"
								color="primary"
								onClick={() => setAddEducationalInstitutionModalOpen(true)}>
								<FaPlus className="ml-0 mr-2" /> Add Org
							</Button>
						</Box>
						<TextFieldInput
							margin="dense"
							name="startDate"
							label="Start Date"
							type="date"
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							control={control}
						/>
						<TextFieldInput
							margin="dense"
							name="endDate"
							label="End Date"
							type="date"
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							control={control}
						/>
						<TextFieldInput
							margin="dense"
							name="description"
							label="Description"
							type="text"
							fullWidth
							multiline
							rows={4}
							control={control}
						/>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={onCancel}>
							Cancel
						</Button>
						<Button variant="outlined" type="submit" color="primary">
							{education ? 'Update' : 'Add'}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
			<OrganizationModal
				id={uuidv4()}
				organization={null}
				forceIsEducationalInstiutionAs={true}
				open={addEducationalInstitutionModalOpen}
				onCancel={() => setAddEducationalInstitutionModalOpen(false)}
				onOrganizationAdded={handleAddEducationalInstitution}
			/>
		</>
	);
};

export default EducationModal;
