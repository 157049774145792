import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from '../auth/ProtectedRoute';
import ChooseOnboardingType from './ChooseOnboardingType';
import FullPageCard from '../components/utilities/FullPageCard';
import SimpleTalentWizard from './SimpleTalentWizard';
import SimpleBuyerWizard from './SimpleBuyerWizard';

export default function OnboardingWizard() {
	return (
		<FullPageCard className="grid">
			<Routes>
				<Route index element={<ChooseOnboardingType />} />
				<Route
					path="talent"
					element={<ProtectedRoute component={SimpleTalentWizard} />}
				/>
				<Route
					path="buyer"
					element={<ProtectedRoute component={SimpleBuyerWizard} />}
				/>
			</Routes>
		</FullPageCard>
	);
}
