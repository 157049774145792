import React from 'react';
import TextButton from '../utilities/TextButton';
import { useNavigate } from 'react-router-dom';
import { DedicatedTeamDto } from '../../api';

interface TeamSearchResultProps {
	result: DedicatedTeamDto;
}

const TeamSearchResult = (props: TeamSearchResultProps) => {
	const team = props.result;
	const navigateTo = useNavigate();
	return (
		<div className="flex h-full w-full items-center gap-4">
			<div className="flex items-center gap-4 h-full text-left w-full">
				<div className="flex flex-col h-full justify-center">
					<TextButton
						className="col-start-5 col-span-1"
						onClick={() => navigateTo(`/dedicated-team/${team.id}`)}>
						<h3 className="font-bold text-accent-400 mb-1">{team.name}</h3>
					</TextButton>
				</div>
			</div>
		</div>
	);
};

export default TeamSearchResult;
