import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';

export const RerouteOn: React.FC<{
	shouldRedirect: (userContext: any) => boolean;
	redirectLocation: string;
	component: any;
}> = ({ shouldRedirect, redirectLocation, component }) => {
	const location = useLocation();
	const Component = component;
	const userContext = useContext(UserContext);

	return shouldRedirect(userContext) ? (
		<Navigate to={redirectLocation} replace state={{ location }} />
	) : (
		Component
	);
};
