// Portfolio.tsx
import React, { useContext, useState } from 'react';
import PortfolioItemModal from './PortfolioItemModal';
import { PortfolioItemDto } from '../../api';
import { v4 as uuidv4 } from 'uuid';
import { UserContext } from '../../contexts/UserContext';
import {
	Box,
	Button,
	IconButton,
	ImageListItem,
	ImageListItemBar,
	Paper,
} from '@mui/material';
import PortfolioItemPreview from './PortfolioItemPreview';
import ContentList from '../utilities/ContentList';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import PortfolioItemDetails from './PortfolioItemDetails';

const Portfolio = (props: {
	portfolioItems: PortfolioItemDto[] | null;
	onSave: any;
	onDelete: any;
	isEditing?: boolean;
}) => {
	const { portfolioItems, onSave, onDelete, isEditing } = props;

	const { user: currentUser } = useContext(UserContext);

	// The modal for adding/editing a portfolio item
	const [showModal, setShowModal] = useState(false);
	const [portfolioItemToView, setPortfolioItemToView] =
		React.useState<PortfolioItemDto | null>(null);
	const [portfolioItemToEdit, setEditingPortfolio] =
		useState<PortfolioItemDto | null>(null);

	const handleEdit = (portfolioItem: PortfolioItemDto | null) => {
		setEditingPortfolio(portfolioItem);
		setShowModal(true);
	};

	const handleSave = (portfolioItem: PortfolioItemDto) => {
		onSave(portfolioItem);
		setShowModal(false);
	};

	const handleDelete = (portfolioItem: PortfolioItemDto) => {
		portfolioItem.deleted = true;
		onDelete(portfolioItem);
	};

	const itemsWithActions =
		portfolioItems
			?.filter((item) => !item.deleted)
			.map((item, index) => (
				<ImageListItem
					key={item.id}
					cols={(index % 2) + 1}
					className="group"
					onClick={!isEditing ? () => setPortfolioItemToView(item) : undefined}>
					<PortfolioItemPreview portfolioItem={item} />
					{isEditing ? (
						<ImageListItemBar
							className="group-hover:inline-flex hidden h-min-24"
							actionIcon={
								<Box className="flex justify-between">
									<IconButton
										className="text-gray-300"
										aria-label={`edit ${item.name}`}
										onClick={() => handleEdit(item)}>
										<FaEdit />
									</IconButton>
									<IconButton
										className="text-gray-300"
										aria-label={`delete ${item.name}`}
										onClick={() => handleDelete(item)}>
										<FaTrash />
									</IconButton>
								</Box>
							}
						/>
					) : null}
				</ImageListItem>
			)) ?? [];

	return (
		<Paper className="p-4">
			<ContentList
				items={itemsWithActions}
				additionalActions={
					isEditing ? (
						<Button
							variant="outlined"
							size="small"
							onClick={() => handleEdit(null)}>
							<FaPlus className="mr-2" />
							Add Item
						</Button>
					) : null
				}
			/>

			{portfolioItemToView ? (
				<PortfolioItemDetails
					portfolioItem={portfolioItemToView}
					visible={portfolioItemToView !== null}
					onClose={() => setPortfolioItemToView(null)}
				/>
			) : null}

			<PortfolioItemModal
				id={portfolioItemToEdit?.id ?? uuidv4()}
				talentProfileId={currentUser?.talentProfileId ?? ''}
				portfolioItem={portfolioItemToEdit}
				visible={showModal}
				onSave={handleSave}
				onCancel={() => setShowModal(false)}
			/>
		</Paper>
	);
};

Portfolio.defaultProps = {
	isEditing: false,
};

export default Portfolio;
