import { useContext, useEffect, useState, useCallback } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import useApi from '../../../services/useApi';
import { PaymentEventDto, PaymentService } from '../../../api';
import FullPageCard from '../../utilities/FullPageCard';
import { Permissions } from '../../../Constants/Permissions';
import { Tooltip } from '@mui/material';
import { useAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import {
	DataGrid,
	GridActionsCellItem,
	GridColDef,
	GridRowParams,
} from '@mui/x-data-grid';
import { Send } from '@mui/icons-material';

const PaymentEvents = () => {
	const { callApi } = useApi();
	const alert = useAlert();
	const { user, isLoading: isUserLoading } = useContext(UserContext);
	const [isLoading, setIsLoading] = useState(true);
	const [page, setPage] = useState(0);
	const [paymentEvents, setPaymentEvents] = useState<PaymentEventDto[]>([]);
	const [processing, setProcessing] = useState(false);

	const getPaymentEvents = useCallback(async () => {
		const response = await callApi(PaymentService.getPaymentEvents(page));
		if (response.data) {
			setPaymentEvents(response.data);
		}
		setIsLoading(false);
	}, [callApi, page]);

	useEffect(() => {
		if (!isUserLoading && user) {
			setIsLoading(true);
			getPaymentEvents();
		}
	}, [getPaymentEvents, isUserLoading, user]);

	const handleSendPaymentEvent = async (paymentEventId: string) => {
		if (!processing) {
			setProcessing(true);
			try {
				const response = await callApi(
					PaymentService.processPaymentEvent(paymentEventId)
				);
				if (!response.error) {
					alert.success('Successfully processed event!');
				} else {
					throw response.error;
				}
			} catch (error: unknown) {
				console.error(error);
			}
			setProcessing(false);
		} else {
			alert.info('Already processing an event.');
		}
	};

	if (isLoading) {
		return null;
	}

	const columns: GridColDef<PaymentEventDto>[] = [
		{
			field: 'id',
			headerName: 'Id',
			flex: 0.5,
		},
		{
			field: 'type',
			headerName: 'Type',
			flex: 0.5,
		},
		{
			field: 'receivedAt',
			headerName: 'Received At',
			flex: 0.5,
		},
		{
			field: 'retryAttempt',
			headerName: 'Retry Attempt',
			flex: 0.5,
		},
		{
			field: 'processedAt',
			headerName: 'Processed At',
			flex: 0.5,
		},
		{
			field: 'errorMessage',
			headerName: 'Error Message',
			flex: 0.5,
		},
		{
			field: 'data',
			headerName: 'Data',
			flex: 0.5,
		},
		{
			field: 'actions',
			headerName: 'Actions',
			type: 'actions',
			headerAlign: 'left',
			align: 'left',
			getActions: (params: GridRowParams<PaymentEventDto>) => {
				const actions = [
					<GridActionsCellItem
						icon={
							<Tooltip title="Send">
								<Send />
							</Tooltip>
						}
						label="Send"
						onClick={() => handleSendPaymentEvent(params.row.id)}
					/>,
				];
				return actions;
			},
		},
	];

	return (
		<FullPageCard className="w-full md:w-3/4 place-self-center items-center">
			<h1>Admin</h1>
			<p>With great power comes great responsibility!</p>
			<div>
				{user?.permissions.includes(Permissions.RESEND_STRIPE_EVENTS) ? (
					<span>
						<Link to="admin">Admin</Link>
					</span>
				) : null}
			</div>
			<br />
			<h2 className="pb-4">Payment Events</h2>
			<DataGrid
				rows={paymentEvents}
				columns={columns}
				autoHeight
				disableRowSelectionOnClick
				loading={isLoading}
				pagination
				paginationMode="server"
				paginationModel={{
					page: page,
					pageSize: 100,
				}}
				pageSizeOptions={[100]}
				rowCount={paymentEvents.length}
				onPaginationModelChange={(model) => {
					setPage(model.page);
				}}
			/>
		</FullPageCard>
	);
};

export default PaymentEvents;
