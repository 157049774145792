import React, {
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import Button from '@mui/material/Button';
import StickyActionsBar from '../utilities/StickyActionsBar';
import { UserContext } from '../../contexts/UserContext';
import {
	ContractDto,
	ContractMaterialDto,
	ContractMemberDto,
	ContractService,
	ContractState,
	TeamSummaryDto,
	ProjectService,
	ProjectState,
	ProjectSummaryDto,
	StoredFileDto,
	TalentProfileSummaryDto,
	TeamService,
	TeamState,
	UpsertContractCommand,
} from '../../api';
import useApi from '../../services/useApi';
import { useDebounce } from 'usehooks-ts';
import { parseISO } from 'date-fns';
import { differenceInBusinessDays } from 'date-fns';
import { formatCurrency, greaterThanZero } from '../../services/helper';
import {
	Grid,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	IconButton,
} from '@mui/material';
import {
	AutocompleteInput,
	DatePickerInput,
	SwitchInput,
	TextFieldInput,
} from '../utilities/Input';
import { v4 as uuidv4 } from 'uuid';
import { Clear as ClearIcon, Add as AddIcon } from '@mui/icons-material';
import { StateIndicator } from '../utilities/StateIndicator';
import Attachments from '../utilities/Attachments';
import {
	ActionDialog,
	ContractDialog,
	StateType,
} from '../../Constants/Dialog';
import UserSideNav from '../utilities/UserSideNav';

const defaultValues = {
	contractMembers: [] as ContractMemberDto[],
	contractMaterials: [] as ContractMaterialDto[],
	attachments: [] as StoredFileDto[],
} as ContractDto;

interface ContractProps {
	id?: string;
	defaultProject?: ProjectSummaryDto;
	children?: ReactNode;
}

const Contract = (props: ContractProps) => {
	const { id, defaultProject } = props;
	const { user, isLoading: isUserLoading } = useContext(UserContext);
	const [isEditing, setIsEditing] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const { callApi } = useApi();
	const [state, setState] = useState<ContractState>();
	const [isContractMember, setIsContractMember] = useState<boolean>(false);
	const [isProjectOwner, setIsProjectOwner] = useState<boolean>(false);

	const { register, handleSubmit, watch, setValue, reset, control, getValues } =
		useForm({
			mode: 'onBlur',
			defaultValues: defaultValues,
			shouldFocusError: false,
		});
	const { fields: contractMemberFields } = useFieldArray({
		control,
		name: 'contractMembers',
	});
	const {
		fields: contractMaterialFields,
		append: appendContractMaterial,
		remove: removeContractMaterial,
	} = useFieldArray({
		control,
		name: 'contractMaterials',
	});
	const [isLoading, setIsLoading] = useState(true);
	const watchAll = useDebounce(
		useWatch({ control, disabled: isLoading || !isEditing }),
		600
	);
	const [durationInWeeks, setDurationInWeeks] = useState<number | null>(null);
	const [totalTimePrice, setTotalTimePrice] = useState<number | null>(null);
	const [totalMaterialsPrice, setTotalMaterialsPrice] = useState<number | null>(
		null
	);

	// Using useWatch instead of watch which was causing an odd issue occasionally on page load where the duration calc wasn't being triggered
	const watchStartDate = useWatch({ control, name: 'startDate' });
	const watchEndDate = useWatch({ control, name: 'endDate' });
	const watchProjectOwner = useWatch({
		control,
		name: `project.ownerBuyerProfileId`,
	});
	// For some reason watch won't trigger on array's field updates but useWatch does
	const watchContractMembers = useWatch({ control, name: 'contractMembers' });
	const watchContractMaterials = useWatch({
		control,
		name: 'contractMaterials',
	});

	const handleProjectChange = useCallback(
		(result: ProjectSummaryDto) => {
			if (!result) {
				return;
			}
			// Pre-populate the start and end date from Project
			if (result.startDate) {
				setValue('startDate', result.startDate);
			}
			if (result.endDate) {
				setValue('endDate', result.endDate);
			}
		},
		[setValue]
	);

	useEffect(() => {
		const loadContract = async () => {
			const response = await callApi(ContractService.getContract(id!));
			if (response.data) {
				const contract = response.data;
				reset(contract);
				setState(contract.state);
				setIsContractMember(
					contract.contractMembers.some(
						(x: ContractMemberDto) =>
							x.talentProfileId === user?.talentProfileId
					)
				);
			} else {
				// Default project
				if (defaultProject) {
					setValue('project', defaultProject);
					setValue('projectId', defaultProject?.id);
					handleProjectChange(defaultProject);
				}
				// Team selection is already filtered by membership
				setIsContractMember(true);
				setIsEditing(true);
			}
			setIsLoading(false);
		};
		if (!isUserLoading && user) {
			setIsLoading(true);
			loadContract();
		}
	}, [
		id,
		isUserLoading,
		user,
		callApi,
		reset,
		defaultProject,
		setValue,
		handleProjectChange,
	]);

	const save = useCallback(
		async (data: UpsertContractCommand) => {
			setIsSaving(true);
			data.id = id!;
			const response = await callApi(ContractService.upsertContract(data));
			if (response.data) {
				setState(ContractState.DRAFT);
			}
			setIsSaving(false);
		},
		[id, callApi, setState]
	);

	useEffect(() => {
		if (isEditing && !isLoading) {
			handleSubmit(save)();
		}
	}, [watchAll, handleSubmit, save, isEditing, isLoading]);

	const searchProjects = useCallback(
		async (query?: string) => {
			const response = await callApi(ProjectService.searchProject(query));
			return response;
		},
		[callApi]
	);

	const getTeams = useCallback(async () => {
		const response = await callApi(TeamService.getUserDedicatedTeams());
		// Filter out non active teams
		if (response.data) {
			response.data =
				response.data?.filter((x) => x.state === TeamState.ACTIVE) ?? [];
		}
		return response;
	}, [callApi]);

	const calculateContractHours = useCallback(() => {
		// Calculate either the weekly hours or total hours for each contract member
		getValues('contractMembers')?.forEach(
			(member: ContractMemberDto, i: number) => {
				member.isHourInputTotal
					? setValue(
							`contractMembers.${i}.weeklyHours`,
							Number(
								(durationInWeeks
									? member.totalHours / durationInWeeks
									: 0
								).toFixed(2)
							)
					  )
					: setValue(
							`contractMembers.${i}.totalHours`,
							Number(
								(durationInWeeks
									? member.weeklyHours * durationInWeeks
									: 0
								).toFixed(2)
							)
					  );
			}
		);
	}, [durationInWeeks, getValues, setValue]);

	const handleTeamChange = useCallback(
		(result: TeamSummaryDto) => {
			if (!result) {
				return;
			}
			setValue(
				'contractMembers',
				result?.teamMembers?.map((x) => {
					const name = x.fullName.split(' ');
					return {
						talentProfile: {
							firstName: name[0],
							lastName: name[1],
						} as TalentProfileSummaryDto,
						contractId: id,
						hourlyRate: x.pay,
						weeklyHours: x.hours,
						role: x.role,
						talentProfileId: x.talentProfileId,
					} as ContractMemberDto;
				})
			);
			calculateContractHours();
		},
		[setValue, id, calculateContractHours]
	);

	useEffect(() => {
		let durationInWeeks = null;
		// Set the estimated price
		if (watchStartDate && watchEndDate) {
			const numBusinessDays =
				differenceInBusinessDays(
					parseISO(watchEndDate),
					parseISO(watchStartDate)
				) + 1; // Include the end date (assumes they work on the end date)
			// Assumes 5 day work week excluding weekends (function does not check for holidays)
			durationInWeeks = numBusinessDays / 5.0;
		}
		setDurationInWeeks(durationInWeeks);
		calculateContractHours();
	}, [watchStartDate, watchEndDate, calculateContractHours]);

	useEffect(() => {
		// Set the category totals and final total
		const totalTimePrice = watchContractMembers?.reduce(
			(sum: number, member: ContractMemberDto) =>
				(sum += member.hourlyRate * member.totalHours),
			0
		);
		const totalMaterialsPrice =
			watchContractMaterials?.reduce(
				(sum: number, material: ContractMaterialDto) =>
					(sum += material.quantity * material.pricePerUnit),
				0
			) ?? 0;
		setTotalTimePrice(totalTimePrice);
		setTotalMaterialsPrice(totalMaterialsPrice);
		setValue('totalPrice', totalTimePrice + totalMaterialsPrice);
	}, [watchContractMembers, watchContractMaterials, setValue]);

	useEffect(() => {
		setIsProjectOwner(watchProjectOwner === user?.buyerProfileId);
	}, [watchProjectOwner, setIsProjectOwner, user?.buyerProfileId]);

	const handleStateChange = async (state: ContractState, dialog: string) => {
		// TODO: Change to a modal
		if (window.confirm(dialog) === true) {
			const response = await callApi(
				ContractService.updateContractState({
					id: id!,
					state: state,
				})
			);
			if (response.data) {
				setState(state);
				setIsEditing(false);
			}
		}
	};

	return (
		<UserSideNav>
			<StateIndicator
				show={true}
				displayState={isSaving ? 'Saving' : state ?? 'Unsaved'}
				stateType={StateType.Contract}
				isBuyer={isProjectOwner}
			/>
			<Grid container spacing={{ xs: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
				<Grid item xs={4} md={6}>
					<AutocompleteInput
						name="project"
						valueName="projectId"
						control={control}
						label="Project"
						readOnly={!isEditing}
						optionApiService={searchProjects}
						onChange={handleProjectChange}
						apiFiltering
						required
					/>
				</Grid>
				<Grid item xs={4} md={6}>
					<AutocompleteInput
						name="team"
						valueName="teamId"
						control={control}
						label="Team"
						readOnly={!isEditing}
						optionApiService={getTeams}
						onChange={handleTeamChange}
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<TextFieldInput
						name="description"
						control={control}
						label="Description"
						readOnly={!isEditing}
						multiline
						maxRows={4}
						fullWidth
					/>
				</Grid>
				<Grid item xs={4} md={3}>
					<DatePickerInput
						label="Estimated Start Date"
						name="startDate"
						rules={{ deps: 'endDate' }}
						control={control}
						required
						maxDate={watchEndDate}
						readOnly={!isEditing}
					/>
				</Grid>
				<Grid item xs={4} md={3}>
					<DatePickerInput
						label="Estimated End Date"
						name="endDate"
						rules={{ deps: 'startDate' }}
						control={control}
						required
						minDate={watchStartDate}
						readOnly={!isEditing}
					/>
				</Grid>
				<Grid item xs={4} md={6}></Grid>
				<Grid item xs={4} md={3}>
					<Stack>
						<h4>Duration In Weeks</h4>
						<div>{durationInWeeks ?? 'N/A'}</div>
					</Stack>
				</Grid>
				<Grid item xs={4} md={3}>
					<Stack>
						<h4>Total Estimated Price</h4>
						<div>{formatCurrency(watch('totalPrice'))}</div>
					</Stack>
				</Grid>
			</Grid>
			<h3 className="mb-4 pt-4">Time</h3>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Role</TableCell>
							<TableCell>Note</TableCell>
							<TableCell>Rate Per Hour</TableCell>
							<TableCell>Week / Total</TableCell>
							<TableCell>Average Hours Per Week</TableCell>
							<TableCell>Total Hours</TableCell>
							<TableCell align="right">Total Price</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{contractMemberFields.map(
							(x: ContractMemberDto & { id: string }, i) => (
								<TableRow key={x.id}>
									<TableCell>
										{x.talentProfile.firstName + ' ' + x.talentProfile.lastName}
									</TableCell>
									<TableCell>
										<TextFieldInput
											name={`contractMembers.${i}.role`}
											control={control}
											size="small"
											readOnly={!isEditing}
											required
										/>
									</TableCell>
									<TableCell>
										<TextFieldInput
											name={`contractMembers.${i}.note`}
											control={control}
											readOnly={!isEditing}
											size="small"
											multiline
											maxRows={4}
										/>
									</TableCell>
									<TableCell>
										<TextFieldInput
											name={`contractMembers.${i}.hourlyRate`}
											rules={{ validate: { greaterThanZero } }}
											control={control}
											size="small"
											prefix="$"
											suffix="/hr"
											type="number"
											min="0.01"
											step="0.01"
											readOnly={!isEditing}
											required
										/>
									</TableCell>
									<TableCell>
										<SwitchInput
											name={`contractMembers.${i}.isHourInputTotal`}
											control={control}
											readOnly={!isEditing}
										/>
									</TableCell>
									<TableCell>
										<TextFieldInput
											name={`contractMembers.${i}.weeklyHours`}
											onChange={calculateContractHours}
											rules={{ validate: { greaterThanZero } }}
											control={control}
											size="small"
											type="number"
											required
											readOnly={!isEditing}
											disabled={
												isEditing &&
												watch(`contractMembers.${i}.isHourInputTotal`)
											}
										/>
									</TableCell>
									<TableCell>
										<TextFieldInput
											name={`contractMembers.${i}.totalHours`}
											onChange={calculateContractHours}
											rules={{ validate: { greaterThanZero } }}
											control={control}
											size="small"
											type="number"
											required
											readOnly={!isEditing}
											disabled={
												isEditing &&
												!watch(`contractMembers.${i}.isHourInputTotal`)
											}
										/>
									</TableCell>
									<TableCell align="right">
										{formatCurrency(
											watch(`contractMembers.${i}.hourlyRate`) *
												watch(`contractMembers.${i}.totalHours`)
										)}
									</TableCell>
								</TableRow>
							)
						)}
						<TableRow>
							<TableCell colSpan={6} />
							<TableCell>Total</TableCell>
							<TableCell align="right">
								{formatCurrency(totalTimePrice)}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<div className="pt-4"></div>
			<h3 className="mb-4 pt-4">Materials</h3>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Description</TableCell>
							<TableCell>Quantity</TableCell>
							<TableCell>Price Per Unit</TableCell>
							<TableCell align="right">Total Price</TableCell>
							{isEditing && <TableCell>Actions</TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{contractMaterialFields.map((x: ContractMaterialDto, i) => (
							<TableRow key={x.id}>
								<TableCell>
									<TextFieldInput
										name={`contractMaterials.${i}.description`}
										control={control}
										size="small"
										readOnly={!isEditing}
										required
										multiline
										maxRows={4}
									/>
								</TableCell>
								<TableCell>
									<TextFieldInput
										name={`contractMaterials.${i}.quantity`}
										rules={{
											validate: { greaterThanZero },
										}}
										type="number"
										control={control}
										readOnly={!isEditing}
										size="small"
										required
									/>
								</TableCell>
								<TableCell>
									<TextFieldInput
										name={`contractMaterials.${i}.pricePerUnit`}
										rules={{
											validate: { greaterThanZero },
										}}
										control={control}
										size="small"
										prefix="$"
										suffix="/unit"
										type="number"
										min="0.01"
										step="0.01"
										readOnly={!isEditing}
										required
									/>
								</TableCell>
								<TableCell align="right">
									{formatCurrency(
										watch(`contractMaterials.${i}.quantity`) *
											watch(`contractMaterials.${i}.pricePerUnit`)
									)}
								</TableCell>
								{isEditing && (
									<TableCell>
										<IconButton
											color="error"
											onClick={() => removeContractMaterial(i)}>
											<ClearIcon />
										</IconButton>
									</TableCell>
								)}
							</TableRow>
						))}
						<TableRow>
							<TableCell colSpan={2}>
								{isEditing && (
									<Button
										variant="outlined"
										startIcon={<AddIcon />}
										onClick={() =>
											appendContractMaterial({
												id: uuidv4(),
												quantity: 1,
											})
										}>
										Add Material
									</Button>
								)}
							</TableCell>
							<TableCell>Total</TableCell>
							<TableCell align="right">
								{formatCurrency(totalMaterialsPrice)}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<div className="pt-4"></div>
			<Attachments
				control={control}
				register={register}
				isEditing={isEditing}
			/>
			<div className="pt-4"></div>
			<StickyActionsBar className="z-10">
				{isContractMember && state === ContractState.DRAFT && (
					<Button
						variant="contained"
						onClick={() =>
							handleStateChange(ContractState.DRAFT, ActionDialog.DeleteDraft)
						}>
						Delete Draft
					</Button>
				)}
				{isContractMember &&
					watch(`project.state`) === ProjectState.BIDDING &&
					(state === ContractState.DRAFT ||
						state === ContractState.REJECTED ||
						state === ContractState.WITHDRAWN) && (
						<Button
							variant="contained"
							onClick={() => setIsEditing(!isEditing)}>
							{isEditing ? 'Preview' : 'Edit'}
						</Button>
					)}
				{isContractMember &&
					watch(`project.state`) === ProjectState.BIDDING &&
					state === ContractState.DRAFT && (
						<Button
							variant="contained"
							onClick={() =>
								handleStateChange(
									ContractState.PENDING,
									ContractDialog.SubmitBid
								)
							}>
							Submit Bid
						</Button>
					)}
				{isContractMember && state === ContractState.PENDING && (
					<Button
						variant="contained"
						onClick={() =>
							handleStateChange(
								ContractState.WITHDRAWN,
								ContractDialog.WithdrawBid
							)
						}>
						Withdraw Bid
					</Button>
				)}
				{isContractMember &&
					state === ContractState.ACTIVE && [
						<Button
							variant="contained"
							onClick={() =>
								handleStateChange(
									ContractState.COMPLETION_REQUESTED,
									ContractDialog.RequestCompletion
								)
							}>
							Request Contract Completion
						</Button>,
						<Button
							variant="contained"
							onClick={() =>
								handleStateChange(
									ContractState.TALENT_ENDED,
									ContractDialog.EndContract
								)
							}>
							{isProjectOwner ? 'End Contract as Talent' : 'End Contract'}
						</Button>,
					]}
				{isProjectOwner &&
					state === ContractState.PENDING && [
						<Button
							variant="contained"
							onClick={() =>
								handleStateChange(
									ContractState.REJECTED,
									ContractDialog.RejectBid
								)
							}>
							Reject Bid
						</Button>,
						<Button
							variant="contained"
							onClick={() =>
								handleStateChange(
									ContractState.ACTIVE,
									ContractDialog.AcceptBid
								)
							}>
							Accept Bid
						</Button>,
					]}
				{isProjectOwner &&
					(state === ContractState.ACTIVE ||
						state === ContractState.COMPLETION_REQUESTED) && (
						<Button
							variant="contained"
							onClick={() =>
								handleStateChange(
									ContractState.COMPLETED,
									ContractDialog.CompleteContract
								)
							}>
							Complete Contract
						</Button>
					)}
				{isProjectOwner && state === ContractState.ACTIVE && (
					<Button
						variant="contained"
						onClick={() =>
							handleStateChange(
								ContractState.BUYER_ENDED,
								ContractDialog.EndContract
							)
						}>
						{isContractMember ? 'End Contract as Buyer' : 'End Contract'}
					</Button>
				)}
			</StickyActionsBar>
		</UserSideNav>
	);
};

export default Contract;
