/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BuyerPaymentDto } from '../models/BuyerPaymentDto';
import type { CancelSuccessRouteDto } from '../models/CancelSuccessRouteDto';
import type { InvoiceDto } from '../models/InvoiceDto';
import type { InvoicePermissionDto } from '../models/InvoicePermissionDto';
import type { InvoiceSummaryDto } from '../models/InvoiceSummaryDto';
import type { PaymentEventDto } from '../models/PaymentEventDto';
import type { RefreshReturnRouteDto } from '../models/RefreshReturnRouteDto';
import type { TalentPaymentDto } from '../models/TalentPaymentDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentService {

	/**
	 * Retrieves invoice by Id
	 * Returns one invoice matching the given Id.
	 * @param id
	 * @returns InvoicePermissionDto Success
	 * @throws ApiError
	 */
	public static getInvoice(
		id: string,
	): CancelablePromise<InvoicePermissionDto> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Payment/Invoice/{id}',
			path: {
				'id': id,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Creates a new invoice
	 * Creates a new invoice.
	 * @param requestBody
	 * @returns InvoiceDto Success
	 * @throws ApiError
	 */
	public static upsertInvoice(
		requestBody: InvoiceDto,
	): CancelablePromise<InvoiceDto> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/Payment/Invoice',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Initiates the process for a buyer's invoice payment
	 * @param invoiceId
	 * @param requestBody
	 * @returns string Success
	 * @throws ApiError
	 */
	public static initiateBuyerPayment(
		invoiceId: string,
		requestBody: CancelSuccessRouteDto,
	): CancelablePromise<string> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/Payment/Invoice/{invoiceId}/InitiateBuyerPayment',
			path: {
				'invoiceId': invoiceId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Initiates the process for a talent's onboarding for payments
	 * @param requestBody
	 * @returns string Success
	 * @throws ApiError
	 */
	public static initiateTalentOnboarding(
		requestBody: RefreshReturnRouteDto,
	): CancelablePromise<string> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/Payment/InitiateTalentOnboarding',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Creates a login link for the user's Stripe Account
	 * @returns string Success
	 * @throws ApiError
	 */
	public static stripeLogin(): CancelablePromise<string> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Payment/StripeLogin',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Retrieve Payment Events
	 * @param page
	 * @returns PaymentEventDto Success
	 * @throws ApiError
	 */
	public static getPaymentEvents(
		page: number,
	): CancelablePromise<Array<PaymentEventDto>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Payment/PaymentEvents/{page}',
			path: {
				'page': page,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Process Payment Event
	 * @param eventId
	 * @returns any Success
	 * @throws ApiError
	 */
	public static processPaymentEvent(
		eventId: string,
	): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/Payment/PaymentEvents/{eventId}',
			path: {
				'eventId': eventId,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Retrieves invoices by talent membership or project ownership
	 * @returns InvoiceSummaryDto Success
	 * @throws ApiError
	 */
	public static getUserInvoices(): CancelablePromise<Array<InvoiceSummaryDto>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Payment/UserInvoices',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Retrieves users's talent payments
	 * @returns TalentPaymentDto Success
	 * @throws ApiError
	 */
	public static getUserTalentPayments(): CancelablePromise<Array<TalentPaymentDto>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Payment/UserTalentPayments',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Retrieves user's buyer payments
	 * @returns BuyerPaymentDto Success
	 * @throws ApiError
	 */
	public static getUserBuyerPayments(): CancelablePromise<Array<BuyerPaymentDto>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Payment/UserBuyerPayments',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

}
