import IconButton from '@mui/material/IconButton';
import React from 'react';
import { useForm } from 'react-hook-form';
import {
	createSearchParams,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import { PostalCodeDto, SearchType, TagDto, TagService } from '../../api';
import Button from '../utilities/Button';
import { MultiSelectLookup, Select } from '../utilities/FloatingLabelInput';
import { Search as SearchIcon } from '@mui/icons-material';
import { TextFieldInput } from '../utilities/Input';
import { InputAdornment } from '@mui/material';
import PostalCodeLookup from '../utilities/PostalCodeLookup';

const SearchBar = (props: { advanced?: boolean }) => {
	const { advanced = false } = props;
	const { filter } = useParams();
	const [searchParams] = useSearchParams();
	const { register, watch, setValue, handleSubmit, control } = useForm(
		advanced
			? {
					defaultValues: {
						query:
							searchParams.get('query') === 'null' ||
							searchParams.get('query') === 'undefined' ||
							searchParams.get('query') === null ||
							searchParams.get('query') === undefined
								? ''
								: searchParams.get('query'),
						skills: searchParams.getAll('skills'),
						type: filter || SearchType.ALL,
						postalCode: searchParams.get('postalCode'),
					},
			  }
			: { defaultValues: { type: SearchType.ALL, query: '' as string | null } }
	);
	const navigateTo = useNavigate();
	const watchSkills = watch('skills');
	const watchPostalCode = watch('postalCode');

	const executeSearch = (searchFormData: any) => {
		const { type = filter, skills, postalCode, ...query } = searchFormData;
		const searchParams = {
			...query,
			...(skills ? { skills } : {}),
			...(postalCode ? { postalCode } : {}),
		};
		navigateTo({
			pathname: `/search/${type ?? SearchType.ALL}`,
			search: createSearchParams(searchParams).toString(),
		});
	};

	const onSkillSelection = (event: any, skillSelection: TagDto | null) => {
		if (skillSelection) {
			setValue('skills', [...watchSkills!, skillSelection.name]);
		}
	};

	const onRemoveSkill = (event: any, skillToRemove: string) => {
		console.log(event, skillToRemove);
		setValue(
			'skills',
			watchSkills!.filter((s: any) => s !== skillToRemove)
		);
	};

	const onPostalCodeChange = (newValue: PostalCodeDto | null) => {
		setValue('postalCode', newValue?.code);
	};

	return (
		<form
			className="flex flex-row flex-auto flex-wrap gapx-2 justify-between w-full"
			onSubmit={handleSubmit(executeSearch)}>
			<div
				className={`flex flex-auto basis-3/4 relative md:pr-2 ${
					advanced ? 'mb-5' : ''
				}`}>
				<TextFieldInput
					className="flex-auto"
					name="query"
					control={control}
					type="text"
					id="header-search"
					placeholder="Search by name"
					size="small"
					noErrorMessage
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<IconButton type="submit" edge="start">
									<SearchIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</div>
			{advanced ? (
				<React.Fragment>
					<Select
						className={'flex-none w-24'}
						{...register('type', {
							value: filter as SearchType,
						})}
						defaultValue={SearchType.ALL}
						label="Search Type"
						options={[
							{ id: SearchType.ALL, name: 'All' },
							{ id: SearchType.PROJECT, name: 'Projects' },
							{ id: SearchType.TALENT, name: 'Talent Profiles' },
							{ id: SearchType.TEAM, name: 'Teams' },
							{ id: SearchType.BUYER, name: 'Buyer Profiles' },
							{ id: SearchType.USER, name: 'Users' },
						]}
					/>
					<MultiSelectLookup
						name="relatedSkills"
						searchService={TagService.searchBasicTags}
						label="Tags"
						placeholder="Tag Search"
						optionMapper={(o: TagDto | string | null) =>
							typeof o === 'string' ? o : o?.name
						}
						valueMapper={(o: TagDto | string | null) =>
							typeof o === 'string' ? o : o?.name
						}
						isEditing={true}
						onChange={onSkillSelection}
						onRemove={onRemoveSkill}
						values={watchSkills}
						className="w-3/4 flex-auto my-0 border-b-2 border-gray-300 dark:border-gray-600 md:ml-2"
						lookupClassName="px-2"
					/>
					<div className="basis-64">
						<PostalCodeLookup
							defaultValue={watchPostalCode ?? null}
							onChange={onPostalCodeChange}
						/>
					</div>
					<Button
						type="submit"
						onClick={handleSubmit(executeSearch)}
						className="flex-none h-8 place-self-center">
						Search
					</Button>
				</React.Fragment>
			) : null}
		</form>
	);
};

export default SearchBar;
