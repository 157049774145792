/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TeamState {
	DRAFT = 'Draft',
	PENDING = 'Pending',
	ACTIVE = 'Active',
	REMOVED = 'Removed',
	INACTIVE = 'Inactive',
}
