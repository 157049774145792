import { useEffect, useState } from 'react';
import {
	FaEnvelope,
	FaFacebookF,
	FaInstagram,
	FaPhone,
	FaTiktok,
	FaTwitter,
	FaYoutube,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { MiniThemeController } from './contexts/ThemeContext';
import nKipoDark from './images/nKipo-black-bgtransparent.png';
import nKipoLight from './images/nKipo-white-bgtransparent.png';
import { HealthStatus } from './api/models/HealthStatus';
import { HealthService } from './api/services/HealthService';
import useApi from './services/useApi';
import ExternalLink from './components/utilities/ExternalLink';

function Footer() {
	const [isHealthy, setIsHealthy] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const { callApi } = useApi();

	useEffect(() => {
		const load = async () => {
			setIsLoading(true);
			const response = await callApi(HealthService.get());
			setIsHealthy(response.data === HealthStatus.HEALTHY);
			setIsLoading(false);
		};
		load();
	}, [callApi]);

	return (
		<footer className="text-center lg:text-left bg-gray-100 text-gray-600 dark:bg-gray-800 dark:text-gray-100 mt-auto">
			<div className="flex justify-center items-center lg:justify-between p-6 border-b border-gray-300">
				<div className="mr-12 hidden lg:block">
					<span>Connect with us on socials:</span>
				</div>
				<div className="flex justify-center">
					<ExternalLink
						href="https://instagram.com/nkipoworks?igshid=YmMyMTA2M2Y="
						className="mr-6 text-gray-600 dark:text-gray-100">
						<FaInstagram className="w-4.5" />
					</ExternalLink>
					<ExternalLink
						href="https://www.tiktok.com/@nkipoworks"
						className="mr-6 text-gray-600 dark:text-gray-100">
						<FaTiktok className="w-4.5" />
					</ExternalLink>
					<ExternalLink
						href="https://twitter.com/nkipoworks"
						className="mr-6 text-gray-600 dark:text-gray-100">
						<FaTwitter className="w-4.5" />
					</ExternalLink>
					<ExternalLink
						href="https://youtube.com/@nkipo"
						className="mr-6 text-gray-600 dark:text-gray-100">
						<FaYoutube className="w-4.5" />
					</ExternalLink>
					<ExternalLink
						href="https://www.facebook.com/profile.php?id=100083437624720&mibextid=LQQJ4d"
						className="mr-6 text-gray-600 dark:text-gray-100">
						<FaFacebookF className="w-2.5" />
					</ExternalLink>
				</div>
			</div>
			<div className="mx-6 py-4 text-center md:text-left">
				<div className="grid grid-1 xs:grid-cols-1 md:grid-cols-3 gap-8">
					<div className="flex flex-col items-center">
						<h6 className="uppercase font-semibold mb-2 flex justify-center md:justify-start">
							Contact
						</h6>
						<p className="flex items-center justify-center md:justify-start dark:text-accent-default">
							<FaEnvelope className="w-4 mr-4 text-accent-default" />
							<a
								className="text-accent-default"
								href="mailto:contact-us@nkipo.com">
								contact-us@nkipo.com
							</a>
						</p>
						<p className="flex items-center justify-center md:justify-start">
							<FaPhone className="w-4 mr-4" />
							<a href="tel:+018323749250">(832) 374-9250</a>
						</p>
					</div>
					<div className="flex flex-col items-center">
						<h6
							className="
								uppercase
								font-semibold
								mb-2
								flex
								items-center
								justify-center
								md:justify-start
							">
							<img src={nKipoLight} alt="" className="w-20 hidden dark:block" />
							<img src={nKipoDark} alt="" className="w-20 block dark:hidden" />
						</h6>
						<div>
							<p className="text-center text-accent-default">
								Work <i>for</i> yourself, not <i>by</i> yourself.
							</p>
							<p className="text-center">Join the movement.</p>
						</div>
					</div>
					<div className="flex flex-col items-center">
						<h6 className="uppercase font-semibold mb-2 flex justify-center md:justify-start">
							Misc.
						</h6>
						<p className="text-accent-default">
							{isLoading && <span>Checking server...</span>}
							{!isLoading && (
								<span>
									Server is {isHealthy && 'HEALTHY'}
									{!isHealthy && 'UNHEALTHY'}
								</span>
							)}
						</p>
						<MiniThemeController />
					</div>
				</div>
			</div>
			<div className="text-center p-6 bg-gray-200 dark:bg-gray-900">
				<span>Copyright © {new Date().getFullYear()}</span>{' '}
				<Link to="/" className="text-gray-600 dark:text-gray-100 font-semibold">
					nKipo
				</Link>
			</div>
		</footer>
	);
}

export default Footer;
