import {
	createContext,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import Joyride, { Step, CallBackProps, EVENTS } from 'react-joyride';
import { UserContext } from './UserContext';
import {
	getDefaultNavList,
	SideAccessEnum,
} from '../components/utilities/UserSideNav';
import MenuContext from './DrawerContext';
import { UserService } from '../api';
import useApi from '../services/useApi';
import ThemeContext from './ThemeContext';
import {
	stateSteps,
	additionalWalkthroughSteps,
} from '../components/Team/DedicatedTeamComponent';

type PageWalkthrough = {
	completed: boolean;
	steps: Step[];
	callback?: (data: CallBackProps, additionalArgs: any[]) => void;
};

export const WalkthroughContext = createContext<{
	isLoading: boolean;
	walkthroughs: { gettingStarted: PageWalkthrough };
	getWalkthrough: (
		walkthrough: 'gettingStarted' | 'dedicatedTeam',
		overrides?: any
	) => JSX.Element | null;
}>({
	isLoading: true,
	walkthroughs: {
		gettingStarted: {
			completed: true,
			steps: [],
		},
	},
	getWalkthrough: () => null,
});

export const WalkthroughContextElement = ({
	children,
}: PropsWithChildren<any>) => {
	const { user, setSessionStorageUserData } = useContext(UserContext);
	const { callApi } = useApi();
	const { setIsAccountMenuOpen } = useContext(MenuContext);
	const { colorGetter } = useContext(ThemeContext);
	const [isLoading, setIsLoading] = useState(true);
	const [walkthroughs, setWalkthroughs] = useState({
		gettingStarted: {
			completed: true,
			steps: [] as Step[],
			callback: (data: CallBackProps, ...additionalArgs: any[]) => {
				const { type, step } = data;
				const [createUserDto] = additionalArgs;
				if (step.target === '.account-button' && EVENTS.TOOLTIP === type) {
					setIsAccountMenuOpen(true);
				}
				if (EVENTS.TOUR_END === type) {
					setIsAccountMenuOpen(false);
					callApi(
						UserService.createUser({
							...createUserDto,
							hasCompletedGettingStartedWalkthrough: true,
						})
					).then((response) => {
						if (response.data) {
							setSessionStorageUserData(response.data);
						}
					});
				}
			},
		},
		dedicatedTeam: {
			completed: true,
			steps: [] as Step[],
			callback: (data: CallBackProps, ...additionalArgs: any[]) => {
				const { type } = data;
				const [createUserDto] = additionalArgs;
				if (EVENTS.TOUR_END === type) {
					callApi(
						UserService.createUser({
							...createUserDto,
							hasCompletedDedicatedTeamWalkthrough: true,
						})
					).then((response) => {
						if (response.data) {
							setSessionStorageUserData(response.data);
						}
					});
				}
			},
		},
	});

	useEffect(() => {
		if (user && (user.talentProfileId || user.buyerProfileId)) {
			if (!user.hasCompletedGettingStartedWalkthrough) {
				const sideNavSteps = getDefaultNavList(user)
					.filter((navItem) => {
						const accessLevel =
							navItem.walkthroughMeta?.accessLevelOverride ??
							navItem.accessLevel;
						return (
							navItem.walkthroughStep &&
							(accessLevel === SideAccessEnum.BuyerOrTalent ||
								(accessLevel === SideAccessEnum.BuyerOnly &&
									user.buyerProfileId) ||
								(accessLevel === SideAccessEnum.TalentOnly &&
									user.talentProfileId) ||
								(accessLevel === SideAccessEnum.BuyerAndTalent &&
									user.talentProfileId &&
									user.buyerProfileId))
						);
					})
					.sort(
						(a, b) =>
							(a.walkthroughMeta?.order ?? 0) - (b.walkthroughMeta?.order ?? 0)
					)
					.map((navItem) => navItem.walkthroughStep!);
				const gettingStartedSteps: Step[] = [
					{
						placement: 'bottom',
						target: '.account-button',
						content: (
							<div>
								Welcome to nKipo!
								<br />
								<br />
								Click here to access your account settings, log out, or find
								help.
							</div>
						),
					},
				];
				// Insert side nav steps before the last step
				gettingStartedSteps.splice(1, 0, ...sideNavSteps);
				gettingStartedSteps[0].disableBeacon = true;
				setWalkthroughs((currentWalkthroughs) => ({
					...currentWalkthroughs,
					gettingStarted: {
						...currentWalkthroughs.gettingStarted,
						completed: user.hasCompletedNavWalkthrough,
						steps: gettingStartedSteps,
					},
				}));
			}
			if (!user.hasCompletedDedicatedTeamWalkthrough) {
				const dedicatedTeamSteps: Step[] = [
					{
						placement: 'center',
						target: 'body',
						content: (
							<div>
								At nKipo, we believe that teams are the fundamental unit of
								productivity for projects and the future of work.
								<br />
								<br />
								This guide will describe the 3 basic stages of creating a team
								so that you can publish your team and start working <i>
									for
								</i>{' '}
								yourself, not <i>by</i> yourself.
							</div>
						),
					},
					{
						placement: 'center',
						target: 'body',
						content: (
							<div>
								There's no limit to the number of teams you can create. Once
								published, nKipo will <i>actively</i> work to find you projects!
							</div>
						),
					},
				];

				const joyrideStateSteps: Step[] = Object.entries(stateSteps).map(
					([stepState, step]) => ({
						placement: 'bottom',
						target: `.${step.target}`,
						content: (
							<div>
								<b>'{stepState}' Stage of team creation:</b>
								<br />
								{step.description}
							</div>
						),
					})
				);

				dedicatedTeamSteps.splice(
					1,
					0,
					...joyrideStateSteps.concat(additionalWalkthroughSteps)
				);

				setWalkthroughs((currentWalkthroughs) => ({
					...currentWalkthroughs,
					dedicatedTeam: {
						...currentWalkthroughs.dedicatedTeam,
						completed: user.hasCompletedDedicatedTeamWalkthrough,
						steps: dedicatedTeamSteps,
					},
				}));
			}
			setIsLoading(false);
		} else {
			setIsLoading(true);
		}
	}, [user]);

	const getWalkthrough = useCallback(
		(walkthrough: 'gettingStarted' | 'dedicatedTeam', overrides?: any) => {
			const flags = {
				gettingStarted: 'hasCompletedGettingStartedWalkthrough',
				dedicatedTeam: 'hasCompletedDedicatedTeamWalkthrough',
			};
			const hasCompletedWalkthrough = user?.[flags[walkthrough]];

			return !hasCompletedWalkthrough ? (
				<Joyride
					callback={(data: CallBackProps) =>
						walkthroughs[walkthrough].callback?.(data, {
							firstName: user!.firstName,
							lastName: user!.lastName,
							countryCode: user!.countryCode,
							postalCode: user!.postalCode,
							hiatusStart: user!.hiatusStart,
							hiatusEnd: user!.hiatusEnd,
							hasCompletedGettingStartedWalkthrough:
								user!.hasCompletedGettingStartedWalkthrough,
							referrerId: user!.referrerId,
						})
					}
					steps={walkthroughs[walkthrough].steps}
					showProgress={true}
					showSkipButton={true}
					continuous={true}
					run={walkthroughs[walkthrough].completed ? false : true}
					styles={{
						options: {
							// arrowColor: '#888',
							// backgroundColor: '#888',
							// beaconSize: 36,
							// overlayColor: 'rgba(0, 0, 0, 0.5)',
							primaryColor: colorGetter('nKipo'),
							// spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
							// textColor: '#FFF',
							// width: undefined,
							zIndex: 1400,
						},
					}}
					{...overrides}
				/>
			) : null;
		},
		[colorGetter, user, walkthroughs]
	);

	return (
		<WalkthroughContext.Provider
			value={{ isLoading, walkthroughs, getWalkthrough }}>
			{children}
		</WalkthroughContext.Provider>
	);
};
