import { Stack } from '@mui/material';
import React, { Children } from 'react';

const StickyActionsBar = (props: any) => {
	const renderedChildrenCount = Children.toArray(props.children).length;
	/** Floating Actions Bar that sticks to the bottom of the page and allows users to take actions on current page's content */
	return renderedChildrenCount ? (
		<div
			className={
				'sticky bottom-0 px-4 py-2 w-full bg-gray-300 dark:bg-gray-800 text-gray-500 dark:text-gray-400 ' +
				(props.className ? props.className : '')
			}>
			<Stack
				direction={{ xs: 'column', sm: 'row' }}
				justifyContent="space-between"
				alignItems="center"
				spacing={1}>
				{props.children}
			</Stack>
		</div>
	) : null;
};

export default StickyActionsBar;
