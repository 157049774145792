/* Modal used for editing or adding a new team member */

import Modal from './Modal';
import StickyActionsBar from './StickyActionsBar';
import Button from '@mui/material/Button';
import { Input, Lookup } from './FloatingLabelInput';
import { useForm } from 'react-hook-form';
import { useApi } from '../../services/useApi';
import { useCallback, useEffect, useState } from 'react';
import {
	SearchType,
	TalentProfileSummaryDto,
	TeamMemberDto,
	TeamMemberState,
	UserService,
} from '../../api';
import { useDebounce } from 'usehooks-ts';
import { TalentLookupOption } from './TalentLookupOption';
import TextButton from './TextButton';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { setValueAsUserInputOptions } from '../../services/helper';

const TeamMemberModal = (props: any) => {
	const {
		member = {} as TeamMemberDto,
		teamId,
		simplified,
		onComplete,
		onClose,
		title,
		children,
		className,
		innerClassName,
		...rest
	} = props;
	const {
		register,
		setValue,
		watch,
		handleSubmit,
		formState: { errors },
	} = useForm<TeamMemberDto>({
		defaultValues: member,
	});
	const { callApi } = useApi();
	const navigateTo = useNavigate();
	const watchRole = watch('role');
	const watchPay = watch('pay');
	const watchHours = watch('hours');
	const [query, setQueryValue] = useState<string | null>(null);
	const debouncedQuery = useDebounce<string | null>(query, 600);

	const onSubmit = (data: TeamMemberDto) => {
		data.teamId = teamId;
		data.preferredName = data.preferredName ?? data.fullName.split(' ')[0];
		data.inviteState = data.inviteState ?? TeamMemberState.DRAFT;
		onComplete(data, member);
		onClose();
	};

	const [talentProfileSearchResults, setTalentProfileSearchResults] = useState<
		TalentProfileSummaryDto[]
	>([]);
	const searchTalentProfiles = useCallback(
		async (newVal: string | null) => {
			if (newVal) {
				const response = await callApi(
					UserService.searchUsers(newVal, SearchType.TALENT)
				);
				console.log(response);
				setTalentProfileSearchResults(
					response.data
						?.filter((u) => u.talentProfile)
						.map((u) => u.talentProfile!) ?? []
				);
			}
		},
		[callApi]
	);
	useEffect(() => {
		searchTalentProfiles(debouncedQuery);
	}, [debouncedQuery, searchTalentProfiles]);

	const handleTalentProfileSearchChange = (
		e: any,
		result: TalentProfileSummaryDto
	) => {
		setValue('talentProfileId', result.id, setValueAsUserInputOptions);
		setValue(
			'fullName',
			`${result.firstName} ${result.lastName}`,
			setValueAsUserInputOptions
		);
		setValue(
			'role',
			watchRole ? watchRole : result.tagLine,
			setValueAsUserInputOptions
		);
		setValue(
			'pay',
			watchPay ? watchPay : result.defaultPayRate,
			setValueAsUserInputOptions
		);
		setValue(
			'hours',
			watchHours ? watchHours : result.desiredHours,
			setValueAsUserInputOptions
		);
		setValue('profilePicUrl', result.profilePicUrl, setValueAsUserInputOptions);
	};

	return (
		<Modal className="w-96" title="Team Member" {...rest} onClose={onClose}>
			<div className="px-4 flex flex-col items-center justify-center">
				<Input
					type="hidden"
					className="hidden"
					{...register('talentProfileId', {
						required: 'Name is required',
					})}
					errors={[]}
				/>
				<Lookup
					label="Name"
					name="fullName"
					defaultValue={member.fullName}
					onInput={(event: any) => setQueryValue(event.target.value)}
					options={talentProfileSearchResults}
					onChange={handleTalentProfileSearchChange}
					OptionElement={TalentLookupOption}
					optionMapper={(option: TalentProfileSummaryDto | null) =>
						option ? `${option.firstName} ${option.lastName}` : null
					}
					className="mb-2"
					errors={[]}
				/>
				<TextButton
					className={`text-xs self-end ${simplified ? 'mb-2' : ''}`}
					onClick={() =>
						navigateTo({
							pathname: `/search/${SearchType.TALENT}`,
							search: createSearchParams({ query: query ?? '' }).toString(),
						})
					}>
					Advanced Search
				</TextButton>
				<div className={simplified ? 'hidden' : 'w-full'}>
					{/* TODO: Add tag selection */}
					<Input
						label="Role"
						{...register('role')}
						className="mb-4"
						errors={errors}
					/>
					<Input
						label="Anticipated Hours"
						suffix="/wk"
						type="number"
						{...register('hours', {
							validate: {
								checkNegative: (v) =>
									!v || v > 0 || 'Must be a positive number',
							},
							valueAsNumber: true,
						})}
						className="mb-4"
						errors={errors}
					/>
					<Input
						prefix="$"
						suffix="/hr"
						label="Pay"
						type="number"
						min="0.01"
						step="0.01"
						{...register('pay', {
							validate: {
								checkNegative: (v) =>
									!v || v > 0 || 'Must be a positive number',
							},
							valueAsNumber: true,
						})}
						className="mb-4"
						errors={errors}
					/>
				</div>
			</div>
			<StickyActionsBar>
				<Button variant="contained" onClick={onClose}>
					Close
				</Button>
				<Button variant="contained" onClick={handleSubmit(onSubmit)}>
					Save changes
				</Button>
			</StickyActionsBar>
		</Modal>
	);
};

export default TeamMemberModal;
