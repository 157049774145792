/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BuyerPaymentState {
	PENDING = 'Pending',
	INITIATED = 'Initiated',
	PROCESSING = 'Processing',
	FAILED = 'Failed',
	COMPLETE = 'Complete',
}
