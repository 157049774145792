import React, { Component } from 'react';

type MessageBubbleProps = { message: any; direction: string };
type MessageBubbleState = {
	hasMedia: boolean;
	mediaDownloadFailed: boolean;
	mediaUrl: string | null;
	type: string | undefined;
};

class MessageBubble extends Component<MessageBubbleProps, MessageBubbleState> {
	constructor(props: any) {
		super(props);

		this.state = {
			hasMedia: this.props.message.type === 'media',
			mediaDownloadFailed: false,
			mediaUrl: null,
			type: undefined,
		};
	}

	componentDidMount = async () => {
		this.setState({
			...this.state,
			type: (await this.props.message.getParticipant()).type,
		});
		document
			?.getElementById(this.props.message.sid)
			?.scrollIntoView({ behavior: 'smooth' });
	};

	componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
		document
			?.getElementById(this.props.message.sid)
			?.scrollIntoView({ behavior: 'smooth' });
	}

	render = () => {
		const { itemStyle, divStyle } =
			this.props.direction === 'incoming'
				? {
						itemStyle: 'text-left',
						divStyle: 'received-message justify-start pr-16',
				  }
				: {
						itemStyle: 'text-right',
						divStyle: 'sent-message justify-end pl-16',
				  };

		const m = this.props.message;

		if (this.state.hasMedia) {
			console.log('Message is media message');
			// log media properties
			console.log('Media properties', m.media);
		}
		return (
			<li id={m.sid} className={itemStyle}>
				<div className={`${divStyle} flex flex-wrap`}>
					<div
						className={`${itemStyle} text-sm text-gray-500 dark:text-gray-400`}>
						{
							m.conversation.participants.get(m.state.participantSid).attributes
								.name
						}
					</div>
					<div className="w-full">{m.body}</div>
					<div
						className={`${itemStyle} text-sm text-gray-500 dark:text-gray-400 scale-75 mx-[-1rem]`}>
						{m.state.timestamp.toLocaleString()}
					</div>
				</div>
			</li>
		);
	};
}

export default MessageBubble;
