/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ReviewCategory {
	BUYER = 'Buyer',
	TEAM = 'Team',
	USER = 'User',
	OTHER = 'Other',
}
