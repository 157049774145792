import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ProjectDto, SearchType } from '../../api';
import { UserContext } from '../../contexts/UserContext';

interface ProjectProps {
	className?: string;
	projects: ProjectDto[];
	userSkills: any[];
	loading: boolean;
}

const ProjectRecommendations = (props: ProjectProps) => {
	const navigateTo = useNavigate();
	const { user } = useContext(UserContext);
	const matchingSkills = (skills: any) => {
		if (props.projects.length !== 0) {
			let match = [];
			const projectSkills: any[] = [];
			skills.forEach(function (element: any) {
				projectSkills.push(element.skill.name);
			});
			match = props.userSkills.filter((item) => projectSkills.includes(item));
			return match.join(', ');
		}
	};
	const buildList = () => {
		if (props.loading) {
			return <div className="pl-2">Loading...</div>;
		} else if (props.projects.length === 0) {
			return (
				<li className="flex justify-between p-3 hover:bg-blue-600 hover:text-blue-200">
					<div className="flex flex-col h-full justify-between">
						<h3 className="font-bold">
							We couldn't find any projects with exact matches for the primary
							tags you have on your profile.
							<br />
							Try adding more tags to{' '}
							<Link
								className="text-accent-400"
								to={`/talent-profile/${user!.talentProfileId!}`}>
								your profile
							</Link>{' '}
							or{' '}
							<Link
								className="text-accent-400"
								to={`/search/${SearchType.PROJECT}`}>
								search for projects
							</Link>{' '}
							directly!
						</h3>
					</div>
				</li>
			);
		} else {
			return props.projects.map((row: ProjectDto, index: any) => (
				<li
					key={row.id}
					className="flex justify-between p-3 hover:bg-blue-600 hover:text-blue-200">
					<div className="flex items-center gap-4 h-full text-left">
						<div className="flex flex-col h-full justify-between">
							<h3 className="flex flex-row items-center font-bold text-accent-400">
								{row.name}
								<p className="text-sm text-black pl-4">
									Matching Skills: {matchingSkills(row.skills)}
								</p>
							</h3>
						</div>
					</div>
					<svg
						onClick={() => navigateTo(`/project/${row.id}`)}
						xmlns="http://www.w3.org/2000/svg"
						className="w-8 h-8 hover:cursor-pointer"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M17 8l4 4m0 0l-4 4m4-4H3"
						/>
					</svg>
				</li>
			));
		}
	};

	return (
		<div className="flex bg-white dark:bg-gray-900 rounded-md shadow-xl max-h-80">
			<div className="flex flex-col container mx-auto overflow-y-auto">
				<ul className="divide-y-2 divide-gray-400">{buildList()}</ul>
			</div>
		</div>
	);
};

export default ProjectRecommendations;
