/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BuyerProfilePublicDto } from '../models/BuyerProfilePublicDto';
import type { ConnectionState } from '../models/ConnectionState';
import type { CreateUserDto } from '../models/CreateUserDto';
import type { SearchType } from '../models/SearchType';
import type { TalentProfileDto } from '../models/TalentProfileDto';
import type { TalentProfilePublicDto } from '../models/TalentProfilePublicDto';
import type { UpsertBuyerProfileCommand } from '../models/UpsertBuyerProfileCommand';
import type { UpsertTalentProfileDto } from '../models/UpsertTalentProfileDto';
import type { UserInfoDto } from '../models/UserInfoDto';
import type { UserSummaryDto } from '../models/UserSummaryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

	/**
	 * Registers a new user.
	 * @param requestBody
	 * @returns UserInfoDto Success
	 * @throws ApiError
	 */
	public static createUser(
		requestBody: CreateUserDto,
	): CancelablePromise<UserInfoDto> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/User/CreateUser',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Registers a user's buyer profile.
	 * Creates a base user if not done already.
	 * @param requestBody
	 * @returns string Success
	 * @throws ApiError
	 */
	public static upsertBuyerProfile(
		requestBody: UpsertBuyerProfileCommand,
	): CancelablePromise<string> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/User/BuyerProfile',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Registers a user's talent profile.
	 * Creates a base user if not done already.
	 * @param talentProfileId
	 * @param requestBody
	 * @returns string Success
	 * @throws ApiError
	 */
	public static upsertTalentProfile(
		talentProfileId: string,
		requestBody: UpsertTalentProfileDto,
	): CancelablePromise<string> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/User/TalentProfile/{talentProfileId}',
			path: {
				'talentProfileId': talentProfileId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Retrieves talent profile by id
	 * @param talentProfileId
	 * @returns TalentProfilePublicDto Success
	 * @throws ApiError
	 */
	public static getFullPublicTalentProfile(
		talentProfileId: string,
	): CancelablePromise<TalentProfilePublicDto> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/User/TalentProfile/{talentProfileId}',
			path: {
				'talentProfileId': talentProfileId,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Establishes the relationship between two individuals
	 * Creates a relationship if one doesn't already exist and will figure out who the real original initiator was.
	 * @param receivingUserId
	 * @param connectionDesiredState
	 * @returns string Success
	 * @throws ApiError
	 */
	public static upsertConnection(
		receivingUserId: string,
		connectionDesiredState: ConnectionState,
	): CancelablePromise<string> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/User/TalentProfile/{receivingUserId}/{connectionDesiredState}',
			path: {
				'receivingUserId': receivingUserId,
				'connectionDesiredState': connectionDesiredState,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Searches existing users.
	 * Creates a base user if not done already.
	 * @param userId
	 * @returns UserSummaryDto Success
	 * @throws ApiError
	 */
	public static getUserById(
		userId: string,
	): CancelablePromise<UserSummaryDto> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/User/{userId}',
			path: {
				'userId': userId,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Searches existing users.
	 * Creates a base user if not done already.
	 * @param query Applied against Name Fields and Email
	 * @param searchType What result types are being sought
	 * @param skills Applied against TalentSkills
	 * @param postalCode Applied against User Location
	 * @param pageNumber Determines which page of results will be returned
	 * @param pageSize Determines how many results will be returned per page
	 * @returns UserSummaryDto Success
	 * @throws ApiError
	 */
	public static searchUsers(
		query?: string,
		searchType?: SearchType,
		skills?: Array<string>,
		postalCode?: string,
		pageNumber?: number,
		pageSize?: number,
	): CancelablePromise<Array<UserSummaryDto>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/User',
			query: {
				'Query': query,
				'SearchType': searchType,
				'Skills': skills,
				'PostalCode': postalCode,
				'PageNumber': pageNumber,
				'PageSize': pageSize,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Retrieves buyer profile by id
	 * @param buyerProfileId
	 * @returns BuyerProfilePublicDto Success
	 * @throws ApiError
	 */
	public static getFullPublicBuyerProfile(
		buyerProfileId: string,
	): CancelablePromise<BuyerProfilePublicDto> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/User/BuyerProfile/{buyerProfileId}',
			path: {
				'buyerProfileId': buyerProfileId,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Grabs the user's active or pending connections
	 * @returns UserSummaryDto Success
	 * @throws ApiError
	 */
	public static getUserConnections(): CancelablePromise<Array<UserSummaryDto>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/User/Connections',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Grabs the current user's full talent profile
	 * @returns TalentProfileDto Success
	 * @throws ApiError
	 */
	public static getCurrentUserFullTalentProfile(): CancelablePromise<TalentProfileDto> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/User/TalentProfile/CurrentUser',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Grabs the user's profile from token.
	 * Creates a base user if not done already.
	 * @returns UserInfoDto Success
	 * @throws ApiError
	 */
	public static getUserFromAuthProvider(): CancelablePromise<UserInfoDto> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/User/TalentProfile/AuthProvider',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

}
