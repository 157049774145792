/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganizationSummaryDto } from '../models/OrganizationSummaryDto';
import type { UpsertOrganizationCommand } from '../models/UpsertOrganizationCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationsService {

	/**
	 * Upserts an organization creating it if it doesn't already exist and updating any properties otherwise
	 * @param requestBody
	 * @returns string Success
	 * @throws ApiError
	 */
	public static upsertOrganization(
		requestBody: UpsertOrganizationCommand,
	): CancelablePromise<string> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/Organizations',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Searches skills by name matching the given query.
	 * Returns any skills matching the given query.
	 * @param query
	 * @param educationalInstitutionsOnly
	 * @returns OrganizationSummaryDto Success
	 * @throws ApiError
	 */
	public static searchOrganizations(
		query?: string,
		educationalInstitutionsOnly?: boolean,
	): CancelablePromise<Array<OrganizationSummaryDto>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Organizations',
			query: {
				'Query': query,
				'EducationalInstitutionsOnly': educationalInstitutionsOnly,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

}
