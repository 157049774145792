import React from 'react';

const MenuContext = React.createContext({
	isSideNavOpen: true,
	setIsSideNavOpen: (isSideNavOpen: boolean) => {},
	isAccountMenuOpen: false,
	setIsAccountMenuOpen: (isAccountMenuOpen: boolean) => {},
});

export const MenuContextElement = ({ children }: any) => {
	const [isSideNavOpen, setIsSideNavOpen] = React.useState(true);
	const [isAccountMenuOpen, setIsAccountMenuOpen] = React.useState(false);

	return (
		<MenuContext.Provider
			value={{
				isSideNavOpen,
				setIsSideNavOpen,
				isAccountMenuOpen,
				setIsAccountMenuOpen,
			}}>
			{children}
		</MenuContext.Provider>
	);
};

export default MenuContext;
