import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TalentProfileSummaryDto, TeamState, TeamSummaryDto } from '../../api';
import SplitButton from '../utilities/SplitButton';
import { v4 as uuidv4 } from 'uuid';
import { UserContext } from '../../contexts/UserContext';

const AddToTeamButton = (props: {
	talentProfile: TalentProfileSummaryDto;
	currentUserTeamMemberships: TeamSummaryDto[];
}) => {
	const { currentUserTeamMemberships, talentProfile } = props;
	const navigateTo = useNavigate();
	const { user: currentUser } = useContext(UserContext);

	const addToTeamOptions = [
		{
			label: 'Add to new team',
			handler: () =>
				navigateTo(`/dedicated-team/${uuidv4()}`, {
					state: {
						isEditing: true,
						talentProfilesToAddToTeam: [talentProfile],
					},
				}),
		},
	].concat(
		currentUserTeamMemberships
			.filter(
				(team) =>
					[TeamState.DRAFT, TeamState.PENDING].includes(team.state) &&
					team.createdById === currentUser?.id
			)
			.map((team) => ({
				label: `Add to ${
					team.name?.trim()
						? `"${team.name}"`
						: `team with: ${
								team.teamMembers.filter(
									(teamMember) =>
										teamMember.talentProfileId !== currentUser?.talentProfileId
								).length
									? team.teamMembers
											.filter(
												(teamMember) =>
													teamMember.talentProfileId !==
													currentUser?.talentProfileId
											)
											.map((teamMember) => teamMember.preferredName)
											.join(', ')
									: 'no other members yet'
						  }`
				}`,
				handler: () =>
					navigateTo(`/dedicated-team/${team.id}`, {
						state: {
							isEditing: true,
							talentProfilesToAddToTeam: [talentProfile],
						},
					}),
			}))
	);

	return <SplitButton options={addToTeamOptions} />;
};

export default AddToTeamButton;
