import { PhotoUpload } from './FloatingLabelInput';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BuyerProfileSummaryDto, OrganizationSummaryDto } from '../../api';
import OrganizationLookup from './OrganizationLookup';
import { useContext } from 'react';
import ThemeContext from '../../contexts/ThemeContext';
import {
	defaultLightProfilePicUrl,
	defaultDarkProfilePicUrl,
	setValueAsUserInputOptions,
} from '../../services/helper';
import { CircularProgress } from '@mui/material';
import { TextFieldInput } from './Input';

const BuyerCard = (props: { isEditing: boolean; isLoading?: boolean }) => {
	const { isEditing } = props;
	const {
		setValue,
		register,
		watch,
		resetField,
		control,
		formState: { errors },
	} = useFormContext<BuyerProfileSummaryDto>();
	const { theme } = useContext(ThemeContext);
	const navigateTo = useNavigate();
	if (props.isLoading) {
		return <CircularProgress />;
	}
	return (
		<div
			className={`w-full md:w-1/4 pl-0 md:pl-2 mt-5 pt-10 pb-5 flex flex-wrap justify-between start`}>
			<div className="w-full flex justify-center ">
				<div className="relative max-w-full w-full py-1 p-0 md:pr-4">
					<div
						className={
							'overflow-show relative max-w-sm w-full shadow-lg ring-1 ring-black/5 rounded-md flex justify-center items-center bg-gray-200 dark:bg-gray-700 rounded-md border-2 border-gray-300 dark:border-gray-800'
						}>
						<div
							onClick={() =>
								!isEditing
									? navigateTo(`/buyer-profile/${watch('id')}`)
									: undefined
							}
							className={`absolute -top-16 mx-1.5 bg-gray-200 dark:bg-gray-700`}>
							<PhotoUpload
								{...register('profilePicId')}
								label=""
								helperText="select a profile pic"
								category="BuyerProfilePic"
								value={watch('profilePicId')}
								url={watch('profilePicUrl')}
								defaultImageUrl={
									theme === 'light'
										? defaultLightProfilePicUrl
										: defaultDarkProfilePicUrl
								}
								// Image Resizing Properties
								maxWidth={200}
								maxHeight={200}
								minWidth={100}
								minHeight={100}
								editing={isEditing}
								errors={errors}
								resetfield={resetField}
								className=""
								styling={
									'h-32 w-32 overflow-hidden shadow-lg ' +
									(isEditing ? 'hover:cursor-pointer' : '')
								}
								imageStyle="object-cover p-0.5 border-transparent"
							/>
						</div>
						<div className="flex flex-col w-full">
							<div className="relative mt-14 mb-0 px-2 pt-2">
								<div className="text-lg text-center justify-center">
									{watch('firstName') + ' ' + watch('lastName')}
								</div>
							</div>
							{!watch('locationState') && !isEditing ? null : (
								<div className="relative pb-2">
									<div className="text-sm text-center justify-center">
										{watch('locationState')}
									</div>
								</div>
							)}
							{!watch('role') && !isEditing ? null : (
								<TextFieldInput
									name="role"
									label="Role"
									control={control}
									rules={{
										maxLength: {
											value: 48,
											message: '48 characters max',
										},
									}}
									className="px-2 mb-2"
									size="small"
									readOnly={!isEditing}
								/>
							)}

							{!watch('organizationId') && !isEditing ? null : (
								<OrganizationLookup
									organization={watch('organization')}
									isEditing={isEditing}
									onChange={(
										result: OrganizationSummaryDto | null | undefined
									) => {
										setValue(
											'organizationId',
											result?.id,
											setValueAsUserInputOptions
										);
										setValue(
											'organization',
											result ?? null,
											setValueAsUserInputOptions
										);
									}}
									errors={errors}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BuyerCard;
