import { useCallback, useContext, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingIndicatorContext } from '../contexts/LoadingIndicatorContext';
import { OpenAPI, CancelablePromise, ApiError } from '../api';
import { useAlert } from 'react-alert';
import { Router } from '../Router';

export interface ApiResponse<T> {
	data: T | null;
	error: ApiError | Error | null;
}

export type ParallelRequest<T> = {
	request: CancelablePromise<T>;
	handler: (response: ApiResponse<T>) => void;
};

export function useApi() {
	const { getAccessTokenSilently } = useAuth0();
	const { setLoadingIndicator } = useContext(LoadingIndicatorContext);
	const alert = useAlert();

	OpenAPI.BASE = process.env.REACT_APP_API_URL as string;
	OpenAPI.TOKEN = async (options) => {
		// Dynamically get the access token if the endpoint being called requires authentication
		if (options.errors && '401' in options.errors) {
			return await getAccessTokenSilently();
		}
		return '';
	};

	const callApi = useCallback(
		async <T>(
			request: CancelablePromise<T>,
			customOptions = { loadingIndicator: true, alertOnError: true }
		): Promise<ApiResponse<T>> => {
			try {
				if (customOptions.loadingIndicator) {
					setLoadingIndicator(true);
				}
				const response = await request;
				return {
					data: response,
					error: null,
				};
			} catch (error) {
				console.error(error);
				if (error instanceof ApiError) {
					if (customOptions.alertOnError) {
						if (error.status === 401) {
							Router.navigate('/unauthorized', {
								state: { message: error.body?.detail },
							});
						} else {
							alert.error(
								`${error.message}: ${error.body?.detail ?? 'Unknown'}`
							);
						}
					}
					return {
						data: null,
						error: error,
					};
				}
				const castError = error as Error;
				if (customOptions.alertOnError) {
					alert.error(`${castError.name}: ${castError.message ?? 'Unknown'}`);
				}
				return {
					data: null,
					error: castError,
				};
			} finally {
				if (customOptions.loadingIndicator) {
					setLoadingIndicator(false);
				}
			}
		},
		[alert, setLoadingIndicator]
	);

	const requestInParallel = useCallback(
		async (parallelRequests: ParallelRequest<any>[]) => {
			const parallelResponses = await Promise.allSettled(
				parallelRequests.map((r) => callApi(r.request))
			);
			parallelResponses.forEach((r, index) => {
				if (r.status === 'fulfilled') {
					parallelRequests[index].handler(r.value);
				} else {
					throw Error(r.reason);
				}
			});
		},
		[callApi]
	);

	return {
		callApi,
		requestInParallel,
		emptyResponse: useMemo(
			() => ({ data: null, error: null } as ApiResponse<any>),
			[]
		),
	};
}

export default useApi;
