import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import UserSideNav from '../utilities/UserSideNav';
import BuyerPayments from './BuyerPayments';
import Invoices from './Invoice/Invoices';
import TalentPayments from './TalentPayments';

const Payments = () => {
	const { user } = useContext(UserContext);

	return (
		<UserSideNav>
			<h1 className="text-center pb-4">Payments</h1>
			<Invoices />
			{user?.talentProfileId && (
				<div className="pt-10">
					<TalentPayments />
				</div>
			)}
			{user?.buyerProfileId && (
				<div className="pt-10">
					<BuyerPayments />
				</div>
			)}
		</UserSideNav>
	);
};

export default Payments;
