/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SearchType {
	ALL = 'All',
	TALENT = 'Talent',
	TEAM = 'Team',
	PROJECT = 'Project',
	BUYER = 'Buyer',
	USER = 'User',
}
