import { IconButton, TextField } from '@mui/material';
import { useAlert } from 'react-alert';
import { FaCopy } from 'react-icons/fa';

export default function CopyExample({ text }: { text: string }) {
	const alert = useAlert();
	async function copyToClipboard() {
		try {
			await navigator.clipboard.writeText(text);
			alert.success('Copied to clipboard');
		} catch (err) {
			alert.error('Error copying to clipboard');
		}
	}

	return (
		<div>
			<TextField
				fullWidth
				value={text}
				aria-readonly
				InputProps={{
					endAdornment: (
						<IconButton onClick={copyToClipboard}>
							<FaCopy />
						</IconButton>
					),
				}}
			/>
		</div>
	);
}
