import React from 'react';
import { Avatar } from '@mui/material';
import { TalentProfileSummaryDto } from '../../api/';

export const TalentLookupOption = (props: {
	option: TalentProfileSummaryDto;
}) => {
	const { option: talentProfile } = props;
	return (
		<div className="flex flex-row justify-between">
			<Avatar
				alt={`${talentProfile.firstName} ${talentProfile.lastName}`}
				src={
					talentProfile!.profilePicUrl
						? talentProfile!.profilePicUrl
						: undefined
				}
				sx={{ width: 24, height: 24 }}
			/>
			<div className="ml-2">{`${talentProfile.firstName} ${
				talentProfile.lastName
			}${talentProfile.tagLine ? '--' + talentProfile.tagLine : ''}`}</div>
		</div>
	);
};
