/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum StoredFileCategory {
	UNKNOWN = 'Unknown',
	TEAM_BANNER = 'TeamBanner',
	TEAM_LOGO = 'TeamLogo',
	TALENT_PROFILE_PIC = 'TalentProfilePic',
	BUYER_PROFILE_PIC = 'BuyerProfilePic',
	ATTACHMENT = 'Attachment',
	PORTFOLIO_ITEM = 'PortfolioItem',
}
