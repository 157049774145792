/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChatParticipantDto } from '../models/ChatParticipantDto';
import type { ConversationDto } from '../models/ConversationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChatService {

	/**
	 * Returns a temporary token that can be used by the client for connecting to Twilio's Chat
	 * @returns string Success
	 * @throws ApiError
	 */
	public static getChatToken(): CancelablePromise<string> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Chat/Token',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Searches for potential chat participants against team names and users
	 * @param query Applied against Name Fields and Email of connections as well as teams
	 * @returns ChatParticipantDto Success
	 * @throws ApiError
	 */
	public static searchChatParticipants(
		query?: string,
	): CancelablePromise<Array<ChatParticipantDto>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Chat/ParticipantSearch',
			query: {
				'Query': query,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Searches for a talent profile with the given Id
	 * @param talentId
	 * @returns ChatParticipantDto Success
	 * @throws ApiError
	 */
	public static talentParticipantSearch(
		talentId: string,
	): CancelablePromise<ChatParticipantDto> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Chat/TalentParticipantSearch/{talentId}',
			path: {
				'talentId': talentId,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Searches for a buyer profile with the given Id
	 * @param buyerId
	 * @returns ChatParticipantDto Success
	 * @throws ApiError
	 */
	public static buyerParticipantSearch(
		buyerId: string,
	): CancelablePromise<ChatParticipantDto> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Chat/BuyerParticipantSearch/{buyerId}',
			path: {
				'buyerId': buyerId,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Establishes the relationship between two individuals
	 * Creates a relationship if one doesn't already exist and will figure out who the real original initiator was.
	 * @param requestBody
	 * @returns string Success
	 * @throws ApiError
	 */
	public static getOrCreateConversation(
		requestBody: ConversationDto,
	): CancelablePromise<string> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/Chat/Conversation',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

}
