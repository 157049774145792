import React, { useContext, useState } from 'react';
import TeamMemberCard from './TeamMemberCard';
import { encodeSvg } from './IconButtons';
import { FaPlusCircle } from 'react-icons/fa';
import TeamMemberModal from './TeamMemberModal';
import ThemeContext from '../../contexts/ThemeContext';
import { UserContext } from '../../contexts/UserContext';
import { TeamMemberDto, TeamMemberState } from '../../api';

function TeamRoster(props: any) {
	const { user } = useContext(UserContext);
	const {
		teamId,
		register,
		editing,
		isTeamCreator,
		teamMembers,
		className,
		onAddNewMember,
		onChangeMember,
		onRemoveMember,
		onTeamMemberCardClick,
		...rest
	} = props;
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [editMember, setEditMember] = useState<TeamMemberDto>();

	const showEditModalHandler = (teamMember: TeamMemberDto) => {
		setShowEditModal(true);
		setEditMember(teamMember);
	};

	// Put the current user at the top of the team roster
	const orderedTeamMembers = teamMembers
		.filter((tm: TeamMemberDto) => tm.inviteState !== TeamMemberState.REMOVED)
		.sort((a: TeamMemberDto, b: TeamMemberDto) => {
			if (a.talentProfileId === user!.talentProfileId) {
				return -1;
			}
			if (b.talentProfileId === user!.talentProfileId) {
				return 1;
			}
			return 0;
		});

	const addMemberHandler = (newMember: TeamMemberDto) => {
		const existingMember = teamMembers.find(
			(member: TeamMemberDto) =>
				member.talentProfileId === newMember.talentProfileId
		);
		if (!existingMember) {
			onAddNewMember(newMember);
		} else {
			existingMember.inviteState = TeamMemberState.DRAFT;
			onChangeMember(newMember, existingMember);
		}
	};

	// Add Icon Styling based on theme
	const { theme, colorGetter } = useContext(ThemeContext);
	const circleFillColor = colorGetter(
		theme === 'light' ? 'gray.400' : 'gray.500'
	);
	const plusColor = colorGetter(theme === 'light' ? 'gray.200' : 'gray.400');

	return (
		<div
			className={
				'flex flex-row flex-wrap gap-x-8 gap-y-5 pl-9 pr-0 py-4 justify-evenly items-center bg-gray-100 dark:bg-gray-700 rounded-md mb-6 shadow-xl ' +
				(className ?? '')
			}
			{...rest}>
			{orderedTeamMembers
				.map((member: TeamMemberDto, index: number) => {
					return (
						<TeamMemberCard
							key={member.talentProfileId}
							member={member}
							editing={editing}
							{...(editing &&
							(user!.talentProfileId === member.talentProfileId ||
								isTeamCreator)
								? {
										onClick: () => {
											showEditModalHandler(member);
											onTeamMemberCardClick?.(member);
										},
										onRemove: onRemoveMember,
										hasOnClick: true,
								  }
								: {
										onClick: () => onTeamMemberCardClick?.(member),
										hasOnClick: onTeamMemberCardClick !== undefined,
								  })}
							className={index === 0 ? 'dedicated-team-member' : ''}
						/>
					);
				})
				.concat(
					!editing || !isTeamCreator
						? []
						: [
								<TeamMemberCard
									key="add"
									editing={true}
									onClick={() => setShowAddModal(true)}
									className="dedicated-team-add-member"
									member={
										{
											profilePicUrl: encodeSvg(
												<FaPlusCircle
													style={{ backgroundColor: plusColor }}
													fill={circleFillColor}
												/>
											),
											preferredName: 'Add New Member',
											role: '',
										} as TeamMemberDto
									}
								/>,
						  ]
				)}
			{showAddModal && (
				<TeamMemberModal
					teamId={teamId}
					visible={showAddModal}
					simplified={true}
					onComplete={addMemberHandler}
					onClose={() => setShowAddModal(false)}
				/>
			)}
			{showEditModal && (
				<TeamMemberModal
					teamId={teamId}
					member={editMember}
					simplified={false}
					visible={showEditModal}
					onComplete={onChangeMember}
					onClose={() => setShowEditModal(false)}
				/>
			)}
		</div>
	);
}

export default TeamRoster;
