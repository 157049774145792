/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ContractState {
	DRAFT = 'Draft',
	PENDING = 'Pending',
	WITHDRAWN = 'Withdrawn',
	ACTIVE = 'Active',
	REJECTED = 'Rejected',
	REMOVED = 'Removed',
	COMPLETED = 'Completed',
	BUYER_ENDED = 'BuyerEnded',
	TALENT_ENDED = 'TalentEnded',
	COMPLETION_REQUESTED = 'CompletionRequested',
}
