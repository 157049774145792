import {
	OrganizationSummaryDto,
	OrganizationsService,
	PortfolioItemDto,
	StoredFileDto,
} from '../../api';
import { AutocompleteInput, TextFieldInput } from '../utilities/Input';
import { useForm } from 'react-hook-form';
import StickyActionsBar from '../utilities/StickyActionsBar';
import { Button, Grid } from '@mui/material';
import Modal from '../utilities/Modal';
import { PhotoUpload } from '../utilities/FloatingLabelInput';
import { useCallback, useEffect, useState } from 'react';
import useApi from '../../services/useApi';
import { FaPlus } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import { OrganizationModal } from '../Organizations/OrganizationModal';
import { setValueAsUserInputOptions } from '../../services/helper';

const defaultValues: Omit<PortfolioItemDto, 'id' | 'talentProfileId'> = {
	name: undefined!,
	description: undefined!,
	forOrganizationId: null!,
	forOrganization: null!,
	url: undefined!,
	fileId: undefined!,
	deleted: false,
};

const PortfolioItemModal = (props: {
	id: string;
	talentProfileId: string;
	portfolioItem: PortfolioItemDto | null;
	visible: boolean;
	onSave: (_: PortfolioItemDto) => any;
	onCancel: any;
}) => {
	const {
		id,
		talentProfileId,
		portfolioItem,
		visible,
		onSave,
		onCancel,
		...rest
	} = props;
	const {
		watch,
		register,
		handleSubmit,
		reset,
		resetField,
		formState: { isDirty, errors },
		control,
		setValue,
	} = useForm({
		mode: 'onBlur',
		defaultValues: portfolioItem ?? defaultValues,
		shouldFocusError: false,
	});
	const { callApi, emptyResponse } = useApi();

	const [fileUrl, setFileUrl] = useState<string | null | undefined>(null);

	const clearValues = useCallback(
		(
			resetValues: Omit<
				PortfolioItemDto,
				'id' | 'talentProfileId'
			> = defaultValues
		) => {
			setFileUrl(' ');
			reset(resetValues);
		},
		[reset]
	);

	useEffect(() => {
		if (portfolioItem) {
			clearValues(portfolioItem);
			setFileUrl(portfolioItem.fileUrl);
		} else {
			clearValues();
		}
	}, [clearValues, portfolioItem]);

	const watchFileId = watch('fileId');

	const onSubmit = (data: Omit<PortfolioItemDto, 'id' | 'talentProfileId'>) => {
		if (isDirty) {
			onSave({
				...data,
				id,
				talentProfileId,
				fileUrl,
			});
		} else {
			onCancel();
		}
	};

	const searchOrganizations = useCallback(
		async (query?: string) => {
			if (query) {
				const response = await callApi(
					OrganizationsService.searchOrganizations(query, false)
				);
				return response;
			} else {
				return emptyResponse;
			}
		},
		[callApi, emptyResponse]
	);

	const [addOrganizationModalOpen, setAddOrganizationModalOpen] =
		useState(false);

	const handleAddOrganization = (newOrganization: OrganizationSummaryDto) => {
		setAddOrganizationModalOpen(false);
		setValue(
			'forOrganizationId',
			newOrganization.id,
			setValueAsUserInputOptions
		);
		setValue('forOrganization', newOrganization, setValueAsUserInputOptions);
	};

	return (
		<>
			<Modal
				title={
					portfolioItem
						? `Edit ${portfolioItem.name ?? 'Portfolio Item'}`
						: 'New Portfolio Item'
				}
				visible={visible}
				onClose={onCancel}
				className="w-96"
				{...rest}>
				<Grid container className="px-2" spacing={1} columns={8}>
					<Grid item xs={8}>
						<TextFieldInput
							name="name"
							control={control}
							label="Title (optional)"
							fullWidth
						/>
					</Grid>
					<Grid item xs={8}>
						<TextFieldInput
							name="description"
							control={control}
							label="Description (optional)"
							fullWidth
						/>
					</Grid>
					<Grid item xs={5}>
						<AutocompleteInput
							name="forOrganization"
							valueName="forOrganizationId"
							control={control}
							label="For"
							optionApiService={searchOrganizations}
							apiFiltering
						/>
					</Grid>
					<Grid
						item
						xs={3}
						style={{
							marginTop: 'auto',
							marginBottom: 'auto',
							textAlign: 'right',
						}}>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => setAddOrganizationModalOpen(true)}>
							<FaPlus className="ml-0 mr-2" /> Add Org
						</Button>
					</Grid>
					<Grid item xs={8}>
						<TextFieldInput
							name="url"
							type="url"
							control={control}
							label="Url for Linked Content (optional)"
							fullWidth
						/>
					</Grid>
					<Grid item xs={8}>
						<PhotoUpload
							{...register('fileId')}
							label="Image"
							helperText="select an image to add to your portfolio"
							category="PortfolioItem"
							value={watchFileId}
							url={fileUrl}
							onChange={(e: any, file: StoredFileDto) => {
								setValue('fileId', file.id, setValueAsUserInputOptions);
								setFileUrl(file.presignedUrl);
							}}
							// Image Resizing Properties
							maxWidth={1080}
							maxHeight={1080}
							minWidth={100}
							minHeight={100}
							editing={true}
							errors={errors}
							resetfield={resetField}
							className="mx-2"
							styling="h-32 w-32"
							imageStyle="object-cover"
						/>
					</Grid>
				</Grid>
				<StickyActionsBar>
					<Button variant="outlined" onClick={onCancel}>
						Cancel
					</Button>
					<Button variant="outlined" onClick={handleSubmit(onSubmit)}>
						Save
					</Button>
				</StickyActionsBar>
			</Modal>
			<OrganizationModal
				id={uuidv4()}
				organization={null}
				open={addOrganizationModalOpen}
				onCancel={() => setAddOrganizationModalOpen(false)}
				onOrganizationAdded={handleAddOrganization}
			/>
		</>
	);
};

export default PortfolioItemModal;
