import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import { LocationService, PostalCodeDto } from '../../api';
import useApi from '../../services/useApi';
import ThemeContext from '../../contexts/ThemeContext';

const PostalCodeLookup = (props: {
	defaultValue?: string | null;
	onChange: (selectedPostalCode: PostalCodeDto | null) => void;
}) => {
	const { defaultValue, onChange } = props;
	const [value, setValue] = React.useState<PostalCodeDto | null>(null);
	const [inputValue, setInputValue] = React.useState('');
	const [options, setOptions] = React.useState<readonly PostalCodeDto[]>([]);
	const { callApi } = useApi();
	const { theme, colorGetter } = React.useContext(ThemeContext);

	const fetch = React.useMemo(
		() =>
			debounce(
				async (
					request: { input: string },
					callback: (results?: readonly PostalCodeDto[]) => void
				) => {
					const response = await callApi(
						LocationService.searchPostalCodes(request.input)
					);
					if (response.data) {
						callback(response.data);
					} else {
						throw response.error;
					}
				},
				400
			),
		[callApi]
	);

	React.useEffect(() => {
		if (defaultValue) {
			fetch({ input: defaultValue }, (results?: readonly PostalCodeDto[]) => {
				setOptions(results ?? []);
				if (results?.length) {
					setValue(results[0]);
				}
				setInputValue(defaultValue);
			});
		}
	}, [defaultValue, fetch]);

	React.useEffect(() => {
		let active = true;

		if (inputValue === '') {
			setOptions(value ? [value] : []);
			return undefined;
		}

		fetch({ input: inputValue }, (results?: readonly PostalCodeDto[]) => {
			if (active) {
				let newOptions: readonly PostalCodeDto[] = [];

				if (value) {
					newOptions = [value];
				}

				if (results) {
					// newOptions = [...newOptions, ...results];
					newOptions = results;
				}

				setOptions(newOptions);
			}
		});

		return () => {
			active = false;
		};
	}, [value, inputValue, fetch]);

	return (
		<Autocomplete
			sx={{
				marginBottom: '1.25rem',
				'&&& .MuiInputBase-root': {
					paddingTop: '0.625rem',
					paddingBottom: '0.625rem',
					fontSize: '0.876rem',
					lineHeight: '1.25rem',
					'::before': {
						borderBottomWidth: 2,
						borderBottomColor:
							theme === 'dark'
								? colorGetter('gray.600')
								: colorGetter('gray.300'),
					},
				},
			}}
			getOptionLabel={(option) =>
				typeof option === 'string' ? option : option.code
			}
			filterOptions={(x) => x}
			options={options}
			autoComplete
			includeInputInList
			filterSelectedOptions
			value={value}
			noOptionsText="No locations"
			onChange={(event: any, newValue: PostalCodeDto | null) => {
				// setOptions(newValue ? [newValue, ...options] : options);
				setValue(newValue);
				onChange(newValue);
			}}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			isOptionEqualToValue={(option, value) =>
				option.code === value.code && option.countryCode === value.countryCode
			}
			renderInput={(params) => (
				<TextField
					{...params}
					label="Postal Code"
					variant="standard"
					InputLabelProps={{
						sx: {
							fontSize: '0.876rem',
							lineHeight: '1.25rem',
							paddingTop: '0.625rem',
							color:
								theme === 'light'
									? colorGetter('gray.500')
									: colorGetter('gray.400'),
						},
					}}
					fullWidth
				/>
			)}
			renderOption={(props, option) => {
				return (
					<li {...props}>
						<Grid container alignItems="center">
							<Grid item sx={{ display: 'flex', width: 44 }}>
								<LocationOnIcon sx={{ color: 'text.secondary' }} />
							</Grid>
							<Grid
								item
								sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
								<Box component="span" sx={{ fontWeight: 'regular' }}>
									{option.code}
								</Box>
								<Typography variant="body2" color="text.secondary">
									{`${option.name}, ${option.stateNameOrFirstOrderSubdivision}, ${option.countryCode}`}
								</Typography>
							</Grid>
						</Grid>
					</li>
				);
			}}
		/>
	);
};
export default PostalCodeLookup;
