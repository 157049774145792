import { createContext, PropsWithChildren, useState } from 'react';

export const LoadingIndicatorContext = createContext({
	showLoadingIndicator: false,
	setLoadingIndicator: (isLoading: boolean) => {},
});

export const LoadingIndicatorContextElement = ({
	children,
}: PropsWithChildren<any>) => {
	const [showLoadingIndicator, setLoadingIndicator] = useState(false);

	return (
		<LoadingIndicatorContext.Provider
			value={{ showLoadingIndicator, setLoadingIndicator }}>
			{children}
		</LoadingIndicatorContext.Provider>
	);
};
