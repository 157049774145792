/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum WorkLocation {
	UNDECIDED = 'Undecided',
	ONSITE = 'Onsite',
	REMOTE = 'Remote',
	HYBRID = 'Hybrid',
}
