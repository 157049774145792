import React from 'react';
import { BiCarousel } from 'react-icons/bi';
import { PortfolioItemDto } from '../../api';
import { Icon, Typography } from '@mui/material';

type PortfolioItemPreviewProps = {
	portfolioItem: PortfolioItemDto;
};

const PortfolioItemPreview: React.FC<PortfolioItemPreviewProps> = ({
	portfolioItem,
}) => {
	const { name, fileUrl } = portfolioItem;
	const [hasFailed, setHasFailed] = React.useState(false);

	return (
		<>
			{fileUrl && !hasFailed ? (
				<img
					className="cursor-pointer"
					src={fileUrl}
					alt={portfolioItem.name ?? undefined}
					onError={(_: any) => {
						setHasFailed(true);
					}}
				/>
			) : null}
			<div className="first:flex flex-col flex-nowrap place-items-center hidden h-full p-1 w-full cursor-pointer overflow-x-hidden bg-gray-100 dark:bg-gray-800">
				<Icon className="inline-flex align-middle" sx={{ fontSize: 56 }}>
					<BiCarousel />
				</Icon>
				<Typography className="break-words overflow-hidden text-center w-full">
					{name}
				</Typography>
			</div>
		</>
	);
};

export default PortfolioItemPreview;
