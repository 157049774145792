import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
	ContractService,
	ContractState,
	ContractSummaryDto,
	ProjectState,
	ContractMemberDto,
} from '../../api';
import {
	formatCurrency,
	getStateInfo,
	getStateName,
} from '../../services/helper';
import { UserContext } from '../../contexts/UserContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import useApi from '../../services/useApi';
import UserSideNav from '../utilities/UserSideNav';
import { Link, Button, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import {
	DataGrid,
	GridColDef,
	GridValueGetterParams,
	GridRenderCellParams,
	GridRowParams,
	GridActionsCellItem,
	GridFooterContainer,
	GridPagination,
} from '@mui/x-data-grid';
import {
	Visibility as ViewIcon,
	Delete as DeleteIcon,
	Add as CreateIcon,
	Send as SubmitIcon,
	CancelScheduleSend as WithdrawIcon,
	Check as AcceptIcon,
	NotInterested as RejectIcon,
	CheckCircle as CompleteIcon,
	Cancel as EndIcon,
} from '@mui/icons-material';

import { parseISO } from 'date-fns';
import {
	ActionDialog,
	ContractDialog,
	StateType,
} from '../../Constants/Dialog';

const Contracts = () => {
	const { user, isLoading: isUserLoading } = useContext(UserContext);
	const [items, setItems] = useState<ContractSummaryDto[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const { callApi } = useApi();
	const navigateTo = useNavigate();

	const loadItems = useCallback(async () => {
		setIsLoading(true);
		const response = await callApi(ContractService.getUserContracts());
		if (response.data) {
			setItems(response.data);
			setIsLoading(false);
		}
	}, [callApi]);

	useEffect(() => {
		if (!isUserLoading && user) {
			loadItems();
		}
	}, [isUserLoading, user, callApi, loadItems]);

	const handleStateChange = async (
		id: string,
		state: ContractState,
		dialog: string
	) => {
		// TODO: Change to a modal
		if (window.confirm(dialog) === true) {
			const response = await callApi(
				ContractService.updateContractState({
					id: id,
					state: state,
				})
			);
			if (response.data) {
				loadItems();
			}
		}
	};

	const getActions = (params: GridRowParams<ContractSummaryDto>) => {
		const actions = [
			<GridActionsCellItem
				icon={
					<Tooltip title="View Details">
						<ViewIcon />
					</Tooltip>
				}
				label="View Details"
				onClick={() => navigateTo(`/contract/${params.row.id}`)}
			/>,
		];
		const isContractMember = params.row.contractMembers.some(
			(x: ContractMemberDto) => x.talentProfileId === user?.talentProfileId
		);
		const isProjectOwner =
			user?.buyerProfileId === params.row.project.ownerBuyerProfileId;

		switch (params.row.state) {
			case ContractState.DRAFT:
				// Project owners don't see drafts but just in case
				if (!isContractMember) {
					break;
				}
				actions.push(
					<GridActionsCellItem
						icon={<DeleteIcon />}
						label="Delete Draft"
						onClick={() =>
							handleStateChange(
								params.row.id,
								ContractState.REMOVED,
								ActionDialog.DeleteDraft
							)
						}
						showInMenu
					/>
				);
				if (params.row.project.state === ProjectState.BIDDING) {
					actions.push(
						<GridActionsCellItem
							icon={<SubmitIcon />}
							label="Submit Bid"
							onClick={() =>
								handleStateChange(
									params.row.id,
									ContractState.PENDING,
									ContractDialog.SubmitBid
								)
							}
							showInMenu
						/>
					);
				}
				break;
			case ContractState.PENDING:
				if (isContractMember) {
					actions.push(
						<GridActionsCellItem
							icon={<WithdrawIcon />}
							label="Withdraw Bid"
							onClick={() =>
								handleStateChange(
									params.row.id,
									ContractState.WITHDRAWN,
									ContractDialog.WithdrawBid
								)
							}
							showInMenu
						/>
					);
				}
				if (isProjectOwner) {
					actions.push(
						<GridActionsCellItem
							icon={<AcceptIcon />}
							label="Accept Bid"
							onClick={() =>
								handleStateChange(
									params.row.id,
									ContractState.ACTIVE,
									ContractDialog.AcceptBid
								)
							}
							showInMenu
						/>
					);
					actions.push(
						<GridActionsCellItem
							icon={<RejectIcon />}
							label="Reject Bid"
							onClick={() =>
								handleStateChange(
									params.row.id,
									ContractState.REJECTED,
									ContractDialog.RejectBid
								)
							}
							showInMenu
						/>
					);
				}
				break;
			case ContractState.ACTIVE:
				if (isContractMember) {
					actions.push(
						<GridActionsCellItem
							icon={<CompleteIcon />}
							label="Request Contract Completion"
							onClick={() =>
								handleStateChange(
									params.row.id,
									ContractState.COMPLETION_REQUESTED,
									ContractDialog.RequestCompletion
								)
							}
							showInMenu
						/>
					);
					actions.push(
						<GridActionsCellItem
							icon={<EndIcon />}
							label={isProjectOwner ? 'End Contract as Talent' : 'End Contract'}
							onClick={() =>
								handleStateChange(
									params.row.id,
									ContractState.TALENT_ENDED,
									ContractDialog.EndContract
								)
							}
							showInMenu
						/>
					);
				}
				if (isProjectOwner) {
					actions.push(
						<GridActionsCellItem
							icon={<CompleteIcon />}
							label="Complete Contract"
							onClick={() =>
								handleStateChange(
									params.row.id,
									ContractState.COMPLETED,
									ContractDialog.CompleteContract
								)
							}
							showInMenu
						/>
					);
					actions.push(
						<GridActionsCellItem
							icon={<EndIcon />}
							label={
								isContractMember ? 'End Contract as Buyer' : 'End Contract'
							}
							onClick={() =>
								handleStateChange(
									params.row.id,
									ContractState.BUYER_ENDED,
									ContractDialog.EndContract
								)
							}
							showInMenu
						/>
					);
				}
				break;
			case ContractState.COMPLETION_REQUESTED:
				if (isProjectOwner) {
					actions.push(
						<GridActionsCellItem
							icon={<CompleteIcon />}
							label="Complete Contract"
							onClick={() =>
								handleStateChange(
									params.row.id,
									ContractState.COMPLETED,
									ContractDialog.CompleteContract
								)
							}
							showInMenu
						/>
					);
				}
				break;
			default:
				break;
		}
		return actions;
	};

	const columns: GridColDef<ContractSummaryDto>[] = [
		{
			field: 'projectName',
			headerName: 'Project',
			valueGetter: (params: GridValueGetterParams<ContractSummaryDto>) =>
				params.row.project.name,
			renderCell: (params: GridRenderCellParams<ContractSummaryDto>) => (
				<Link component={RouterLink} to={`/project/${params.row.projectId}`}>
					{params.row.project.name}
				</Link>
			),
			flex: 1,
		},
		{
			field: 'teamName',
			headerName: 'Team',
			valueGetter: (params: GridValueGetterParams<ContractSummaryDto>) =>
				params.row.team.name,
			renderCell: (params: GridRenderCellParams<ContractSummaryDto>) => (
				<Link
					component={RouterLink}
					to={`/dedicated-team/${params.row.teamId}`}>
					{params.row.team.name}
				</Link>
			),
			flex: 1,
		},
		{
			field: 'startDate',
			headerName: 'Start Date',
			type: 'date',
			valueGetter: (params: GridValueGetterParams<ContractSummaryDto>) =>
				parseISO(params.row.startDate),
			flex: 0.5,
		},
		{
			field: 'endDate',
			headerName: 'End Date',
			type: 'date',
			valueGetter: (params: GridValueGetterParams<ContractSummaryDto>) =>
				parseISO(params.row.endDate),
			flex: 0.5,
		},
		{
			field: 'totalPrice',
			headerName: 'Total Price',
			type: 'number',
			valueFormatter: ({ value }) => formatCurrency(Number(value)),
			flex: 0.5,
		},
		{
			field: 'state',
			headerName: 'State',
			flex: 0.5,
			renderCell: (params: GridRenderCellParams<ContractSummaryDto>) => (
				<Tooltip
					title={getStateInfo(
						params.value,
						StateType.Contract,
						params.row.project.ownerBuyerProfileId === user?.buyerProfileId
					)}>
					<div>
						{getStateName(
							params.value,
							StateType.Contract,
							params.row.project.ownerBuyerProfileId === user?.buyerProfileId
						)}
					</div>
				</Tooltip>
			),
		},
		{
			field: 'updatedAt',
			headerName: 'Last Updated',
			type: 'dateTime',
			valueGetter: (params: GridValueGetterParams<ContractSummaryDto>) =>
				parseISO(params.row.updatedAt),
			flex: 1,
		},
		{
			field: 'actions',
			headerName: 'Actions',
			type: 'actions',
			headerAlign: 'left',
			align: 'left',
			getActions: getActions,
		},
	];

	return (
		<UserSideNav>
			<h1 className="text-center pb-4">Bids</h1>
			<DataGrid
				initialState={{
					sorting: {
						sortModel: [{ field: 'updatedAt', sort: 'desc' }],
					},
				}}
				rows={items}
				columns={columns}
				autoHeight
				disableRowSelectionOnClick
				loading={isLoading}
				slots={{
					footer: () => (
						<GridFooterContainer>
							{user?.talentProfileId && (
								<Button
									className="m-4"
									variant="contained"
									startIcon={<CreateIcon />}
									onClick={() => navigateTo(`/contract/${uuidv4()}`)}>
									Create Bid
								</Button>
							)}
							<GridPagination />
						</GridFooterContainer>
					),
				}}
			/>
		</UserSideNav>
	);
};

export default Contracts;
