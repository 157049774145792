import React, { useContext, useState } from 'react';
import { TalentEducationDto } from '../../api';
import { v4 as uuidv4 } from 'uuid';
import { UserContext } from '../../contexts/UserContext';
import { Box, Button, Typography, Paper } from '@mui/material';
import EducationModal from './EducationModal';
import UrlFavIcon from '../utilities/UrlFavIcon';
import { FaPlus } from 'react-icons/fa';

type EducationListProps = {
	educationHistory: TalentEducationDto[];
	onSave: (education: TalentEducationDto) => void;
	onDelete: (education: TalentEducationDto) => void;
	isEditing?: boolean;
};

const EducationList: React.FC<EducationListProps> = ({
	educationHistory,
	onSave,
	onDelete,
	isEditing,
}) => {
	const { user: currentUser } = useContext(UserContext);

	const [showModal, setShowModal] = useState(false);
	const [educationToEdit, setEditingEducation] =
		useState<TalentEducationDto | null>(null);

	const handleEdit = (education: TalentEducationDto | null) => {
		setEditingEducation(education);
		setShowModal(true);
	};

	const handleSave = (education: TalentEducationDto) => {
		onSave(education);
		setShowModal(false);
	};

	const handleDelete = (education: TalentEducationDto) => {
		education.deleted = true;
		onDelete(education);
	};

	return (
		<Paper className="p-4">
			<div>
				{educationHistory
					?.filter((education) => !education.deleted)
					.map((education) => (
						<Box key={education.id} mb={2}>
							<Typography variant="h6">{education.degree}</Typography>
							<Typography variant="subtitle1">
								<UrlFavIcon
									className="inline-flex align-middle"
									sx={{ width: 24, height: 24 }}
									url={education.educationalInstitution.website}
									name={education.educationalInstitution.name}
								/>{' '}
								{education.educationalInstitution.name}
							</Typography>
							<Typography variant="body2">{education.major}</Typography>
							{education.startDate || education.endDate ? (
								<Typography variant="body2">
									{education.startDate} - {education.endDate || 'Present'}
								</Typography>
							) : null}
							<Typography variant="body2">{education.description}</Typography>
							{isEditing && (
								<Box>
									<Button
										className="text-nKipo"
										size="small"
										onClick={() => handleEdit(education)}>
										Edit
									</Button>
									<Button
										className="text-nKipo"
										size="small"
										onClick={() => handleDelete(education)}>
										Delete
									</Button>
								</Box>
							)}
						</Box>
					)) ?? null}
			</div>
			{isEditing && (
				<Box mt={2} textAlign="center">
					<Button variant="outlined" onClick={() => handleEdit(null)}>
						<FaPlus className="mr-2" />
						Add Education
					</Button>
				</Box>
			)}
			{!showModal ? null : (
				<EducationModal
					id={educationToEdit?.id ?? uuidv4()}
					talentProfileId={currentUser?.talentProfileId ?? ''}
					education={educationToEdit}
					visible={showModal}
					onSave={handleSave}
					onCancel={() => setShowModal(false)}
				/>
			)}
		</Paper>
	);
};

EducationList.defaultProps = {
	isEditing: false,
};

export default EducationList;
