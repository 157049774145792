// Import the functions you need from the SDKs you need
import {
	createContext,
	PropsWithChildren,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';

interface FirebaseConfig {
	apiKey: string;
	authDomain: string;
	projectId: string;
	storageBucket: string;
	messagingSenderId: string;
	appId: string;
}

const webPushCertKey = process.env
	.REACT_APP_FIREBASE_WEB_PUSH_CERT_KEY as string;

const firebaseConfig: FirebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY as string,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID as string,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET as string,
	messagingSenderId: process.env
		.REACT_APP_FIREBASE_MESSAGING_SENDER_ID as string,
	appId: process.env.REACT_APP_FIREBASE_APP_ID as string,
};

const registerServiceWorker = async () => {
	if ('serviceWorker' in navigator) {
		try {
			const registration = await navigator.serviceWorker.register(
				`/firebase-messaging-sw.js?firebaseConfig=${encodeURIComponent(
					JSON.stringify(firebaseConfig)
				)}`,
				{
					scope: '/',
				}
			);
			if (registration.installing) {
				console.log('Service worker installing');
			} else if (registration.waiting) {
				console.log('Service worker installed');
			} else if (registration.active) {
				console.log('Service worker active');
			}
			return registration;
		} catch (error) {
			console.error(`Registration failed with ${error}`);
		}
	}
};

export const FirebaseContext = createContext({
	app: undefined,
	messaging: undefined,
	setOnMessage: undefined,
	config: firebaseConfig,
	webPushCertKey: webPushCertKey,
	getCurrentToken: () => {},
} as { app: any | undefined; messaging: any | undefined; setOnMessage: any; config: FirebaseConfig; webPushCertKey: string; getCurrentToken: () => Promise<string | undefined> });

export const FirebaseContextElement = ({
	children,
}: PropsWithChildren<any>) => {
	const [app, setApp] = useState<any | undefined>(undefined);
	const [messaging, setMessaging] = useState<any | undefined>(undefined);

	const getCurrentToken = useCallback(async () => {
		if (!messaging) {
			return;
		}
		const registration = await registerServiceWorker();
		const token = await getToken(messaging, {
			vapidKey: webPushCertKey,
			serviceWorkerRegistration: registration,
		});
		return token;
	}, [messaging]);

	useEffect(() => {
		const app = initializeApp(firebaseConfig);
		setApp(app);
		const messaging = getMessaging(app);
		setMessaging(messaging);
	}, []);

	return (
		<FirebaseContext.Provider
			value={{
				app,
				messaging,
				setOnMessage: onMessage,
				config: firebaseConfig,
				webPushCertKey,
				getCurrentToken,
			}}>
			{children}
		</FirebaseContext.Provider>
	);
};

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
