import { Avatar, Icon } from '@mui/material';
import { getFaviconUrl } from '../../services/helper';
import { MdOutlineImageNotSupported } from 'react-icons/md';
import { GrOrganization } from 'react-icons/gr';
import ExternalLink from './ExternalLink';

const UrlFavIcon = ({
	url,
	name,
	className,
	sx,
}: {
	url?: string | null;
	name?: string | null;
	className?: string | undefined;
	sx?: any;
}) => {
	return !url && !name ? (
		<Icon className={`inline-flex align-middle ${className ?? ''}`} sx={sx}>
			<MdOutlineImageNotSupported />
		</Icon>
	) : (
		<ExternalLink href={url}>
			<Avatar
				className={`text-center ${className ?? ''}`}
				src={
					url
						? getFaviconUrl(url)
							? getFaviconUrl(url)
							: getFaviconUrl('https://' + url)
						: undefined
				}
				alt={`${name ? name : url!}`}
				sx={sx}>
				{url ? null : (
					<Icon>
						<GrOrganization />
					</Icon>
				)}
			</Avatar>
		</ExternalLink>
	);
};

export default UrlFavIcon;
