// ContentList.tsx
import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import ImageList from '@mui/material/ImageList';

type ContentListProps = {
	items: React.ReactNode[];
	collapsedByDefault?: boolean;
	additionalActions?: React.ReactNode;
};

const ContentList: React.FC<ContentListProps> = ({
	items,
	collapsedByDefault,
	additionalActions,
}) => {
	const [showMore, setShowMore] = useState(!collapsedByDefault);
	const ref = React.useRef<HTMLElement>();
	const [hasOverflow, setHasOverflow] = React.useState(false);

	const toggleShowMore = () => {
		setShowMore(!showMore);
	};

	React.useEffect(() => {
		const trigger = () => {
			const hasOverflow =
				(ref?.current?.scrollHeight ?? 0) > (ref?.current?.clientHeight ?? 0) ||
				(ref?.current?.scrollWidth ?? 0) > (ref?.current?.clientWidth ?? 0);
			setHasOverflow(hasOverflow);
		};
		if (ref?.current) {
			// Only IE doesn't support this, in that case just always show the show more
			if ('ResizeObserver' in window) {
				new ResizeObserver(trigger).observe(ref?.current);
				trigger();
			}
		}
		// Trigger effect when ref is set, or showMore has changed
	}, [ref, showMore]);

	// TODO: determine cols based on screen size. e.g. `const isSmall = useMediaQuery('(min-width:600px)'); ... cols={isSmall ? 1 : 4}`
	return (
		<Box>
			{showMore ? (
				<ImageList variant="masonry" cols={4} gap={8}>
					{items}
				</ImageList>
			) : (
				<Box className="relative overflow-hidden max-h-56" ref={ref}>
					<ImageList variant="masonry" cols={4} gap={8}>
						{items}
					</ImageList>
					{/* Don't show gradiant if there is nothing to expand, ends up covering actions */}
					{hasOverflow !== false && (
						<Box className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-gray-200 dark:from-gray-800 to-transparent" />
					)}
				</Box>
			)}
			<Box mt={2} textAlign="center" className="flex flex-wrap justify-around">
				{/* Explicate false check since undefined we want to always show it */}
				{(showMore || hasOverflow !== false) && (
					<Button variant="outlined" size="small" onClick={toggleShowMore}>
						{showMore ? 'Show Less' : 'Show More'}
					</Button>
				)}
				{additionalActions}
			</Box>
		</Box>
	);
};

ContentList.defaultProps = {
	collapsedByDefault: true,
};

export default ContentList;
