import { useCallback } from 'react';
import { useAlert } from 'react-alert';

export function useProfanityFilter() {
	const alert = useAlert();

	const hashText = async (textToHash: string) => {
		const msgUint8 = new TextEncoder().encode(textToHash.toLowerCase()); // encode as (utf-8) Uint8Array
		const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8); // hash the message
		const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
		const hashHex = hashArray
			.map((b) => b.toString(16).padStart(2, '0'))
			.join(''); // convert bytes to hex string
		return hashHex;
	};

	const isInProfanityList = useCallback(async (text: string) => {
		const hashedText = await hashText(text);
		return hashedProfanityWithSeverityScores[hashedText] ? true : false;
	}, []);

	const filterProfanity = useCallback(
		async (text: string) => {
			if (!(await isInProfanityList(text))) {
				return text;
			}
			const errorMessage = `"${text}" was flagged as being NSFW. Please contact us if you think this was in error.`;
			alert.error(errorMessage);
			throw Error(errorMessage);
		},
		[alert, isInProfanityList]
	);

	return {
		isInProfanityList,
		filterProfanity,
	};
}

export default useProfanityFilter;

// The following list is generated with a list of english profanities found here: https://www.kaggle.com/datasets/mathurinache/the-obscenity-list
// I used RBQL on the csv file to filter to only those values that were above a 'mild' severity. The list oddly enough does not always include the basic "canonical"
// form of the words, so I had to union the "text" column with the "canonical_form_1" column. For the canonical_forms that did not already have a "text" form
// I provided a severity value of `-1`. I then took the list and converted it into a json object.
// Admittedly, the hashing process was just for my own sanity as the unhashed list is something I didn't really want to have in source control. It unfortunately
// adds a lot of complexity, async functions, larger file sizes, additional operations. But it seems odd to have to have a NSWF list of things uploaded to a work
// repository, I think having an npm package that does this for people might be something nice to do. The other option would be to create a ML model that does
// detection but that would be a whole other level of complexity. I'm hoping that this will be enough to keep us compliant for using Stripe and avoiding
// any illegal/inappropriate offerings.
// The following code was used to generate the hashes from the JSON object I created above and assumes it has been assigned to the variable `z`:
// const hashedProfanityWithSeverityScores = (
// 	await Promise.all(
// 		Object.entries(z).map(async (e) => {
// 			const encoder = new TextEncoder();
// 			const data = encoder.encode(e[0]);
// 			const hash = await crypto.subtle.digest('SHA-1', data);
// 			return { hash, severity: e[1] };
// 		})
// 	)
// ).reduce((previous, nextEntry) => {
// 	const hashArray = Array.from(new Uint8Array(nextEntry.hash));
// 	previous[hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')] =
// 		nextEntry.severity;
// 	return previous;
// }, {});
const hashedProfanityWithSeverityScores: { [index: string]: number } = {
	fea1272a90ba4d2403be4c6cb9b0bf00e304e918: 1.8,
	'4b8cfc115af495125c084f26210ab91158f1ed34': -1,
	'6a3578663cb2d556758daac5b70587c84b5e7a6c': -1,
	abc1f33c7c1d1404428c8da06026c36aa24462d4: -1,
	'82da4c33e3a5ae9e51def466745e2c8965fa1476': -1,
	'2f61cb7837b83df50a7fffb58e802b87679cdaff': -1,
	'0ab79927a592f1727d4d2c6abd69b159462b349d': -1,
	f73127d74a6afc9d56eeb12da554e3765018ccbb: -1,
	'55472b0fe1f21afef6eb6815d19efd25e0b4ce23': 2.2,
	'7c8a961e37adf5254fa066c86430b5fd832bffef': 2.6,
	a7eea0a0a5aa33e914efdb01e3f04f080aab5a6a: 1.6,
	'3b19ecd69b492a40e3061f17786b33c28f504239': -1,
	'90c9ffff2321fd5425e6a800a52c6ec72c79ddf1': 2.4,
	'860e7ae8800175cdadd65ef45c00a72b974d7ece': 1.6,
	a2b7429c2d5480505d5e2673c8e4eb580f65d80d: -1,
	dc23dff05cc944a620c29ebd4310fe9e50a69362: -1,
	'11dbf66d28b6e3b7508f9732611e5e2634ae4be6': -1,
	'306305805401b52bebbafb0b776590cb91330d04': -1,
	fcff8ed87ab3ea3eff50b10378504a4fc360788c: -1,
	a7a9e7e59519897d21dd30a85174187253d385c6: -1,
	a2a0cb6a4343ccf5eea23cd870a3cb64ec831f2b: -1,
	'4c7b9744a0a453bf3cf2693b70290b4eabf7d1bc': 2.4,
	'23f1e99688866724835a5e010dfbac3e8ce094ae': -1,
	f37b67a5ac29df4eec5f815b58a66ecb726dcbd0: 2.8,
	'926dee392274290fc2228b21a01e0c7ced85cef9': 3,
	'684c5c9cc7810a6320746ce6184634b428364106': -1,
	'38e7bf84518b3bd8d6e097b8f795bd084f170524': 2.4,
	c2524f73dd44053eb57a101d19e06e86aa839215: 1.6,
	a209cf6c99764a49344cba4184cba97c21b7f592: -1,
	'266f83d202fa3da4a075cea751b4b8d6a30da1a8': -1,
	'547fabfc254555911195d234582b555a62628edb': 1.8,
	'38cbc7bbe54a6a9ed7b210d683f12b57dc013f6e': 3,
	a7db60b68cd852a35d67c1c3bd41c0ea8e757ff8: 1.6,
	'350576caf12e3b12dfdb31d5a555395b71951905': 2.8,
	'6b85eb510d784593413b02fc27a67a6a24574697': 2.2,
	cb9b1ed27a40f22dcdc0e678268999c3893b0d58: -1,
	ab14d94055e71ffa6e93f213d950c654f42fd275: -1,
	ec7c1c6abc5177cda3e180fcd72c7e1c5ccd5c2f: 2,
	'8c4947e96c7c9f770aa386582e32ce7ce1b96e69': 2.6,
	eccbad6466bd9ceb463e9c5ee73df4f96712eef7: 2.2,
	'1f45855e4097abe90bfd5187b23d141a00d529ec': -1,
	'50a326e74375135283030398ae064b35be8f0303': 2.8,
	'39f6f95327b31d796f8d305a29df43b1d585e3cf': -1,
	'2a75e29d5e18bcfecaae771d6436f2069d8cd48a': -1,
	'16912fdb53c1633ca66171f56bc0652de521844a': 2.4,
	'1ef3c16f2ef023c107968ca7461a4fe8851e58e1': -1,
	'3d9afbb84d2dc2c91fb03dc4fb7fbede5069b527': 2.6,
	'2716cd0558e09c5aa905eb8eb98fdbb67f39c97c': 1.6,
	'737de7673447fc150108aa2f7295813b1d15c93b': 2.8,
	'99e9c387522d6d6464b0a370be48441db80c4904': -1,
	'9b69f0e3308a40031f2c11534925412c5f0e61c8': 1.8,
	'0391a1e58f324b3a0c79d32dd09436bd45bfc773': 2.8,
	ce6a8d7004c8a77566a1ca530b7b0c94ecd79da5: -1,
	ec654d9c75e7c1ccf8905b533a62f295688d4def: -1,
	'9e7246decb9be211161f5486099666cd6ba027bd': 2,
	'38d0f91a99c57d189416439ce377ccdcd92639d0': -1,
	e100c69afd60845018996a3b0722f1965da9bd35: -1,
	'7f67169fe8181b579366eb341ec2de8977657ad4': -1,
	'3e58f1de9b47ee6c19616a30f293baed4da5b794': 2,
	'2871591e64f027a23fadafaa00305a8bd3484292': -1,
	aa4a6b5ae048ef427a7ad41740390870c42bee44: 2,
	d64a57b064fd148d12bf59655e3f00832d700315: 2.8,
	'2bdd8f5b28f1a74c7fb75ea4a4125bd5ee18f622': 1.6,
	b29acca0f1bae89eb86c657ae265ffb04d9d86fb: 2,
	'035547f67529e1ed8ddbd2944c5dab3c4e5dcfc5': -1,
	'17bce85ed09917e549fa8f886706fdd919f18b18': 1.6,
	d5c5c4f100fe10ed5203725698823a2725ebfc06: -1,
	f0b5bcedceba24502ea412707be522b8572bdd3b: 2,
	'34da3d303be5957417aca018bda58df4b9c73770': -1,
	'6e9e2b753aa22fddc3b22189bc7527b5594c7ad5': 2.4,
	'3b7875c2bf964f1d095c3bcd7a6f8a502b8db680': -1,
	'81bcd017b082c083bd3cff794ecea8176b60d08c': -1,
	'495eb2dad0938705e1400459d7a6a697809bdb86': 2.8,
	'1397127d6966f199fa0074dd04f084b970f4c881': -1,
	ef43e0560381ef319d94ad4bb1948e03beeb31b1: -1,
	'6f6b4395559d7fd8c2f6e697022fd97cd306f279': 3,
	'9ee1a1f1f1772aabc93e0a38ade4c870a50447f4': 1.6,
	a5ca7f27efd9af199f3fafaf17752c40d36c3ac0: -1,
	'3844f1150f735253bed7c52a4d6c1e07c473901f': 3,
	e3884bfae8f1f0359ff37ccd6f518140e2342a77: 2.2,
	'5150d2104c8cd974b27fad3f25ec4e8098bb7bbe': 1.8,
	feb359f10411b20d5405c7f2948dad069b7d47bc: 1.8,
	'5e50971cb25b5c86b6e15c25b761463434fc40f5': -1,
	'3a11dc42cb09ba1e095a41172eac627e20c7a106': 2.6,
	d6752fa818754f25f8acd46bd7edf5154cf2cee7: 2.2,
	'6122291990a7b5141270dda3e265a66c4cacae15': -1,
	'781c5c74d6e4f98ac4c0021143f3090cc9078d3e': -1,
	'0b21020d50f7a68a2be811fd3ae9c02a7c4635aa': 2,
	ae972466bae3c23198910a01fc438139181eff84: 1.8,
	'22bb59c3d8765cb5393b2716ab0e6985d4a3024c': -1,
	'138947b8405b9dd2813d9d824b224eac42f45c70': 1.8,
	a258d468b4fe38e55afde3fe4e7d0a7ee11ed688: 2.2,
	'172ad7b0562d985ecfe64e238b69cfe5faace2b8': 2.6,
	ab87d24bdc7452e55738deb5f868e1f16dea5ace: -1,
	'1f49e04244df917090bab54f9ebc0b54191e3707': 3,
	ad386a2b5a6403a1b0cb2cd94dee5d65bdf42736: -1,
	'867268472cd8f956dc38e7181f1768a42d417d7f': 2,
	'85fe8de475bc9884da850bb5ac9dedaa50a5f850': 3,
	b1aa14315bdbc2bdb4db442c176b0819f2eae550: 3,
	a6359bfe6d73f1e626b3c850cc1eefefebad03e2: -1,
	'808320ff4626e3e3e2347c9b152cbacc09b1ffe4': -1,
	ceb3373dbb23ca5c66570926a6cab5f0b5976ece: 1.8,
	d13ee344c4eddc5552947634dc8de683bafbfbe9: 2,
	'71bcdde68808c7363210b244ab69b9d12ef4fa89': 2.6,
	'12d5d235b7ca28dcd481e7c6b97d60857b36395b': 1.6,
	'9dbb71a0ac6e346778b2df939565377c0fa39cbf': -1,
	'4b3bca0dca39206bea564df0e5f1bf1e484e8ff5': -1,
	'3e83b13d99bf0de6c6bde5ac5ca4ae687a3d46db': -1,
	aa002ef2ddfa4e104362ee00c9a5b7e5e28efcea: 1.8,
	'0cdda96fa585a9940277ec1cab8a47863fdf477a': 2.4,
	'9b392740ad071146e8d210b9e45b7f02f44bc439': 2.2,
	'9ea11e6776346577af69a09cee91b3cc0773ec2a': 2,
	d78166822da221ca551f070cec4a30e8254674c1: 1.8,
	cf44c057f3bafd9deea47ed3dcf3eb3aa766d2e2: 2.2,
	'04ac84e9192557acbfdc60d742c27ae24e2c8de2': 3,
	'327c4c086e0a12d81682196ec356e59c127dd28e': -1,
	d525d64bd66e9001c8c4a3cbcbe180de42381cd9: 1.8,
	'9cd5199e634633f73d7bc8df0cd50f7e9c5bd234': -1,
	'35ed5406781ebfdf7161bbbb18e16cb9ad1f3be4': -1,
	c8645c4a303b1e048d61215ba0247c4495b95d2a: -1,
	'17544c65e715b451de0583705667b665ca4b50c9': 3,
	'9f104303e758bb37f77ade128ed83155b109617f': 2.2,
	'320d1a474a0dece4a7fac9136406fd6d63d62ec5': 2.8,
	e3a82186438a9a2ef80c2a3fdba0182fb9b2d5f1: -1,
	'12f5c2ee515cd282210a0d0cdff07c80c493a149': 2,
	'691e6f0af080d5c18bbeb2e7f088ea214bffaffe': 2,
	e85a5cdcd26dd52a8c9d6c7f5bce19b49779e26b: 2.2,
	f2ca1e73a09b4bb088f9e058dcc3edc4c55775e0: 2.6,
	'68bb04bd54b8f6c530695e0b77de298276a0511d': -1,
	a657551d5577cab20a1eb1ea3f65eb5b83c4349b: 2.6,
	'8ff30b9642b75fb852e8255a171dc731dcea7018': 2,
	bff272e9d673fa941d0a1920551d01a695516140: -1,
	'67618303e018ded84d8a638bc6d2f3f3f78e433e': 1.6,
	'55d3893c8cba2a8f3500459892deae5d23958430': 1.8,
	b9766249ea771f62b07ba32a9a6bc632092fa592: 1.6,
	a112a7b6c37ab76d207f4abca26a384aaca2d190: -1,
	a4632becd715fdbab87ef070c5bb0685350dd01a: 1.6,
	'02ac484597c896c5aebd246b0f08825ce547b603': 2,
	'86b761a3bba60506a0804937c4ea4c5a0cec3eb0': -1,
	'21554666d2756b4e4f348999054f68fe3b7c9f02': 3,
	'6ee78e56e1780105f5a98866841816c6a458d992': 3,
	'4f79cc4a70ffd2150f61471f2c104811aa5c9e17': -1,
	'4ce39d3ee07cc9e8ca566ab0b677b3be3b850832': 2.4,
	'65419c44e6e2e0e4f79d2621fce8ccfb16bc0371': 2.6,
	'81e208865feb8a6b81a421665d9dc4bec88c8a55': 1.8,
	'56432f9791e434379f28195f5299624d40c53994': 2.4,
	e8c325cb2828fd170aeb4598cf5df3751734fac8: 1.8,
	'28ddedf202ccd45a129e4d6d5ead893f1423657c': 3,
	'91e9cc4e769bbc15114a2dbb813c6d53806afbd1': 1.6,
	f9c8390832e5e2e44310fa916e0b61eea8d3ee49: -1,
	'88b6ad09f7cf671aaa7850b7b05d1ef3c428cece': 2.4,
	'11ab3a5d9ae5e9b03b532c949a2508139a4c1652': -1,
	'4dbc8c31da4ffd6e039f26f7a2707d0cd15fc563': 1.8,
	c369f99c21d313f5e9ee2b957b93cc70c3b0204d: -1,
	'63aef6ff8e1e37cc18ce1b02b5a1534fa1a7cd6c': -1,
	'3852a12677f0ce9e494fa47f9371cf419c8cdbb5': 3,
	'921f208a404db48b79a244743c5c8cf0f04ed05c': 1.8,
	'2dab506953534e93ae8a253869d9d2fd87535161': 1.8,
	ed38a7180d66e5341bb15bef4a426b23ea8c8b3c: 1.8,
	'26fe597d2aa306766261ee94410d3d91e1691ff1': 2.4,
	'9fc3d224aa0cb6d66a9505f516a9e151fe0d641b': 2.4,
	cdbfacb5ad8c4331c290f811a17adaf93dea46ba: 2.8,
	'80d26dd4dc512a8f0b2d184be08397735f5df832': 2.8,
	'2046c912b8c4852d75ad18a32e8f67f3ed96c43f': 2.4,
	'82b42df0e51469967ca966ff8182a8231580c71e': 1.6,
	'1822111b2b56f6dbcb0f8ea8d224544fecbd3ea5': 1.8,
	'9944fa86c1e40a9977d4d96b064871df045aa44c': 1.6,
	'019affcaf3a8cc4071ed0a52c8d0968384a30588': 2,
	'39e63283e268e182523dd150e6846b1926b02535': 2.2,
	'6f502dd259a23f150209ac0e5385acaa3b0e5bcf': 2.4,
	'3a146fcd4e21fcd7598bd5ed5c65416ce00d366a': 2.2,
	'3bef21100ba7cb4e792d6c1ce7a5f3c8e6157ed9': 1.6,
	fc09fc658ab087ddf2fe649e1eeb29e81f90c252: 2.2,
	'088e4552e408c2d1b7eee2575f3be82e812b7c49': 2,
	'308fd76ff9f65ebd62bf4b6dbdca030c4d254bb7': 1.8,
	c58a92c74fe65ff68c36be4daf8380710029c9c9: 3,
	'5992dd9efbc3651d1bc22f905bd327c7dc323047': 2,
	'2b7339064cec3be11e9b864ea7508cdef762c4c4': 1.6,
	'9d1c15d7a0f87fa6307ae14c592b444ab1a8510c': 1.6,
	'25ecf89d442c776206cff43f0dfaf49a55553aac': 2,
	'0828bcf6d1e4cc58bb1387931926c3ee0f418ff3': 2,
	'5f30b82e1c510907999f1208dd5dc782f626f1a1': 2.2,
	'601719d4bf8aab3be673e15ede1a4d373c9ef66f': 2.6,
	c280bdc670db2fed43e843b6e24ff2e7527656c4: 2.6,
	'489a32a780c90c2eb7e382ec82539157fa43219d': 1.8,
	'1dd3a24f66158669e69ccec07487f099d76114c0': 2.4,
	a60fe8af6ec2ff301c3f8d2bf143a2e565b75da8: 2.8,
	cf72759acf737db99bcf3f76a96ece705bb96388: 2.6,
	'8d064d41297c26b917b6c62fa33f7b1aedb56a0e': 1.8,
	fb4f7ff9a1a1dd1c8e30c0ce3f919ee4d1b04349: 1.6,
	f2a49273a142c1e5cd9551708571eb38578c96fe: 1.6,
	'69d4c977a1c15acf3e62a3da4b437d33c9c12199': 2.8,
	'73cb67ead4bab820d1d72058e86cf9522cd38974': 2.8,
	fb79f82f0111daa39fc8e2619ff20d160e1d6182: 2.6,
	'3bc24235d3e8a383b5d7a5c1e9cae22ec5c423b0': 2.8,
	aa5e4bf21a936e02c0ba9a71c40fa08209587e8e: 2.6,
	'074cade2767aeb9f5c0e254b24d49105a04051ce': 2.6,
	fd132471f5408461c537950f45f3077cb3b1d1b5: 2.6,
	'90613b4aefde40e836d4b3c90f4277f6b00acc46': 1.6,
	'3d1286664065fd767b5e23486d730b7c46c8dc3c': 2.2,
	f8f15698fdfe7d261f379ddcfadf00a8d896b4f2: 1.8,
	'471b6f0163bc07aa9a8fa1bc5d36dbad6a948266': 2.8,
	'911b7000662ffa03fb4cc8a2403bb1ffacfbb067': 2.6,
	'8c467f6f48494c0639964a56b9e6fe4c0f7a0966': 2,
	'8302b12cc7ce88d0e594da95fc05bcd5417d392f': 1.6,
	'17cae01c0a7ead2d25eaae316703148f22cc3557': 2.6,
	'913b88acc202423cde4a75c6d80f0808cac38865': 2.8,
	'7208d57afabbdb24d5e5fbd90e0b08d8f8160490': 2.2,
	de55e5c83840eb37dc6457f890e5688a13ac7785: 2.6,
	'07a3104b7af08d1d2bd576c01c00bcb0951635ad': 2.2,
	'18fbd158f212f4131df09bb8a4aec32ccc90d2d7': 2.2,
	'4d95f0ae81ed736b3efdacb4b357014298ec153a': 2.6,
	'0f2ca16e9dc2f4629d9ecd3079c94f8795966f4d': 2.2,
	b4ce3f2d1cdbb6ba40a06cc1067c458084663841: 2.6,
	d6f0ce075214bfae84b13f898b9bd9b7084adbf4: 2.8,
	'6931f889cd56103b7300d2c148f4c4e2dcd2b85f': 2.8,
	ac22b1b6288ade5fb9b0297db02585f36a02cbd0: 2.8,
	c763e6b80495df57ce5fe245893d63bb9e3b2c37: 2.4,
	'749dad3e0635c3c7963099ec2c953f49b9fe1e68': 2.4,
	'954fb5b7085db5d7d0ceb8b1ffecdf9262e8f32e': 2.8,
	'583fff117bad79549f4bd3833b32f1031149d423': 2.6,
	'1cbb3117ef5112b1a69ddb0997b12e6ee085a093': 2.8,
	c774d585e3634798286628894b3920bcb4ea211c: 3,
	'9c52905df3d82524d3dd34239823afca35af6fb2': 2.6,
	'99020f6d928b0de6bd1046593aee5572569867cc': 1.8,
	a2bfbed4fe08641f801c049854cd2481859bbe61: 2.6,
	e60a7eb679948af2bb49c0e540fb211d33ac5dbd: 1.6,
	d5e8b878f7165b70a3edcaaf18ae8463ff6959ac: 1.8,
	f4591eaa814a33fd18975ddae3ab7dc782575d90: 2.2,
	'28d916b46699e3d45573f89b4a4c0a6db50b1aad': 1.6,
	'6dd432750304d42c41f0a8dff7716b8120dda2ee': 1.6,
	'7bddeeebf4b76ebbed087185d58bad55a056d91b': 1.6,
	'6ab3adb9fbf33a7d0bf74cf60fae326df7388a31': 1.6,
	'4d1e1cbdae487f8babb4b54dc7d261f7bf254a13': 1.8,
	'943a2d89558d950aa7aeb560415b20902d78ae47': 1.6,
	ebcbccb0707d0b0d316a7aaf7ebdc5af22d73a01: 2.6,
	d9af52596e32036ba7cfebdc95481059ce98bb2e: 1.6,
	'17088011556a0efbc86c1246479fc88fa562d5d4': 1.8,
	c0049442a7ca6d3b3eae5bfc4439eb4fd9e52464: 1.6,
	ebdeadd7799fe19dc9a10bf1be548f7825a7975c: 2.4,
	d7ed17fb20000b42a24463a47dfc6ba84fb8bce9: 2.4,
	'2cede82ff7cd19cb154b736e1d336dd6375ce5fb': 1.6,
	'30ac96f32c58f1ac4c16ae620004254409e11de5': 2.2,
	'5944c66be3bfbe1e125d55a0bbc0fa62233cca6f': 1.8,
	'4916395e3e4ef3464f923f8007bd6d599462ec52': 1.6,
	'4a315297f8a7fb1e729e8db050435953432f4477': 1.6,
	feb57fddb90793f29c3622864d3194f823073766: 2,
	'8feb6886889dd89c23fcadd70adaead0ed364b91': 2.4,
	feef4ae0a3fe25e0e493d874c84f81dca483463a: 1.6,
	d8e53963e77f03754e82af20cc12a76809a3926a: 1.8,
	d3cf9409c26b5ac1a85338378c64ee351b812961: 2.8,
	'72b436a15fded27e35cbe2903b07bb7ae22f0805': 2.2,
	'67bdb1515836f9cc3197570a1449176f5d670b46': 3,
	cf41ac5913881a9ed11ac2562a069e938b411adc: 2.2,
	'55113e61ce5e0b1823364582c85718c14973362e': 2.2,
	'1ed36ff337ef508b87bf66c87e30d6ae2402f803': 2.8,
	a35a14053ace6cce28282eaeb2e5cd2c38786561: 2.6,
	'7ade74f5fe04c1a1ef584587a2e147850eb69a8e': 2,
	'17776c3d135e0d109ab208f971e735e684912644': 2.2,
	b723f2469db65edf5fa40ea2b0ac70b692353812: 3,
	adfc3dfab2b098d0e5c58907f372798027aaa7b2: 3,
	'8c4efec8d1609d4df77a916b7454aa3adece5ea8': 2.6,
	ff417e6ad36c9fa839fd2ede2ff1df3b7b5e4f47: 3,
	c74af0a9344acb8e500ad6275f42d0943ee9b500: 3,
	f26bdfea1c74bee7c28757b1e6c8bca3024ee05c: 2.8,
	'2fcc803f7a21dc7cb1f14ee090b45b8c5acecc04': 2.8,
	'7e5125dafb80590b7ced47c20244320a9a521303': 2.8,
	'98fe82c92f6da8756fb28ee36cb4add8833d6701': 2.6,
	eadd39cf41747792adb7dec1c430b5897fac2cd4: 2,
	ce35d169d8eebf750c6ac5862c6570418cf339cd: 1.6,
	'84d5774d9afd5d10c875d95c0a9178bc81a551d2': 2,
	'5d37fe4a86531bbbe87a3c2900c9016eb9615114': 2,
	ed7f79aa235f984cae390a95ae35af6ee9611380: 2.2,
	'54e13484e74f02f95498c6d69e0e0074db271832': 2.2,
	'252c862ddccdfe2a810380111557b7882b8e82c0': 1.8,
	'074be86d0fed950c8e09d8b75e6491f45b88e321': 2.8,
	'2b3481076f035e5b800a7aabd87cc168dfea31da': 2.6,
	'7efb26cb49e1e99d3b7293678ff1aed465fec77e': 2.2,
	'03ccb4218cc7286c1241f63882868b8385219350': 1.6,
	cb2e4e2ab792b8227219655b8c98d2c52bc55548: 2.4,
	dfc64f4f0bd7393ab5dc05ebc60adda1847e0d85: 2.2,
	'2d1d32d41203527e683dc2dcc40753fa44ee90fa': 2.6,
	'1d1afe1c2c7e8ee609c324f6dca24d4ad0c76ba0': 3,
	'1eff608b900b0f29d6a49655a467bfd9ef0cc816': 2.2,
	'3a2d56fcc70c515b58fedb02575423a705324cfb': 2.6,
	'8ecf1d1f9b899d313e0e5d3a94cde9a3fb0a71f3': 2.8,
	'193e8c199eb77d16467736c83e813b1f65e662aa': 3,
	'33d3610ae81ac3174f77a5e45d7e056f8647313f': 2,
	'6510127b21c6231bf6648682378987f2abd2420a': 2,
	'179404fdba49fe83c7fe2d10df73d32416c259a5': 2.8,
	b03b16a3ee6aa1c78aad0bfa7b88b86bb29fa788: 1.8,
	eb429c815fc9ca220a86d870abecae13aaf59f3d: 2.2,
	'243ed01244fa65d7963659313b7e968ed6746384': 2.4,
	fedc6eb64584713114ff31578d6ffacfd59697b4: 2,
	cbd0dcb9d9492b2c7edc1891c6b70445b56a57d0: 2,
	cf3602591402586588ca8d8874b76a5ef2aa5f51: 1.8,
	'9eba959cd2a8f3873bdc657c996df0c00dbc2551': 2.4,
	'8cfade08d6431d05202c7f85071fa52345b5526d': 2,
	f173f50a040edba8a248e568d8fb57f35de168ad: 2.4,
	c5b6c18a77f916f4a3ff085eaec62b30daa1aed5: 1.8,
	ebace078f313bb44ccbce2e1fa1cc2c572e43008: 2,
	'49d1cd03a6ca6a5513152065fc30ae938796b581': 2,
	'549a74c8e6b1e63fe24f599316b491bc15009217': 2.4,
	d2d5d4e308eb46879c7b530b5b2032f1b4d4ff7e: 2.2,
	e789222a6f952a36dc71ae930095d2e57272779d: 2.2,
	'9c339dd5bce9ddc1a1f5f66cde26d24bef2ad57c': 1.8,
	'6777fbc944f5c9b2ffefa2de6a2da16beba10053': 2.4,
	'818deb0f6a6d68ff5af44bbc81e3bcf1ad55cc46': 2.2,
	cc15cf242f6d0ec5087b39b98d2af5550439a1e6: 2.6,
	b1813a88a187856e6a306897e6e60eeee56de36a: 2.8,
	a290e4ce5d32f19f8288eae5cd9a8e80d32ed6bd: 1.8,
	fd54fcc190bdc37c51190c0e05396afb185bf25a: 2.2,
	'0a74f2cafa63b328ff22e26b2ece8496c19b96eb': 2.4,
	'697093e26862d9300b0133f797ee8afd94cd9c57': 1.8,
	'24a9f0133a99afe7248aa9f9977abef307c585e5': 2,
	'6b25a5b4b2adbf1576df704d0344c2aa56914864': 2,
	'6e71edae753777b5f7398c42c972949668290cd0': 2.4,
	'246d2bfd2558b5e12c618f80406360ea53541b5a': 2.2,
	'8894c26728ad10e4cd00fcaa55be2550430e88f5': 2,
	'234f285eeaee890658f92b372717b62ff6308050': 1.8,
	'2b4a6f10ae1acf196040361f51417168910a1f72': 2.4,
	'7daa77ffb950c66b915bbdc947da15da668910a6': 1.8,
	f065ad1d378befc43a16859c5314be82a670af48: 2.4,
	a7a98a588cad35f1b67b28190655ec4e01b33cf8: 2,
	c7cc0c94ff5db20ee86b40de192ed52aebf9829b: 2.2,
	'37e691591b85ef536ca0ea5973e7465d215abec2': 2.2,
	'19a48c7f3ed72acfa6d329e11bafc2f437e56a04': 2.2,
	'7c6d0e5cc6d43fc06ac37f3484d3f730302af0f0': 1.8,
	'7f50fd4afd66e6540da10249bc7f0cce6a71cce7': 1.6,
	f80966d76cb7d0a6af0e959456e84fe88ff8f0e4: 2.2,
	a1cf7173579441c34ab8f36116e97eb70018a81d: 1.8,
	fbff54117376c757cb190810c2b425738a745979: 2.2,
	'88796a0be7b81dd70364364709f6ba8c8f042e71': 2,
	'8734f3028f8f74b0202a891c4cab68f0d8ba47ef': 2.2,
	'78800186937787f3f63077de06c594e3ee62c054': 2.2,
	'2d01b70e3022493a63b85d7971b26a8ef2e72322': 2.2,
	'308849fd41a2bd49f6be87b798e62fc6da6ffd8d': 2.2,
	'2f7a49f8b363ac8c2f12bccdc07f685ddeb9dbfb': 2.4,
	'5824aceec77613cec1fb818ff1aadde0f96d66e3': 2,
	'4e29a02a78ae2e945fccdf57175da9fe62a7ba7f': 2.2,
	'28057fb12ff23e001c5213d37a0309bd564ade8f': 2.2,
	'2f7507b6ba103d240d9d7535dac60ea697be7ee2': 2.4,
	'42390657f1d22c9d5b02ed346614906e40576c36': 3,
	cb8c25e6a3ecb5eb28fa33a8ec5e250a27a3e26b: 2.2,
	e0965bba7161046346088aa8de009e7d304c457b: 2,
	'5e43be2e42fe0aef7afdb50954507bcdb996677d': 2,
	'5d5c1553e43eb62f63d031952a353af0de0b60ac': 2.2,
	'246e2eff91d5560a087422125c864e4b79fc48d8': 2,
	'55c6154d5601a1aa1b6dc2e98e057c511696f9b2': 2.2,
	bc75196a63dfd15dea5001aea9d4af3662bcc13f: 1.8,
	'03e1d6a4830d777a1be124b8395d19c37fa645d0': 3,
	'91f6b67adffcaafe03d9279b1d89fa65e8f65ab1': 2.4,
	ae5d4cf2729b3e279a6dbc3131f5dd41c45196af: 2.8,
	'75d4d0bf01d6fed26f76193923c0b62350fc884e': 3,
	'8f709233af3ed8d5c3751ff385ca6a9823cc04f9': 2.8,
	'6730e0429bc41ea326f0f00232e5c8aea76a6aa9': 2.6,
	'42d10b6702487142d09fffcb83e0eaeb72d9cebc': 2.4,
	b98b0bd237f5a973f75065c21956dcece685c051: 2.2,
	bb9430ffea94c2e9482a8e043ab98f3736e64ddf: 2.4,
	'940fdbeee6c97c5e67d7e71730239e92db203c3c': 2.2,
	'9fec1b57edc22e180f75a075a7cf3a574849e572': 2,
	fefa72ed7cf3907ca4f3c0d164ac9328e8b83c1c: 2.4,
	'342794a44daebfc0eee75d72b86a62a78fe75260': 2.8,
	'627b6a2d00146bb48fafdf49d9f06b11342470be': 1.6,
	ed91b610e7de17d55da5ada282645dd9c6063c54: 2.8,
	'8b2a67ced93a8e1b7c92795c82f3db94cbce4c6b': 2,
	'2e439f323ba35dfdd5e0a4b3127b6d38a8d7e7d8': 2.8,
	'21d2e2259ce5149822f69fc1077d5fa104ee5e07': 2.6,
	'92432aa17a7e67a1f1c53386548af5356c35deec': 3,
	efcfd39602cac0399f2b0a8ff1cf6d448618cd50: 3,
	'3318e0232e9af43e9b1f7df2b15a0de8900c4d93': 2.8,
	'882460a5b586aee34173c2b73544e98d96e5f183': 2.8,
	d64783694e67ed14f77a4b288b889de7c1d2c906: 3,
	e6021cf32b02535cd7e3d39f8f2c94d60e84e863: 2.2,
	'3873c5effa534de959e71ce277fafdac1cc8d2a1': 2.4,
	f11e53b0dc3a0164fc826465d2ee56b1a0452d5a: 2.8,
	'120883f6f146ff8fb04e9f8831c4977043bd7649': 2.8,
	'0b8317f80ab5134afa952b8b5ee61f10da874496': 3,
	'356731e4d8730af14ea5166119c523afea9f0cfb': 2.4,
	'5d15b9f9129a0402b77ebdbdc0b6bfc0ad55579e': 2.6,
	'6dd5b451f21c98291159d37749472c32be6d114c': 3,
	'23c1bf668c18253208357a3fddc5c54c47d703de': 2.6,
	'9ebe35aee8f22b24aaec8f73a6fa5be354bf80b1': 2.8,
	'2ef6800cd2d425b9f0a30166cc20aa735f1dc63b': 2.8,
	a98bc250ea19bd20abaf9f587e79f314a6a65617: 2.6,
	'9121afd99208aa35b0328ed57b2619f1cb4140e4': 2.2,
	'0a51aab8e6d1311b30886047e7018435f5b33c25': 2.4,
	'0e7ab29f5d49f25382f8682bce18ebdc289ce467': 2.2,
	'2a010ae8140c77d9db48723e76802a1dbcf7c3df': 2.2,
	'622fac2f7a935d461ffb4c0432017c371db2d37d': 2,
	'16d54c4e5a97538ac17d8e5bfdb35c51dda9b6f9': 2,
	cc3447a8cde2790d4ef7495b246da9e638e60f51: 2.2,
	d3c3fcb16d116f95f93d1b710ecceaba506d5769: 2.2,
	'2672399459049e89a1f0d82b563c43b8d19a7158': 1.8,
	'0e73be50fe84fbe681b7d07f2081782d45624827': 1.8,
	'25f5a07c78270387ddc4e6c03a826b5f7769dfe4': 1.8,
	bb4107ce0d5a488899b69f3d322893d3e8ffba6d: 1.8,
	c6274be4f7aaeed9db6209b17e5952f9281f5338: 2,
	'4daa3a2af84e5a065c006d190eec5ffe7bd6aa28': 2.2,
	f73a1301b8deb334f671f9c3a3b3ec5991ceb52d: 2.6,
	'4249ce353807927a597ab2f6914b67b24b7bef81': 2.4,
	f5c4da51fb899e211cd7945439006987da577509: 2.8,
	de99ed5f5896d36f118919b0eea9ac92cd91b96e: 3,
	b4f9f9cf3ab0538522a2fb9855225c70483469e9: 3,
	'8fdf432472c535b89775cb85fca540c9e08ebd89': 2,
	'0e7f6a5304f3238a20f4f940ac08105cd31c33bc': 1.8,
	'878052b9e58ff4f9d23a0abc165e4f92db4ede5c': 2.8,
	'5bf0560f75f70134ead79cb61276fba494ec8e01': 1.8,
	b05e40b4c6820e3adf672e2a89e322482cfe1715: 2.4,
	'2139c211051fa192b4bff877d4f69b328450caac': 2.2,
	e12a5f41e6240822268ca44cfdd66b712fc8796d: 2.4,
	'4ba9e60683991734f92292c6bba293bd39dfd69c': 2.4,
	e86a7241056fe06c56526b7a5e278e6e17b0d088: 2.2,
	'2fc3d3098c005cf1e1b6e744c09e8e6ec790fb81': 1.8,
	'3fce94cebaea4a1ec5a23e2c43aec2e1d33266f4': 1.8,
	d5850053c7b0aacf4b4ee9d28b44e145d8a6fe86: 1.8,
	a901bd168845488afacbffc3cd76fe49a94d8652: 2,
	'00a3844db67fc519258f4f76aba0b95d080f07f6': 2.8,
	'5edf257ab0926e163da2fc52df82e5d97ade5f2a': 2,
	'9896e50be53f60dd269b1574b95935bb9fb46a94': 1.6,
	'14b12c67df1abe00392168105b7615cf3d9871f4': 2.2,
	a0db344e59173213dbfb1effe5b3904fa0b4109f: 2.2,
	e0e183616cd2be4b12a5271e567c9b8a16ed1a21: 2,
	'41c9c5951519e4566fa9fd007d30d7c6db3cfbde': 1.8,
	'526517823c464b6dded16d3bd9eb9ee8a555b66f': 1.8,
	'2113e0daa9ba16fbd58785bb5e655cfe59fa784e': 2.8,
	da5e54573088c4702c421aba2b0885d846d52df1: 2.2,
	'95612d8418d64225f2c10b33c0b62570e60e753e': 1.6,
	e48c7ade3f3ff820251406e09460bdd800e668fe: 1.6,
	'4b997bf99bc43ebd0ea0e2e75d81650a1a2316fc': 1.6,
	fd207f2e662b9f0e88f7e4a3d4787cb74a566665: 1.6,
	'1bfb319989c0a05b5985b272ff3a2e7c4b34cfcb': 1.6,
	'8933077536dbaf019df29dc2cc5487301ad8a21d': 1.6,
	'2bfa35d65dc8b95c3f132da30637e7e218150e6b': 1.6,
	'37731e53073af1a6ba08a36c994d34d64afa63b5': 1.6,
	'7d9bd377f66ff17dd5971175e9629e780f2274e3': 1.8,
	'72d8d155091c6a63b74901168d68999a25f3e158': 2.8,
	'5b9cff0fc847b76e8401202c564f095c745fbe5e': 3,
	c2329421d25e595d8c3335311f1e252633615951: 2.8,
	bc852540638556f5c73feaff9991852b4b5170ed: 2.8,
	'2913d248c9d0a810ad72407a1c2eeb85fff77f70': 1.6,
	'31afefd64587effe6663e054db61ea13eee7bfea': 2.2,
	'99c696c1e101579797797a5fdf71c7d792519d20': 2,
	'94da58dd7d9b3cc7aad021c3bf6af897dafc3f1a': 2.6,
	f6668d1ca2c37fd715c8017bc771b62913277478: 2.4,
	'0d779ba75ac3df91f36e30915431e904263dcb54': 2.8,
	'941c8a79eb428fddc570b64a1ca53cc02e4549cf': 1.8,
	df9cc7531e7334c2cd9d1fc40ba1973819b43571: 2.2,
	f7b1f4397ada2d1b4c431a04bb6054f07513a63f: 1.6,
	cd079c482fb49d853bae4cb264c6ad6bf658f661: 3,
	c0c9bf7aafd31cd746b96480e6f03fa06c22c73a: 2.2,
	'9dfd8342836754525b85ce09a13e6211f3977bec': 1.8,
	'1a91481cd5cdecb2f1f3d9abef43cfdce079ae5f': 1.6,
	'49eb289aa2ea498ab0c640cd77030db1aa886c0a': 1.6,
	fb8b2d1e4d38a0794fbaffc5caf4809f7ed08cf7: 2.2,
	'95ba128463bdf2b6bc0320e667b267a0cafafa63': 2.8,
	'9fd99ffb470a1fb130088bf6d1ed90d3e0f476ff': 3,
	'61f133d3172fe4017ea78c6d1ff365c549974c34': 2.8,
	e8a9775420b2c956bca47557bc245425673b60cc: 3,
	ee7c3339791ee5173956549015e9784cd25e2d45: 3,
	'1a2020c7f5daf62a34e860f754c6964482de0567': 2.8,
	'9fd33468584d1fa33dab3d9a5afc96368142e414': 1.8,
	'14eb5e1a5a014a010d5df061df1cbc9e9d3b4bd0': 2,
	'49f851a56cc1dc51b98f3bcc2932037566bb75a3': 2,
	'19b6550a98abcda38bc305eab89b2eeeefed324b': 2.4,
	'9c4a4fd098d092fe23e8085454bc6094fda9381a': 2.2,
	'0dd49fbce00918a3d26c3409af120f36c41acc6f': 2.8,
	'368d3245e849c1dd34f1cc273adaaddef7e9971a': 2,
	ac04b70e6de36da0333a6467549a6453d2882719: 2.8,
	'89a3fcb2c5839715a595a8ace30bf08b5dce43f9': 2.8,
	'3d83938025d9f023516e3b31ec8241bdde26a995': 2.8,
	'5763bdbe72d73f9f1621abeef49908bec9e1d8a3': 2.6,
	dbe4c5f449f2a8a6f0ce8b49d0870cdc7f2bccfe: 2.8,
	b5a6135a73dd47ebda9a6d96075034589411505b: 3,
	'01a21245032323fcec627111a3da8158b64ed4cd': 3,
	'00558134786fb37983695195909da5e47807d76d': 3,
	'51c9085101602821679108ca19af3a653a919b9d': 3,
	'7061978f5aa358ceb41389c6b3ef0a6fba01fcc5': 2.8,
	'39b55fd51e4485407dc8d3e48ff48b59980c661f': 3,
	be95628b863b5ffaa2a45290c2c0aee8a7cdc970: 3,
	'5ad8aa07a30e387da42abed5d65f4db57eedb4e4': 3,
	'6ec8601887bb1a5d082adf20b7b0b84e1c16e1a8': 3,
	'2f5cb8cd18a97a84bd212055134074b1d8e46abc': 3,
	'69c2748d71bf854623bfece67cac60293174e41d': 3,
	cdb96284a9ff3ab3018f5263b19a544b70539ba2: 3,
	'28748b467ca7b90aa802f4d659909775a006d5ae': 3,
	'19dcd9af19accab5d7f6410bca4ebbfa3f245df5': 3,
	'93fcf6531f66b5834b000d52db7aa6c2b544e278': 2.8,
	'0b77230a89e424d4c170fae02614ee955573c852': 3,
	'86b630bfb5e2bda7a05eb29158cf0168a6921acc': 3,
	'1fae9ad3b0edac80dc8e931b8c41fd9f982a408b': 2.2,
	'7ffea63fe7ef890ae75d60f18ff246c134236b40': 2,
	f111c4dfa8c2d3ba96c734e4f7f7ae4c0bb1faa8: 2.4,
	'8c2b4cc8393d168623f811ceeb9697762273fab1': 2.2,
	a4985da48a9cc48aec7a6a394ab19b93c2bdb90d: 1.8,
	'8d9f45c85cf46a7f5f4298ca7c72dfddaf738ad1': 2.2,
	'8ca9174b120e5ee49eb305c104e053ce6381daa7': 2,
	ad030d69309d634570fc66e6410686d04e976c66: 2,
	d18baa33cb80487f8cb2715a2f4faa96afa5b77b: 2.2,
	'2d35e42bc48e70fef3319b114a4edf81f4b23d28': 2,
	'7e76df415140856088ed14fe7762a67991a6384d': 2.2,
	f0e4aa17250a28f477ce9380bf21bc51fb96f965: 2.2,
	e113566274097d51e163c4392e38872cebe9dd48: 2,
	'0f77528b270726b7f886fffed80c2a09072ac79b': 2.2,
	'973f474f06d7dfd0fc11c049212701f1f75f01f3': 2,
	'4dc47bb77c28d5e63e8a409d4f6d38d18af651b2': 2.8,
	'9d23c7a30f9462e056f4b6ae814eac832603c2bc': 1.6,
	'34d81825e5127cc42997e9edc4297e497540086a': 2,
	c1226513178bfc91edb4c943ee1c744fd55be645: 2.2,
	b4a1c89d527f353f28509ccf188cec331abd49f4: 2.4,
	'31c2c8489e9007f6f89431f068317f4f1a6c8738': 2.2,
	'397f5f5a2867ce154ec613e004125d2f7871b433': 2.2,
	f0ca4701901b759eb37f1e748045eb99dc29aa2b: 2.2,
	d32c4a709de4bc5dbcbc030c203d6a21e7c1960f: 2.2,
	'6bea8abc8a84e5ec2c70a916327fcf65cacc430a': 2.2,
	'76902663a07222a71d1a651d7354e8582b5ca3d0': 1.6,
	cd17b31d0e882c748434b3d29f19d77c5ae80d28: 2.2,
	'21794c5fc9d99496438144125d38b4f4f12440f7': 2.2,
	be352966d326716ac0bba173a5d4a3b06b1fa8eb: 2.4,
	'4188bbe82e4d6d881a305a1c7b27b6fe1452a39a': 2,
	b66f1e884ed77b718898ce2ba8ba79c8a8430728: 1.6,
	'5d617c5e35b8e56071a7f7a29e810e0b5524f3c4': 1.6,
	'751b0e440ef1d1a19b37637ec02063de84add384': 2.6,
	'7bed01e2d6fa9cbae79e7f0927514699b098bef9': 1.6,
	'974496d8296ce7259322f961972e8ea417a8ea36': 2,
	'84bbaa17e3e6304d98a6bcadacf6615667b3bf97': 1.6,
	'0c2c5e667224d908dcd2bf3cf4317e8f3b78ed17': 2,
	e17d254b02eeac4f85bb914eb775b8e4a2dd2ca6: 2.4,
	'5063141bda5a6d7c3f29cef356921fcb03aef355': 2.2,
	bf389094bb625a77eab0681df4ec4c244004ddbf: 1.8,
	'3f755e65bd9c861cfc84dbf4480593c634aecf05': 2.2,
	'6ac17a0472e73166b3cbe8a9933f90c908dd5f7f': 2.8,
	'00abb0a14a14629d612c265fbfaae1948e39eb44': 2,
	'2646ead29ca931f398f7020590a6076064f0697d': 1.6,
	'60cff055f1c8c96d04aeaa34fab1bd0c54c1e997': 1.8,
	f76fd3c0de191cd7c5de77346801328c640462ba: 1.8,
	'70fa8e2a2cdbb01064913c1db2d11b08c9ba2439': 1.8,
	'55391ea0166a88452ea2edab6e85d1c03d46dcdf': 1.6,
	f3cd4d63b495f2185eeffc03e89e9d392481d0a0: 2,
	'383d188580dff1ba2df575e80d8e03f14a6c8ce6': 2,
	a8dfdba3baafcb9e2f41dc1b7c59f4c6687524f5: 2.2,
	'38ca84584b3194275615b189cf1498390c7cb281': 2.6,
	'55a6b8cc0de2aaee5d1e0b0ce36c51c05ad29422': 2,
	'3cd74c3e0f2ae584e7b5311a9b9537516915a1b1': 2,
	'0997e32a0e6104db80f04001516f9f130839799d': 2.4,
	'0f7107bede39f499d9e079c098559295f4ad022b': 2.4,
	'686f467c974c6908f30873e47e5683173358e93c': 2.2,
	'3f46b3d508c85549228bc669167c0e8bd1de5ba1': 2.2,
	'93261f9a728f3d4b36a8a7d58c65b129e91c2e7b': 2.4,
	f9d275a0c5072863410fa68d5dd64a87a1b7b693: 2,
	de8e420a4718994b4945b3f1fcfc70e2ee62c98f: 1.8,
	'26a12c0c9b41077a0584247ef286d3b94d664c4e': 2.4,
	a7f9b77c16a3aa80daa4e378659226f628326a95: 2.4,
	'93c8479079bf98390f4f03a0864896693fcb3031': 2.4,
	'17b29cbe5b481cc9ab6bd89e3176992ad79605e0': 2.8,
	'205c2eed20f585b847f15cf7abd3e2c22100f453': 2.2,
	'26f0592d1978833432f947e25029a3e0d232eab9': 2,
	fd766cc2c73e1205c3e1db31839d7df634d6dc6f: 2.4,
	ff289d1f1ee70dc0be8ceb6fc17e6a6f615e1a98: 2.4,
	'32dfd1bd7e63a5c9a5c605852b3defab6b4042e2': 2.2,
	'66d65458df6b45ad29fcfb4bae8ef61e04254db7': 2.6,
	a7e98258c61c66345bf6b480d85ab7035d6dd01b: 2.6,
	'305f0a31538beaacc5187a587baccfba6624edff': 1.8,
	'3c39995721c03df88b196e252c9dfa8045d8b5c9': 2.2,
	'5e807afcf4eafda9c7c9ac74224184ac3ce34055': 2.2,
	c177922cb7715a94aa4758eb140e08bfce4c5a04: 2.2,
	'020770e1370be34d72c6c85f63786a50f8d7a5c5': 2,
	'595ed903cafe794bdf46e6739dc0d51d3c396d44': 2.4,
	'148507a61a9b121e1b37172f1b8f84cacc38dd69': 2.2,
	'4e74e8c57ec99f3acad57baf3024efea4e8a9c3f': 2.2,
	eb38b0b2ad36096380e2d11c250f7dbf90bd5518: 2.2,
	cdab1f16ff40b9f19daea88f0dfc76192be28f9c: 2.6,
	'8cfa59729cf49b7c29cd516207c729a8b0752eb0': 2.6,
	edec82bdbb6e51a051d31dc38175fdd65fb51b67: 2,
	'08bc5beda7a9157ef65f8d90a511c77c8bedefa4': 2.2,
	b3cf318008acbba91e15d203350b293498a56c74: 2.4,
	'7e380c0a172057fd52814bc6ed8c80a2a8d40d39': 2.8,
	e55e66c8cc3a76de6afe156947a19e75ae0ea51a: 2.4,
	fb360bffc93a3b7c3a09bb584338a4649361674e: 2,
	d547e6f739421a01d347a0bc830acebe47f5c353: 2.2,
	'646bc029222eef74f35f174e185f1b460c46dedd': 3,
	a69b929629bfeacb45188c073545024f67cc942f: 1.8,
	'7a6c557f67002ac89d079525babfec1b7cf4fc66': 2.2,
	'004e215d7eceb48ce711516dd10db35e9d4388b3': 2,
	'2162370a2f1eb87f086162d26eec4b2dcde4433b': 2,
	'21d7397ff9b6e9e01112bfacd042dd12c13e0c82': 2.4,
	'95e261baa83ed6ef264e94950321f97832f7d2f6': 2,
	b33a210730d7c0e3681a7a4879143962a798f1ff: 2.4,
	cb103335aefa7394e750eac6a6e66995b0e3f277: 2.4,
	'12a8f09d657f3210773636c3afe31d10690c6ea2': 2.8,
	'77269315ca38f837e1ff34a09c87ac3ef84f377b': 2.2,
	bed7ed8ac5a5448eebe0827b795f80810fb37f75: 2.2,
	cc41daf83970a98f0092d4193530c337c77aebd0: 2.2,
	c4c4ff2230c5b7f1c2c620a3f3792578540362bb: 2.8,
	'7d3fba1cbd22999f3d23426d09e47d9779e81a4a': 2.6,
	'02cec970e055d352b11d8c22359fb5509e3cac52': 2,
	'918f83efb8ab4adb889ebd49546cc8e6efd27668': 2.2,
	'3b8aa6cd9847210d90eaf494e517432d3dc15593': 2.6,
	ae2d14e7c85a9cd74f73b1c7f5f4d72309014e1e: 2.4,
	'20f732ad725d115196ad1f29a6345e5fbc6c56cc': 2.2,
	d19f1070e75b63ca36713bd1d801e0f4be89211a: 3,
	'6ea50b6566e6f7e868e4ac6d8aab60171ac6f2b7': 2.4,
	accf1ad1a8acc512c680318c5efc5965ba34bb96: 2.2,
	'513978f9f9e8b5906efaf40a8257608b5159dae4': 3,
	'89541cb3b08f3109ba222ef06248c909ad0b30fa': 3,
	'18db53b051ea8b8bff9f329710d56a72ed8fb805': 1.8,
	db2d44651079b37053ccf20bab86fc4a682fea6a: 2,
	'116d4f33f57ad623d95d008de7272588ee45b02e': 2.6,
	fc0e7d5b80b02e04bf0dc733c7fc125ac015e5d2: 2,
	'2ddee02a793ccc6de72317968fc00ab858b13eff': 2,
	'5398d36a16dfc13c94feee971895f3a32c06fd42': 2,
	e123b2697474e3253d84679f0a3f384782b50c68: 2.2,
	'8f45a94ae2ac54f06b18186ec2067d429e90c486': 2,
	d1bc77889121b2f07df5e394ee82aea6aaf4ddc1: 2.2,
	'5a1b72f9f5503ed84805e214a2d8e31521cd0f6f': 2,
	'0006613a010761d42457e4aab5f9eb43bade1174': 2.2,
	dd242634d33287631d4cd7144a53b86f9d44c7fd: 2,
	'4a04a3022f871123f34462f137b6021a4a0cef0c': 2,
	'2276ae62e2613016d872cb1385ecae03bd610b10': 2.2,
	'45b64e1cce41dcc9d6e79cafdb947a58c401a0d3': 2,
	dd21fef208c454bc594958cd4923dd8163790b66: 2,
	b4762c9fd44617c12d18f79f0e97a89824c97c20: 2,
	'7f982d3b395eb5ff754848706eeb5fc657924cc4': 2,
	a3831c3b8c5deea3110e52f4986faefba0526faf: 2,
	'3cc10e7d23f8dacb68b1ca7a3df1b28286d5e641': 2.2,
	'415b20f833cf9162bcf36a1c5197759be7da0253': 2.2,
	becb7b3fbe2bb34772a03b92ec55ca4b6a91cd5a: 2.6,
	'4e07cda1b96208110dc417e5eea789aa619f0931': 2.8,
	'27d2b822a950a4dadba6aec6992aa1f5a1103eb7': 2.2,
	'8cdd48bce5d1c2de5ebe7e88a658552f3e507672': 1.8,
	'4951da11ca09e6a28776bedaf6d0e9a7bcf8c643': 2.6,
	f3765df4fd090984a6b87c339e2313db2f09b706: 2.2,
	a9aa1f18b3cacaf016394c4f71bbad1c981f0ba4: 2,
	'6b84e891666042d06a9e3e28545e447dd85ac091': 1.8,
	f5b2f12c7c6dc20c983cdf2fc72d59686c7f74cd: 2.2,
	bb42ca20ea04edd3eda669592def60929f923c7c: 2,
	b52279a57a24abe0b17a40d5a9aa5630ef45d753: 2.2,
	'65067b27da7a1050b4a40572d4db3ed7ab59c5af': 2.4,
	f51c674820afe5dc5cc4d6461fe62f15398c69df: 2.2,
	e72faac2163cce2b63ca9eed30d3280a25900d41: 1.8,
	b31e4121405a662bfdfd7f5f45b1020c68be54b4: 2,
	feadd67d8630adbb58cd21982f9e2b9f60e9cd40: 2.2,
	e18fe07e5a8de958a6af85a861a666adab79a616: 2.2,
	'2f9b60ab8a8465ec4a36f659cb51c6fc7d02d97f': 1.6,
	'5d175ab5817359815a807004b82799fe43627806': 2,
	'084ccc775cfb765bee7c51e4c1b2bf5a1bdf5052': 2.2,
	'275da37714710c53d2811928fbd28034e2096124': 2.2,
	'4cce33e0eaaafff4b24e90ce646509005fdc6834': 2.6,
	f576beb74a22b7261fba31e62ef6103ec10522c0: 1.8,
	'8a1c68d43cc3484f846f5ce187cb551d013072b5': 2.6,
	'09b58e0d2f19259b946c6ee623bb0def0a430fcc': 1.6,
	f94f14e1ac7ec29df72cc71b70e630c241dab071: 2.6,
	'0f852446c792ecfff21e2518b70d1786b3117382': 2.6,
	d14c1414a9911ccebbbb070788da95630540df16: 2.6,
	'6a4de0623310e43fabe89a3057b82bdec0d53b51': 2.8,
	b7ff77a7764012b76f49ac490e4220cda21efa1f: 1.8,
	'9923e2ac3054b2e166d0b1a315f1382b2b4655da': 2,
	'390ddc6815eba316905ce1913a60b17953da48ff': 1.6,
	'3491c4f6ad4f627ac9795df3d04240564aeb2999': 2.2,
	'33dcfddd224171ccc09d708d0000f2b2bc50600b': 2.6,
	'3c71b37aa705380c9d2c22552e894e6bf1a82789': 2.8,
	'856f300a3430361d1279fdb022286569b09a4b3b': 1.8,
	'3b0d1c4c0ee7456858a9813d96cef6b4fbe14af5': 2.4,
	'91812099915ca3f25062689b69dac3e961fa0324': 2.6,
	eaa29ee7d3b0edb0e63e8a2301b13c2ae3722ab0: 1.8,
	c6a62baeabd18c620247961f955dada831cd7de8: 1.6,
	'0f575561cb83576474bee3f77cb68a8fff1846c3': 1.8,
	'5f8d6704fe47216ddd61abd132a1c47a2f3367b5': 2.2,
	'321a78063e17eca358366530c597577221fdd5ed': 2,
	'0fd1c08d42b8a0fcdfd538fbd99bc5f5ba4d29a5': 1.6,
	d8cbbde664c304cdb07c21dfa92861673812ce01: 2.8,
	d56fb099286e283e760d075857b74b903a512c91: 2.2,
	ba67c46493a223505d595d9fade14e9d6c63f77c: 1.8,
	'3cbb9de322068568e53b8b1ade5f09c1685cf1ed': 1.6,
	'6719c46a0a0a18abfb6c3dbce45c7ccd772b243d': 2,
	'73ba593c4d45d4b965e124179a187da495c6ee5e': 2.2,
	'57d97eef3e42be8c6b24a8f4a038973f20ed6e8f': 2.6,
	d758fda408a91b4b10c04516ead0d9ca50591d16: 2.8,
	'5f1cf5c4d79e77dc2374bd104fb9208e305a2938': 2.8,
	a21150d5e470a8cff1348f83fdb7e54c61420536: 2.6,
	ad94b9ef550fb11b40829d6c3336291b8d07ffb4: 3,
	'251618a77c5f341df949e69f24eb6dd771f24637': 1.8,
	'702fac942b8c1ae1a67232087154348518d5c4ad': 2.2,
	a91c33dc028096b433a5604f1546d933f69c8fc6: 2,
	'6b80a34a0eede39e3eeeb763544b36eacef20407': 2,
	'4206cbda0194d14b94cfd9fb4e7e0f1282b580df': 2,
	c71230fc13c6460d5a6a731cc29e7ccb5a89a434: 2.2,
	aeeafe7a70e89ac523349a577e754159a52e31fb: 2.8,
	f4560fe525c6fa05d76b330b911f096ae71c9293: 2.2,
	'8c9d9029a4b74852674ebab0510a57251522fdb2': 2,
	efd4456ce675b0121ff8f90149a6e1fe9dccf8a6: 1.6,
	ff6fba0e0b57fb0a963bda38651da17009ce48a5: 1.6,
	'17faaaa9b3d02a61d0f88a6cb4cb3a5d5a346719': 1.6,
	e0adc9c9f4b17cc218efae200918a28a599b7057: 2.8,
	e85b1f13ef46f5155b50934365981303409246cb: 2.4,
	e6bdbea33c8471589ef3991c52307f9984e02a2d: 2.8,
	'3d339f23e1fcf40d82efde8e37cb54da282a5376': 2.4,
	b16cc62e1f09ec7d17cca4c8fdd7035727a5d46d: 2.4,
	'3e9707e89b5bf4c90d0c55417f24d2984635e92a': 3,
	'579746a42f7d03b182d6d31a33d3ac4995e930fa': 3,
	f7baf15e8a2c4c347f224e68f2f02080af7ae94f: 2.8,
	aabe9e2b5805a5d36742a31b27293ae6d321b455: 3,
	'84de7b631aca9a2a5160e942960a7a7c171a6365': 2.6,
	'0be9bb1cbc53c12f59af930663615690939dc635': 3,
	'7deef72dafbf9ac5e9f990c6a594375f7565fa12': 2.6,
	c7d64095d2c8a83f1f99b76cbd6d7c5c4a54491e: 3,
	'6eb79ad5d56a703c96567da62a0d577c4fa2c4b4': 2.8,
	'854485cee482cfe3d12278f4663d6a833b16e8e9': 2.4,
	c4bf9833b957686155503273d0ad98f2221d4bcb: 2.4,
	'9c453490a40b4ae1f3fd6f505b7fb7e23174bb59': 2.2,
	'1880b1e46d1953b2e33b732f883ced0ca88755bf': 2.4,
	'058022947326a505f86579b4261c6e1a307e1917': 2,
	'913ce4c7ee009a6bcee3b1a6928ec8ba9aa09d8b': 2.2,
	'66ebe8d140e74e4624939930546da85a753f6b9c': 2.6,
	e8e762e5119bdd1c5d68dc9f81e59d8bb7276288: 1.6,
	af0ef18403ba42dd1b35896ae36ae692698f9d86: 1.8,
	f3178b7927c8dfe4aa18260a7775938a8d0af2ac: 2.8,
	d00a9927a72baf6eed7dbcc579225841191ce47a: 1.6,
	d83c0f519c621eea0d974ae9f9dbba8fc47e9ec3: 2.6,
	'156813a99712f7da5a75ff7f5d1b24a9bac5e9bf': 3,
	'55feb7daad157d88c1d69f3b8ceb01af1f69acc9': 3,
	aed81e09bd028ed81a4ac85366bf7ef761ccad88: 3,
	'6856b245ad2fd5ef8b36bcfdc78f6c7d3bb703c7': 2.8,
	'2db5ecff8abfdd59a2e5c35def7bb1777c8f3a8f': 3,
	f419e659575ef4552fa3324ea15403b2ffe4cd9b: 1.8,
	ab440bfa32423efc9c19140ba541551e4eb94ad6: 3,
	'57e3ee37e57bc4d8d16b3055a6f316d5cf8f39d9': 1.6,
	d56c90121e33a797b66f9f2cc37e39d27255a30f: 2.8,
	'039f5979c51541cf0afb8aded5ba73594999bde0': 1.8,
	'8666ebd85b290558227ad73bcd256d20177a0b5a': 2.2,
	'112262cb05f0087cc93194b3ef52373149b5bf27': 1.8,
	f2dadb91a6d3daf809c57ee4d002d074607a8c8f: 1.8,
	a5f9d2101e4d8404a387b78bb47d4059760aef1c: 1.6,
	'065920c0c4dd00ad95ada6b9887e0939f1ee8292': 1.6,
	de639b947d1b9575af5dbfca5f511f418ac8c60b: 2,
	'1a7f4060f2f38a74ccd6cfa7face084e9aef073f': 1.6,
	ff932c2bbc9986478483f4fdc2475623bf3eef4c: 1.8,
	'26e2f59985ea1f1317b2a9c19a35ae638be376cc': 2.2,
	'39a2f270bcb77fa9b08c22b95bbe388a2daad636': 1.6,
	cf67b6f7b722581f0722066de60b008360dee949: 1.8,
	'7a06f4da373aa91a509533174ab088e9ef6f9dcd': 1.6,
	f8c76b01d37ed1f110765abf806582dbd940f1af: 1.6,
	d31e6d6a5d0c66bb84fc4eee4e560a99362741ac: 1.8,
	'02509793e05fdfec6f6f8dda9f83ce4da6529542': 2.2,
	'73a4292db184b5e1986e3397712ce71596a82a3c': 2.6,
	d557232a87e828b3d203b5af5543a1659071e735: 1.8,
	'1763cef866f7aee8572c86b68d23d59e64b92041': 3,
	'4fea6f7fcd7cf91d10325a8bc23c9224d18ca55a': 2.2,
	'76ed92d279f4d4bef81eae0ca8947fdd2d15f068': 2,
	df6248d712b6bd144de7e788c8609776a7a991c1: 2,
	'78f95d06d0a4010b2aac3bfbb207af9dfd40d20d': 2.2,
	d4fb3a5ba7c8e17e3e3cc14df44cbbc7e4613733: 2.6,
	'729a85f44a779fcd3fbc570deb6b3fafbfd99ce3': 2.2,
	'5f5525d7cd09b270d4e060aa4d3d67290a70ac1d': 2.4,
	fee12d740bbb925a83c36f12aa64e17938fb029d: 2.4,
	'96fa6f5afa884e26d4a3c2c859f10dd6e2d910fa': 2.2,
	f6ab3c6b1802898ea98871bb8a22873b1b4369f9: 2.8,
	'0118e95e5fd1aa89d69247862c729d2fefc38d3d': 3,
	'7ffbb23cdf5712a696f0946ffb6ab53817658ec2': 2.6,
	'5fb61dc399e20d673e0c82ac121fdc176076ea30': 2,
	'18a06911b173b88de6cedbf30f6f688af3d46a00': 2.4,
	'92e7ff49ce083a51c988b9ba7c784be6b90f0290': 2.6,
	'753b50487f96768895f79cb6293e13f18ee85f2f': 2.2,
	'671643ebc9c2d2ef37a5fa0541743e4b712babc5': 2.6,
	'1972a03137550a96fd7547c845ca69c90ae9c68a': 2.2,
	'969db55d0bf94545d0b317c955817d7c4e6d70e6': 2.2,
	'8c6255dd3048a4c3d87ace7cb6cd53282c34fb48': 3,
	'567f4d2aee3c1d340deba1f6154cf8de43e339e6': 3,
	'42ae10ed2190d8fcb7f31a0bba9e3c24162dc82b': 2.8,
	cc4309e1b39135f4336769c06af6c33bdddd8f0f: 2.8,
	f1d8604d2a21f6ccf8499d782bcff1dbfc8c2d66: 2.4,
	b1acccc6e64dd8b261d65c0d6951a144a0f5d04a: 3,
	b3e1de1df8f2ea9e9633809f28e189ac61ff4141: 3,
	eefaab6569651ed5bffd49d53e2b23507e209665: 2,
	'28f602d452cdea8821d81f08fb15947c39f6af2b': 3,
	'416b54bbb4e489ad9c07fabf002b7645bfa9f72a': 2.8,
	'71baa624e91344bcae8ec386dd9f17968fd88e67': 2.2,
	'25aeee570e2200bfaa8d123571238cfbf04002f3': 2.6,
	'2206cb09ed97fcb4aca0589f29bb3aec5b03a1e2': 2.2,
	d21c37629012362d349f5ae5c1c105582c349408: 2.2,
	d7deb446e3cffe90e72f63708aceea8a745b850e: 1.8,
	'51b9dcc3ff9096a4bd6e4629d8d48de887d540a8': 2.8,
	daa7736ad5792a9cdb4aee57e33729182af09f20: 1.8,
	'2aa594fbf5f23a308fc5e5a7ddfdfff50774819c': 1.6,
	'3da89190a00c07f51ad6ba419e13cb35ec439169': 2.2,
	'370beda21de41e1e3095c09227618f1e5e8cd298': 3,
	'2848a594c6f56274a2662c591dc31a6d2e5616b0': 2.8,
	'7106a43bd56de7d881ff12753a53ecc26926f4de': 2.2,
	'0a0214e9231e16ff39df95b39fde5452afe72c56': 2.2,
	'374ae6d9d39930eb83413825df74aa31f39669bb': 2.6,
	dd05bd81ada13c1458d3c9bfb3f319c13e332979: 2.6,
	'7ca27eed38fa64e732466fe0636dd175ea4b9fed': 2,
	'90b99d80d393768d6c43ba9352daf6ccfbb63c13': 2.4,
	d83bbf4b2147d2facbc744c292c1e51860c1a3fb: 2,
	d7b529c531e7692ba2f0e5189ffb2aa2c548e5eb: 2,
	d57f7becf928f1043dba9552ff796e1b524e9a93: 2.2,
	'7a8c6fa5aab382729bd4ed712c024283531a1ad3': 2.4,
	e67b6f2746dd9c3496b99486b12977bca8958467: 2.4,
	'72e2b5785083ee29c843b0e115693f6a186bbb03': 2.8,
	'7cb4f1a7acf84e7ff18b8b759db75bd7a72bf8b4': 2,
	'5074a692d86959719609b63ad3b2d7dbbc6ae338': 2.4,
	'55fbccd632cee65f59d41a13f8ad090629803e56': 2.2,
	'271bd9b6b8a1eed3336703658341bcc01745c16b': 2.4,
	d822527d724f10b9ee1478a51598194b47d1898c: 2.2,
	'5509c9811f6bc15ac6a8dc20605f16c550a13c02': 2.8,
	'5394b83def10fd00b883c359519618b93c3f722f': 2.4,
	d971d6b28922b6992de1ab1d8f82c6a0397abc72: 3,
	d3d63b02cf5a12a281128535fb20b6b921f47d6c: 2.6,
	'9020321109cb75b73b38e8aedc07da3700a6f40e': 2.6,
	'5471be15c02864f6a99aa0a2f5f543da9c99ae7e': 2.8,
	'54b42f8db80dbaf063555f673df5454a51817116': 2.8,
	a02bd6728113ab39b9d0c0139ab7e9115ee3edd5: 2.6,
	'28caa7100d3a4fd3b7c8d97d6b7d53d5d6c5e1b3': 3,
	'7a5535d2636b34029c9b3cfbce4a4aa3ca9e27fb': 3,
	af23dead6385f69f0afeeb97d142e3c0f717fdb6: 2.8,
	c379ea6983582748150c2c6cad498a3110f93936: 2.8,
	'6dd80fc90a99e5dd01d43db7eff75b71ee08d017': 2.6,
	'0fbaae32ff53e252d3f8fbed599f74a4118e2d24': 3,
	'0ed43ed605eeca38031b362b74c06c499664b3f3': 2.6,
	c9301c1024ba780ea497d3a5ae249bb19d6b63e8: 3,
	'8b9b097322881882f96c4032d7022ed3ad952d7d': 2.8,
	'5194afdedc922c62548ec5a6a533e1e0535a3cc3': 2.8,
	b576c396e52a7d62856c3bb8fb4275a47e122192: 3,
	'527a4b4fe6a46bf9f2614612c5ec15e6d83f2847': 2.6,
	'8eec163872b8ccd73be5377ad88ce715ddd1e363': 2.6,
	'2a5a6aff213597670d8693150b102ceb7e33f0d0': 3,
	'84b4ae06e24a4a37e63a6e15b476b8c66f6bbae6': 2.8,
	af1cce305f8278cb99dd916b183fd673e53b4369: 2.6,
	'16669877b1559060720a4a625490b23c29f5b635': 2.8,
	'8424ad0a1fe2097e539c0c473b67a5db75e7bdce': 2.6,
	ab6b1ec07e68e8c8a5f9e94a5904a78161bbe979: 2.8,
	'072d871ea3703ff932862b5a213abee041b95ff6': 2.2,
	'373b3d91fecabcad1d5e250810c7b2da5de5eee3': 2.8,
	'1e14770475385afe069bc448e2abf9edd3f3ea9c': 2.6,
	f9316f84fa03419fe1a4368d80e5bb7e23303438: 2.8,
	'80caefb7aff97ba797b9592386f668325cd906a3': 3,
	'6afda1bcb7c0745e7fba328782734836122d3ffd': 3,
	ed15b2e739e289f67db1a1bebd43432ab216b69c: 3,
	cd8c3818a4293bdaec372635cb47490b345e3ee3: 2.2,
	'3598348ea5894e2bb45f677833ff706e5a2d55f0': 2.6,
	dd712189332e2f25cb493779435cd7d202decc0a: 2.4,
	ee34ae08336903aff91caca87a355f0fad96e686: 2.8,
	b2bab8382151856c25a821d869f965d3fd3e7892: 3,
	'71f063ac447e771e2a02e0a415d614af45abdd28': 3,
	c73cad34c55e4a81f450f2af08256a7a7f2f4733: 2.8,
	'10ade325d10fc0462a8370cab6c1a62e2a2a7247': 2.8,
	c7061b892270edf32111791924f0c78d7378802c: 2.4,
	'8d75f00e75619c4c883742ba2e6d6cd7f0e72312': 2.8,
	'97c3b60086de32a6a99357eb2c83682d54c1707d': 2.8,
	'8ffa0f21bf819aad64dcb8f15ed7ac8c09deedce': 2.6,
	bc11eaf46efadf26438d444cd5fa31b64515bded: 3,
	'6f09c469cf6ac68a3d1fed65a5fa68ba0f55290c': 3,
	'1833fc74dcdafd7e7e104a1f352d0647786916b3': 2.4,
	'48bddb6341d57bc760ddc11ff285415eb483d69d': 3,
	'5e884056fc320764a553a7b92f26d1ba1787f78c': 3,
	'5f82df6094553dece94818be2c42aeca8dad5b45': 2.8,
	'68aa3d7a879506aa5a486696b1d3d18fa189da95': 2.6,
	'7972a89d46dfd445103d452f41cdd450338f1c45': 2.8,
	'28d4d9fd5b0eb1982d858481d1dc796d728ecd47': 2.8,
	'7313e81ab87acb32fc3f9ccf64a30644ac373ebc': 2.8,
	'28c3780a9f5558266d1e3a87e4fd5ea6a44345e1': 2.8,
	'2c2fe9f65fd6ec25c74576987a25346a8732c990': 3,
	db862b7c071affaa17482c32b67d46706e40eaa4: 3,
	e928767b36e66757610e4feacef5c1bdb0fe73f8: 3,
	cb12756207c27a7668f434f50dcea9f8ea4b6a21: 3,
	'6d13922308cea928a832a640aa8b6c746fd0e13f': 3,
	fc7e933080c006abfec50910576aa952b04ec131: 3,
	d6bef72a4e662fd9a8addd495ff229eeb5581059: 2.6,
	'8247bc11fb7e881c2b1e2a12d395cc40444a841e': 2.6,
	d0f572bfe5c219042d6ca6050cbfb481bbaf7e95: 2.6,
	'0156836866ab80281d30bb065fded65ae1ca5b4a': 2.2,
	'7b882ac405e178bbe7aacb4648d711cde2c7ef2d': 3,
	d4012f300c29135bfc68da25acb03ec1521a0f6d: 2.8,
	bb7907538ccea9a34be9d12041403a08ebb12fa2: 2.8,
	'6acfaec025796324c5bb0729bb8e2186718423ef': 2.4,
	'12510f494b1340c62ea4a3ae14e5f64aaf5d386b': 2.6,
	'65a8904a23c6074c4b54606fd7e84e9f43e8d59a': 2.6,
	'4b822f374ae1829ace85853e240684456dd29b0d': 3,
	dab634767bed24598f524a581170a7b45c816d96: 3,
	dc68d355b802538e8c8dc768328a533b5b8c70d4: 2.6,
	ba5ad00e5513c528645d3e4848e84de714b2e20b: 3,
	fdaff528f9c2faf0241e12be58591734ac79f82b: 2.2,
	'9f534fb5ef3d0f6f02695026a71a83e343902d94': 2.6,
	'547b6bae3d4720d35af41a172997bdcac670450c': 1.8,
	'38e4d85acbd56d3ce09316ff514df14e3b4b0acb': 2.8,
	'78c07d38b5db8ac2b86f46a2f09cbebfec17db76': 2,
	'88285523abcdebe6e753fa85ccb42c2063299300': 2.2,
	'058d1da3259fc8de9c98bd44506fe25c569dcedd': 1.8,
	d9551c161dd19a98b6e272060f65935f2f4388e2: 1.8,
	'2a1f30fc6bd329611cb5f740119dec556318f383': 2.4,
	'744f689d2b2a0ad5201ea99e70e36b88e980d3cc': 2.8,
	'790dd98f4822fb1547f8355e3aabc03304bc5e8c': 2.4,
	ec52e7a09395c294e096b8dc145ab081ca39a81b: 2.8,
	'65e3762f2c89325c4f0e775a7e63fd65779fb25e': 3,
	'033c1125f8750ba5af5a48085f63f4a296e194bb': 2.8,
	'82d93c172bc0b508f7629af34c65d71f7ae806b5': 3,
	'6d0239a2ad707677f287152572b7a755db989ef7': 3,
	'0e30caf8808b19a44fe8c92afd48b15c38a0802e': 3,
	'3f561b48d480e0e6d63e19d6c9e2d29d1725560d': 2.8,
	'34052873491a43904790c5ae55dd33011f535a02': 3,
	'7bc78b7b6599e252e4870be0c084c7e307cb8475': 2.6,
	d360f81b1fdc65db4ca10ca7110e7a4caa10833b: 2.8,
	c5baa179e9a2a048a09419f76a599fb39db9c62a: 1.6,
	d8963038eea261a4e9a2b83f31a245a3c9869e48: 2.4,
	e67f13374cdea3f7a321e0774047da0cd6c78265: 2.8,
	'4ec13a79ebc75742b6ab2738029d7bc26a3f7d02': 3,
	'803e77b4f9c382a013a464e76fc1641545e026bd': 3,
	'9566a639677fe28972adcc4cb022697932236b30': 3,
	'3c1722ac59bd2ca4408a5a786b63c60f7fb8cd7c': 2.8,
	'9f7369ca91e31110fdea68531680b00cc88189d2': 3,
	c1bcea1feb52409444ec33589ba362f33fd4754d: 3,
	'9357c55c02e9dd1cfe6e04ad8d3d37c9e2cfc421': 2.4,
	'9824b754f3056b889b1b50ecd6d4d339c5f5f742': 2.8,
	'60bd3b4002de9d86e0e7f1da8b6b454c79cc7ba3': 3,
	'234310b44426845f2d38bfd3c772076cc7b69f36': 3,
	'9c446e72b6047aacf2546348c0ee21592eb02a43': 3,
	a59750df8d443d6d242efefe663b7cfbec79f552: 3,
	c898cd7d0b67878b50f035bcd6ab15979a5d2a9a: 3,
	aafef9a5cb830e5520b7a15c9fd3c389c7c3d1bb: 2.8,
	'503ca990540be8f87adb699fe3b2882d74629f7f': 2.4,
	facb0790dab9dfb27742062ec7715a76926eeffc: 2.8,
	'2480255b9c3d05ab92bf1460c5c6b8ad1cd54167': 2.8,
	'75d9bdcd3c421492fe786f7834628b0bbe28d149': 2.8,
	f69c25333392841f2fb4e08153412317fa136fec: 2.6,
	'4caab7daf378d8aa6ed5414ee24a7a8af9e8ce14': 2.6,
	aee9d083eccf73a017307f45ccbf1f7c38747b60: 2.6,
	'6adf14b6a34830b67001c9957a002fb80505df05': 2.6,
	'3e80c6890ff91b4da561e7ee41efb1b7634e53da': 2.8,
	cc25a33a82fb6016ac0628735441292f462bf127: 3,
	f0eaf9d51779b032e1b309102f76103800a0cdfa: 3,
	'6e22aacd1875bd2e4a8f16bccb51d35ab45abcce': 3,
	'17d26e585223fa64d813e4531610d8367417cbd1': 3,
	da544d8b45fe916127a60bfbad6717b7e7241236: 2.8,
	'2f95e91737fc14fae8935f6525ea68e77aaf7f48': 2.6,
	cc57a18cdca55edcfb342b71d61d07bd1f8e413a: 2.8,
	'5bb1acbb8a7b56e2f6d3a3381fd784a7899925b2': 3,
	'7e39575bc88d35a124120d418d294697facb076d': 3,
	'670339665a591c09a4cdca73712c67a34909beef': 2.8,
	ccc1e1fbb54dc938e966786a7f1345135f3108cb: 2.8,
	d5b79c095dcbc6eb05f0bcefe08bad43e7596e3e: 2.8,
	'280b9650d364eb11287fb49749e388dc33acc783': 2.6,
	'5012210e57f0ccdf50a394f335e63e55b2475810': 2.8,
	'1b1368681e9d17acf4492883287d9dc5b83a5ca8': 3,
	c8ca50e77b39e08394b48452b2c29c6df602012b: 3,
	f37c94388481550d5e7174f03699fd7c819044a3: 3,
	adbe1d9ba4a0c74a3ad282e198d7c0b16783e739: 2.8,
	f8a17e958f70b7990df493f4a53a36a67ba9163c: 2.8,
	d1fd8d1b8a181da5e3dbfa678460a1cd7b711ad8: 3,
	'801e934907d978969d1fdcafc90d60bc7ed0023a': 2.8,
	'7076172227a01010614a88b20655e5cfa0cc7e7c': 2.2,
	df4d8ab2ea149e35f15432250f84689124078a26: 3,
	c016fad843194a77bc9a018f36801b6dd1506e0a: 2.8,
	f4374f29a2e175c015b4cd7cb23df731433c4705: 2.6,
	'3eefe555117083e16d4eb1c7b2ca0ae573485a17': 2.2,
	'163ce329fe4c55802133a659cdbd6d7a10fb5dac': 2.8,
	'7af8de62594c42ba62a5cb51176b02107d896403': 3,
	dcb239ca5836bc26937a2ce5543fa8b75a24e22e: 3,
	f13a1dd5e695fcca33989dbf9d110e5b0c34814d: 3,
	b86a2fa63a00f29a5ceaa737103cea5a24faf7cd: 3,
	fc1bf6b3cb865eeebd53edf93e76084d8a54c58e: 2.8,
	d7c8bf7c17156c5214351b2d34d223966d10fde1: 3,
	'58a1d8e6ff87eaeb5b654c54256faeeaf32a3cd5': 3,
	ad903da4c2785ab1f8851fa978d3a1d4a804d9ad: 3,
	'1e49e8aeb0c0180163b848bb6b7303eccc86576a': 3,
	d249176d49d18582456cac9a2c08ef91e153554e: 2.8,
	b568f93c7d19869215207a807220b418fdf65efe: 2.8,
	'59c65eea027ad3b6e6802fcecc134e202645678d': 3,
	'8dd95bcc1c8e349e77c2972a20de96673215c20c': 2.8,
	'56e433c70ab6fee100bbec765a61516a0aab6f03': 2.8,
	'7d81b05af60e03a4b4f49246e57e3e6fbe67827a': 3,
	'75aa5e1cdba85b488b6b1a667878b1f1ec3a4f31': 3,
	'63a29809926c801dc46b6742d1a9b4ec6a8f29ee': 2.8,
	'8815ea2060a152caa7be831d58c1141e6c86ffe1': 2,
	f1ab7268490e6d1083003d5124ef8b5b5768b50e: 2.2,
	'8e5bc8083b9a49af534f950daee7b23e428f24b2': 1.8,
	a2941097145da119eacb751de01b8f1f6cadc79e: 1.6,
	'3115438195ebc5322b6221ad294753debd626233': 1.8,
	'64353d710220c6fcae39cd416d765345cd998bf9': 1.8,
	'90138bd944257dbae815763ff42302df262d91b7': 2.4,
	fbbbde366dd9c47c53bed1536b449e391169aafc: 2,
	'17831e74228ac1bd4ce4ecc5460155ff7808bd6e': 1.8,
	baa3ffe92e7939329afc83fbcbed302afad593ed: 2.6,
	c590748ce1b1475884cac64d6c4ecbc9a106edb2: 1.6,
	'243d032f1c3c1768b29786f32e5453df3f12c73c': 1.8,
	'7d810e4ee66de9933f8cca5f9dee6c1343e098f5': 3,
	'0e7307438325501cd7b7b43104facc682d5d51ef': 2.8,
	eb7457ad26c51cffa85d7c4231d6942ccf17a46f: 2.8,
	a48e04ff900c9dfd1c2e5cba19b5950038c9d50d: 2.8,
	a030fa686db1d50506fc24796e40be0b361be872: 2,
	'9a2ee813d9ac4236ae71df05dbcb78a0bd3ebaaf': 2.2,
	'7006da2201dc742e97e0e1b12a0625841dba88fc': 2.4,
	'74793f87a5b7ac18c27be600e9980ecd2964ce4c': 1.8,
	'9568261a676c96a4d8b6ced94dab6ef349342631': 2.4,
	d824c7bc636d17fd0c4c63e8b2569ecb2649e520: 2.8,
	dc0f50b3ef00b44519f3e852cc8a30c9d38be3aa: 2.4,
	'2455f4084304c7274fa7fe1c7d6b4dfc28dcea28': 2.4,
	'64ce76e4b16d8d40af3586c501efcbcb23681bf1': 1.6,
	'4d6bc77ca367ecb6c24632b5427ab1b35a324bf8': 2.2,
	e5200a437fbd9329460addf8bb13396cf636174d: 2,
	'083e07040cf55c3d207c82a6885aa1300f112d55': 2.2,
	'94d83d42f4dffc8942953da32caa11ca233e0733': 2.2,
	ea75988fe7e1c8ab06fdc180669aff460c92bb7d: 2.4,
	'4ccd04cbd293fabeda425283d2e9e3f07a8cd21b': 2,
	'36cdc3bb2216a25a24597f8b87b21b0045960f26': 3,
	'74a63a7a179d5faa61d939db2838b2141a65b4be': 2.8,
	'9cbd14165a3629a9e7b2f38eb814fa529c411f89': 3,
	f1051f38ef9057110d86649a61242842f22afecb: 3,
	'2e1d69ff80e3ceda4e6f180dffca586c685746c3': 3,
	e6247eab9cee9d4e130cbdc0b4a619ee83e93d8d: 2.8,
	'34ccfbe6cdcf644ac038fbe7608a091b59c43a4c': 2.8,
	b79a328df328491fb5012bfbdcd65b08ffedd27e: 2.8,
	b64f1721d016b01b547aafe897ebe0639fabdcd3: 3,
	ad9c3714fa4cfbc00440930c890df8694fb689a7: 3,
	'8b0764b43105b61d3985eb609aa5b664695a0ce2': 3,
	'9229ec79a248d8810d401bdf4febed623264819d': 2,
	dbc798f1b30180bb58f3131927b10448e002c89e: 2,
	e1bf8a372a4eca6ca8c8b030aba67290c7c183ae: 2.2,
	'5061880e6fd88ba23b6c051e59a0400b78fd80a2': 2,
	e3c4528311a2a560c8c443c09f073c7e70d483e4: 1.8,
	'7c873c50b7c96cb6f0342cc2530b95d8be913f9a': 2.2,
	'4f50233a13802c23674fadf9f7d96cf48d049257': 1.8,
	aeb07d630ed1713477a2f8ba853f97b43a8c20af: 2,
	'033f2fe99317cb29ac6854c2a3fa8ed9edffa331': 2.2,
	'3e18ede5d4c8afd4a8879764cf1dec07d539d369': 2,
	bc761eab1e555da8680ac4b922ddf0514ccb540e: 2.4,
	'049fe0144eb1e509f9141f63df63a1f5088ad313': 2.4,
	'54771d180643be4d43ba6ac900d5b6bcbe750c9a': 2,
	'455ef7357e4ca301ce85c075c74406e53a5a4838': 1.8,
	'3b1fe0e1879bc3f5d8592b74401a85626df6a662': 2,
	afa5e67203014572ae25e3ea52795ad74ca2b721: 2.2,
	'9e507283fdd8e3a69b7b858f2ff715c176e1519b': 2,
	e0b1e69bd7cade44fabc98517493a50ee44d09b4: 2.4,
	'9ea1eedcd6a6c2a42f9f61d4d10a1770631a7d98': 2.2,
	'753593a1edce1f48e6170b334f06e3c9071136b6': 2.2,
	'4e2353379064aea7c20055581c5f362279680bb8': 1.8,
	'4885985b3568f871b4d0e22a4fe9c1acb8d5e3e3': 2,
	'1af5009472b6b9f43ab4653904eacd5717040245': 2,
	'2acb2d4cc20dc6197748c67221c078e406a2547f': 2.2,
	'34a2be3bf55f6b07e02c03bf9ff5d5a19fa6e90f': 1.8,
	'4ffc719ec1088ceaa597f01092ce0c2c7de65d36': 2.2,
	'9705938a26c68792ee77b2273464d17d2c8761f4': 2.2,
	fb72672edf6078c0ee8cb0680b0d4512b1ef7854: 2.2,
	'9696cc59be58a716d3ab47b7ad0f1714a529a961': 1.8,
	ec5862ed0afe1fc88ddd6a1de1abea3a46e30ad2: 2.2,
	bc59fcaf1fe9f7978ac6bdf6e7037b1dfe9f6300: 1.8,
	'7635c9c121d9bd76df5f2dbfd183b42ef7fbd69d': 2.2,
	cb1dd6bd32b520b4d35ea868edc44c146f336450: 2.8,
	f11968dcf839319129787bac7c310b9d563e0248: 2.6,
	'28a4e545bca888de25d8efe79c90b728210b9592': 3,
	c1e55df720a352936321439ee8975ce13934a45b: 2.6,
	'1fdc74023c472c3e03cf826c576a843774c0c3b6': 2.8,
	'54751474b67846bdaaa56cb64655ec07d80faef0': 3,
	'9bcd890a5d7bd08c427d426d6a68908183416322': 1.6,
	fa43493bbe8d1dbee6249653fcffd628aecf4531: 3,
	'9c161927815936880c8ee75aa3aad569a6ccd15f': 2.2,
	e2dc10123b21b3bf45b38578921f4cd776f844df: 1.8,
	'4dfe48d146593841b59b702ca769b7fae68259c2': 1.6,
	f46bae8e212d7282332532e5245df364203e1a94: 1.6,
	'2cada6ae34ec826064a544d91b256f126641ffc5': 2.8,
	'6431f577440ebe519b9621228e12e4ff5205cf04': 2,
	'66fe272cf99299996ced6c203dc3d2caaabc4d32': 2,
	'318b359991c8cc0787b4a0efc11aeba1b6cd30fd': 2.2,
	c5431961c85bac419bef7b29b1b3f9bf2c65856e: 2.8,
	'07757ddc800cbeee02ab0902c16220f572cddf11': 2.4,
	'6c3938b8c1f685e90f88e4faada516b8e6467579': 2.4,
	fd651593fb09d126f75bc437715d7296074ad0eb: 2.4,
	f3b0608c5e69d3adc01ad7036bfc43caa5196d12: 2.8,
	c8d4d270e5efcb67d0c69b8832478d28e71b63f5: 2,
	fd82160ac0ad4fedc4879c8999a9771e041ec7cd: 1.8,
	'442ed285407b635427559a3f1f1695b260b46ded': 1.8,
	'928b224877ad3bd9a9ed8ed1c27a8d013c2f8803': 3,
	'03a15bf823650d1584261703f303ac85f4ca9626': 1.8,
	'466f15df789988f33acc3352a6c58b63414f2e4e': 2.8,
	'357ab87c029ccc8674432ad79a4e3d05f6a2a7a7': 2.8,
	'4208ce88ac8bd9456b94db5fbb49b4750cfb7de8': 2.6,
	c293d6fc15c645921c4e8c9317e9f58405736c2e: 2.6,
	'6868c77b570b7b5b2630cf84d434d0c28be8be35': 3,
	'6651ce5a992c30d173b4bd6ddb63a497759fa6c8': 1.6,
	f9d7c4abccc7118959c000e42f6b716e04a7a5ac: 1.8,
	'5daec9beec95d1897311aaf74864e72863229f90': 2.6,
	'82520031410b771b18d6c466b64262f37b514742': 1.8,
	'647c231e685c1ec0010932e3a64fc7a6abbdba3d': 3,
	c4ee878577600cfb98868fd0530118c4528a735a: 1.8,
	'2c506283d7c289f6821196b65f38abd47d87c376': 1.6,
	'5f1f4a66691574b0e76e4cbed2e8f05572029627': 3,
	'3ad8f2e40d7198791896ad3d2efe7710e97f51b7': 2.4,
	'880c55996abbb0ef6441c399ef8e0903f2887fc2': 2.8,
	'073d4e12cb57cfad58dd192cd8a4c2abee43eae6': 2.8,
	a6f3e14a53713a858e261ef576fe6885377be1d8: 2.4,
	'985691a48582d9064d49e34ca82442e757e67103': 2,
	'545028f9febbb1e1959b0644ed3e18922d2cfe30': 2.4,
	a8eb0b7d2c6a492b088b36648b817e6fbbcf09e3: 2.6,
	'6f0b107a35f2ffad749a3086954b54691a62c7a8': 2,
	'9beb067efce40c3db2323c1c84336942654bf49f': 2,
	fe859dc7e99d6eb227b637ff3be8104598281a80: 2.6,
	'72c19b3f2460ae09e726cfacd56f7117578f34f4': 2.4,
	a2db3d2de807f6f5a2ea0253eb71d94b37e3bdb4: 2.8,
	'13943fe39c2ef706d82a06fe949c3e7c2de0365d': 2,
	'283f2a30b0f375292fea3837a1a16c2d12a2ac42': 1.8,
	'75ca6462e48e0665ead613c772643256c3e5bf6b': 1.8,
	'9a151bc161ea24a737408c7783733388a4d39439': 2,
	'96f3241436a9d143fb8158cfb8b19a3c45b8ea2a': 2.2,
	'5f1b2331041a7ae118484208adb296661ee86c2f': 2.8,
	ec3f1592b3688ca465b73da13fb5126afe81b340: 2.2,
	c6d39153e225fcdfcf7a532dd3a513c631f8d841: 2.4,
	c1d2be612d1801b0e646ccfb399f9f21c44010da: 1.8,
	'2291d212f53380f51e9de988e663886eac6dc79a': 1.6,
	a2695e022012d7fe909219006fabc18de4a1383e: 2.4,
	'00ac72b722e504f33144bad5b2fd09d6c91ebc23': 2.8,
	a1b75518efe63f7670760744007869f4c2f29428: 1.8,
	'5a8bd3abcb9d2d15d8d4427ee595642008e6b329': 2,
	c118b6d6f332d56fd398da2e67632cf21f07c38f: 1.8,
	'3cac4001ce3d04260ed4067d1a3f8ee14ff024e8': 1.6,
	'6c7e5779032968624416d3c4b64fb7faa3f96ce2': 2,
	df0a6a69b0123d1688077a4fb5d1c7a74d75a6c7: 1.6,
	'4438f4ada5167a1fe82517666019d3210b66808d': 2.6,
	'4e80bb565a1ed05c8223ab86ea0cbfdfc5e09023': 1.8,
	a93b5c6d2304a5e530e491a44bb1de74321baadb: 2,
	'79294934ca04f8039b0bd3bd646ecfdd863402b1': 2,
	'738ae08be7437e32005ef14cbcfae47a1d373081': 2.6,
	'9909eace3b03686a32bb399b8292c69e4ec0b5e6': 2.8,
	'78bbdfc4918e4df894fcde26218c171c3aa42bba': 2,
	'1b06c6f385d1de2be83d3152d073679784f12e4f': 1.8,
	adeb682f15d9bd1bf82942712981d62d8887d4db: 1.6,
	d84b8241aa5f8b62a6115dae552802adcacbcce7: 1.6,
	'672d5b0b459c5da731d73e2b37ac3384a6285b38': 2,
	'84bfe0ff55c840cb5318aa5fa07999187e53ef47': 2,
	'22f5a698444ecb7a895fdee16f1e353f79722a69': 2.4,
	'1de0dfa644722241c16c9626a0a7aa547a40500d': 2,
	e6036f071ca8e554fe5209ecc3d43d9b5c52c74d: 2,
	'3a92c0d4b6015e7134f24babbeeea8a906213b24': 1.8,
	'15035a5456ac60bbff10ad813f4b27bb1285e8c8': 1.8,
	'23511b060b85a16f6d3b0ef75be60faf6a0615c1': 2,
	'033fde4acd8bea2f1ee2ca788511a196ef3e6d93': 1.6,
	'1fedd92bea4434a3940dcb848e223e6e959d9ba5': 1.8,
	'4447d64da1990488ed4f9b9c73d048e996b34c31': 1.8,
	'421675cb2d8847b6761c0eb477b0f1410b1143ff': 2,
	c511282154eefa3695c561cae82b10ed6dfe1c61: 2.8,
	c25c9eb3860244c0df2ad7f15ec94db32330e1c9: 2.6,
	'7775f5bfaa4efc063d828560ac8594c7e35bf39d': 2.4,
	'00d1133b258b9c0020d29a1971e40b4934d54881': 1.8,
	'5f01700c50b966bed9b8a66a85440a2f76d40b1c': 2,
	'297d6b8c288212bab82dc5b8d811ab548f50f501': 2.2,
	f2e94568151cf85be72f6e5dd6045da05fe4064f: 2.4,
	'36c50efa52ec9486b7372c57e3d75b580e6d277b': 2.6,
	'17a68aa14e8606ca2e732b55d2e148ab09cedb98': 2.8,
	f169cabb4470d49da94c12c52cb0e0fc6d11a63c: 2.4,
	'3ca4515113f599b44bfc839c0ed71d016762111b': 2.6,
	'21dbeb67ef7fadd97f2c0b35d92ed1a9120488fe': 2.2,
	e18ba7e526c93a837d7ba6d45ea292ad66c42930: 1.8,
	ebeb99c1db8051782fd24e8410f1035982c82520: 2.8,
	e389b211d05592a64082aaf6e933d96eee6de95e: 2.4,
	'061878a8315d779965c8344d225814b1ac4337bc': 1.8,
	'7a9eccf6e404924c4ff10199105cc787901b08c3': 1.8,
	b911c367c6aeb957cd6b9f6fbc9092318fe3e8b9: 1.8,
	'380f627f94a61116f2fdce377c391187a0b4bc1c': 2.2,
	'4d89f838f29e82271ce3c440d0b16eccae805d90': 2.2,
	'1c97e270fd054f56f34cf46310257eee79ec0e4b': 2.6,
	'8012abefe06cc2af12f4e8480c11fe689d2e71e1': 1.6,
	'99ef46283f1ded907b844935483176d3f2a2cd2a': 2,
	c305bcbf1910f65684e48ee3b83f346ebd5e774f: 2,
	'1d3c3b937e8567d911daaada99de8c1e327cf099': 1.8,
	f7f5df6c2b5f6d1782bcbb4a3bce69d15174a4b3: 2.2,
	bf1ca3ee295338f8698bf9c8d94c2c01a22df16c: 1.6,
	'5ba7412fea31344dda8e76317c9244a1d1734871': 1.8,
	e01283ad991d1b461467c08b2264c0c385b4fd4f: 2.2,
	'8c1ddbaa95952d675a7828d6c4bab9e6089628a0': 2.6,
	'9daf2f506b25ebd678a93a37994db60bbdcac3bb': 1.6,
	'37a0ed365efffdca6a75717d67313793b768f38e': 1.6,
	'228db36bc6768812b112fc770caf3e5c50fb4348': 3,
	f905bf2b31c6eb8ba9c75731957529f03c7e829f: 2.8,
	a7edff6518ad55595aba4380dc1421804e29a5a6: 3,
	'6ce6e3f01ec4f05a9143740c850da66486a495ea': 2.6,
	'6e8cf322aa67bbf81d190546d6733d042b7c93a1': 3,
	'4652ad46b656c0605f09d43b50370a6efa1fcb94': 3,
	'7a4bf55e4a282538caf7761ef9c3a901dd453e69': 2.6,
	fbd43d0ed129723219cbadbd61123fc6cb5c863c: 2.6,
	'1446d33fab21ed95df6df738ccfa170bf18f754a': 2.4,
	'56ece01521bf94a48e9836356f9a3471ae53ada2': 1.6,
	'85d7d012765f71378b980fa314741b9d6e30caee': 1.8,
	'32c3ebb2f59acbd27ff0f092972fcd03f9af60d4': 2.8,
	'8c55bc094f3cb887a72095ec4dc5b99c27bd549c': 1.6,
	a53ee0ea216bd9915630064c95b5590d56b5af54: 1.6,
	'0d897800155a1061361f2a23dbd5cd1dd8a31bee': 1.6,
	'5926876aeb4bcc939428c867b7d6866b5e12b575': 1.6,
	dd093de393876212318c6206206887c0c3ff5c7a: 1.8,
	'73000869a57edc3703941f2f3b4773d0c8df30e7': 1.6,
	a24a279bfe04e3ad85535fc836c70cf910e58f0d: 3,
	'0f2ca3909baf78ce936ab2d36a7adb0714e52dea': 2.8,
	fefacb3323cce4941ffc2a072f4511dc6edb63a9: 2.8,
	'6b68ca69d02acc2abcc62935733c4315d7a8e02d': 3,
	'1c6968b8d777e2814d0cbff190252bb9c5753854': 2.6,
	b959c82227d8deb0b8b0106d6ab8883a6093be34: 3,
	'3bd713447251e12a44c5cab72373fa35c20bcdeb': 3,
	'9f9e4ec373e2abb841530fdef3722c695066e97a': 2.6,
	f867d43fa7fb8608a7c4b9d40584f1c36bf51a24: 1.8,
	c5e3d46c9063c151a2a362706d2342692af7b609: 2.4,
	c238069c2df6709877a8187a037d0aaed0e41a50: 2.4,
	'86db6641ba4f0d49036e8cf81fd22a47a507e132': 2.2,
	bcce192e071ea4b0bcf4278971d21c9eda63c57c: 1.8,
	'633a53aca47dd49badf12b7fc4d5be6c51f83bc8': 2.2,
	'6c4737596381a9db0ab6431af586f88d2f73771a': 1.8,
	'08ef9d76b9991fe581276e0e459b4ee5533f0e9d': 1.6,
	f6f41425ee451978ce14963bc6c2c1ecc692539d: 2.8,
	'9473d93666ab8c41596d738ba1fc36297f6904c8': 2.8,
	b8a037cea1c756f85e135fad1e20e3df947fe9b7: 3,
	'41628b9c93e533f7622fd93a104baaad5e681b41': 3,
	f29b652e7961794ddcbdb36dd7c4110de348fab7: 2.4,
	afbe814fcb3efc506874d8c64d58240c46608762: 2.8,
	a7b3f221ef5a2bea629d755061edf1a856f64edb: 3,
	'3b1acdd28b955215bc9196a8aa95a958f634feda': 2.6,
	'4db018cb66b7f74a9f3a21181f5967fb6ed18e0a': 2.8,
	d3ee064e859f0146b3a35760ab451ff8d085d2df: 1.8,
	d917b14b3931c4797e27ddc19faa37a6ed808c88: 2,
	adf82b5ce3e66b2456e782773f545dbd01212d96: 1.8,
	b5954ece2e7fa34bf5dc17e6cc4d9ad386bdfa1d: 1.6,
	'2835f52de4af9012909312535b2810f1a3d91f9b': 1.6,
	'3d288b63281bc5a474f81a0edee86bc0c1face48': 1.8,
	d4a94485a50f9b2a78c3d92823cbea4905a813b8: 2,
	'8be472789e028b7aef53f4f785c729cf28fba58b': 2.4,
	'6a3022e49109ed7a4fa1eb94385675b5dd6c2138': 1.6,
	'5584da235935264f39829e3056c94b961fc748e6': 1.6,
	'1ae9e51ec532d6a86512246615d39f98a15f1090': 1.6,
	'0118a5285516942506820a930dc42f9634fbf69e': 2,
	'1c1f8a8d3c46f48ff5cb5ff6e18ffc6fee676b9a': 2.6,
	'5c7caf7beb8adc805d46c1f399ee90596c2d970a': 1.6,
	'4dc7fc19c5cab28b60964c01cfda4c5d71c28d4b': 2,
	'069bc8a7586e43b3eb9b5e069eabc7a4a2dd0676': 1.8,
	'5ed8f74929bad8b7928442faac2bb023719b11d4': 1.6,
	'6da4d647d5fc84f9e1dbd38ac281f3a2a5660470': 2.6,
	'1c225e18ffc77a5de189a604d2e36754e4746e0b': 2.8,
	'36b7542f9635b521361e6709beac4e14edca3cf8': 2.6,
	'11966aaba150eb18d5b97320509996ad4a152546': 2,
	'8388e1ebbee7c423ba2df84e5bc5ee74b1f78e5d': 2,
	'8fd40591588a3dd881f3199123ec24962d0e8008': 2.2,
	'45eaf91a6a0050a0a95d3883e4095938bf8cc31d': 1.8,
	'0c22b5e2d88878d95c64e5c72f67f5d9c906f157': 2,
	'5dd755ff42fe8dadef5a048df37e7bd0d305ba21': 1.8,
	b5af50a4c2653f63b6b84f7db26dbc9ddaed7790: 2,
	f8b67e9ed9632b1eb0ebb03600e2b29de6617a2f: 2.6,
	ce36feadb621e7a78257982956063f5518d46841: 2.2,
};
