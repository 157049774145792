import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useForm, useWatch } from 'react-hook-form';
import Button from '@mui/material/Button';
import {
	LabeledEditableContent,
	Lookup,
	TextArea,
} from '../../utilities/FloatingLabelInput';
import StickyActionsBar from '../../utilities/StickyActionsBar';
import { UserContext } from '../../../contexts/UserContext';
import LineItems from './LineItems';
import { useAlert } from 'react-alert';
import {
	ContractSummaryDto,
	ContractMemberDto,
	ContractService,
	ContractState,
	GenericLineItemDto,
	InvoiceDto,
	InvoiceState,
	PaymentService,
} from '../../../api';
import useApi, { ApiResponse } from '../../../services/useApi';
import { useDebounce } from 'usehooks-ts';
import { StateIndicator } from '../../utilities/StateIndicator';
import UserSideNav from '../../utilities/UserSideNav';
import { ErrorMessage } from '@hookform/error-message';
import { setValueAsUserInputOptions } from '../../../services/helper';
import { debounce } from '@mui/material/utils';
import { startOfDay, parseISO } from 'date-fns';
import { StateType } from '../../../Constants/Dialog';

const defaultValues = {
	state: InvoiceState.DRAFT,
	lineItems: [] as GenericLineItemDto[],
} as Omit<InvoiceDto, 'id'>;

const Invoice = React.memo((props: any) => {
	const { id, redirect, projectId: defaultProjectId } = props;
	const { user, isLoading: isUserLoading } = useContext(UserContext);
	const [canEdit, setCanEdit] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const { callApi } = useApi();

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		getValues,
		setError,
		clearErrors,
		formState: { errors, isDirty },
		control,
	} = useForm({
		mode: 'onBlur',
		defaultValues: defaultValues,
	});
	const [isLoading, setIsLoading] = useState(true);
	const watchAll = useDebounce(
		useWatch({ control, disabled: isLoading || !isEditing }),
		// This is set pretty low because we now also have the debounce on the API call--we can't do the inverse otherwise it will still kick off duplicate calls
		// I'm keeping it because it still helps to avoid excess validation calls and other save logic
		100
	);
	const alert = useAlert();

	const [contract, setContract] = useState<ContractSummaryDto | null>(null);
	const [canInitiateBuyerPayment, setCanInitiateBuyerPayment] = useState(false);
	const [userContracts, setUserContracts] = useState<ContractSummaryDto[]>([]);

	const watchTitle = watch('title');
	const watchDescription = watch('description');
	const watchState = watch('state');
	const watchLineItems = watch('lineItems');

	const paymentCancelRedirect = 'paymentCancel';
	const paymentSuccessRedirect = 'paymentSuccess';

	useEffect(() => {
		if (redirect === paymentCancelRedirect) {
			alert.info('Payment has been cancelled.');
		} else if (redirect === paymentSuccessRedirect) {
			alert.success('Payment has been submitted.');
		}
	}, [alert, redirect]);

	useEffect(() => {
		const loadInvoice = async () => {
			const response = await callApi(PaymentService.getInvoice(id));
			if (response.data) {
				const invoice = response.data;
				setValue('contractId', invoice.contractId);
				// TODO: Should have a seperate DTO on backend so this isn't a required field
				setValue('contract', invoice.contract);
				setValue('state', invoice.state);
				setValue('lineItems', invoice.lineItems);
				setValue('title', invoice.title);
				setValue('description', invoice.description);

				setContract(invoice.contract!);

				// Only team members may edit invoice and only if the contract is not passed it's deadline and it's state is in draft
				const isCurrentUserAContractMember =
					invoice.contract!.contractMembers.find(
						(x: ContractMemberDto) =>
							x.talentProfileId === user?.talentProfileId
					);
				if (
					isCurrentUserAContractMember &&
					(!invoice.contract!.invoiceSubmissionDeadlineDate ||
						parseISO(invoice.contract!.invoiceSubmissionDeadlineDate) >=
							startOfDay(new Date())) &&
					invoice.state === InvoiceState.DRAFT
				) {
					setCanEdit(true);
				}
				if (response.data.canInitiateBuyerPayment) {
					setCanInitiateBuyerPayment(true);
				}
				// TODO: add logic for buyer being able to make edits--the types of edits will probably be different depending on who is editing
			} else {
				console.log('Invoice not found! We are creating a new invoice!');
				setCanEdit(true);
				setIsEditing(true);
			}
			setIsLoading(false);
		};
		const loadContracts = async () => {
			const response = await callApi(ContractService.getUserContracts());
			const activeContracts =
				response.data?.filter(
					(x) =>
						x.state === ContractState.ACTIVE ||
						(x.invoiceSubmissionDeadlineDate &&
							parseISO(x.invoiceSubmissionDeadlineDate) >=
								startOfDay(new Date()))
				) ?? [];
			setUserContracts(activeContracts);
			setProjectSearchResults(activeContracts);
		};
		if (!isUserLoading && user) {
			setIsLoading(true);
			loadContracts();
			loadInvoice();
		}
	}, [id, isUserLoading, setValue, user, setUserContracts, callApi]);

	/* 
		Do not use this function directly it is only meant to be called in the save function below
	*/
	const upsertInvoiceToApi = useMemo(() => {
		return debounce(
			async (
				data: InvoiceDto,
				callBack: (response: ApiResponse<InvoiceDto>) => void
			) => {
				const response = await callApi(PaymentService.upsertInvoice(data));
				callBack(response);
			},
			600
		);
	}, [callApi]);

	const save = useCallback(
		async (data: Omit<InvoiceDto, 'id'>) => {
			setIsSaving(true);
			console.log('saving invoice');
			await upsertInvoiceToApi({ ...data, id }, (response) => {
				if (response.data) {
					if (
						[InvoiceState.REMOVED, InvoiceState.PENDING].includes(data.state)
					) {
						setIsEditing(false);
						// Load back any added platform fees
						setValue('lineItems', response.data.lineItems ?? []);
					}
				}
				console.log('save Invoice response', response);
				setIsSaving(false);
			});
		},
		[upsertInvoiceToApi, id, setValue]
	);

	const additionalValidations = useCallback(
		(data: any) => {
			let passedInitialValidation = true;
			if (
				![InvoiceState.DRAFT, InvoiceState.REMOVED].includes(data.state) &&
				(!data.lineItems || data.lineItems.length === 0)
			) {
				setError('lineItems', {
					type: 'required',
					message: 'Please add at least one line item to submit invoice.',
				});
				passedInitialValidation = false;
			} else {
				clearErrors('lineItems');
			}
			return passedInitialValidation;
		},
		[clearErrors, setError]
	);

	// Instead of the debounced upsertInvoiceToApi method, we might also want to look into moving everything from the dependencies array below
	// into a single debounced object and then ignore the react lint checks but that seems more dangerous than the current approach
	useEffect(() => {
		if (isEditing && !isLoading && isDirty) {
			const passedInitialValidation = additionalValidations(watchAll);
			if (passedInitialValidation) {
				// Attempt a submit
				const submitFunction = handleSubmit(save);
				submitFunction();
			}
		}
	}, [
		watchAll,
		handleSubmit,
		save,
		isEditing,
		isLoading,
		isDirty,
		additionalValidations,
	]);

	const [projectSearchResults, setProjectSearchResults] = useState<
		ContractSummaryDto[]
	>([]);
	const [teamSearchResults, setTeamSearchResults] = useState<
		ContractSummaryDto[]
	>([]);
	const searchProjects = (query?: string) => {
		setProjectSearchResults(
			userContracts.filter(
				(x: ContractSummaryDto) =>
					!query || x.project.name.toLowerCase().includes(query.toLowerCase())
			)
		);
	};

	const handleProjectChange = useCallback(
		(e: any, result: ContractSummaryDto | null) => {
			const contract = userContracts!.find(
				(x: ContractSummaryDto) => x.projectId === result?.projectId
			);

			setValue('contractId', contract?.id!, setValueAsUserInputOptions);
			setValue('contract', contract!, setValueAsUserInputOptions);
			setContract(contract!);
			if (!contract) {
				// Reset the teams
				setTeamSearchResults(userContracts);
				setError('contractId', {
					type: 'custom',
					message: 'A project is required',
				});
				return;
			}
			// Currently there can only be one team with an active contract per project
			// TODO: Look into issue where the display of Lookup doesn't change if user has touched the input
			setTeamSearchResults([contract]);
			clearErrors('contractId');
		},
		[clearErrors, setError, setValue, userContracts]
	);

	useEffect(() => {
		// Logic for setting the default project during creation button from a specific project
		const defaultContract = userContracts?.find(
			(x: any) => x.projectId === defaultProjectId
		);
		if (defaultContract && !contract) {
			handleProjectChange(null, defaultContract);
		}
	}, [defaultProjectId, userContracts, contract, handleProjectChange]);

	const searchTeams = (query?: string) => {
		setTeamSearchResults(
			userContracts.filter(
				(x: ContractSummaryDto) =>
					!query || x.team.name?.toLowerCase().includes(query.toLowerCase())
			)
		);
	};

	const handleTeamChange = (e: any, result: ContractSummaryDto) => {
		const contract = userContracts!.find(
			(x: ContractSummaryDto) => x.teamId === result?.teamId
		);
		setValue('contractId', contract?.id!, setValueAsUserInputOptions);
		setContract(contract!);
		if (!contract) {
			// Reset the projects
			setProjectSearchResults(userContracts);
			setError('contract.teamId', {
				type: 'custom',
				message: 'A team is required',
			});
			return;
		}
		// Currently there can only be one team with an active contract per project
		setProjectSearchResults([contract]);
		clearErrors('contract.teamId');
	};

	const handleDeleteDraft = async () => {
		if (
			window.confirm('Are you sure you want to delete this draft?') === true
		) {
			setValue('state', InvoiceState.REMOVED, setValueAsUserInputOptions);
		}
	};

	const requestPayment = async () => {
		// Mimic desired state change
		const passedInitialValidation = additionalValidations({
			...getValues(),
			state: InvoiceState.PENDING,
		});
		if (!passedInitialValidation) {
			alert.error('Please fix validation errors before submitting invoice');
		} else if (
			window.confirm(
				'Are you sure you want to send this to the Buyer for payment? A platform fee line item will be automatically added to the Invoice.'
			) === true
		) {
			setValue('state', InvoiceState.PENDING, setValueAsUserInputOptions);
		}
	};

	const payInvoiceOnClick = async () => {
		// Strip any previous redirect parameters
		const baseUrl = window.location.href
			.replace('/' + paymentCancelRedirect, '')
			.replace('/' + paymentSuccessRedirect, '');
		const cancelUrl = baseUrl + '/' + paymentCancelRedirect;
		const successUrl = baseUrl + '/' + paymentSuccessRedirect;
		setIsLoading(true);
		const response = await callApi(
			PaymentService.initiateBuyerPayment(id, {
				cancelUrl,
				successUrl,
			})
		);
		setIsLoading(false);
		if (response.data) {
			// Redirect to Stripe checkout
			window.location.replace(response.data);
		}
	};

	const onItemAdded = useCallback(
		(addedItem: GenericLineItemDto) => {
			console.log('Item added', addedItem);
			setValue(
				'lineItems',
				[addedItem, ...watchLineItems],
				setValueAsUserInputOptions
			);
		},
		[setValue, watchLineItems]
	);

	const onItemRemoved = useCallback(
		(removedItem: GenericLineItemDto) => {
			console.log('Item removed', removedItem);
			setValue(
				'lineItems',
				watchLineItems.filter((x) => x.id !== removedItem.id),
				setValueAsUserInputOptions
			);
		},
		[setValue, watchLineItems]
	);

	return (
		<UserSideNav className="w-full flex flex-column flex-wrap gap-x-4 justify-between">
			<StateIndicator
				show={true}
				displayState={isSaving ? 'Saving' : watchState}
				additionalElements={
					<input type="hidden" {...register('state')} value={watchState} />
				}
				stateType={StateType.Invoice}
				isBuyer={
					user?.buyerProfileId === contract?.project?.ownerBuyerProfileId
				}
			/>
			<div className="flex-auto">
				<Lookup
					label="Project"
					name="projectLookup"
					disabled={!isEditing}
					defaultValue={
						contract
							? `${contract.project.name} | ${contract.project.description}`
							: undefined
					}
					onInput={(event: any) => searchProjects(event.target.value)}
					options={projectSearchResults}
					onChange={handleProjectChange}
					optionMapper={(option: ContractSummaryDto | null) =>
						option
							? `${option?.project.name} | ${option?.project.description}`
							: null
					}
					className="mb-4"
				/>
				{errors?.['contractId'] && (
					<div className="mt-[-1.25rem] mb-1">
						<p className="text-[12px] text-sm text-red-600">
							{errors?.['contractId']?.message ?? ''}
						</p>
					</div>
				)}
			</div>
			<div className="flex-auto">
				<Lookup
					label="Team"
					name="teamLookup"
					disabled={!isEditing}
					defaultValue={contract?.team.name}
					onInput={(event: any) => searchTeams(event.target.value)}
					options={teamSearchResults}
					onChange={handleTeamChange}
					optionMapper={(option: ContractSummaryDto | null) =>
						option?.team?.name
					}
					className="mb-4"
					errors={errors}
				/>
				{errors?.['contract']?.['teamId'] && (
					<div className="mt-[-1.25rem] mb-1">
						<p className="text-[12px] text-sm text-red-600">
							{errors?.['contract']?.['teamId']?.message ?? ''}
						</p>
					</div>
				)}
			</div>

			{isEditing || watchTitle ? (
				<LabeledEditableContent
					{...register('title', { required: true })}
					errors={errors}
					disabled={!isEditing}
					value={watchTitle}
					label="Invoice Title"
					className="">
					<h1 className="min-h-[1em]">{watchTitle}</h1>
				</LabeledEditableContent>
			) : null}

			{isEditing || watchDescription ? (
				<TextArea
					{...register('description')}
					errors={errors}
					disabled={!isEditing}
					value={watchDescription}
					label="Invoice Description"
					className=""
				/>
			) : null}

			{contract ? (
				<div className="w-full flex justify-center">
					<LineItems
						items={watchLineItems}
						isEditing={isEditing}
						invoiceId={id}
						contract={contract}
						onAdd={onItemAdded}
						onRemove={onItemRemoved}
						className="w-3/4"
					/>
				</div>
			) : null}
			<ErrorMessage
				errors={errors}
				name="lineItems"
				render={({ message }) => (
					<div className="mt-[-1.25rem] flex w-full justify-center">
						<p className="text-[12px] text-sm text-red-600">{message}</p>
					</div>
				)}
			/>

			<StickyActionsBar className="z-10">
				{canEdit && watchState === 'Draft' ? (
					<Button variant="contained" onClick={() => setIsEditing(!isEditing)}>
						{isEditing ? 'Preview' : 'Edit'}
					</Button>
				) : null}
				{isEditing && watchState === 'Draft' && (
					<Button variant="contained" onClick={() => handleDeleteDraft()}>
						Delete Draft
					</Button>
				)}
				{isEditing && watchState === 'Draft' && (
					<Button variant="contained" onClick={requestPayment}>
						Request Payment
					</Button>
				)}
				{canInitiateBuyerPayment && (
					<Button variant="contained" onClick={payInvoiceOnClick}>
						Pay Invoice
					</Button>
				)}
			</StickyActionsBar>
		</UserSideNav>
	);
});

export default Invoice;
