/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DedicatedTeamDto } from '../models/DedicatedTeamDto';
import type { TeamSummaryDto } from '../models/TeamSummaryDto';
import type { UpsertPotentialTeamMemberReviewCommand } from '../models/UpsertPotentialTeamMemberReviewCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TeamService {

	/**
	 * Creates a new dedicated team
	 * Creates a new dedicated team.
	 * @param requestBody
	 * @returns string Success
	 * @throws ApiError
	 */
	public static upsertDedicatedTeam(
		requestBody: DedicatedTeamDto,
	): CancelablePromise<string> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/Team/DedicatedTeam',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Searches existing dedicated teams.
	 * Returns any teams matching the given query.
	 * @param query Applied against Name and Description Fields
	 * @param skills Applied against TalentSkills of TeamMembers
	 * TODO: Switch this to only use Skills that are attached to the Team itself
	 * @param pageNumber Current Page number for results
	 * @param pageSize How many results to return per query
	 * @returns DedicatedTeamDto Success
	 * @throws ApiError
	 */
	public static searchDedicatedTeam(
		query?: string,
		skills?: Array<string>,
		pageNumber?: number,
		pageSize?: number,
	): CancelablePromise<Array<DedicatedTeamDto>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Team/DedicatedTeam',
			query: {
				'Query': query,
				'Skills': skills,
				'PageNumber': pageNumber,
				'PageSize': pageSize,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Retrieves dedicated team by Id
	 * Returns one dedicated team matching the given Id.
	 * @param id
	 * @returns DedicatedTeamDto Success
	 * @throws ApiError
	 */
	public static getDedicatedTeam(
		id: string,
	): CancelablePromise<DedicatedTeamDto> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Team/DedicatedTeam/{id}',
			path: {
				'id': id,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Retrieves dedicated teams by talent membership
	 * @returns TeamSummaryDto Success
	 * @throws ApiError
	 */
	public static getUserDedicatedTeams(): CancelablePromise<Array<TeamSummaryDto>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Team/UserDedicatedTeams',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Uses AI to generate a draft team
	 * User determines whether they like the team enough to actually save it or not.
	 * @param requestBody
	 * @returns DedicatedTeamDto Success
	 * @throws ApiError
	 */
	public static generateTeam(
		requestBody: Array<string | null>,
	): CancelablePromise<DedicatedTeamDto> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/Team/DedicatedTeam/Generate',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Review AI Generated Team
	 * Saves user's decision to pass, save/edit, or indicate interest in a team.
	 * @param requestBody
	 * @returns string Success
	 * @throws ApiError
	 */
	public static reviewPotentialTeam(
		requestBody: UpsertPotentialTeamMemberReviewCommand,
	): CancelablePromise<string> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/Team/DedicatedTeam/PotentialTeamReview',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

}
