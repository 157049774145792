import { Tooltip } from '@mui/material';
import { StateType } from '../../Constants/Dialog';
import { getStateInfo, getStateName } from '../../services/helper';

interface StateIndicatorProps {
	show?: boolean;
	displayState: string;
	stateType?: StateType;
	isBuyer?: boolean;
	additionalElements?: any;
	className?: string;
}

export const StateIndicator = (props: StateIndicatorProps) => {
	const {
		show,
		stateType,
		displayState,
		isBuyer,
		additionalElements,
		className,
	} = props;
	return show ? (
		<div
			className={`sticky z-10 w-full h-0 top-[4.5rem] -right-2 text-right text-sm`}>
			<Tooltip title={getStateInfo(displayState, stateType, isBuyer)}>
				<span
					className={`uppercase px-1 bg-white dark:bg-gray-900 ${
						className ?? ''
					}`}>
					State: {getStateName(displayState, stateType, isBuyer)}
				</span>
			</Tooltip>
			{additionalElements}
		</div>
	) : null;
};
