/* Modal with default styling */

import React from 'react';
import { CloseButton } from './IconButtons';

export default function Modal(props: any) {
	const {
		visible,
		onClose,
		title,
		children,
		className,
		innerClassName,
		...rest
	} = props;

	return (
		<div
			{...rest}
			className={
				(visible ? '' : ' hidden') +
				' fixed top-0 left-0 z-50 flex justify-center place-items-center w-full h-full'
			}>
			<div
				onClick={onClose}
				className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 transition-opacity"></div>
			<div
				className={
					(className ?? '') +
					' border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white dark:bg-gray-900 bg-clip-padding rounded-md outline-none text-current'
				}>
				<div className="flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
					<h5 className="text-xl font-medium leading-normal text-ellipsis basis-full flex-shrink overflow-hidden">
						{title}
					</h5>
					<CloseButton onClick={onClose} />
				</div>
				<div className={'relative pt-4 ' + (innerClassName ?? '')}>
					{children}
				</div>
			</div>
		</div>
	);
}
