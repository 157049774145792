import * as React from 'react';
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
	AppBar,
	Box,
	Button,
	Container,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
} from '@mui/material';
import { FaBars } from 'react-icons/fa';

import LoginButton from './LoginButton';
import SignupButton from './SignupButton';
import ProfileButton from './ProfileButton';
import logo from './images/nKipo-orange-bgtransparent.png';
import SearchBar from './components/Search/SearchBar';
import { UserContext } from './contexts/UserContext';
import ExternalLink from './components/utilities/ExternalLink';

const pages = [{ name: 'About', link: 'https://nKipo.com' }];

function ResponsiveAppBar() {
	const navigateTo = useNavigate();
	const {
		isAuthenticated,
		user: authUser,
		isLoading: isAuthLoading,
	} = useContext(UserContext);
	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
		null
	);

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const makeMenuItem = (key: string, onClick: any) => {
		return (
			<MenuItem key={key} onClick={onClick}>
				<Typography textAlign="center" color="inherit">
					{key}
				</Typography>
			</MenuItem>
		);
	};

	const makeMenuButton = (key: string, onClick: any) => {
		return (
			<Button
				className="text-black dark:text-white"
				key={key}
				onClick={onClick}
				sx={{ my: 2, display: 'block' }}>
				{key}
			</Button>
		);
	};

	const onDashboardClick = () => {
		navigateTo('/dashboard');
	};

	const makeBlog = () => {
		return (
			<MenuItem key="Blog" onClick={handleCloseNavMenu}>
				<Typography textAlign="center" color="inherit">
					<ExternalLink href="https://blog.nkipo.com">Blog</ExternalLink>
				</Typography>
			</MenuItem>
		);
	};

	const makeFaq = () => {
		return (
			<MenuItem key="FAQ" onClick={handleCloseNavMenu}>
				<Typography textAlign="center" color="inherit">
					<ExternalLink href="https://blog.nkipo.com/frequently-asked-questions/">
						FAQ
					</ExternalLink>
				</Typography>
			</MenuItem>
		);
	};

	return (
		<>
			<AppBar position="static" className="bg-white dark:bg-gray-900">
				<Container maxWidth="xl">
					<Toolbar disableGutters>
						<Link to="/" className="mr-4">
							<img src={logo} alt="nKipo" className="w-[6rem]" />
						</Link>
						<Box
							sx={{
								marginLeft: 1,
								flexGrow: 1,
								display: { xs: 'none' },
							}}>
							<IconButton
								className="stroke-black dark:stroke-white"
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}>
								<FaBars />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: 'block', md: 'none' },
								}}>
								{!isAuthLoading &&
									isAuthenticated &&
									makeMenuItem('Dashboard', onDashboardClick)}
								{makeBlog()}
								{makeFaq()}
								{pages.map((page) => (
									<MenuItem
										key={page.name}
										onClick={() => {
											handleCloseNavMenu();
										}}>
										<ExternalLink href={page.link}>
											<Typography textAlign="center" color="inherit">
												{page.name}
											</Typography>
										</ExternalLink>
									</MenuItem>
								))}
							</Menu>
						</Box>
						<Box
							sx={{
								flexGrow: 1,
								display: { xs: 'none', md: 'flex' },
								marginRight: 2,
							}}>
							{!isAuthLoading &&
								isAuthenticated &&
								makeMenuButton('Dashboard', onDashboardClick)}
							<Button
								className="text-black dark:text-white"
								key="Blog"
								sx={{ my: 2, display: 'block' }}>
								<a href="https://blog.nkipo.com">Blog</a>
							</Button>
							<Button
								className="text-black dark:text-white"
								key="FAQ"
								sx={{ my: 2, display: 'block' }}>
								<a href="https://blog.nkipo.com/frequently-asked-questions/">
									FAQ
								</a>
							</Button>
							{pages.map((page) => (
								<ExternalLink key={page.name} href={page.link}>
									<Button
										className="text-black dark:text-white"
										key={page.name}
										onClick={() => {
											handleCloseNavMenu();
										}}
										sx={{ my: 2, display: 'block' }}>
										{page.name}
									</Button>
								</ExternalLink>
							))}
						</Box>
						{!isAuthLoading && isAuthenticated && (
							<Box
								sx={{
									flexGrow: 1,
									marginRight: 2,
								}}>
								<SearchBar />
							</Box>
						)}
						<Box className="text-black dark:text-white" sx={{ flexGrow: 0 }}>
							{!isAuthLoading &&
								(isAuthenticated ? (
									<ProfileButton user={authUser} />
								) : (
									<>
										<LoginButton /> or <SignupButton />
									</>
								))}
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
		</>
	);
}
export default ResponsiveAppBar;
