import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import ExternalLink from './components/utilities/ExternalLink';
import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';

export default function Home() {
	const { loginWithRedirect } = useAuth0();
	const [searchParams] = useSearchParams();

	return (
		<div className="">
			<div className="container mx-auto flex flex-col items-center py-12 sm:py-24">
				<div className="w-11/12 sm:w-2/3 lg:flex justify-center items-center flex-col mb-5 sm:mb-10">
					<h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl text-center dark:text-neutral-100 font-black leading-[2.5rem] md:leading-[2.5rem]">
						Freelance
						<span className="text-accent-400"> Teams </span>
						Are the
						<span className="text-accent-400"> Future </span>
						of Work
					</h1>
					<p className="mt-5 sm:mt-10 lg:w-10/12 dark:text-white text-gray-700 font-normal text-center text-base sm:text-xl">
						<strong>Form Teams. Find Projects. Get Paid!</strong>
					</p>
				</div>
				<div className="flex justify-center items-center">
					<button
						className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-default bg-accent-default transition duration-150 ease-in-out hover:bg-accent-500 lg:text-xl lg:font-bold rounded text-white px-4 sm:px-10 border border-accent-500 py-2 sm:py-4 text-sm mr-4 h-full"
						onClick={() =>
							loginWithRedirect({
								screen_hint: 'signup',
								appState: {
									returnTo: '/start',
									...Object.fromEntries(searchParams.entries()),
								},
							})
						}>
						Get Started <FiArrowRight size={20} className="inline-block" />
					</button>
					<ExternalLink href="https://blog.nkipo.com/frequently-asked-questions/">
						<button className="text-accent-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-default bg-transparent transition duration-150 ease-in-out hover:border-accent-500 lg:text-xl lg:font-bold  hover:text-accent-600 rounded border border-accent-500 dark:border-neutral-100 dark:text-neutral-100 px-4 sm:px-10 py-2 sm:py-4 text-sm h-full">
							Learn More
						</button>
					</ExternalLink>
				</div>
				<br />
				<div>
					<p className="text-accent-400 text-center text-l mx-4 mt-4 font-bold">
						nKipo is a professional network & marketplace for freelance teams
						with free tools to help you manage your work.
					</p>
				</div>
			</div>
		</div>
	);
}
