import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import Teams from '../Dashboard/Teams';
import Button from '@mui/material/Button';
import ChatContext from '../../contexts/ChatContext';
import {
	Input,
	LabeledEditableContent,
	PhotoUpload,
	Tags,
} from '../utilities/FloatingLabelInput';
import StickyActionsBar from '../utilities/StickyActionsBar';
import { UserContext } from '../../contexts/UserContext';
import {
	ChatService,
	DedicatedTeamDto,
	ConnectionState,
	TalentSkillState,
	UpsertTalentProfileDto,
	UserService,
	PortfolioItemDto,
	TalentExperienceDto,
	TalentEducationDto,
	TagDto,
	TalentSkillDto,
	ConnectionDto,
} from '../../api';
import useApi from '../../services/useApi';
import { useDebounce } from 'usehooks-ts';
import UserSideNav from '../utilities/UserSideNav';
import { StateIndicator } from '../utilities/StateIndicator';
import {
	defaultDarkProfilePicUrl,
	defaultLightProfilePicUrl,
	greaterThanZero,
	recursiveTrim,
	setValueAsUserInputOptions,
} from '../../services/helper';
import ConnectButton from '../utilities/ConnectButton';
import Portfolio from '../Portfolio/Portfolio';
import ExperienceList from '../Experience/ExperienceList';
import EducationList from '../Education/EducationList';
import ThemeContext from '../../contexts/ThemeContext';
import { ErrorMessage } from '@hookform/error-message';

const defaultValues = {
	tagLine: undefined,
	shortBio: undefined,
	profilePicId: null,
	defaultPayRate: undefined,
	desiredHours: undefined,
	primaryDescriptors: [],
	secondaryDescriptors: null,
	portfolio: null,
	education: null,
	experience: null,
	externalLinks: null,
} as Omit<UpsertTalentProfileDto, 'id' | 'userId'>;

export enum ConnectionDirection {
	Initiated = 'Initiated',
	Received = 'Received',
}

// TODO: Move pic, etc to the left side since it should be the most important part. Label My Teams section. Portfolio up top

const TalentProfile = (props: any) => {
	const { id, isEditing: startInEditingMode } = props;
	const [profileUserId, setProfileUserId] = useState<string | undefined>(
		undefined
	);
	const { user, isLoading: isUserLoading } = useContext(UserContext);
	const { theme } = useContext(ThemeContext);
	const canEdit = id === user?.talentProfileId;
	const [isEditing, setIsEditing] = useState(canEdit && startInEditingMode);
	const [isSaving, setIsSaving] = useState(false);
	const [connectionState, setConnectionState] =
		useState<ConnectionState | null>(null);
	const [connectionDirection, setConnectionDirection] =
		useState<ConnectionDirection | null>(null);
	const [profileConnection, setProfileConnection] = useState<ConnectionDto>();
	const { callApi } = useApi();
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		setError,
		clearErrors,
		formState: { isDirty, errors },
		resetField,
		control,
	} = useForm({
		mode: 'onBlur',
		defaultValues: defaultValues,
	});
	const [isLoading, setIsLoading] = useState(true);
	const watchAll = useDebounce(
		useWatch({ control, disabled: isLoading || !isEditing }),
		600
	);
	const { createConversation, chatState } = useContext(ChatContext);

	const watchTagLine = watch('tagLine');
	const watchShortBio = watch('shortBio');
	const watchProfilePic = watch('profilePicId');
	const watchDefaultPayRate = watch('defaultPayRate');
	const watchDesiredHours = watch('desiredHours');
	const watchPrimaryDescriptors = watch('primaryDescriptors');
	const watchSecondaryDescriptors = watch('secondaryDescriptors');
	const watchPortfolio = watch('portfolio');
	const watchExperience = watch('experience');
	const watchEducation = watch('education');
	const [name, setName] = useState('');
	const [location, setLocation] = useState<string | undefined | null>(
		undefined
	);
	const [profilePicUrl, setProfilePicUrl] = useState('');
	const [teamMemberships, setTeamMemberships] = useState(
		[] as DedicatedTeamDto[]
	);
	const rawPrimaryDescriptors = watchPrimaryDescriptors
		.filter((ts) => ts.state === 'Active')
		.map((ts) => ts.skill);
	const rawSecondaryDescriptors = watchSecondaryDescriptors
		?.filter((ts) => ts.state === 'Active')
		.map((ts) => ts.skill);

	useEffect(() => {
		const loadTalentProfile = async () => {
			const response = await callApi(
				UserService.getFullPublicTalentProfile(id)
			);
			if (response.data) {
				const profile = response.data;
				setProfileUserId(profile.userId);
				setValue('defaultPayRate', profile.defaultPayRate);
				setValue('desiredHours', profile.desiredHours);
				setValue('tagLine', profile.tagLine);
				setValue('shortBio', profile.shortBio);

				setName(profile.firstName + ' ' + profile.lastName);
				setLocation(profile.locationState);
				if (profile.profilePicId && profile.profilePicUrl) {
					setValue('profilePicId', profile.profilePicId);
					setProfilePicUrl(profile.profilePicUrl);
				}
				setTeamMemberships(profile.teamMemberships);
				setValue('primaryDescriptors', profile.primaryDescriptors);
				setValue('secondaryDescriptors', profile.secondaryDescriptors);
				setValue('portfolio', profile.portfolio);
				setValue('experience', profile.experience);
				setValue('education', profile.education);

				if (profile.connection) {
					setConnectionDirection(
						profile.connection.initiatorUserId === profile.userId
							? ConnectionDirection.Received
							: ConnectionDirection.Initiated
					);
					setConnectionState(profile.connection.state);
					setProfileConnection(profile.connection);
				}
			}
			setIsLoading(false);
		};
		if (!isUserLoading && user) {
			setIsLoading(true);
			loadTalentProfile();
		}
	}, [id, isUserLoading, setValue, user, callApi]);

	const save = useCallback(
		async (data: Omit<UpsertTalentProfileDto, 'id' | 'userId'>) => {
			setIsSaving(true);
			console.log('saving talentProfile');
			const payload = {
				...data,
			};
			const response = await callApi(
				UserService.upsertTalentProfile(
					id,
					recursiveTrim({
						...payload,
						id: id,
						userId: user!.id,
					})
				)
			);
			console.log('save Talent Profile response', response);
			setIsSaving(false);
		},
		[callApi, id, user]
	);

	const specialValidations = useCallback(
		(data: any) => {
			const activeTags = data?.primaryDescriptors.filter(
				(ts: TalentSkillDto) => ts.state === TalentSkillState.ACTIVE
			);
			if (activeTags?.length > 5) {
				setError('primaryDescriptors', {
					type: 'manual',
					message: 'You can only select up to 5 skills',
				});
				return false;
			} else if (activeTags?.length < 1) {
				setError('primaryDescriptors', {
					type: 'manual',
					message: 'At least one primary tag is required',
				});
				return false;
			} else {
				clearErrors('primaryDescriptors');
				return true;
			}
		},
		[clearErrors, setError]
	);

	useEffect(() => {
		if (isEditing && !isLoading && isDirty) {
			const isValid = specialValidations(watchAll);
			if (isValid) {
				const submitFunction = handleSubmit(save);
				submitFunction();
			}
		}
	}, [
		watchAll,
		handleSubmit,
		save,
		isEditing,
		isLoading,
		isDirty,
		specialValidations,
	]);

	const onDescriptorChange = (
		valueName: any,
		tag: TagDto | null | undefined,
		watchReference: any,
		isPrimary: boolean
	) => {
		if (tag) {
			if (watchReference == null) {
				setValue(valueName, [], setValueAsUserInputOptions);
			}
			const existingSkill = watchReference.find(
				(ts: any) => ts.skill.id === tag.id
			);
			if (existingSkill) {
				existingSkill.state = TalentSkillState.ACTIVE;
				setValue(valueName, watchReference, setValueAsUserInputOptions);
			} else {
				setValue(
					valueName,
					[
						...watchReference,
						{
							talentProfileId: id,
							isPrimary: isPrimary,
							state: TalentSkillState.ACTIVE,
							skillId: tag.id,
							skill: tag,
						},
					],
					setValueAsUserInputOptions
				);
			}
		}
	};

	const onDescriptorRemoval = (
		valueName: any,
		tag: TagDto,
		watchReference: any
	) => {
		const talentSkill = watchReference.find(
			(ts: any) => ts.skill.id === tag.id
		);
		talentSkill!.state = TalentSkillState.INACTIVE;
		setValue(valueName, watchReference, setValueAsUserInputOptions);
	};

	const sendDirectMessage = async () => {
		if (chatState.conversationsReady) {
			const response = await callApi(ChatService.talentParticipantSearch(id));
			if (response.data) {
				const talentParticipant = response.data;
				await createConversation([talentParticipant]);
			}
		}
	};

	const updateCollection = (
		item: any,
		formValueName: any,
		watchReference: any
	) => {
		const existingItemIndex = watchReference?.findIndex(
			(pi: any) => pi.id === item.id
		);
		if (existingItemIndex !== -1 && existingItemIndex !== undefined) {
			watchReference![existingItemIndex] = item;
			setValue(formValueName, watchReference, setValueAsUserInputOptions);
		} else {
			setValue(
				formValueName,
				[...(watchReference ?? []), item],
				setValueAsUserInputOptions
			);
		}
	};

	const className = '';
	const rest = {};
	return (
		<UserSideNav>
			<div className="w-full flex flex-column flex-wrap">
				{!isLoading &&
				!canEdit &&
				connectionState === ConnectionState.PENDING &&
				connectionDirection === ConnectionDirection.Received ? (
					<div className="flex flex-col place-content-center place-items-center w-full">
						<div className="w-full text-center">
							<h2>You have been invited to connect by this user.</h2>
						</div>
						<ConnectButton
							userId={profileUserId!}
							connection={profileConnection}
							condensed={false}
						/>
					</div>
				) : null}
				<StateIndicator
					show={isEditing}
					displayState={isSaving ? 'Saving' : 'Saved'}
				/>
				<div className="w-full md:w-1/4 pl-0 md:pl-2 pt-16 flex flex-wrap justify-between">
					<div className="w-full flex justify-center ">
						<div
							className="relative max-w-full w-full py-1 p-0 md:pr-4"
							{...rest}>
							<div
								className={`${
									className ?? ''
								} overflow-show max-w-sm w-full shadow-lg ring-1 ring-black/5 rounded-md flex justify-center items-center bg-gray-200 dark:bg-gray-700 rounded-md border-2 border-gray-300 dark:border-gray-800`}>
								<div
									className={
										'absolute -top-16 mx-1.5 bg-gray-200 dark:bg-gray-700 rounded-full'
									}>
									<PhotoUpload
										{...register('profilePicId')}
										label=""
										helperText="select a profile pic"
										category="TalentProfilePic"
										value={watchProfilePic}
										url={profilePicUrl}
										defaultImageUrl={
											theme === 'light'
												? defaultLightProfilePicUrl
												: defaultDarkProfilePicUrl
										}
										// Image Resizing Properties
										maxWidth={200}
										maxHeight={200}
										minWidth={100}
										minHeight={100}
										editing={isEditing}
										errors={errors}
										resetfield={resetField}
										className=""
										styling={
											'h-32 w-32 overflow-hidden rounded-full shadow-lg ' +
											(isEditing ? 'hover:cursor-pointer' : '')
										}
										imageStyle="object-cover p-0.5 rounded-full border-transparent"
									/>
								</div>
								<div className="flex flex-col w-full">
									<div className="relative mt-14 mb-0 px-2 pt-2">
										<div className="text-lg text-center justify-center">
											{name}
										</div>
									</div>
									{!location && !isEditing ? null : (
										<div className="relative pb-2">
											<div className="text-sm text-center justify-center">
												{location}
											</div>
										</div>
									)}
									{!watchTagLine && !isEditing ? null : (
										<LabeledEditableContent
											{...register('tagLine', {
												maxLength: { value: 48, message: '48 characters max' },
											})}
											errors={errors}
											disabled={!isEditing}
											value={watchTagLine}
											label="Tag Line"
											className="px-2 mb-2">
											<div className="h-5 text-center font-medium text-gray-600 dark:text-gray-300 truncate">
												{watchTagLine}
											</div>
										</LabeledEditableContent>
									)}
									{!rawPrimaryDescriptors?.length && !isEditing ? null : (
										<>
											<Tags
												tagType="Skill"
												name="primaryDescriptors"
												label="Primary Tags"
												isEditing={isEditing}
												onChange={(_: any, tag: TagDto | null | undefined) =>
													onDescriptorChange(
														'primaryDescriptors',
														tag,
														watchPrimaryDescriptors,
														true
													)
												}
												onRemove={(_: any, tag: TagDto) =>
													onDescriptorRemoval(
														'primaryDescriptors',
														tag,
														watchPrimaryDescriptors
													)
												}
												errors={errors}
												disabled={!isEditing}
												values={rawPrimaryDescriptors}
												className="my-3 border-b-2 border-gray-300 dark:border-gray-600 mx-2"
												lookupClassName="px-2"
											/>
											<ErrorMessage
												errors={errors}
												name="primaryDescriptors"
												render={({ message }) => (
													<div className="mt-[-1.25rem] flex w-full justify-center">
														<p className="text-[12px] text-sm text-red-600">
															{message}
														</p>
													</div>
												)}
											/>
										</>
									)}
									<div className="px-2 align-center w-full flex md:flex-wrap 2xl:flex-nowrap flew-row gap-2">
										{!watchDefaultPayRate && !isEditing ? null : (
											<Input
												prefix="$"
												suffix="/hr"
												label="Normal Pay"
												disabled={!isEditing}
												type="number"
												min="0.01"
												step="0.01"
												{...register('defaultPayRate', {
													validate: {
														greaterThanZero,
													},
													setValueAs: (v: string) =>
														Number(v) ? Number(v) : null,
												})}
												className="basis-1/2 flex-auto"
												errors={errors}
											/>
										)}
										{!watchDesiredHours && !isEditing ? null : (
											<Input
												label="Desired Hours"
												suffix="/wk"
												disabled={!isEditing}
												type="number"
												min="0"
												step="1"
												{...register('desiredHours', {
													validate: {
														greaterThanZero,
													},
													setValueAs: (v: string) =>
														Number(v) ? Number(v) : null,
												})}
												className="basis-1/2 flex-auto"
												errors={errors}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* TODO: add review and ratings info here */}
					{/* <div className="w-full flex justify-center">
						<h4>Reviews/Past Projects</h4>
					</div> */}
				</div>
				<div className="w-full md:w-3/4 pr-0 md:pr-2 place-self-start">
					<h2 className="pb-2 invisible md:visible">{name}</h2>
					{!watchShortBio && !isEditing ? null : (
						<LabeledEditableContent
							{...register('shortBio', {
								maxLength: {
									value: 300,
									message: "Oops, let's keep it under 300 characters",
								},
							})}
							errors={errors}
							disabled={!isEditing}
							label="Short Bio"
							value={watchShortBio}
							className="px-2 my-2">
							{watchShortBio}
						</LabeledEditableContent>
					)}
					{!rawSecondaryDescriptors?.length && !isEditing ? null : (
						<Tags
							tagType="Skill"
							name="secondaryDescriptors"
							label="Secondary Tags"
							isEditing={isEditing}
							onChange={(_: any, tag: TagDto | null | undefined) =>
								onDescriptorChange(
									'secondaryDescriptors',
									tag,
									watchSecondaryDescriptors,
									false
								)
							}
							onRemove={(_: any, tag: TagDto) =>
								onDescriptorRemoval(
									'secondaryDescriptors',
									tag,
									watchSecondaryDescriptors
								)
							}
							errors={errors}
							disabled={!isEditing}
							values={rawSecondaryDescriptors}
							className="my-3 border-b-2 border-gray-300 dark:border-gray-600 mx-2"
							lookupClassName=""
						/>
					)}
					{!teamMemberships?.length && !isEditing ? null : (
						<Teams teams={teamMemberships}></Teams>
					)}
					{!watchPortfolio?.filter((pi) => !pi.deleted)?.length &&
					!isEditing ? null : (
						<div className="my-4 mx-0">
							<h3>Portfolio</h3>
							<Portfolio
								portfolioItems={watchPortfolio!}
								onSave={(portfolioItem: PortfolioItemDto) =>
									updateCollection(portfolioItem, 'portfolio', watchPortfolio)
								}
								onDelete={(portfolioItem: PortfolioItemDto) =>
									updateCollection(portfolioItem, 'portfolio', watchPortfolio)
								}
								isEditing={isEditing}
							/>
						</div>
					)}
					{!watchExperience?.filter((e) => !e.deleted)?.length &&
					!isEditing ? null : (
						<div className="my-4 mx-0">
							<h3>Experience</h3>
							<ExperienceList
								experiences={watchExperience!}
								onSave={(experienceItem: TalentExperienceDto) =>
									updateCollection(
										experienceItem,
										'experience',
										watchExperience
									)
								}
								onDelete={(experienceItem: TalentExperienceDto) =>
									updateCollection(
										experienceItem,
										'experience',
										watchExperience
									)
								}
								isEditing={isEditing}
							/>
						</div>
					)}
					{!watchEducation?.filter((e) => !e.deleted)?.length &&
					!isEditing ? null : (
						<div className="my-4 mx-0">
							<h3>Education</h3>
							<EducationList
								educationHistory={watchEducation!}
								onSave={(educationItem: TalentEducationDto) =>
									updateCollection(educationItem, 'education', watchEducation)
								}
								onDelete={(educationItem: TalentEducationDto) =>
									updateCollection(educationItem, 'education', watchEducation)
								}
								isEditing={isEditing}
							/>
						</div>
					)}
				</div>
				{!isLoading && (
					<StickyActionsBar className="z-10">
						{canEdit ? (
							<Button
								variant="contained"
								onClick={() => setIsEditing(!isEditing)}>
								{isEditing ? 'Preview' : 'Edit'}
							</Button>
						) : null}
						{!canEdit && connectionState === null ? (
							<ConnectButton
								userId={profileUserId!}
								connection={profileConnection}
								condensed={true}
							/>
						) : null}
						{!canEdit ? (
							<Button variant="contained" onClick={() => sendDirectMessage()}>
								Message
							</Button>
						) : null}
					</StickyActionsBar>
				)}
			</div>
		</UserSideNav>
	);
};

export default TalentProfile;
