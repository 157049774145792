import React from 'react';
import TextButton from '../utilities/TextButton';
import { useNavigate } from 'react-router-dom';
import { ProjectDto } from '../../api';

interface ProjectSearchResultProps {
	result: ProjectDto;
}

const ProjectSearchResult = (props: ProjectSearchResultProps) => {
	const project = props.result;
	const navigateTo = useNavigate();
	return (
		<div className="flex h-full w-full items-center gap-4">
			<div className="flex items-center gap-4 h-full text-left w-full">
				<div className="flex flex-col h-full justify-center">
					<TextButton
						className="col-start-5 col-span-1"
						onClick={() => navigateTo(`/project/${project.id}`)}>
						<h3 className="font-bold text-accent-400 mb-1">{project.name}</h3>
					</TextButton>
				</div>
			</div>
		</div>
	);
};

export default ProjectSearchResult;
