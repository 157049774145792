import MessageBubble from './MessageBubble';
import React, { PureComponent } from 'react';

type ConversationsMessagesProps = { messages: any[]; identity: string };

class ConversationsMessages extends PureComponent<ConversationsMessagesProps> {
	render = () => {
		return (
			<div id="messages">
				<ul>
					{this.props.messages.map((m) => {
						if (m.author === this.props.identity)
							return (
								<MessageBubble key={m.index} direction="outgoing" message={m} />
							);
						else
							return (
								<MessageBubble key={m.index} direction="incoming" message={m} />
							);
					})}
				</ul>
			</div>
		);
	};
}

export default ConversationsMessages;
