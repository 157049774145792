/* ThemeContext and helper components to set theme and make it accessible to tailwind and react */
import { useEffect, createContext, useContext, PropsWithChildren } from 'react';
import useLocalStorage from 'use-local-storage';

import resolveConfig from 'tailwindcss/resolveConfig';
import React from 'react';
import {
	createTheme,
	PaletteMode,
	ThemeOptions,
	ThemeProvider,
} from '@mui/material';
const customTailwindConfig = require('../tailwind.config');
const fullThemeConfig = resolveConfig(customTailwindConfig);

const colorGetter = (key: any, currentSearch?: any): any => {
	const themeColors = currentSearch ?? fullThemeConfig.theme.colors;
	const [firstIndex, ...rest] = key.split('.');
	// ThemeColors can be an object or a function
	const color =
		typeof themeColors === 'object'
			? themeColors[firstIndex]
			: themeColors
			? firstIndex
			: undefined;
	if (typeof color !== 'string') {
		return colorGetter(rest.join('.'), color);
	}
	return color;
};

const ThemeContext = createContext({
	theme: window.matchMedia('(prefers-color-scheme: dark)').matches
		? 'dark'
		: 'light',
	changeTheme: (newTheme?: string) => {},
	colorGetter,
	explicitTheme: 'system',
});

const ThemeContextElement = ({ children }: PropsWithChildren<any>) => {
	const themeContext = useTheme();
	const theme = React.useMemo(
		() => createTheme(getThemeOptions(themeContext.theme as PaletteMode)),
		[themeContext.theme]
	);
	return (
		<ThemeContext.Provider value={themeContext}>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</ThemeContext.Provider>
	);
};

function useTheme() {
	const [theme, setTheme] = useLocalStorage('theme', 'system');

	useEffect(() => {
		const body = document.documentElement;

		// We set the theme to the body element so that it can be used by tailwinds CSS selector class
		// Use the stored/user-selected theme if it exists, otherwise use the system theme
		// The body's colorScheme is also set to leverage built-in browser elements (like the calendar picker on a date input)
		if ('dark' === theme) {
			body.classList.add('dark');
			body.style.colorScheme = 'dark';
		} else if ('light' === theme) {
			body.classList.remove('dark');
			body.style.colorScheme = 'light';
		} else if ('system' === theme || !theme) {
			if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
				body.classList.add('dark');
				body.style.colorScheme = 'dark';
			} else if (window.matchMedia('(prefers-color-scheme: light)').matches) {
				body.classList.remove('dark');
				body.style.colorScheme = 'light';
			}
		}
	}, [theme]);

	const storeTheme = (newTheme: string) => {
		if (
			!(newTheme === 'system' || newTheme === 'light' || newTheme === 'dark')
		) {
			throw new Error(`Invalid theme: ${newTheme}`);
		}
		setTheme(newTheme);
	};

	return {
		get theme() {
			if (theme === 'system') {
				return window.matchMedia('(prefers-color-scheme: dark)').matches
					? 'dark'
					: 'light';
			}
			return theme;
		},
		changeTheme(newTheme?: string) {
			if (typeof newTheme === 'undefined') {
				if (theme === 'light') {
					storeTheme('dark');
				} else if (theme === 'dark') {
					storeTheme('system');
				} else {
					storeTheme('light');
				}
			} else {
				storeTheme(newTheme);
			}
		},
		explicitTheme: theme,
		colorGetter,
	};
}

export default ThemeContext;
export { ThemeContextElement };
export function ThemeController(props: any) {
	const { explicitTheme, changeTheme } = useContext(ThemeContext);

	const setTheme = (event: React.MouseEvent<HTMLButtonElement>) => {
		changeTheme(event.currentTarget.value);
	};

	return (
		<div className="flex items-center justify-center">
			<div
				className="inline-flex shadow-md hover:shadow-lg focus:shadow-lg"
				role="group">
				<button
					value="dark"
					onClick={setTheme}
					className={
						'rounded-l px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase hover:bg-accent-400 focus:outline-none focus:ring-0 active:bg-accent-800 transition duration-150 ease-in-out' +
						(explicitTheme === 'dark' ? ' bg-accent-800' : ' bg-accent-600')
					}>
					Dark
				</button>
				<button
					value="system"
					onClick={setTheme}
					className={
						'px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase hover:bg-accent-700 focus:outline-none focus:ring-0 active:bg-accent-800 transition duration-150 ease-in-out' +
						(explicitTheme === 'system' ? ' bg-accent-800' : ' bg-accent-600')
					}>
					OS Default
				</button>
				<button
					value="light"
					onClick={setTheme}
					className={
						'rounded-r px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase hover:bg-accent-700 focus:outline-none focus:ring-0 active:bg-accent-800 transition duration-150 ease-in-out' +
						(explicitTheme === 'light' ? ' bg-accent-800' : ' bg-accent-600')
					}>
					Light
				</button>
			</div>
		</div>
	);
}
export function MiniThemeController(props: any) {
	const { explicitTheme, changeTheme } = useContext(ThemeContext);

	return (
		<div className="pr-3">
			<button
				onClick={() => changeTheme()}
				className="text-black dark:text-white bg-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
				Change Theme:
			</button>
			<span
				onClick={() => changeTheme()}
				className="cursor-pointer uppercase text-xs">
				{explicitTheme}
			</span>
		</div>
	);
}

const getThemeOptions = (mode: PaletteMode): ThemeOptions => ({
	typography: {
		fontFamily:
			'Open Sans, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
	},
	components: {
		MuiTextField: {
			defaultProps: {
				color: 'secondary',
			},
		},
		MuiButton: {
			styleOverrides: {
				root: { color: 'white' },
				outlined: {
					color: colorGetter('accent.400'),
				},
			},
		},
	},
	palette: {
		mode,
		// Standard values shared regardless of mode
		contrastThreshold: 4.5,
		...(mode === 'light'
			? {
					// palette values for light mode
					primary: {
						main: colorGetter('accent.500'),
					},
					secondary: {
						main: colorGetter('nKipo'),
					},
			  }
			: {
					// palette values for dark mode
					primary: {
						main: colorGetter('accent.500'),
					},
					secondary: {
						main: colorGetter('nKipo'),
					},
			  }),
	},
});
