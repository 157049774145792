import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { TeamService, TeamSummaryDto } from '../../api';
import { UserContext } from '../../contexts/UserContext';
import { useContext, useEffect, useState } from 'react';
import useApi from '../../services/useApi';
import UserSideNav from '../utilities/UserSideNav';
import { Button, Link, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import {
	DataGrid,
	GridColDef,
	GridValueGetterParams,
	GridRowParams,
	GridActionsCellItem,
	GridFooterContainer,
	GridPagination,
	GridRenderCellParams,
} from '@mui/x-data-grid';
import { Visibility as ViewIcon, Add as CreateIcon } from '@mui/icons-material';

import { parseISO } from 'date-fns';
import { getStateInfo, getStateName } from '../../services/helper';
import { StateType } from '../../Constants/Dialog';

const Teams = () => {
	const { user, isLoading: isUserLoading } = useContext(UserContext);
	const [items, setItems] = useState<TeamSummaryDto[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const { callApi } = useApi();
	const navigateTo = useNavigate();

	useEffect(() => {
		const loadItems = async () => {
			const response = await callApi(TeamService.getUserDedicatedTeams());
			if (response.data) {
				setItems(
					response.data.sort(
						(a, b) =>
							parseISO(b.updatedAt).getTime() - parseISO(a.updatedAt).getTime()
					)
				);
				setIsLoading(false);
			}
		};
		if (!isUserLoading && user) {
			setIsLoading(true);
			loadItems();
		}
	}, [isUserLoading, user, callApi]);

	const columns: GridColDef<TeamSummaryDto>[] = [
		{
			field: 'name',
			headerName: 'Name',
			flex: 1,
			renderCell: (params: GridRenderCellParams<TeamSummaryDto>) => (
				<Link component={RouterLink} to={`/dedicated-team/${params.row.id}`}>
					{params.value}
				</Link>
			),
		},
		{
			field: 'description',
			headerName: 'Description',
			flex: 1,
		},
		{
			field: 'state',
			headerName: 'State',
			flex: 0.5,
			renderCell: (params: GridRenderCellParams<TeamSummaryDto>) => (
				<Tooltip title={getStateInfo(params.value, StateType.Team)}>
					<div>{getStateName(params.value, StateType.Team)}</div>
				</Tooltip>
			),
		},
		{
			field: 'updatedAt',
			headerName: 'Last Updated',
			type: 'dateTime',
			valueGetter: (params: GridValueGetterParams<TeamSummaryDto>) =>
				parseISO(params.row.updatedAt),
			flex: 1,
		},
		{
			field: 'actions',
			headerName: 'Actions',
			type: 'actions',
			headerAlign: 'left',
			align: 'left',
			getActions: (params: GridRowParams<TeamSummaryDto>) => {
				const actions = [
					<GridActionsCellItem
						icon={
							<Tooltip title="View Details">
								<ViewIcon />
							</Tooltip>
						}
						label="View Details"
						onClick={() => navigateTo(`/dedicated-team/${params.row.id}`)}
					/>,
				];
				// TODO: Add quick actions once seperate api call available
				return actions;
			},
		},
	];

	return (
		<UserSideNav>
			<h1 className="text-center pb-4">Teams</h1>
			<DataGrid
				rows={items}
				columns={columns}
				autoHeight
				disableRowSelectionOnClick
				loading={isLoading}
				slots={{
					footer: () => (
						<GridFooterContainer>
							{user?.talentProfileId && (
								<Button
									className="m-4"
									variant="contained"
									startIcon={<CreateIcon />}
									onClick={() => navigateTo(`/dedicated-team/${uuidv4()}`)}>
									Create Team
								</Button>
							)}
							<GridPagination />
						</GridFooterContainer>
					),
				}}
			/>
		</UserSideNav>
	);
};

export default Teams;
