import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Box,
} from '@mui/material';
import {
	OrganizationSummaryDto,
	OrganizationsService,
	UpsertOrganizationCommand,
} from '../../api';
import { SwitchInput, TextFieldInput } from '../utilities/Input';
import useApi from '../../services/useApi';
import UrlFavIcon from '../utilities/UrlFavIcon';
import { useDebounce } from 'usehooks-ts';

type OrganizationModalProps = {
	id: string;
	organization: OrganizationSummaryDto | null;
	forceIsEducationalInstiutionAs?: boolean | undefined;
	open: boolean;
	onCancel: () => void;
	onOrganizationAdded: (organizationId: OrganizationSummaryDto) => void;
};

const defaultValues: Omit<OrganizationSummaryDto, 'id'> = {
	name: '',
	alternativeNamesAndAbbreviations: '',
	website: '',
	location: '',
	isEducationalInstitution: false,
};

export const OrganizationModal: React.FC<OrganizationModalProps> = ({
	id,
	organization,
	forceIsEducationalInstiutionAs,
	open,
	onCancel,
	onOrganizationAdded,
}) => {
	const [instanceDefaults] = useState({
		...defaultValues,
		isEducationalInstitution: forceIsEducationalInstiutionAs ?? false,
	});

	const {
		reset,
		watch,
		control,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		defaultValues: organization ?? instanceDefaults,
		shouldFocusError: false,
	});
	const { callApi } = useApi();
	const watchName = watch('name');
	const watchWebsite = useDebounce(watch('website'), 500);

	useEffect(() => {
		if (organization) {
			reset(organization);
		} else {
			reset(instanceDefaults);
		}
	}, [instanceDefaults, organization, reset]);

	const onSubmit = async (data: Omit<UpsertOrganizationCommand, 'id'>) => {
		try {
			const command = { ...data, id };
			const response = await callApi(
				OrganizationsService.upsertOrganization(command)
			);
			if (!response.error) {
				onOrganizationAdded(command);
				reset();
			}
		} catch (error) {
			console.error('Error creating organization:', error);
		}
	};

	const onClose = () => {
		onCancel();
		reset();
	};

	return (
		<Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth>
			<DialogTitle>{organization ? 'Edit' : 'Add'} Organization</DialogTitle>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogContent>
					<TextFieldInput
						label="Name"
						name="name"
						fullWidth
						control={control}
						error={!!errors.name}
						helperText={errors.name?.message}
						rules={{
							required: 'Name is required',
						}}
					/>
					{forceIsEducationalInstiutionAs === undefined ? (
						<SwitchInput
							label="Is a school?"
							name="isEducationalInstitution"
							control={control}
						/>
					) : null}
					<TextFieldInput
						label="Alternative Names and Abbreviations"
						name="alternativeNamesAndAbbreviations"
						control={control}
						fullWidth
						inputRef={register}
					/>
					<Box
						display="flex"
						className="gap-4 justify-between mb-4"
						alignItems="center">
						<TextFieldInput
							className="flex-auto"
							label="Website"
							name="website"
							control={control}
							fullWidth
							inputRef={register}
						/>
						<div className="mb-5">
							<UrlFavIcon url={watchWebsite} name={watchName} />
						</div>
					</Box>
					<TextFieldInput
						label="Location"
						name="location"
						control={control}
						fullWidth
						inputRef={register}
					/>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={onClose} color="primary">
						Cancel
					</Button>
					<Button variant="outlined" type="submit" color="primary">
						{organization ? 'Update' : 'Add'}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
