import React from 'react';

function ensureUrlHasProtocol(url: string) {
	if (!/^https?:\/\//i.test(url)) {
		return 'https://' + url;
	}
	return url;
}

const OpenInNewTabLink = ({ href, children, ...otherProps }: any) => {
	const formattedHref = ensureUrlHasProtocol(href);

	let isValidUrl = false;
	try {
		new URL(formattedHref);
		isValidUrl = true;
	} catch {
		isValidUrl = false;
	}

	return (
		<a
			href={isValidUrl ? formattedHref : null}
			target="_blank"
			rel="noopener noreferrer"
			{...otherProps}>
			{children}
		</a>
	);
};

export default OpenInNewTabLink;
