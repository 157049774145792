/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminService {

	/**
	 * Gets all permissions
	 * Gets all permissions
	 * @returns string Success
	 * @throws ApiError
	 */
	public static getPermissions(): CancelablePromise<Array<string>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Admin/Permissions',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Grants Permission to user
	 * Grants Permission to user
	 * @param permissionName
	 * @returns any Success
	 * @throws ApiError
	 */
	public static createPermission(
		permissionName: string,
	): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/Admin/Permissions/{permissionName}',
			path: {
				'permissionName': permissionName,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Grants Permission to user
	 * Grants Permission to user
	 * @param userId
	 * @param permissionName
	 * @returns any Success
	 * @throws ApiError
	 */
	public static togglePermission(
		userId: string,
		permissionName: string,
	): CancelablePromise<any> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/Admin/Permissions/{userId}/{permissionName}',
			path: {
				'userId': userId,
				'permissionName': permissionName,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

}
