/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HealthStatus } from '../models/HealthStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HealthService {

	/**
	 * Returns the health report
	 * @returns HealthStatus Success
	 * @throws ApiError
	 */
	public static get(): CancelablePromise<HealthStatus> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/healthz',
		});
	}

}
