import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DedicatedTeamDto, SearchType, TeamMemberDto } from '../../api';
import TeamMemberCard from '../utilities/TeamMemberCard';

interface TeamsProps {
	className?: string;
	teams: DedicatedTeamDto[];
}

const shuffleArray = (array: any[]) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
};

const teamVisual = (team: any) => {
	const hasTeamBanner = team.bannerUrl;
	const hasTeamLogo = team.logoUrl;

	if (hasTeamBanner || hasTeamLogo) {
		return (
			<div className="w-full overflow-hidden">
				<img
					src={team.logoUrl ?? team.bannerUrl}
					alt={team.name}
					className="w-full h-full object-contain"
				/>
			</div>
		);
	} else {
		return shuffleArray(team.teamMembers ?? [])
			.map((tm: TeamMemberDto, index: number) => (
				<div
					key={tm.talentProfileId}
					style={{
						marginLeft: `-${11.25 * (index === 0 ? 0 : 1)}rem`,
						zIndex: index,
					}}
					className={(index === 0 ? 'pl-10' : '') + ' flex place-items-center'}>
					<TeamMemberCard member={tm} />
				</div>
			))
			.slice(0, 4);
	}
};

const Teams = (props: TeamsProps) => {
	const navigateTo = useNavigate();
	const buildList = () => {
		if (!props.teams || props.teams?.length === 0) {
			return (
				<li className="flex justify-between p-3 hover:bg-blue-600 hover:text-blue-200">
					<div className="flex flex-col h-full justify-between">
						<h3 className="font-bold">
							You are not currently a member of any active teams!
							<br />
							<Link className="text-accent-400" to={'/teams'}>
								Create one
							</Link>
							,{' '}
							<Link
								className="text-accent-400"
								to={`/search/${SearchType.TALENT}`}>
								find new teammates
							</Link>
							, or get ideas from{' '}
							<Link
								className="text-accent-400"
								to={`/search/${SearchType.TALENT}`}>
								nKipo's AI Team Generator
							</Link>{' '}
							to get started!
						</h3>
					</div>
				</li>
			);
		}
		return props.teams?.map((row: DedicatedTeamDto) => (
			<li
				onClick={() => navigateTo(`/dedicated-team/${row.id}`)}
				key={row.id ?? row.name}
				className="flex justify-between p-3 hover:bg-blue-600 hover:text-blue-200 cursor-pointer">
				<div className="flex w-full items-center gap-4 h-full text-left">
					<div className="flex flex-col h-full w-2/3 justify-between">
						<h3 className="font-bold text-accent-400 mb-1">{row.name}</h3>
						<p className="text-md font-bold text-gray-800 dark:text-gray-200">
							{row.description}
						</p>
					</div>
					<div className="flex w-1/3 max-w-1/3 max-h-24">{teamVisual(row)}</div>
				</div>
			</li>
		));
	};

	return (
		<div className="flex bg-white dark:bg-gray-900 rounded-md shadow-xl max-h-80">
			<div className="flex flex-col container mx-auto overflow-y-auto">
				<ul className="divide-y-2 divide-gray-400">{buildList()}</ul>
			</div>
		</div>
	);
};

export default Teams;
