import React from 'react';
import { ContractDto, GenericLineItemDto } from '../../../api';
import LineItem from './LineItem';

interface LineItemsProps {
	isEditing: boolean;
	items: GenericLineItemDto[];
	invoiceId: string;
	contract: ContractDto;
	onAdd: (x: GenericLineItemDto) => void;
	onRemove: (x: GenericLineItemDto) => void;
	className: string;
	[x: string]: any;
}

const LineItems = (props: LineItemsProps) => {
	const {
		isEditing,
		items,
		invoiceId,
		contract,
		onAdd,
		onChange,
		onRemove,
		className,
		...rest
	} = props;

	const addItem = (
		<LineItem
			key="add"
			invoiceId={invoiceId}
			contract={contract}
			isEditing={true}
			onAdd={onAdd}
		/>
	);
	// TODO: Might make more sense to group by team member
	const groupsToRender = items.reduce(
		(types: any, lineItem: GenericLineItemDto) => {
			const lineItemGroup = types[lineItem.type] ?? (types[lineItem.type] = []);
			lineItemGroup.push(lineItem);
			return types;
		},
		{}
	);

	const currencyFormatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});

	return (
		<div
			className={
				'flex flex-col flex-wrap gap-x-4 gap-y-4 px-4 py-4 bg-gray-100 dark:bg-gray-700 rounded-md mb-6 shadow-xl ' +
				(className ?? '')
			}
			{...rest}>
			{isEditing ? addItem : null}
			{Object.entries(groupsToRender)
				// Show platform fees on the bottom
				.sort(([lineItemType, lineItemTypeGroup]: any, _) =>
					lineItemType === 'PlatformFeeLineItem' ? 1 : -1
				)
				.map(([lineItemType, lineItemTypeGroup]: any) => (
					<React.Fragment key={lineItemType}>
						<h3>{lineItemType}</h3>
						{lineItemTypeGroup.map((lineItem: any) => (
							<LineItem
								key={lineItem.id}
								invoiceId={invoiceId}
								contract={contract}
								item={lineItem}
								isEditing={isEditing}
								onRemove={onRemove}
							/>
						))}
					</React.Fragment>
				))}
			<h3>
				Invoice Total:{' '}
				{currencyFormatter.format(
					items.reduce(
						(runningTotal: number, li: GenericLineItemDto) =>
							runningTotal + li.subTotal,
						0
					)
				)}
			</h3>
		</div>
	);
};

export default LineItems;
