import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import ConversationsMessages from './ConversationsMessages';
import { BsCloudUploadFill } from 'react-icons/bs';
import { VALID_IMAGE_TYPE_STRINGS } from '../../services/helper';

type ConversationProps = {
	conversationProxy: any;
	myIdentity: string;
	messageInput: any;
	onUserFocus: (conversation: any) => void;
};
type ConversationState = {
	conversationProxy: any;
	newMessage: string;
	messages: any[];
	loadingState: string;
	myIdentity: string;
	boundConversations: any;
	onUserFocus: (conversation: any) => void;
};

class Conversation extends Component<ConversationProps, ConversationState> {
	constructor(props: ConversationProps) {
		super(props);
		this.state = {
			newMessage: '',
			conversationProxy: props.conversationProxy,
			messages: [],
			loadingState: 'initializing',
			myIdentity: props.myIdentity,
			boundConversations: new Set(),
			onUserFocus: props.onUserFocus,
		};
	}

	loadMessagesFor = (thisConversation: any) => {
		if (this.state.conversationProxy === thisConversation) {
			thisConversation
				.getMessages()
				.then((messagePaginator: any) => {
					if (this.state.conversationProxy === thisConversation) {
						this.setState({
							messages: messagePaginator.items,
							loadingState: 'ready',
						});
					}
				})
				.catch((err: any) => {
					console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
					this.setState({ loadingState: 'failed' });
				});
		}
	};

	componentDidMount = () => {
		if (this.state.conversationProxy) {
			this.loadMessagesFor(this.state.conversationProxy);

			if (!this.state.boundConversations.has(this.state.conversationProxy)) {
				const newConversation = this.state.conversationProxy;
				newConversation.on('messageAdded', (m: any) =>
					this.messageAdded(m, newConversation)
				);
				this.setState({
					boundConversations: new Set([
						...this.state.boundConversations,
						newConversation,
					]),
				});
			}
		}
	};

	componentDidUpdate = (
		oldProps: ConversationProps,
		oldState: ConversationState
	) => {
		if (this.state.conversationProxy !== oldState.conversationProxy) {
			this.loadMessagesFor(this.state.conversationProxy);

			if (!this.state.boundConversations.has(this.state.conversationProxy)) {
				const newConversation = this.state.conversationProxy;
				newConversation.on('messageAdded', (m: any) =>
					this.messageAdded(m, newConversation)
				);
				this.setState({
					boundConversations: new Set([
						...this.state.boundConversations,
						newConversation,
					]),
				});
			}
		}
	};

	static getDerivedStateFromProps(
		newProps: ConversationProps,
		oldState: ConversationState
	) {
		const logic =
			oldState.loadingState === 'initializing' ||
			oldState.conversationProxy !== newProps.conversationProxy;
		if (logic) {
			return {
				loadingState: 'loading messages',
				conversationProxy: newProps.conversationProxy,
			};
		} else {
			return null;
		}
	}

	messageAdded = (message: any, targetConversation: any) => {
		if (targetConversation === this.state.conversationProxy)
			this.setState((prevState, props) => ({
				messages: [...prevState.messages, message],
			}));
	};

	onMessageChanged = (event: any) => {
		this.setState({ newMessage: event.target.value });
	};

	sendMessage = async (event: any) => {
		event.preventDefault();
		const message = this.state.newMessage;
		this.setState({ newMessage: '' });
		await this.state.conversationProxy.sendMessage(message, {
			senderIdentity: this.state.myIdentity,
		});
	};

	onDrop = (acceptedFiles: any) => {
		this.state.conversationProxy.sendMessage({
			contentType: acceptedFiles[0].type,
			media: acceptedFiles[0],
		});
	};

	render = () => {
		return (
			<Dropzone onDrop={this.onDrop} accept={VALID_IMAGE_TYPE_STRINGS}>
				{({ getRootProps, getInputProps, isDragActive }: any) => (
					<div
						className="w-full h-full flex flex-col"
						onFocus={() => this.state.onUserFocus(this.props.conversationProxy)}
						onBlur={() => this.state.onUserFocus(this.props.conversationProxy)}>
						<div className="border-b pl-2 text-left dark:border-gray-700">
							{this.props.conversationProxy?.channelState.friendlyName}
						</div>
						<div className="grow h-6 min-h-max overflow-auto">
							<div
								{...getRootProps()}
								onClick={() => {}}
								id="OpenChannel"
								style={{ position: 'relative', top: 0 }}>
								{isDragActive && (
									<div className={'styles.drop'}>
										<BsCloudUploadFill
											style={{ fontSize: '5em', color: '#fefefe' }}
										/>
										<h3 style={{ color: '#fefefe' }}>Release to Upload</h3>
									</div>
								)}
								<div
									className={'styles.messages'}
									style={{ filter: `blur(${isDragActive ? 4 : 0}px)` }}>
									<input id="files" {...getInputProps()} />
									<div
										style={{ flexBasis: '100%', flexGrow: 2, flexShrink: 1 }}>
										<ConversationsMessages
											identity={this.props.myIdentity}
											messages={this.state.messages}
										/>
									</div>
								</div>
							</div>
						</div>
						<div>
							<form onSubmit={this.sendMessage}>
								{this.props.messageInput({
									value: this.state.newMessage,
									onChange: this.onMessageChanged,
									disabled: this.state.loadingState !== 'ready',
								})}
							</form>
						</div>
					</div>
				)}
			</Dropzone>
		);
	};
}

export default Conversation;
