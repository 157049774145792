/* Full page with a card content */

import { PropsWithChildren } from 'react';

interface FullPageCardProps {
	className?: string;
}

export default function FullPageCard(
	props: PropsWithChildren<FullPageCardProps>
) {
	return (
		<div className="flex flex-col flex-auto">
			<div
				className="px-4 flex flex-1
					items-center
					justify-center
					w-full
					h-full
					py-4
					sm:py-8
					bg-gradient-to-r
					from-accent-400
					to-primary-400
					dark:from-accent-600
					dark:to-primary-800">
				<div className="px-2 h-full md:px-20 py-2 md:py-10 bg-white dark:bg-gray-900 rounded-md shadow-xl container">
					<div className="flex flex-col items-center h-full">
						<div className={'w-full ' + props.className}>{props.children}</div>
					</div>
				</div>
			</div>
		</div>
	);
}
