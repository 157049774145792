import { FormProvider, useForm } from 'react-hook-form';
import BuyerCard from './BuyerCard';
import { BuyerProfileSummaryDto } from '../../api';
import { useEffect } from 'react';

function BuyerCardViewOnly(props: {
	buyerProfile: Omit<
		BuyerProfileSummaryDto,
		'sentConnections' | 'receivedConnections'
	>;
	isLoading?: boolean;
}) {
	const { buyerProfile, isLoading } = props;
	const form = useForm();
	useEffect(() => {
		form.reset(buyerProfile);
	}, [buyerProfile, form]);

	return (
		<FormProvider {...form}>
			<BuyerCard isEditing={false} isLoading={isLoading} />
		</FormProvider>
	);
}

export default BuyerCardViewOnly;
