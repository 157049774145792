import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
	DedicatedTeamDto,
	PotentialTeamReview,
	TeamMemberDto,
	TeamService,
	UserService,
	UserSummaryDto,
} from '../../api';
import { UserContext } from '../../contexts/UserContext';
import UserSideNav from '../utilities/UserSideNav';
import { Button, LinearProgress, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import useApi from '../../services/useApi';
import DedicatedTeamComponent from '../Team/DedicatedTeamComponent';
import { useDebounce } from 'usehooks-ts';
import StickyActionsBar from '../utilities/StickyActionsBar';
import { useNavigate } from 'react-router-dom';

export interface CondensedTalentProfile {
	id: number;
	shortBio: string;
	primaryDescriptors: string[];
	secondaryDescriptors: string[];
	education: CondensedEducation[];
	experience: CondensedExperience[];
	firstName: string;
	lastName: string;
	tagLine: string;
	locationState: string;
	defaultPayRate: number | null;
	desiredHours: number | null;
}

export interface CondensedEducation {
	educationalInstitution: string;
	degree: null | string;
	major: null | string;
	description: null;
}

export interface CondensedExperience {
	organization: string;
	title: string;
	description: null | string;
	experienceType: string;
}

export interface CondensedTeam {
	tags: string[];
	name: string;
	description: string;
	teamMembers: CondensedTeamMember[];
}

export interface CondensedTeamMember {
	condensedTalentProfileId: number;
	fullName: string;
	role: string;
	hours: number;
	pay: number;
}

const defaultValues = {
	teamSizeMin: 2,
	teamSizeMax: 5,
};

const TeamGenerator = () => {
	const { user } = useContext(UserContext);
	const navigate = useNavigate();
	const [connections, setConnections] = useState<UserSummaryDto[]>([]);
	// TODO: Add functionality allowing for the user to pin team members they would like to include in a team
	// const [pinnedTeamMemberProfileIds, setPinnedTeamMemberProfileIds] = useState<
	// 	string[]
	// >([]);
	const [team, setTeam] = useState<DedicatedTeamDto | undefined>(undefined);
	const [progress, setProgress] = useState(5);

	const { callApi } = useApi();
	const form = useForm({
		mode: 'onBlur',
		defaultValues: defaultValues,
	});
	// const { handleSubmit, watch, control } = form;
	const { watch } = form;
	const [isLoadingCurrentUserConnections, setIsLoadingCurrentUserConnections] =
		useState(true);
	const [isGeneratingTeam, setIsGeneratingTeam] = useState(false);
	const watchTeamSizeMin = watch('teamSizeMin');
	const watchTeamSizeMax = watch('teamSizeMax');

	const generateTeam = useCallback(async () => {
		setIsGeneratingTeam(true);
		const teamSize = Math.floor(
			Math.random() * (watchTeamSizeMax + 1 - watchTeamSizeMin) +
				watchTeamSizeMin
		);
		const teamTalentProfileIds: (string | null)[] = [user?.talentProfileId!]
			.concat
			// pinnedTeamMemberProfileIds
			();
		const connectionsWithTalentProfiles = connections.filter(
			(c) => c.talentProfileId
		);

		// We take a random number of spots on the team and reserve them for to be used by people outside of the User's connections
		// (Math.random's end is exclusive so this will always leave one spot open for the current user and accounts for any other pinned talent profiles)
		const numberOfSpotsReservedForNkipoNetwork = Math.floor(
			Math.random() * (teamSize + 1 - teamTalentProfileIds.length)
		);

		const numberOfSpotsFromUserConnections =
			teamSize -
			(numberOfSpotsReservedForNkipoNetwork + teamTalentProfileIds.length);
		// For the remaining open spots on the team, randomly select a connection
		for (let i = 0; i < numberOfSpotsFromUserConnections; i++) {
			const randomConnection =
				connectionsWithTalentProfiles[
					Math.floor(Math.random() * connectionsWithTalentProfiles.length)
				];
			// We ensure that the randomly selected team member has not already been selected
			if (
				randomConnection &&
				!teamTalentProfileIds.includes(randomConnection.talentProfileId!)
			) {
				teamTalentProfileIds.push(randomConnection.talentProfileId!);
			} else {
				// Use a random user from the network as the user's network grows this becomes less and less likely but initially it could be every additional member
				teamTalentProfileIds.push(null);
			}
		}

		// Add the remaining spots from the nKipo network
		for (let i = 0; i < numberOfSpotsReservedForNkipoNetwork; i++) {
			teamTalentProfileIds.push(null);
		}

		const response = await callApi(
			TeamService.generateTeam(teamTalentProfileIds)
		);
		if (response.data) {
			setTeam(response.data);
		}
		setIsGeneratingTeam(false);
	}, [
		callApi,
		connections,
		// pinnedTeamMemberProfileIds,
		user?.talentProfileId,
		watchTeamSizeMax,
		watchTeamSizeMin,
	]);

	const debouncedGenerateTeam = useDebounce<any>(
		// { generateTeam, isLoadingCurrentUserConnections },
		useMemo(
			() => ({ generateTeam, isLoadingCurrentUserConnections }),
			[generateTeam, isLoadingCurrentUserConnections]
		),
		600
	);

	useEffect(() => {
		const loadConnections = async () => {
			const response = await callApi(UserService.getUserConnections());
			if (response.data) {
				setConnections(response.data);
			}
			setIsLoadingCurrentUserConnections(false);
		};
		setIsLoadingCurrentUserConnections(true);
		if (user?.talentProfileId) {
			loadConnections();
		}
	}, [callApi, user?.talentProfileId]);

	useEffect(() => {
		if (!debouncedGenerateTeam.isLoadingCurrentUserConnections) {
			debouncedGenerateTeam.generateTeam();
		}
	}, [debouncedGenerateTeam]);

	useEffect(() => {
		const timer = isGeneratingTeam
			? setInterval(() => {
					setProgress((prevProgress) =>
						prevProgress >= 100 ? 5 : prevProgress + 5
					);
			  }, 800)
			: null;
		return () => {
			if (timer) {
				clearInterval(timer);
				setProgress(5);
			}
		};
	}, [isGeneratingTeam]);

	const reviewTeam = useCallback(
		async (review: PotentialTeamReview) => {
			const response = await callApi(
				TeamService.reviewPotentialTeam({
					potentialTeamReviewId: team?.id!,
					talentProfileId: user?.talentProfileId!,
					response: review,
				})
			);
			if (response.data) {
				// If we saved to draft then we should navigate to the new team and save it as a draft, otherwise, generate a new team
				if (review === PotentialTeamReview.SAVE_TO_DRAFTS) {
					navigate(`/dedicated-team/${team?.id}`);
				} else {
					// Generate a new team
					generateTeam();
				}
			}
		},
		[callApi, generateTeam, navigate, team, user?.talentProfileId]
	);

	return (
		<UserSideNav>
			{/* <Grid container spacing={2}>
				<Grid item xs={2}>
					<TextFieldInput
						name="teamSizeMin"
						label="Min Team Size"
						type="number"
						maxNumDecimals={0}
						required
						min="2"
						step="1"
						rules={{
							validate: {
								lessThanMax: (v: number) =>
									v <= watchTeamSizeMax ||
									'Must be less than or equal to Team Size Max',
							},
						}}
						control={control}
					/>
				</Grid>
				<Grid item xs={2}>
					<TextFieldInput
						name="teamSizeMax"
						label="Max Team Size"
						type="number"
						maxNumDecimals={0}
						required
						min="2"
						step="1"
						rules={{
							validate: {
								greaterThanMin: (v: number) =>
									v >= watchTeamSizeMin ||
									'Must be greater than or equal to Team Size Min',
							},
						}}
						control={control}
					/>
				</Grid>
				<Grid item xs={2} alignItems="center" justifyContent="center">
					<Button
						variant="contained"
						onClick={handleSubmit(generateTeam)}
						disabled={isLoadingCurrentUserConnections}>
						Generate Team
					</Button>
				</Grid>
			</Grid> */}
			{/* TODO: this needs to be updated to allow for a view only perspective until we get an accept from the user to create a draft
                of the team as is, or indicate whether they like or dislike the idea of that team (strongly or softly perhaps?)  */}
			{isGeneratingTeam ? (
				<div>
					<Typography variant="h5" className="mb-4">
						Generating Team
					</Typography>
					<LinearProgress variant="determinate" value={progress} />
				</div>
			) : (
				team && (
					<>
						{/* <LinearProgress
							variant="buffer"
							value={0}
							valueBuffer={100}
							className="mb-4"
						/> */}
						<DedicatedTeamComponent
							id={team.id}
							team={team}
							isEditing={false}
							onTeamMemberCardClick={(member: TeamMemberDto) =>
								window.open(`/talent-profile/${member.talentProfileId}`)
							}
						/>
						<StickyActionsBar>
							<Button
								variant="contained"
								onClick={() => reviewTeam(PotentialTeamReview.PASS)}>
								I'll Pass
							</Button>
							<Button
								variant="contained"
								onClick={() => reviewTeam(PotentialTeamReview.SAVE_TO_DRAFTS)}>
								Love it! Save to Drafts
							</Button>
							<Button
								variant="contained"
								onClick={() => reviewTeam(PotentialTeamReview.INTERESTED)}>
								I'm Interested
							</Button>
						</StickyActionsBar>
					</>
				)
			)}
		</UserSideNav>
	);
};
export default TeamGenerator;
