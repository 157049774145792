import React from 'react';
import { Control, useFieldArray, UseFormRegister } from 'react-hook-form';
import Button from '@mui/material/Button';
import { StorageService, StoredFileCategory, StoredFileDto } from '../../api';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	IconButton,
	Link,
} from '@mui/material';
import { TextFieldInput } from './Input';
import { v4 as uuidv4 } from 'uuid';
import { Clear as ClearIcon, Add as AddIcon } from '@mui/icons-material';
import useApi from '../../services/useApi';

export type AttachmentsEntity = {
	attachments?: StoredFileDto[] | null;
};

interface AttachmentProps<T extends AttachmentsEntity> {
	register: T extends AttachmentsEntity ? UseFormRegister<T> : never;
	control: T extends AttachmentsEntity ? Control<T> : never;
	isEditing: boolean;
}

const Attachments = <T extends AttachmentsEntity>({
	control,
	isEditing,
	register,
}: AttachmentProps<T>) => {
	const { callApi } = useApi();
	const hiddenFileInput = React.useRef<HTMLInputElement>(null);
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'attachments',
	});

	const ACCEPT_TYPES = [
		'.jpg',
		'.jpeg',
		'.png',
		'.webp',
		'.pdf',
		'.doc',
		'.docx',
		'.xls',
		'.xlsx',
		'.ppt',
		'.pptx',
	];

	const handleFileChange = async (event: any) => {
		const fileUploaded: File = event.target.files[0];
		const response = await callApi(
			StorageService.createFile({
				Id: uuidv4(),
				File: fileUploaded,
				Category: StoredFileCategory.ATTACHMENT,
			})
		);
		if (response.data) {
			append(response.data);
		}
		// Clear the file input incase they select the same file
		hiddenFileInput!.current!.value = '';
	};

	const handleAddAttachment = () => {
		hiddenFileInput?.current?.click();
	};

	return (
		<div>
			<h3 className="mb-4 pt-4">Attachments</h3>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>File Name</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>Description</TableCell>
							{isEditing && <TableCell>Actions</TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{fields.map((x: StoredFileDto, i) => (
							<TableRow key={x.id}>
								<TableCell>
									<Link href={x.presignedUrl} target="_blank" rel="noreferrer">
										{x.originalFileName.substring(
											0,
											x.originalFileName.lastIndexOf('.')
										)}
									</Link>
								</TableCell>
								<TableCell>
									{x.originalFileName
										.substring(x.originalFileName.lastIndexOf('.') + 1)
										.toUpperCase()}
								</TableCell>
								<TableCell>
									<TextFieldInput
										name={`attachments.${i}.description`}
										control={control}
										size="small"
										readOnly={!isEditing}
										multiline
										maxRows={4}
										noErrorMessage
										fullWidth
									/>
								</TableCell>
								<TableCell>
									{isEditing && (
										<IconButton color="error" onClick={() => remove(i)}>
											<ClearIcon />
										</IconButton>
									)}
								</TableCell>
							</TableRow>
						))}
						{!isEditing && fields.length === 0 && (
							<TableRow>
								<TableCell colSpan={4} align="center">
									No attachments
								</TableCell>
							</TableRow>
						)}
						{isEditing && (
							<TableRow>
								<TableCell colSpan={4}>
									<Button
										variant="outlined"
										startIcon={<AddIcon />}
										onClick={handleAddAttachment}>
										Add Attachment
									</Button>
									<input
										type="file"
										ref={hiddenFileInput}
										onChange={handleFileChange}
										accept={ACCEPT_TYPES.join(',')}
										className="hidden"
									/>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default Attachments;
