import React, { useState, useContext, useEffect } from 'react';
import Projects from './Projects';
import Revenue from './Revenue';
import Teams from './Teams';
import UserSideNav from '../utilities/UserSideNav';
import { UserContext } from '../../contexts/UserContext';
import ProjectRecommendations from './ProjectRecommendations';
import { useApi, ApiResponse, ParallelRequest } from '../../services/useApi';
import {
	ProjectDto,
	ProjectService,
	ProjectState,
	ProjectSummaryDto,
	TalentProfileDto,
	UserService,
} from '../../api';
import { Paper } from '@mui/material';

const Dashboard = () => {
	const { user } = useContext(UserContext);
	const [projects, setProjects] = useState<ProjectSummaryDto[]>([]);
	const [recommendedProjects, setRecommendedProjects] = useState<ProjectDto[]>(
		[]
	);
	const [userSkills, setUserSkills] = useState([] as any);
	const [talentProfile, setTalentProfile] = useState(null as any);
	const [isLoadingData, setIsLoadingData] = useState(false);
	const { requestInParallel } = useApi();

	/*Get Projects Data & Talent Skills*/
	useEffect(() => {
		const loadData = async () => {
			const parallelRequests = [
				{
					request: ProjectService.getUserProjects(),
					handler: (response: ApiResponse<ProjectSummaryDto[]>) =>
						setProjects(
							response.data?.filter((x) => x.state === ProjectState.ACTIVE) ??
								[]
						),
				},
			].concat(
				// Only request TalentProfile details if the user has a talentProfile
				(user?.talentProfileId
					? [
							{
								request: ProjectService.getRecommendedProjects(),
								handler: (response: ApiResponse<ProjectDto[]>) =>
									setRecommendedProjects(response.data ?? []),
							},
							{
								request: UserService.getCurrentUserFullTalentProfile(),
								handler: (response: ApiResponse<TalentProfileDto>) => {
									if (response.data) {
										const profile = response.data;
										setTalentProfile(profile);
										if (profile && profile.primaryDescriptors) {
											profile.primaryDescriptors.forEach(function (
												element: any
											) {
												setUserSkills((userSkills: any) => [
													...userSkills,
													element.skill.name,
												]);
											});
										}
									}
								},
							},
					  ]
					: []) as ParallelRequest<any>[]
			);

			await requestInParallel(parallelRequests);
			setIsLoadingData(false);
		};

		if (user) {
			loadData();
			setIsLoadingData(true);
		}
	}, [user, requestInParallel]);

	return (
		<UserSideNav>
			<div>
				<div
					className="container
					mx-auto flex flex-row bg-white dark:bg-gray-900">
					<div className="w-full bg-white dark:bg-gray-900">
						{talentProfile ? (
							<>
								<Paper elevation={5} className="dark:bg-gray-900 mb-10">
									<div className="pt-5 pl-5">
										<h2>Active Teams</h2>
									</div>
									<Teams teams={talentProfile?.teamMemberships} />
								</Paper>
								<Paper elevation={5} className="dark:bg-gray-900 mb-10">
									<div className="pt-5 pl-5">
										<h2>Active Projects</h2>
									</div>
									<Projects projects={projects} loading={isLoadingData} />
								</Paper>
								<Paper elevation={5} className="dark:bg-gray-900 mb-10">
									<div className="pt-5 pl-5">
										<h2>Revenue</h2>
										<Revenue
											talentProfile={talentProfile}
											isLoading={isLoadingData}
										/>
									</div>
								</Paper>
								<Paper elevation={5} className="dark:bg-gray-900 mb-10">
									<div className="pt-5 pl-5">
										<h2>Recommended Projects</h2>
									</div>
									<ProjectRecommendations
										projects={recommendedProjects}
										userSkills={userSkills}
										loading={isLoadingData}
									/>
								</Paper>
							</>
						) : (
							<Paper elevation={5} className="dark:bg-gray-900 mb-10">
								<div className="pt-5 pl-5">
									<h2>Active Projects</h2>
								</div>
								<Projects projects={projects} loading={isLoadingData} />
							</Paper>
						)}
					</div>
				</div>
			</div>
		</UserSideNav>
	);
};

export default Dashboard;
