import { useContext } from 'react';
import { CircularProgress } from '@mui/material';

import { LoadingIndicatorContext } from '../../contexts/LoadingIndicatorContext';

const LoadingIndicator = () => {
	const { showLoadingIndicator } = useContext(LoadingIndicatorContext);

	return showLoadingIndicator ? (
		<CircularProgress className="fixed top-20 right-2" color="primary" />
	) : null;
};

export default LoadingIndicator;
