import {
	BuyerPaymentState,
	ContractState,
	InvoiceState,
	ProjectState,
	TalentPaymentState,
	TeamState,
} from '../api';

export const ContractDialog = {
	SubmitBid:
		'Submitting this bid will notify the Buyer to review, bid can be withdrawn before Buyer has reviewed. Are you sure you want to submit it for review?',
	AcceptBid:
		'Accepting this bid will create an Active Contract and mark Project as Active. All other pending bids will automatically be rejected. Are you sure you want to accept?',
	RejectBid:
		'Rejecting this bid will notify the team, they can resubmit the bid so long as the project remains in Bidding. Are you sure you want to reject?',
	WithdrawBid:
		'Withdrawing this bid will make it no longer reviewable by Buyer, you can edit and resubmit the bid so long as the project remains in Bidding. Are you sure you want to withdraw?',
	CompleteContract:
		'Completing this contract will mark the project as completed giving the team one business week to submit invoices. Are you sure you want to mark the contract as completed successfully?',
	EndContract:
		'Ending this contract will mark the project as ended giving the team one business week to submit invoices. Are you sure you want to mark the contract as not completed successfully?',
	RequestCompletion:
		'Are you sure you want to notify the Buyer that the contract has been completed?',
};

export const ProjectDialog = {
	Publish:
		'Publishing this project will open it up to Teams for bidding. Are you sure you want to publish?',
	Complete:
		'Completing this project will mark the associated contract as completed. Are you sure you want to mark the project as completed successfully?',
	End: 'Ending this project will mark the associated contract as ended. Are you sure you want to mark the project as not completed successfully?',
};

export const ActionDialog = {
	DeleteDraft: 'Are you sure you want to delete this draft?',
};

// Houses any conversions or information to relay to the user in place of our back-end states
export type StateDefinition = {
	genericName?: string;
	genericInfo?: string;
	talentName?: string;
	talentInfo?: string;
	buyerName?: string;
	buyerInfo?: string;
};

export enum StateType {
	Generic,
	Team,
	Project,
	Contract,
	Invoice,
	TalentPayment,
	BuyerPayment,
}

export const StateDefinitions: {
	[key in StateType]?: { [key: string]: StateDefinition };
} = {
	[StateType.Generic]: {
		Unsaved: {
			genericInfo:
				'Data has not been saved due to incomplete or invalid information.',
		},
		Saving: { genericInfo: 'Data is currently being saved.' },
		Saved: { genericInfo: 'Data has been saved.' },
		Draft: {
			genericInfo: 'A work in progress only visible to you.',
			talentInfo:
				'A work in progress only visible to you or your team members to which this item belongs.',
		},
		// Technically nothing should show if it's been removed
		Removed: {
			genericInfo: 'Data has been removed.',
		},
	},
	[StateType.Team]: {
		[TeamState.PENDING]: {
			genericInfo:
				'Team is currently being formed and is awaiting member invite responses. Visible to team members who have accepted the invite or are pending a response.',
		},
		[TeamState.ACTIVE]: {
			genericInfo: 'Team is active and publicly visible.',
		},
		[TeamState.INACTIVE]: {
			genericInfo: 'Team has been disbanded and is no longer publicly visible.',
		},
	},
	[StateType.Project]: {
		[ProjectState.BIDDING]: {
			genericInfo:
				'Project is open to having teams place bids and is publicly visible.',
		},
		[ProjectState.ACTIVE]: {
			genericInfo:
				'Project is actively being worked on by a team and is publicly visible.',
		},
		[ProjectState.COMPLETED]: {
			genericInfo: 'Project has successfully completed.',
		},
		[ProjectState.ENDED]: {
			genericInfo: 'Project as been terminated before completion.',
		},
	},
	[StateType.Contract]: {
		[ContractState.PENDING]: {
			talentInfo:
				"Bid has been placed and is awaiting project owner's response.",
			buyerInfo: 'Bid is awaiting your response.',
		},
		[ContractState.WITHDRAWN]: {
			genericInfo:
				'Bid has been withdrawn and is no longer viewable by project owner.',
		},
		[ContractState.REJECTED]: {
			genericInfo: 'Bid has been rejected.',
		},
		[ContractState.ACTIVE]: {
			genericInfo: 'Bid has been accepted, and a contract has been formed.',
		},
		[ContractState.COMPLETED]: {
			genericInfo: 'Contract has been completed.',
		},
		[ContractState.BUYER_ENDED]: {
			genericInfo:
				'Contract has been terminated by the project owner before it could be completed.',
		},
		[ContractState.TALENT_ENDED]: {
			genericInfo:
				'Contract has been terminated by the team before it could be completed.',
		},
		[ContractState.COMPLETION_REQUESTED]: {
			genericInfo: 'Team has requested that the buyer complete the contract.',
		},
	},
	[StateType.Invoice]: {
		[InvoiceState.PENDING]: {
			talentInfo: 'Invoice has been submitted to buyer for review and payment.',
			buyerName: 'Awaiting Payment',
			buyerInfo:
				'Invoice is awaiting your review and payment if payment is not currently processing.',
		},
		[InvoiceState.PAID]: {
			genericInfo: 'Invoice has been paid.',
		},
	},
	[StateType.TalentPayment]: {
		[TalentPaymentState.PENDING]: {
			genericInfo:
				'Associated invoice has been submitted and awaiting payment.',
		},
		[TalentPaymentState.COMPLETE]: {
			genericInfo:
				'Associated invoice has been paid and your portion of the payment has been transferred in Stripe.',
		},
		[TalentPaymentState.FAILED]: {
			genericInfo:
				'Associated invoice has been paid but your portion of the payment cannot be transferred until Stripe onboarding has been completed.',
		},
	},
	[StateType.BuyerPayment]: {
		[BuyerPaymentState.PENDING]: {
			genericInfo: 'Associated invoice is awaiting your review and payment.',
		},
		[BuyerPaymentState.INITIATED]: {
			genericInfo: 'Payment checkout has begun but has not yet been completed.',
		},
		[BuyerPaymentState.PROCESSING]: {
			genericInfo:
				'Payment checkout has been completed and payment is processing.',
		},
		[BuyerPaymentState.FAILED]: {
			genericInfo: 'Payment has failed to process and must be restarted.',
		},
		[BuyerPaymentState.COMPLETE]: {
			genericInfo: 'Payment has been made successfully.',
		},
	},
};
