import React from 'react';
import { Link } from 'react-router-dom';
import FullPageCard from './components/utilities/FullPageCard';

export const Unauthorized: React.FC<{ message?: string }> = (props) => {
	return (
		<FullPageCard className="px-20 py-10">
			<h1 className="font-bold text-accent-400 text-9xl">401</h1>

			<h6 className="mb-2 text-2xl font-bold text-center text-gray-800 dark:text-gray-200 md:text-3xl">
				<span className="text-primary-500">Unauthorized</span>
			</h6>

			{props.message && (
				<p className="mb-8 text-center text-gray-500 md:text-lg">
					{props.message}
				</p>
			)}

			<Link
				to="/"
				className="px-6 py-2 text-sm font-semibold text-primary-800 bg-gray-200">
				Go home
			</Link>
		</FullPageCard>
	);
};
