import UserSideNav from '../utilities/UserSideNav';
import Chat from './Chat';

function FullscreenChat(props: any) {
	return (
		<UserSideNav>
			<Chat isChatInFullscreen={true} {...props} />
		</UserSideNav>
	);
}

export default FullscreenChat;
