import React, { useContext } from 'react';
import { TeamMemberDto } from '../../api';
import { CloseButton } from './IconButtons';
import ThemeContext from '../../contexts/ThemeContext';
import {
	defaultLightProfilePicUrl,
	defaultDarkProfilePicUrl,
} from '../../services/helper';

function TeamMemberCard(props: any) {
	const {
		member: {
			talentProfileId,
			preferredName,
			role,
			profilePicUrl,
			hours,
			pay,
			inviteState,
		},
		editing,
		className,
		onRemove,
		hasOnClick,
		...rest
	}: {
		member: TeamMemberDto;
		editing?: boolean;
		onRemove: any;
		className: string;
		hasOnClick: boolean;
		rest: any[];
	} = props;

	const { theme } = useContext(ThemeContext);

	const defaultProfilePicUrl =
		theme === 'light' ? defaultLightProfilePicUrl : defaultDarkProfilePicUrl;
	return (
		<div
			className="relative group py-1"
			title={preferredName + (role ? '; ' + role : '')}
			{...rest}>
			<div
				className={`${className ?? ''} ${
					hasOnClick ? 'group-hover:border-nKipo hover:cursor-pointer' : ''
				} overflow-show relative max-w-sm w-52 h-24 max-h-24 shadow-lg ring-1 ring-black/5 rounded-md flex items-center bg-gray-200 dark:bg-gray-600 rounded-md group border-2 border-gray-300 dark:border-gray-800`}>
				<img
					className={`object-cover absolute -left-10 w-24 h-24 rounded-full shadow-lg p-0.5 border-transparent ${
						hasOnClick
							? 'group-hover:bg-gradient-to-r group-hover:from-nKipo group-hover:to-transparent'
							: ''
					}`}
					src={profilePicUrl ?? defaultProfilePicUrl}
					alt={'Profile picture of ' + preferredName}
					onError={(event: any) => {
						event.currentTarget.onerror = null;
						event.currentTarget.src = defaultProfilePicUrl;
					}}
				/>
				{/* Text over profile photo indicating state */}
				{editing && inviteState && (
					<div className="absolute -left-10 bottom-0 text-xs flex items-center justify-center bg-gray-200 dark:bg-gray-600 rounded-md shadow-lg px-1 py-0.5 opacity-90">
						{inviteState}
					</div>
				)}
				<div className="flex flex-col py-5 pl-14">
					<strong className="w-36 text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
						{preferredName}
					</strong>
					<span className="w-36 text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
						{role}
					</span>
					{!!hours && (
						<span className="w-36 text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
							{'Hours: ~' + hours}
						</span>
					)}
					{!!pay && (
						<span className="w-36 text-sm font-medium text-gray-500 dark:text-gray-400 truncate">
							{'Pay: $' + pay + '/hr'}
						</span>
					)}
				</div>
				{editing && onRemove && (
					<CloseButton
						onClick={(event: any) => {
							onRemove({ talentProfileId });
							event.stopPropagation();
						}}
						className="absolute -top-2 -right-2"
					/>
				)}
			</div>
		</div>
	);
}

export default TeamMemberCard;
