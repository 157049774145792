import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FiUserPlus } from 'react-icons/fi';
import { useSearchParams } from 'react-router-dom';

const SignupButton = () => {
	const { loginWithRedirect } = useAuth0();
	const [searchParams] = useSearchParams();
	return (
		<button
			className="mx-2 text-black dark:text-white rounded-full border hover:border-accent-200 hover:text-accent-200 px-2 py-1 bg-transparent"
			onClick={() =>
				loginWithRedirect({
					screen_hint: 'signup',
					appState: {
						returnTo: '/start',
						...Object.fromEntries(searchParams.entries()),
					},
				})
			}>
			Sign Up
			<FiUserPlus size={20} className="ml-2 sm:inline-block hidden" />
		</button>
	);
};

export default SignupButton;
