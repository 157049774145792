import React from 'react';
import { Input, Lookup, Select } from '../../utilities/FloatingLabelInput';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { AddButton, CloseButton } from '../../utilities/IconButtons';
import { v4 as uuidv4 } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import {
	ContractDto,
	ContractMemberDto,
	GenericLineItemDto,
} from '../../../api';
import { setValueAsUserInputOptions } from '../../../services/helper';

const defaultLineItem = {
	type: 'TalentTimeLineItem',
	subTotal: 0,
	timeWorked: 0,
	baseRate: 0,
} as GenericLineItemDto;

interface LineItemProps {
	isEditing: boolean;
	item?: GenericLineItemDto;
	invoiceId: string;
	contract: ContractDto;
	onAdd?: (x: GenericLineItemDto) => void;
	onRemove?: (x: GenericLineItemDto) => void;
	className?: string;
	[x: string]: any;
}

function LineItem(props: LineItemProps) {
	const {
		item = defaultLineItem,
		invoiceId,
		contract,
		isEditing,
		className,
		onAdd,
		onRemove,
		...rest
	} = props;
	const {
		register,
		setValue,
		handleSubmit,
		watch,
		reset,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		defaultValues: item,
	});
	const [contractMember, setContractMember] = useState<
		ContractMemberDto | undefined
	>(
		contract?.contractMembers?.find(
			(x: ContractMemberDto) => x.talentProfileId === item.talentProfileId
		) as ContractMemberDto
	);

	const areFieldsEditable = isEditing && onAdd;
	const watchType = watch('type');
	const watchTimeWorked = watch('timeWorked');
	const watchBaseRate = watch('baseRate');
	const watchQuantity = watch('quantity');
	const watchPricePerUnit = watch('pricePerUnit');
	// TODO: it'd be nice if we can eliminate this. watchType on it's own doesn't work well when inputs are disabled
	// But item.type is modified by useForm outside of the react change detection process and therefore doesn't cause the needed re-renders :/
	const type = watchType ?? item.type;
	const timeWorked = watchTimeWorked ?? item.timeWorked;
	const baseRate = watchBaseRate ?? item.baseRate;
	const quantity = watchQuantity ?? item.quantity;
	const pricePerUnit = watchPricePerUnit ?? item.pricePerUnit;

	const addingHandler = (data: GenericLineItemDto) => {
		const lineItem = {
			...data,
			id: uuidv4(),
			invoiceId: invoiceId,
		} as GenericLineItemDto;
		onAdd!(lineItem);
		resetItem();
	};
	const onAddingError = (data: any) => {
		console.error(data);
	};
	const removingHandler = () => {
		onRemove!(item);
	};
	const resetItem = () => {
		reset();
		if (type === 'TalentTimeLineItem') {
			setContractMember(undefined);
		} else if (type === 'MaterialsLineItem') {
			setContractMember(undefined);
		}
	};

	const [contractMemberSearchResults, setContractMemberSearchResults] =
		useState(contract.contractMembers);
	const searchContractMembers = (newVal: string) => {
		if (newVal) {
			const lowerNewVal = newVal.toLowerCase();
			const potentialContractMembers = contract.contractMembers.filter(
				(x: ContractMemberDto) =>
					(x.talentProfile.firstName + ' ' + x.talentProfile.lastName)
						.toLowerCase()
						.includes(lowerNewVal)
			);
			setContractMemberSearchResults(potentialContractMembers);
		}
	};

	const handleContractMemberSearchChange = (
		e: any,
		result: ContractMemberDto
	) => {
		setValue(
			'talentProfileId',
			result.talentProfileId,
			setValueAsUserInputOptions
		);
		if (type === 'TalentTimeLineItem') {
			setValue('timeWorked', result.weeklyHours, setValueAsUserInputOptions);
			setValue('baseRate', result.hourlyRate, setValueAsUserInputOptions);
		}
		setContractMember(result);
	};

	const ref = useHotkeys(
		'Ctrl+enter',
		() => {
			handleSubmit(addingHandler)();
		},
		{
			enabled: onAdd ? true : false,
			enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'],
		},
		[handleSubmit, addingHandler]
	);

	const ContractMemberLookup = () => (
		<React.Fragment>
			<div className="basis-48">
				<Input
					type="hidden"
					{...register('talentProfileId', {
						required: 'Contract member is required',
						disabled: !areFieldsEditable,
					})}
					defaultValue={item.talentProfileId}
					errors={[]}
					className="hidden"
				/>
				<Lookup
					label="Contract member"
					name="contractMember"
					defaultValue={contractMember?.talentProfile.firstName}
					disabled={!areFieldsEditable}
					onInput={(event: any) => searchContractMembers(event.target.value)}
					options={contractMemberSearchResults}
					onChange={handleContractMemberSearchChange}
					valueMapper={(option: ContractMemberDto | null) =>
						option?.talentProfileId
					}
					optionMapper={(option: ContractMemberDto | null) =>
						option?.talentProfile.firstName
					}
					className="mb-4"
					errors={[]}
				/>
			</div>
		</React.Fragment>
	);

	const currencyFormatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});
	const SubTotal = () => {
		let subTotal = 0;
		switch (type) {
			case 'TalentTimeLineItem':
				subTotal = (timeWorked ?? 0) * (baseRate ?? 0);
				break;
			case 'MaterialsLineItem':
				subTotal = (quantity ?? 0) * (pricePerUnit ?? 0);
				break;
			default:
				subTotal = item.subTotal;
				break;
		}
		setValue('subTotal', subTotal);
		return (
			<div className="basis-28">
				<Input
					type="hidden"
					{...register('subTotal', { value: subTotal })}
					defaultValue={item.subTotal}
					errors={[]}
					className="hidden"
				/>
				<Input
					readOnly={true}
					disabled={true}
					value={currencyFormatter.format(subTotal)}
					label="Sub-total"
				/>
			</div>
		);
	};
	return (
		<div
			className="flex flex-row flex-wrap w-full justify-between gap-x-4 gap-y-4"
			ref={ref as any}
			{...rest}>
			{type !== 'PlatformFeeLineItem' ? (
				<div className="basis-24">
					<Select
						{...register('type', {
							value: item.type,
							disabled: !areFieldsEditable,
						})}
						label="Line-item Type"
						defaultValue={item.type}
						options={[
							{ id: 'TalentTimeLineItem', name: 'Time' },
							{ id: 'MaterialsLineItem', name: 'Materials' },
						]}
					/>
				</div>
			) : null}
			{type === 'TalentTimeLineItem' ? (
				<React.Fragment>
					<ContractMemberLookup />
					<div className="basis-28">
						{/* Need to make sure this doesn't go beyond the agreed upon hours. If it is above anticipated hours it should probably require approval from the Buyer or some kind of strong notification. */}
						<Input
							label="Time Worked"
							{...register('timeWorked', {
								required: 'Time worked is required',
								validate: {
									checkNum: (v) => isNaN(v!) === false || 'Must be a number',
									checkNegative: (v) => v! > 0 || 'Must be a positive number',
								},
								value: item.timeWorked,
								valueAsNumber: true,
								disabled: !areFieldsEditable,
							})}
							className="mb-4"
							errors={errors}
						/>
					</div>
					<div className="basis-28">
						{/* Need to set a max on this to ensure it is not above the agreed upon rate */}
						<Input
							prefix="$"
							suffix="/hr"
							label="Bill Rate"
							type="number"
							min="0.01"
							step="0.01"
							{...register('baseRate', {
								required: 'Bill rate is required',
								validate: {
									checkNegative: (v) => v! > 0 || 'Must be a positive number',
								},
								value: item.baseRate,
								valueAsNumber: true,
								disabled: !areFieldsEditable,
							})}
							className="mb-4"
							errors={errors}
						/>
					</div>
				</React.Fragment>
			) : type === 'MaterialsLineItem' ? (
				<React.Fragment>
					<ContractMemberLookup />
					<div className="basis-28">
						<Input
							label="Quantity"
							{...register('quantity', {
								required: 'Quantity is required',
								validate: {
									checkNum: (v) => isNaN(v!) === false || 'Must be a number',
									checkNegative: (v) => v! > 0 || 'Must be a positive number',
								},
								value: item.quantity,
								valueAsNumber: true,
								disabled: !areFieldsEditable,
							})}
							className="mb-4"
							errors={errors}
						/>
					</div>
					<div className="basis-28">
						<Input
							prefix="$"
							suffix="/unit"
							label=""
							type="number"
							min="0.01"
							step="0.01"
							{...register('pricePerUnit', {
								required: 'Price per unit is required',
								validate: {
									checkNegative: (v) => v! > 0 || 'Must be a positive number',
								},
								value: item.pricePerUnit,
								valueAsNumber: true,
								disabled: !areFieldsEditable,
							})}
							className="mb-4"
							errors={errors}
						/>
					</div>
				</React.Fragment>
			) : (
				<React.Fragment>
					<div className="basis-48">Platform Fees</div>
				</React.Fragment>
			)}
			<div className="basis-32">
				<Input
					label="Note"
					{...register('note', {
						value: item.note,
						disabled: !areFieldsEditable,
					})}
					title={item.note}
					className="mb-4"
					errors={errors}
				/>
			</div>
			<SubTotal />
			{isEditing ? (
				<div className="place-self-center flex justify-end">
					{onAdd ? (
						<AddButton onClick={handleSubmit(addingHandler, onAddingError)} />
					) : (
						<CloseButton onClick={removingHandler} />
					)}
				</div>
			) : null}
		</div>
	);
}

export default LineItem;
