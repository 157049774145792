import React, { useContext, useEffect, useState, useCallback } from 'react';
import { UserContext } from '../../contexts/UserContext';
import Button from '../utilities/Button';
import useApi from '../../services/useApi';
import { AdminService, UserService, UserSummaryDto } from '../../api';
import FullPageCard from '../utilities/FullPageCard';
import { Permissions } from '../../Constants/Permissions';
import { AutocompleteInput, TextFieldInput } from '../utilities/Input';
import { useForm } from 'react-hook-form';
import {
	userOptionDisplayMapper,
	userOptionValueMapper,
} from '../../services/helper';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

type CreatePermissionForm = {
	permissionName: string | undefined;
};

const defaultCreatePermissionValues: CreatePermissionForm = {
	permissionName: '',
};

type TogglePermissionForm = {
	user: UserSummaryDto | undefined;
	permission: { id: string; name: string } | undefined;
};

const defaultTogglePermissionValues: TogglePermissionForm = {
	user: undefined,
	permission: undefined,
};

const Admin = () => {
	const { callApi } = useApi();
	const {
		user,
		isLoading: isUserLoading,
		setSessionStorageUserData,
	} = useContext(UserContext);
	const {
		handleSubmit: handleCreatePermissionSubmit,
		control: createPermissionControl,
		setValue: setValueCreatePermission,
	} = useForm({
		mode: 'onBlur',
		defaultValues: defaultCreatePermissionValues,
		shouldFocusError: false,
	});
	const {
		handleSubmit: handleTogglePermissionSubmit,
		reset: resetTogglePermission,
		control: togglePermissionControl,
	} = useForm({
		mode: 'onBlur',
		defaultValues: defaultTogglePermissionValues,
		shouldFocusError: false,
	});
	const [canCreatePermission, setCanCreatePermission] = useState(false);
	const [canGrantAnyPermission, setCanGrantAnyPermission] = useState(false);
	const [canViewAllPermissions, setCanViewAllPermissions] = useState(false);
	const [permissions, setPermissions] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const getPermissions = async () => {
			const response = await callApi(AdminService.getPermissions());
			if (response.data) {
				setPermissions(response.data);
			}
			setIsLoading(false);
		};
		if (!isUserLoading && user) {
			setIsLoading(true);
			setCanCreatePermission(
				user.permissions.includes(Permissions.CREATE_PERMISSION)
			);
			setCanGrantAnyPermission(
				user.permissions.includes(Permissions.GRANT_ANY_PERMISSION)
			);
			const ableToViewAllPermissions = user.permissions.includes(
				Permissions.VIEW_ALL_PERMISSIONS
			);
			setCanViewAllPermissions(ableToViewAllPermissions);
			if (ableToViewAllPermissions) {
				getPermissions();
			} else {
				setPermissions([...user.permissions]);
				setIsLoading(false);
			}
		}
	}, [callApi, isUserLoading, user]);

	const handleCreatePermission = async ({
		permissionName: newPermission,
	}: CreatePermissionForm) => {
		if (!newPermission?.trim()) return;
		try {
			await AdminService.createPermission(newPermission);
			setPermissions((existingPermissions) => {
				existingPermissions.unshift(newPermission);
				return existingPermissions;
			});
			setSessionStorageUserData((currentUserData) => ({
				...currentUserData!,
				permissions: [...currentUserData!.permissions, newPermission],
			}));
			// resetCreatePermission();
			setValueCreatePermission('permissionName', '');
		} catch (error) {
			console.error('Failed to create permission', error);
		}
	};

	const searchUsers = useCallback(
		async (query?: string) => {
			const response = await callApi(UserService.searchUsers(query));
			return response;
		},
		[callApi]
	);

	const handleTogglePermission = async ({
		user,
		permission,
	}: TogglePermissionForm) => {
		if (!user?.id || !permission?.name?.trim()) return;
		try {
			await AdminService.togglePermission(user.id, permission.name);
			resetTogglePermission();
		} catch (error) {
			console.error('Failed to toggle permission', error);
		}
	};

	const userSorter = useCallback(() => 0, []);

	if (isLoading) {
		return null;
	}

	return (
		<FullPageCard className="w-full md:w-3/4 place-self-center items-center">
			<h1>Admin</h1>
			<p>With great power comes great responsibility!</p>
			<div>
				{user?.permissions.includes(Permissions.RESEND_STRIPE_EVENTS) ? (
					<span>
						<Link className="text-nKipo" to="payment-events">
							Stripe Events
						</Link>
					</span>
				) : null}
			</div>
			<br />
			<h2>Permissions</h2>
			{canCreatePermission || permissions.length === 0 ? (
				<>
					<h3>Create New Permission</h3>
					<br />
					<form>
						<Grid
							container
							spacing={{ xs: 2 }}
							columns={{ xs: 4, sm: 8, md: 12 }}
							alignItems="center">
							<Grid item xs={4} md={6}>
								<TextFieldInput
									name="permissionName"
									control={createPermissionControl}
									label="Permission Name"
									readOnly={false}
									fullWidth
								/>
							</Grid>
							<Grid item xs={2} md={3}>
								<Button
									type="submit"
									onClick={handleCreatePermissionSubmit(
										handleCreatePermission
									)}>
									Create New Permission
								</Button>
							</Grid>
						</Grid>
					</form>
				</>
			) : null}
			{canGrantAnyPermission ? (
				<>
					<h3>Toggle User Permission</h3>
					<br />
					<form>
						<Grid
							container
							spacing={{ xs: 2 }}
							columns={{ xs: 4, sm: 8, md: 12 }}
							alignItems="center">
							<Grid item xs={3} md={4}>
								<AutocompleteInput
									name="user"
									control={togglePermissionControl}
									label="User"
									readOnly={false}
									fullWidth
									optionDisplayMapper={userOptionDisplayMapper}
									optionValueMapper={userOptionValueMapper}
									optionApiService={searchUsers}
									sorter={userSorter}
									apiFiltering
								/>
							</Grid>
							<Grid item xs={3} md={4}>
								<AutocompleteInput
									name="permission"
									control={togglePermissionControl}
									label="Permission"
									readOnly={false}
									fullWidth
									options={permissions.map((perm) => ({
										id: perm,
										name: perm,
									}))}
								/>
							</Grid>
							<Grid item xs={2} md={3}>
								<Button
									type="submit"
									onClick={handleTogglePermissionSubmit(
										handleTogglePermission
									)}>
									Toggle Permission
								</Button>
							</Grid>
						</Grid>
					</form>
				</>
			) : null}
			{canViewAllPermissions ? (
				<>
					<ul>
						{permissions.map((perm) => (
							<li key={perm}>{perm}</li>
						))}
					</ul>
				</>
			) : null}
		</FullPageCard>
	);
};

export default Admin;
