import './index.css';
import App from './App';
import { createRoutesFromElements, Route } from 'react-router-dom';
import { ProtectedRoute } from './auth/ProtectedRoute';
import DedicatedTeam from './components/Team/DedicatedTeam';
import Dashboard from './components/Dashboard/Dashboard';
import { NotFound } from './NotFound';
import Home from './Home';
import OnboardingWizard from './onboarding/OnboardingWizard';
import UserAccount from './components/UserAccount/UserAccount';
import SearchResults from './components/Search/SearchResults';
import TalentProfile from './components/TalentProfile/TalentProfile';
import Connections from './components/Dashboard/Connections';
import Project from './components/Projects/Project';
import Invoice from './components/Payment/Invoice/Invoice';
import { RerouteOn } from './components/utilities/RerouteOn';
import BuyerProfile from './components/BuyerProfile/BuyerProfile';
import Contract from './components/Contract/Contract';
import { Unauthorized } from './Unauthorized';
import Contracts from './components/Contract/Contracts';
import Payments from './components/Payment/Payments';
import Projects from './components/Projects/Projects';
import Teams from './components/Team/Teams';
import { createBrowserRouter } from 'react-router-dom';
import TeamGenerator from './components/TeamGenerator/TeamGenerator';
import InstallApp from './components/InstallApp/InstallApp';
import FullscreenChat from './components/Chat/FullscreenChat';
import Admin from './components/Admin/Admin';
import PaymentEvents from './components/Admin/PaymentEvents/PaymentEvents';

const hasAnyProfile = (userContext: any) =>
	userContext?.user?.talentProfileId || userContext?.user?.buyerProfileId
		? true
		: false;
const hasNoProfile = (userContext: any) =>
	userContext?.isLoading ||
	userContext?.user?.talentProfileId ||
	userContext?.user?.buyerProfileId
		? false
		: true;

const Router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<App />}>
			<Route
				index
				element={
					<RerouteOn
						shouldRedirect={hasAnyProfile}
						redirectLocation="/dashboard"
						component={<Home />}
					/>
				}
			/>
			<Route
				path="dashboard"
				element={
					<RerouteOn
						shouldRedirect={hasNoProfile}
						redirectLocation="/start"
						component={<ProtectedRoute component={Dashboard} />}
					/>
				}>
				<Route path=":redirect" />
			</Route>
			<Route
				path="start/*"
				element={
					<RerouteOn
						shouldRedirect={hasAnyProfile}
						redirectLocation="/"
						component={<ProtectedRoute component={OnboardingWizard} />}
					/>
				}
			/>
			<Route
				path="user-account"
				element={<ProtectedRoute component={UserAccount} />}
			/>
			<Route
				path="dashboard/connections/:id"
				element={<ProtectedRoute component={Connections} />}
			/>
			<Route
				path="talent-profile/:id"
				element={<ProtectedRoute component={TalentProfile} />}
			/>
			<Route
				path="buyer-profile/:id"
				element={<ProtectedRoute component={BuyerProfile} />}
			/>
			<Route
				path="dedicated-team/:id"
				element={<ProtectedRoute component={DedicatedTeam} />}
			/>
			<Route path="teams" element={<ProtectedRoute component={Teams} />} />
			<Route
				path="team-generator"
				element={<ProtectedRoute component={TeamGenerator} />}
			/>
			<Route
				path="project/:id"
				element={<ProtectedRoute component={Project} />}
			/>
			<Route
				path="projects"
				element={<ProtectedRoute component={Projects} />}
			/>
			<Route path="payments" element={<ProtectedRoute component={Payments} />}>
				<Route path=":redirect" />
			</Route>
			<Route
				path="invoice/:id"
				element={<ProtectedRoute component={Invoice} />}>
				<Route path=":redirect" />
			</Route>
			<Route
				path="contract/:id"
				element={<ProtectedRoute component={Contract} />}
			/>
			<Route
				path="contracts"
				element={<ProtectedRoute component={Contracts} />}
			/>
			<Route
				path="search/:filter"
				element={<ProtectedRoute component={SearchResults} />}
			/>
			<Route
				path="chat/:activeConvoSid?"
				element={<ProtectedRoute component={FullscreenChat} />}
			/>
			<Route path="install-app" element={<InstallApp />} />
			<Route path="admin" element={<ProtectedRoute component={Admin} />} />
			<Route
				path="admin/payment-events"
				element={<ProtectedRoute component={PaymentEvents} />}
			/>
			<Route
				path="unauthorized"
				element={<ProtectedRoute component={Unauthorized} />}
			/>
			<Route path="*" element={<NotFound />} />
		</Route>
	)
);

export { Router };
