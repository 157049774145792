/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InvoiceState {
	DRAFT = 'Draft',
	PENDING = 'Pending',
	DISPUTED = 'Disputed',
	PAID = 'Paid',
	REMOVED = 'Removed',
}
