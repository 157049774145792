/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TeamMemberState {
	DRAFT = 'Draft',
	REMOVED = 'Removed',
	UNREAD = 'Unread',
	STALE = 'Stale',
	READ = 'Read',
	INTERESTED = 'Interested',
	DECLINED = 'Declined',
	ACCEPTED = 'Accepted',
}
