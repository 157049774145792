import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useRef } from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import ResponsiveAppBar from './ResponsiveBar';
import LoadingIndicator from './components/utilities/LoadingIndicator';
import './App.css';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import Chat from './components/Chat/Chat';
import MenuContext from './contexts/DrawerContext';
import { WalkthroughContext } from './contexts/WalkthroughContext';
import { useLocation } from 'react-router-dom';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export default function App() {
	const { getAccessTokenSilently, isAuthenticated } = useAuth0();
	const windowSize = useRef([window.innerWidth, window.innerHeight]);
	const { setIsSideNavOpen } = useContext(MenuContext);
	const { isLoading, getWalkthrough } = useContext(WalkthroughContext);
	const smallWidth = 768;
	const location = useLocation();
	const isFullScreenChatRoute = location.pathname.startsWith('/chat/');

	useEffect(() => {
		// Print access token if we have one
		const gat = async () => {
			console.debug('Access Token:', await getAccessTokenSilently());
		};
		if (isAuthenticated && process.env.NODE_ENV === 'development') {
			gat();
		}
		windowSize.current[0] < smallWidth
			? setIsSideNavOpen(false)
			: setIsSideNavOpen(true);
	}, [getAccessTokenSilently, isAuthenticated, setIsSideNavOpen, windowSize]);

	return (
		<StyledEngineProvider injectFirst>
			<LoadingIndicator />
			<ResponsiveAppBar />
			<Outlet />
			<Footer />
			{!isFullScreenChatRoute && <Chat isChatInFullscreen={false} />}
			{!isLoading && getWalkthrough('gettingStarted')}
		</StyledEngineProvider>
	);
}
