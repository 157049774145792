import React, { useState } from 'react';
import Button from '@mui/material/Button';
import useApi from '../../services/useApi';
import { ConnectionDto, ConnectionState, UserService } from '../../api';
import { ConnectionDirection } from '../TalentProfile/TalentProfile';
import SplitButton from './SplitButton';
import { useAlert } from 'react-alert';
import { Stack } from '@mui/material';

const ConnectButton = (props: {
	userId: string;
	connection?: ConnectionDto | null;
	condensed: boolean;
	onConnectionUpdated?: (userId: string, state: ConnectionState) => void;
}) => {
	const { userId, connection, condensed, onConnectionUpdated } = props;
	const [connectionState, setConnectionState] = useState(connection?.state);
	const [connectionDirection, setConnectionDirection] = useState(
		connection?.initiatorUserId === userId
			? ConnectionDirection.Received
			: ConnectionDirection.Initiated
	);

	const { callApi } = useApi();
	const alert = useAlert();

	const updateConnection = async (connectionState: ConnectionState) => {
		const response = await callApi(
			UserService.upsertConnection(userId, connectionState)
		);
		if (response.data) {
			// If there wasn't already a connection user is an initiator
			setConnectionState(connectionState);
			setConnectionDirection(
				connectionDirection ?? ConnectionDirection.Initiated
			);
			onConnectionUpdated?.(userId, connectionState);
		}
	};

	const connectActions = [
		{
			label: 'Connect',
			handler: () => updateConnection(ConnectionState.PENDING),
		},
	];

	const connectionSentPendingActions = [
		{
			label: 'Connection Pending',
			handler: () => {
				alert.info("Connection is still pending other user's response");
			},
			disabled: true,
		},
		{
			label: 'Withdraw Invite',
			handler: () => updateConnection(ConnectionState.INACTIVE),
			disabled: false,
		},
	];

	const receivedInvitePendingActions = [
		{
			label: 'Accept Connection',
			handler: () => updateConnection(ConnectionState.ACTIVE),
		},
		{
			label: 'Ignore Connection',
			handler: () => updateConnection(ConnectionState.IGNORED),
		},
	];

	const connectedActions = [
		{
			label: 'Connected',
			handler: () => {
				alert.info('You are already connected');
			},
			disabled: true,
		},
		{
			label: 'Remove Connection',
			handler: () => updateConnection(ConnectionState.INACTIVE),
			disabled: false,
		},
	];

	return (
		<React.Fragment>
			{!connectionState || connectionState === ConnectionState.INACTIVE ? (
				<SplitButton options={connectActions} />
			) : null}
			{connectionDirection === ConnectionDirection.Initiated &&
			connectionState === ConnectionState.PENDING ? (
				<SplitButton options={connectionSentPendingActions} />
			) : null}
			{connectionDirection === ConnectionDirection.Received &&
			connectionState === ConnectionState.PENDING ? (
				condensed ? (
					<SplitButton options={receivedInvitePendingActions} />
				) : (
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						alignItems="center"
						spacing={1}>
						{receivedInvitePendingActions.map((buttonAction) => (
							<Button variant="contained" onClick={buttonAction.handler}>
								{typeof buttonAction.label === 'string'
									? buttonAction.label.split(' ')[0]
									: buttonAction.label}
							</Button>
						))}
					</Stack>
				)
			) : null}
			{connectionState === ConnectionState.ACTIVE ? (
				<SplitButton options={connectedActions} />
			) : null}
		</React.Fragment>
	);
};

export default ConnectButton;
