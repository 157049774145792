/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractDto } from '../models/ContractDto';
import type { ContractSummaryDto } from '../models/ContractSummaryDto';
import type { UpdateContractStateCommand } from '../models/UpdateContractStateCommand';
import type { UpsertContractCommand } from '../models/UpsertContractCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContractService {

	/**
	 * Upserts a contract
	 * @param requestBody
	 * @returns string Success
	 * @throws ApiError
	 */
	public static upsertContract(
		requestBody: UpsertContractCommand,
	): CancelablePromise<string> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/Contract/Contract',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Updates a contract's state
	 * @param requestBody
	 * @returns string Success
	 * @throws ApiError
	 */
	public static updateContractState(
		requestBody: UpdateContractStateCommand,
	): CancelablePromise<string> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/Contract/ContractState',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Retrieves contract by Id
	 * Returns one contract matching the given Id.
	 * @param id
	 * @returns ContractDto Success
	 * @throws ApiError
	 */
	public static getContract(
		id: string,
	): CancelablePromise<ContractDto> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Contract/Contract/{id}',
			path: {
				'id': id,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Retrieves contracts by talent membership or project ownership
	 * @returns ContractSummaryDto Success
	 * @throws ApiError
	 */
	public static getUserContracts(): CancelablePromise<Array<ContractSummaryDto>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Contract/UserContracts',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

}
