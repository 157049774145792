import React, { ReactNode } from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { Router } from '../Router';

const Auth0ProviderWithHistory = ({ children }: { children: ReactNode }) => {
	const domain = process.env.REACT_APP_AUTH0_DOMAIN;
	const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
	const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

	if (!(domain && clientId && audience)) {
		throw new Error(
			'Missing Auth0 Configuration - either REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, or REACT_APP_AUTH0_AUDIENCE'
		);
	}

	const onRedirectCallback = (appState: AppState | undefined) => {
		const { returnTo, ...restOfState } = appState ?? { returnTo: undefined };
		const referrerParams = restOfState?.['referrerId']
			? `?referrerId=${restOfState['referrerId']}`
			: undefined;
		Router.navigate(
			{
				pathname: appState?.returnTo?.split('?')[0] || window.location.pathname,
				search: referrerParams,
			},
			{ state: restOfState }
		);
	};

	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			audience={audience}
			redirectUri={window.location.origin}
			onRedirectCallback={onRedirectCallback}>
			{children}
		</Auth0Provider>
	);
};

export default Auth0ProviderWithHistory;
