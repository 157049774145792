import React, { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	List,
	ListItem,
	Paper,
} from '@mui/material';
import TeamSearchResult from './TeamSearchResult';
import { UserContext } from '../../contexts/UserContext';
import ProjectSearchResult from './ProjectSearchResult';
import {
	DedicatedTeamDto,
	ProjectDto,
	ProjectService,
	SearchType,
	TeamService,
	UserService,
	UserSummaryDto,
} from '../../api';
import useApi, { ApiResponse } from '../../services/useApi';
import UserSideNav from '../utilities/UserSideNav';
import SearchBar from './SearchBar';
import UserSearchResult from './UserSearchResult';
import TextButton from '../utilities/TextButton';
import { TeamSummaryDto } from '../../api';

type PaginationDataLookup = {
	[K in SearchType]: {
		pageNumber: number;
		pageSize: number;
		hasMore: boolean;
	};
};

type SearchResultsHandlerHelperType = <
	T extends keyof Omit<
		PaginationDataLookup,
		SearchType.ALL | SearchType.TALENT | SearchType.BUYER
	>,
	ResultT extends UserSummaryDto[] | ProjectDto[] | DedicatedTeamDto[]
>(
	paginationDataSetter: (
		value: React.SetStateAction<
			Omit<
				PaginationDataLookup,
				SearchType.ALL | SearchType.TALENT | SearchType.BUYER
			>
		>
	) => void,
	resultsSetter: (value: React.SetStateAction<ResultT>) => void,
	type: T,
	results: ResultT,
	paginationData: {
		pageNumber: number;
		pageSize: number;
		hasMore: boolean;
	}
) => void;

const SearchResults = () => {
	const { filter } = useParams();
	const [searchParams] = useSearchParams();
	const [teamResults, setTeamResults] = useState<DedicatedTeamDto[]>([]);
	const [projectResults, setProjectResults] = useState<ProjectDto[]>([]);
	const [userResults, setUserResults] = useState<UserSummaryDto[]>([]);
	const query = (searchParams.get('query') ?? '')!.toLowerCase();
	const { user, isLoading: isUserLoading } = useContext(UserContext);
	const { callApi } = useApi();
	const [teamMemberships, setTeamMemberships] = useState(
		[] as TeamSummaryDto[]
	);

	const [resultPaginationData, setResultPaginationData] = useState<
		Omit<
			PaginationDataLookup,
			SearchType.ALL | SearchType.BUYER | SearchType.TALENT
		>
	>({
		[SearchType.PROJECT]: { pageNumber: 1, pageSize: 8, hasMore: true },
		[SearchType.TEAM]: { pageNumber: 1, pageSize: 8, hasMore: true },
		[SearchType.USER]: { pageNumber: 1, pageSize: 8, hasMore: true },
	});

	const [areTeamSearchResultsLoading, setAreTeamSearchResultsLoading] =
		useState(false);
	const [areProjectSearchResultsLoading, setAreProjectSearchResultsLoading] =
		useState(false);
	const [areUserSearchResultsLoading, setAreUserSearchResultsLoading] =
		useState(false);

	useEffect(() => {
		const getTeamMemberships = async () => {
			const response = await callApi(TeamService.getUserDedicatedTeams());
			setTeamMemberships(response.data ?? []);
		};
		if (!isUserLoading && user?.talentProfileId) {
			getTeamMemberships();
		}
	}, [isUserLoading, callApi, user?.talentProfileId]);

	useEffect(() => {
		// Helper function to handle pagination and results processing
		const handleResults: SearchResultsHandlerHelperType = (
			paginationDataSetter,
			resultsSetter,
			type,
			results,
			paginationData
		) => {
			const hasMore = (results?.length ?? 0) >= paginationData.pageSize;
			if (!hasMore) {
				paginationDataSetter((currentPaginationData) => ({
					...currentPaginationData,
					[type]: {
						...currentPaginationData[type],
						hasMore,
					},
				}));
			}
			resultsSetter(
				(currentResults) =>
					[...currentResults, ...(results ?? [])].filter(
						(result, i, arr) => arr.findIndex((r) => r.id === result.id) === i
					) as any
			);
		};

		const getIndividuals = async (
			searchType: keyof Omit<PaginationDataLookup, SearchType.ALL>
		) => {
			// We use the User search type to store buyer or talent results as well
			const paginationData = resultPaginationData[SearchType.USER];
			if (paginationData.hasMore === false) return;
			setAreUserSearchResultsLoading(true);
			const response: ApiResponse<UserSummaryDto[]> = await callApi(
				UserService.searchUsers(
					query,
					searchType,
					searchParams.getAll('skills'),
					searchParams.get('postalCode') ?? undefined,
					paginationData.pageNumber,
					paginationData.pageSize
				)
			);
			const searchResults = response.data;
			if (searchResults) {
				handleResults(
					setResultPaginationData,
					setUserResults,
					SearchType.USER,
					searchResults,
					paginationData
				);
			}
			setAreUserSearchResultsLoading(false);
		};
		const getTeams = async () => {
			const paginationData = resultPaginationData[SearchType.TEAM];
			if (paginationData.hasMore === false) return;
			setAreTeamSearchResultsLoading(true);
			const response = await callApi(
				TeamService.searchDedicatedTeam(
					query,
					searchParams.getAll('skills'),
					paginationData.pageNumber,
					paginationData.pageSize
				)
			);
			const searchResults = response.data;
			if (searchResults) {
				handleResults(
					setResultPaginationData,
					setTeamResults,
					SearchType.TEAM,
					searchResults,
					paginationData
				);
			}
			setAreTeamSearchResultsLoading(false);
		};
		const getProjects = async () => {
			const paginationData = resultPaginationData[SearchType.PROJECT];
			if (paginationData.hasMore === false) return;
			setAreProjectSearchResultsLoading(true);
			const response = await callApi(
				ProjectService.searchProject(
					query,
					searchParams.getAll('skills'),
					searchParams.get('postalCode') ?? undefined,
					paginationData.pageNumber,
					paginationData.pageSize
				)
			);
			const searchResults = response.data;
			if (searchResults) {
				handleResults(
					setResultPaginationData,
					setProjectResults,
					SearchType.PROJECT,
					searchResults,
					paginationData
				);
			}
			setAreProjectSearchResultsLoading(false);
		};
		if (!isUserLoading && user) {
			if (filter === SearchType.ALL) {
				getIndividuals(SearchType.USER);
				getTeams();
				getProjects();
			} else if (
				filter === SearchType.TALENT ||
				filter === SearchType.USER ||
				filter === SearchType.BUYER
			) {
				getIndividuals(filter);
			} else if (filter === SearchType.TEAM) {
				getTeams();
			} else if (filter === SearchType.PROJECT) {
				getProjects();
			} else {
				console.error('Unhandled SearchType:', filter);
			}
		}
	}, [
		isUserLoading,
		query,
		user,
		callApi,
		filter,
		searchParams,
		resultPaginationData,
	]);

	interface ResultType {
		associatedSearchTypes: Omit<
			SearchType,
			SearchType.ALL | SearchType.BUYER | SearchType.TALENT
		>[];
		title: string;
		component: Function;
		results: any[];
		key: (item: any) => string;
	}

	const resultTypes: ResultType[] = [
		{
			associatedSearchTypes: [SearchType.TEAM],
			title: 'Teams',
			component: TeamSearchResult,
			results: teamResults,
			key: (item: DedicatedTeamDto) => item.id,
		},
		{
			associatedSearchTypes: [SearchType.PROJECT],
			title: 'Projects',
			component: ProjectSearchResult,
			results: projectResults,
			key: (item: ProjectDto) => item.id,
		},
		{
			associatedSearchTypes: [
				SearchType.USER,
				SearchType.BUYER,
				SearchType.TALENT,
			],
			title: 'Users',
			component: UserSearchResult,
			results: userResults,
			key: (item: UserSummaryDto) => item.id,
		},
	];

	const buildRecords = (resultType: ResultType) => {
		const ComponentName = resultType.component;
		const associatedSearchTypes = resultType.associatedSearchTypes;
		if (resultType.results.length === 0) {
			return <ListItem>No results found</ListItem>;
		}

		const isUserQuery = associatedSearchTypes.some(
			(searchType) =>
				searchType === SearchType.TALENT ||
				searchType === SearchType.BUYER ||
				searchType === SearchType.USER
		);
		const currentUserTeamMemberships = isUserQuery
			? teamMemberships
			: undefined;
		const resultPaginationIndex = isUserQuery
			? SearchType.USER
			: (associatedSearchTypes[0] as keyof Omit<
					PaginationDataLookup,
					SearchType.ALL | SearchType.BUYER | SearchType.TALENT
			  >);

		const showLoadMoreButton =
			resultPaginationData[resultPaginationIndex].hasMore;

		return (
			<React.Fragment>
				{resultType.results.map((result) => (
					<ListItem
						key={`${resultType.key(result)}`}
						className="flex justify-between p-0 md:p-3 mb-6">
						<ComponentName
							result={result}
							currentUserTeamMemberships={currentUserTeamMemberships}
						/>
					</ListItem>
				))}
				{(associatedSearchTypes.includes(SearchType.TEAM) &&
					areTeamSearchResultsLoading) ||
				(associatedSearchTypes.includes(SearchType.PROJECT) &&
					areProjectSearchResultsLoading) ||
				(associatedSearchTypes.includes(SearchType.USER) &&
					areUserSearchResultsLoading) ? (
					<ListItem className="justify-center">Loading . . . </ListItem>
				) : showLoadMoreButton ? (
					<ListItem
						key="next-page"
						className="flex justify-center p-0 md:p-3 mb-6">
						<TextButton
							onClick={() => {
								setResultPaginationData((currentPaginationState) => {
									const searchTypePaginationState =
										currentPaginationState[resultPaginationIndex];
									const newPageNumber =
										searchTypePaginationState.pageNumber + 1;
									const newSearchTypePaginationState = {
										...searchTypePaginationState,
										pageNumber: newPageNumber,
									};
									return {
										...currentPaginationState,
										[resultPaginationIndex]: newSearchTypePaginationState,
									};
								});
							}}>
							Load More
						</TextButton>
					</ListItem>
				) : (
					<ListItem
						key="next-page"
						className="flex justify-center p-0 md:p-3 mb-6">
						You've reached the end of matching results
					</ListItem>
				)}
			</React.Fragment>
		);
	};

	const buildSummaries = () =>
		resultTypes
			.filter(
				(resultType) =>
					!(
						filter !== SearchType.ALL &&
						!resultType.associatedSearchTypes.includes(filter as SearchType)
					)
			)
			.map((resultType) => (
				<Paper key={resultType.title} elevation={3}>
					<Card>
						<CardHeader title={resultType.title} />
						<CardContent>
							<List>{buildRecords(resultType)}</List>
						</CardContent>
					</Card>
					<Divider />
				</Paper>
			));

	return (
		<UserSideNav className="flex flex-col flex-auto w-full items-center">
			<div className="w-full md:w-1/2">
				<SearchBar advanced={true} />
			</div>
			<div className="w-full 2xl:w-3/4">{buildSummaries()}</div>
		</UserSideNav>
	);
};

export default SearchResults;
