import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import ThemeContext from '../../contexts/ThemeContext';
// import { useEditable } from 'use-editable'; // TODO: when https://github.com/FormidableLabs/use-editable/issues/16 is resolved switch back to using the package
import { useEditable } from '../../services/useEditable';
import Resizer from 'react-image-file-resizer';
import { useDropzone } from 'react-dropzone';
import { AddButton, CloseButton, HelperInfo } from './IconButtons';
import { useForm } from 'react-hook-form';
import TextButton from './TextButton';
import { FaSearch } from 'react-icons/fa';
import { ApiResponse, useApi } from '../../services/useApi';
import { v4 as uuidv4 } from 'uuid';

import { StorageService, StoredFileCategory, TagService } from '../../api';
import { useDebounce } from 'usehooks-ts';
import { VALID_IMAGE_TYPE_STRINGS } from '../../services/helper';
import useProfanityFilter from '../../services/useProfanityFilter';

/**
 * WARNING: If using a custom prefix or suffix size you must ensure it is on these lists for tailwind to be able to include your desired prefix/suffix sizes in the css output
 * Accepted padding Prefix sizes: pl-5 pl-7 pl-8 pl-9
 * Accepted absolute left Prefix sizes: peer-placeholder-shown:left-5 peer-placeholder-shown:left-7 peer-placeholder-shown:left-8 peer-placeholder-shown:left-9
 * Accepted padding Suffix sizes: pr-9 pr-12
 */
const Input = React.forwardRef((props: any, ref: any) => {
	const {
		prefix,
		prefixSize = '5',
		suffix,
		suffixSize = '9',
		placeholder,
		autoComplete = 'off',
		className,
		label,
		disabled,
		errors = {},
		...rest
	} = props;
	return (
		<div
			className={
				'relative z-0 mb-5 w-full group text-left ' + (className ?? '')
			}>
			<input
				ref={ref}
				placeholder={placeholder || ' '}
				disabled={disabled}
				autoComplete={autoComplete}
				className={
					(prefix ? `pl-${prefixSize}` : '') +
					(suffix ? ` pr-${suffixSize}` : '') +
					(label ? ' placeholder:invisible' : '') +
					' focus:placeholder:visible block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-nKipo focus:outline-none focus:ring-0 focus:border-nKipo peer'
				}
				{...rest}
			/>
			{prefix ? (
				<div className="absolute top-0 left-0 mt-2 ml-1 text-gray-400 peer-focus:text-nKipo">
					{prefix}
				</div>
			) : null}
			{suffix ? (
				<div className="absolute top-0 right-0 mt-2 mr-2 text-gray-400 peer-focus:text-nKipo">
					{suffix}
				</div>
			) : null}
			<label
				className={
					(prefix ? `peer-placeholder-shown:left-${prefixSize}` : '') +
					' absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-nKipo peer-focus:dark:text-nKipo peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5'
				}>
				{label}
			</label>
			{!disabled && errors[props.name] && (
				<p className="text-[12px] text-sm text-red-600">
					{errors[props.name].message}
				</p>
			)}
		</div>
	);
});

const DateInput = (props: any) => {
	const [inputType, setInputType] = React.useState('text');
	useEffect(() => {
		setInputType(props.value ? 'date' : 'text');
		// We explicitly do not want to run this effect on every render, so we use the [] syntax. It is only intended for setting the type on first render depending on whether a value is present or not.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const {
		name,
		value,
		label,
		placeholder,
		register,
		autoComplete = 'off',
		className,
		...rest
	} = props;
	return (
		<div
			className={
				'relative z-0 mb-5 w-full group text-left ' + (className ?? '')
			}>
			<input
				type={inputType}
				{...register(name)}
				onFocus={() => setInputType('date')}
				onBlur={() => setInputType(value ? 'date' : 'text')}
				placeholder={placeholder || ' '}
				autoComplete={autoComplete}
				className="placeholder:invisible focus:placeholder:visible block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-nKipo focus:outline-none focus:ring-0 focus:border-nKipo peer"
				{...rest}
			/>
			<label
				htmlFor={name}
				className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-nKipo peer-focus:dark:text-nKipo peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5">
				{label}
			</label>
		</div>
	);
};

const TextArea = React.forwardRef((props: any, ref: any) => {
	const { name, label, placeholder, className, ...rest } = props;
	return (
		<div
			className={
				'relative z-0 mb-5 w-full group text-left ' + (className ?? '')
			}>
			<textarea
				ref={ref}
				name={name}
				placeholder={props.placeholder || ' '}
				className="placeholder:invisible focus:placeholder:visible block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-nKipo focus:outline-none focus:ring-0 focus:border-nKipo peer"
				{...rest}
			/>
			<label
				htmlFor={name}
				className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-3 peer-placeholder-shown:-z-10 origin-[0] peer-focus:left-0 peer-focus:text-nKipo peer-focus:dark:text-nKipo bg-white dark:bg-gray-900 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5">
				{label}
			</label>
		</div>
	);
});

const defaultIdMapper = (o: any) => o.id ?? o;
const defaultNameMapper = (o: any) => o.name ?? o;
const Select = React.forwardRef((props: any, ref: any) => {
	// I tried my best to get the css for the `float-when-unchosen` class working with just the utility classes tailwind provides but couldn't get it to work. Feel free to eliminate that class if you can.
	const {
		name,
		defaultValue,
		label,
		placeholder,
		className,
		onChange,
		options,
		idMapper = defaultIdMapper,
		nameMapper = defaultNameMapper,
		...rest
	} = props;
	return (
		<div
			className={
				'relative z-0 mb-5 w-full group text-left ' + (className ?? '')
			}>
			<select
				ref={ref}
				name={name}
				defaultValue={defaultValue ?? ''}
				onChange={(event, ...remainingParams) => {
					event.target.dataset.chosen = event.target.value;
					onChange(event, ...remainingParams);
				}}
				data-chosen={defaultValue ?? ''}
				className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-nKipo focus:outline-none focus:ring-0 focus:border-nKipo peer"
				{...rest}>
				<option value="" disabled hidden>
					{placeholder}
				</option>
				{options.map((option: any) => {
					return (
						<option
							key={idMapper(option)}
							value={idMapper(option)}
							className="text-gray-900 bg-white dark:text-white dark:bg-gray-900">
							{nameMapper(option)}
						</option>
					);
				})}
			</select>
			<label
				htmlFor={name}
				className="float-when-unchosen absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-nKipo peer-focus:dark:text-nKipo peer-focus:scale-75 peer-focus:-translate-y-5">
				{label}
			</label>
		</div>
	);
});

const Checkbox = React.forwardRef(
	({ className, label, isSet, ...rest }: any, ref: any) => {
		const { theme, colorGetter } = useContext(ThemeContext);
		const radiantColor =
			theme === 'dark' ? colorGetter('gray.900') : colorGetter('white');
		return (
			<div className={'inline-flex ml-4 first:ml-0 mb-5 ' + (className ?? '')}>
				<label className="self-center text-sm text-gray-900 dark:text-white">
					<input
						ref={ref}
						type="checkbox"
						className="appearance-none h-3 w-3 border border-gray-500 dark:border-gray-400 border-[1.5px] rounded-sm bg-white checked:bg-darkBlue checked:border-darkBlue dark:checked:bg-lightBlue dark:checked:border-lightBlue focus:checked:bg-nKipo focus:border-nKipo dark:focus:checked:bg-nKipo dark:focus:border-nKipo focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
						style={
							isSet
								? {
										backgroundImage: `radial-gradient(circle farthest-corner, ${radiantColor} 30%, transparent 100%)`,
								  }
								: undefined
						}
						{...rest}
					/>
					{label}
				</label>
			</div>
		);
	}
);

const RadioFieldSet = React.forwardRef((props: any, ref: any) => {
	const {
		placeholder,
		name,
		className,
		label,
		value,
		isEditing,
		disabled,
		errors,
		options,
		...rest
	} = props;
	const { theme, colorGetter } = useContext(ThemeContext);
	const radiantColor =
		theme === 'dark' ? colorGetter('gray.900') : colorGetter('white');

	return (
		<fieldset
			disabled={disabled}
			className={'relative z-0 w-full p-px ' + (className ?? '')}>
			<legend className="text-gray-500 text-sm dark:text-gray-400">
				{label}
			</legend>
			{isEditing ? (
				<div className="block pt-2 ml-5 flex flex-col flex-wrap">
					{options.map(({ value, label }: any) => {
						return (
							<div
								key={value}
								className="relative z-0 mb-2 first:ml-0 text-left flex">
								<label className="self-center text-sm text-gray-900 dark:text-white">
									<input
										ref={ref}
										key={value}
										id={value}
										name={name}
										value={value}
										type="radio"
										className="appearance-none rounded-full h-3 w-3 border border-gray-500 dark:border-gray-400 bg-white checked:bg-darkBlue checked:border-darkBlue dark:checked:bg-lightBlue dark:checked:border-lightBlue focus:checked:bg-nKipo focus:border-nKipo dark:focus:checked:bg-nKipo dark:focus:border-nKipo focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
										style={
											value === props
												? {
														backgroundImage: `radial-gradient(circle, ${radiantColor} 15%, transparent 85%)`,
												  }
												: undefined
										}
										{...rest}
									/>
									{label}
								</label>
							</div>
						);
					})}
				</div>
			) : (
				<div className="relative z-0 mb-5 w-full text-left flex flex-col">
					<label className="pl-5 text-sm text-gray-900 dark:text-white">
						<input
							type="radio"
							className="appearance-none rounded-full h-3 w-3 border border-gray-500 dark:border-gray-400 bg-white bg-darkBlue border-darkBlue dark:bg-lightBlue dark:border-lightBlue transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2"
						/>
						{value}
					</label>
				</div>
			)}

			<span className="text-sm text-red-600 hidden" id="error">
				Option has to be selected
			</span>
		</fieldset>
	);
});

/**
 * A component that uses a dropzone to allow the user to select a file, immediately uploads it and renders the image as soon as it is selected.
 * If an image has already been uploaded and a url to that image is passed in, the image will be rendered.
 */
const PhotoUpload = React.forwardRef((props: any, ref: any) => {
	const {
		name,
		label,
		editing,
		value,
		url,
		defaultImageUrl,
		onChange,
		category,
		helperText,
		className,
		styling,
		imageStyle,
		resetfield,
		maxWidth,
		maxHeight,
		minWidth,
		minHeight,
		errors,
	} = props;

	const [presignedUrl, setpresignedUrl] = useState(url);
	const [dataUrl, setDataUrl] = useState('');
	const [errorType, setErrorType] = useState('');
	const [invalidImage, setInvalidImage] = useState(false);
	const [imageSrcError, setImageSrcError] = useState(false);
	const { callApi } = useApi();
	const [files, setFiles] = useState([]);

	useEffect(() => {
		if (url) {
			setpresignedUrl(url);
		}
	}, [url]);

	const clearValues = useCallback(() => {
		resetfield(name);
		setDataUrl('');
		setpresignedUrl('');
		setFiles([]);
	}, [resetfield, name]);

	const fileToDataUrl = (image: File) => {
		let dataUrl = '';
		const promise = new Promise((resolve) => {
			const reader = new FileReader();
			reader.onload = function () {
				resolve(reader.result);
			};
			reader.readAsDataURL(image);
		});
		promise.then((image: any) => {
			dataUrl = image;
			setDataUrl(dataUrl);
		});
	};

	const uploadImageUrl = useCallback(
		async (image: File) => {
			const response = await callApi(
				StorageService.createFile({
					Id: uuidv4(),
					File: image,
					Category: category as StoredFileCategory,
				})
			);

			if (response.data) {
				onChange({ target: { name, value: response.data.id } }, response.data);
				setpresignedUrl(response.data.presignedUrl);
			} else {
				clearValues();
			}
		},
		[callApi, clearValues, name, category, onChange]
	);

	const imageHandler = (image: any) => {
		if (image) {
			try {
				Resizer.imageFileResizer(
					image,
					maxWidth ?? 300, //maxWidth
					maxHeight ?? 300, //maxHeight
					// Safari and IE don't fully support WEBP
					'PNG', //compressFormat
					100, // quality (0-100)
					0, // rotation
					(uri) => {
						fileToDataUrl(uri as File);
						uploadImageUrl(uri as File);
					},
					'file', // output type,
					minWidth, // minWidth
					minHeight // minHeight
				);
			} catch (err) {
				console.log(err);
			}
		}
	};

	const validationWarning = () => {
		setInvalidImage(true);
		setTimeout(function () {
			setInvalidImage(false);
		}, 1000);
	};

	const validate = (event: any) => {
		const type = event.target.files[0]?.type;
		if (VALID_IMAGE_TYPE_STRINGS[type]) {
			setErrorType('');
			setInvalidImage(false);
			imageHandler(event.target.files[0]);
		} else {
			setErrorType('type');
			validationWarning();
		}
	};

	const { getRootProps, getInputProps } = useDropzone({
		noClick: true,
		noKeyboard: true,
		preventDropOnDocument: true,
		multiple: false,
		onDropAccepted: (acceptedFiles: any) => {
			setErrorType('');
			setInvalidImage(false);
			imageHandler(acceptedFiles[0]);
		},
		onDropRejected: (event: any) => {
			if (event[0].errors[0].code.includes('type')) {
				setErrorType('type');
			}
			validationWarning();
		},
		onError: (err: any) => {
			console.log(err);
		},
		accept: VALID_IMAGE_TYPE_STRINGS,
	});

	return (
		<div
			className={
				(!editing && !presignedUrl && !defaultImageUrl ? 'hidden ' : '') +
				'relative z-0 mb-5 group text-left hover:border-nKipo dark:hover:border-nKipo ' +
				(className ?? '') +
				(invalidImage ? ' animate-[alert_1s_ease-in-out_0s]' : '')
			}>
			<label
				className={`absolute text-sm text-gray-500 dark:text-gray-400 transform scale-75 -top-3 -left-3 origin-0 bg-white dark:bg-gray-900 pr-1 ${
					editing ? 'group-hover:text-nKipo' : ''
				}`}>
				{label}
			</label>
			<div
				{...(editing ? getRootProps() : null)}
				className="dropzone flex items-center justify-center w-full h-full">
				<label
					className={
						(styling ?? '') +
						' flex flex-col place-content-center place-items-center bg-transparent w-full ' +
						(!(value && (presignedUrl || dataUrl)) && editing
							? ' border-2 border-dashed border-gray-500 dark:border-gray-400 group-hover:border-nKipo'
							: '')
					}>
					{(value && (presignedUrl || dataUrl)) ||
					(defaultImageUrl && !editing) ? (
						<img
							className={'w-full h-full ' + (imageStyle ?? '')}
							alt=""
							src={`${
								presignedUrl
									? presignedUrl
									: dataUrl
									? dataUrl
									: defaultImageUrl
							}`}
							onError={(e) => {
								// To avoid infinite loop
								if (imageSrcError) return;
								setImageSrcError(true);
								e.currentTarget.onerror = null;
								e.currentTarget.src = defaultImageUrl;
							}}
						/>
					) : (
						<div className="flex flex-col place-content-center place-items-center">
							<svg
								className={
									'w-10 h-10 text-gray-500 dark:text-gray-400 ' +
									(editing ? 'group-hover:text-nKipo' : '')
								}
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
							</svg>
							<p
								className={
									'lowercase text-center text-sm text-gray-500 dark:text-gray-400 pt-1 tracking-wider ' +
									(editing ? 'group-hover:text-nKipo' : '')
								}>
								{helperText}
							</p>
						</div>
					)}
					<input
						{...(editing ? getInputProps() : null)}
						type="file"
						value={files}
						className="hidden"
						disabled={!editing}
						onChange={(e) => validate(e)}
					/>
				</label>
			</div>
			{editing && value && (
				<CloseButton
					onClick={clearValues}
					className="absolute -top-2 -right-2"
				/>
			)}
			{errorType === 'type' && (
				<p className="text-center text-[12px] text-sm text-red-600">
					jpeg, png, webp only
				</p>
			)}
			{errors[name] && !value && (
				<p className="text-center text-[12px] text-sm text-red-600">
					{errors[name].message}
				</p>
			)}
		</div>
	);
});

const EditableInlineContent = React.forwardRef((props: any, ref: any) => {
	const editorRef = useRef(ref);
	const { name, onChange, ...rest } = props;
	const onEditableChange = useCallback(
		(newValue) => {
			editorRef.current.name = name;
			editorRef.current.value = newValue;
			onChange({ target: editorRef.current });
		},
		[name, onChange, editorRef]
	);

	useEditable(editorRef, onEditableChange, {
		disabled: props.disabled,
	});

	return <div ref={editorRef} {...rest}></div>;
});

const LabeledEditableContent = React.forwardRef((props: any, ref: any) => {
	const { label, value, className, errors, disabled, ...rest } = props;
	const isPopulated = (value ?? '').trim() !== '';
	return (
		<div
			className={
				'relative z-0 mb-5 w-full group text-left ' + (className ?? '')
			}>
			<EditableInlineContent
				ref={ref}
				data-populated={isPopulated ? 1 : 0}
				disabled={disabled}
				{...rest}
				className={
					(disabled && (isPopulated ? 0 : 1) ? 'py-5' : 'py-2.5') +
					' block px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-nKipo focus:outline-none focus:ring-0 focus:border-nKipo peer'
				}></EditableInlineContent>
			<label className="float-when-populated absolute text-sm text-gray-500 dark:text-gray-400 duration-300 top-3 -z-10 origin-[0] peer-focus:text-nKipo peer-focus:dark:text-nKipo peer-focus:scale-75 peer-focus:-translate-y-5">
				{label}
			</label>
			{!disabled && errors[props.name] && (
				<p className="text-[12px] text-sm text-red-600">
					{errors[props.name].message}
				</p>
			)}
		</div>
	);
});

const defaultValueMapper = (v: any | null) => v?.id;
const Lookup = React.forwardRef((props: any, ref: any) => {
	const {
		name,
		register,
		onInput,
		onChange,
		onBlur,
		options,
		createNew,
		onNoMatches,
		valueMapper = defaultValueMapper,
		OptionElement,
		optionMapper,
		resultsContainerStyling,
		resultsOptionStyling,
		...rest
	} = props;
	const [showResults, setShowResults] = useState(false);
	const [resultsReceived, setResultsReceived] = useState(false);
	const internalForm = useForm();
	const internalFormName = `lookup-${name}`;
	const { ref: internalFormRef, ...internalFormRest } =
		internalForm.register(internalFormName);
	const watchInternalForm = internalForm.watch(internalFormName);

	const onInputHandler = async (e: any) => {
		setResultsReceived(false);
		if (e.target.value) {
			setShowResults(true);
		} else {
			setShowResults(false);
		}
		internalForm.setValue(internalFormName, e.target.value);
		await onInput(e);
		setResultsReceived(true);
	};

	const onLookupChange = (e: any, option: any) => {
		internalForm.setValue(internalFormName, optionMapper(option));
		onChange(e, option);
		setShowResults(false);
	};

	return (
		<section
			onFocus={(e: any) => setShowResults(true)}
			// Only hide results if we are blurring away from the container and all of it's children
			onBlur={(e) => {
				if (!e.currentTarget.contains(e.relatedTarget)) {
					// In the case that we are currently showing results and there is only one match or a perfect match then we select that option automatically
					if (showResults && options) {
						const perfectMatch = options.find(
							(o: any) => watchInternalForm === optionMapper(o)
						);
						if (options.length === 0) {
							onLookupChange(e, null);
						} else if (options.length === 1) {
							onLookupChange(e, options[0]);
						} else if (perfectMatch) {
							onLookupChange(e, perfectMatch);
						}
					} else if ((watchInternalForm ?? '') === '') {
						onLookupChange(e, null);
					}
					setShowResults(false);
					onBlur?.(e);
				}
			}}
			className="relative w-full">
			<div className="relative">
				<Input
					ref={(e) => {
						internalFormRef(e);
						if (ref) {
							ref.current = e;
						}
					}}
					{...internalFormRest}
					onChange={onInputHandler}
					prefixSize="7"
					prefix={<FaSearch className="mt-1 w-4 h-4 text-gray-400" />}
					{...rest}
				/>
			</div>
			{options?.length ? (
				<div
					/* 
						Safari doesn't focus buttons causing the blur logic above to run and prevent the on-click action since it fires before
						Adding a focusable div so the logic still works in Safari
						https://bugs.webkit.org/show_bug.cgi?id=22261
					*/
					tabIndex={-1}
					className={`${
						showResults ? '' : 'hidden'
					} absolute z-10 inset-x-0 px-3 py-1 ml-6 -mt-5 overflow-y-auto bg-white border rounded-md max-h-72 dark:bg-gray-800 dark:border-transparent ${
						resultsContainerStyling ?? ''
					}`}>
					{options
						.map((option: any) => (
							<TextButton
								key={valueMapper(option)}
								onClick={(e) => onLookupChange(e, option)}
								className="mx-0 px-0 block py-1">
								{OptionElement ? (
									<OptionElement option={option} />
								) : (
									<p
										className={`text-sm text-left text-gray-500 dark:text-gray-400 hover:underline ${
											resultsOptionStyling ?? ''
										}`}>
										{optionMapper(option)}
									</p>
								)}
							</TextButton>
						))
						.concat(
							createNew
								? [createNew(watchInternalForm, { key: 'createNew', ...props })]
								: []
						)}
				</div>
			) : !resultsReceived ? null : (
				<div
					tabIndex={-1}
					className={`${
						showResults ? '' : 'hidden'
					} absolute z-10 inset-x-0 px-3 py-1 ml-6 -mt-5 overflow-y-auto bg-white border rounded-md max-h-72 dark:bg-gray-800 dark:border-transparent ${
						resultsContainerStyling ?? ''
					}`}>
					{onNoMatches ? (
						onNoMatches(watchInternalForm, props)
					) : (
						<p
							className={`text-sm text-left text-gray-500 dark:text-gray-400 ${
								resultsOptionStyling ?? ''
							}`}>
							No matches
						</p>
					)}
					{createNew ? createNew(watchInternalForm, props) : null}
				</div>
			)}
		</section>
	);
});

const defaultQueryCreator = (query: any) => query;
const defaultOptionMapper = (option: any): string | null => option?.name;
const Tags = (props: any) => {
	const {
		name,
		label,
		tagType,
		className,
		values,
		isEditing,
		canCreate = true,
		createService = TagService.createBasicTag,
		searchService = TagService.searchBasicTags,
		newTagCreator,
		queryCreator = defaultQueryCreator,
		onChange,
		onRemove,
		valueMapper = defaultValueMapper,
		optionMapper = defaultOptionMapper,
		lookupClassName,
		helperInfo,
		...rest
	} = props;

	const { callApi } = useApi();
	const { filterProfanity } = useProfanityFilter();
	const internalFormName = `tags-lookup-${name}`;
	const [showAdd, setShowAdd] = useState(false);
	const [tagOptions, setTagOptions] = useState([] as any[]);
	const inputRef = useRef<HTMLInputElement>();
	const defaultNewTagCreator = useCallback(
		async (_: any, name: string) => {
			await filterProfanity(name);
			return {
				id: uuidv4(),
				type: tagType,
				name,
			};
		},
		[filterProfanity, tagType]
	);
	const [query, setValue] = useState<string | null>(null);
	const debouncedQuery = useDebounce<string | null>(query, 600);

	const onChangeWrapper = useCallback(
		(e: any, option: any) => {
			const valuesSet = new Set(values?.map(valueMapper));
			if (!valuesSet.has(valueMapper(option))) {
				onChange(e, option);
				setShowAdd(false);
			}
		},
		[onChange, valueMapper, values]
	);

	const createNewTag = useCallback(
		async (event: any, name: string) => {
			const response = await callApi(
				createService(
					await Promise.resolve(
						(newTagCreator ?? defaultNewTagCreator)(event, name)
					)
				)
			);
			console.log(response);
			if (response.data) {
				onChangeWrapper(event, response.data);
			}
		},
		[
			callApi,
			createService,
			defaultNewTagCreator,
			newTagCreator,
			onChangeWrapper,
		]
	);

	const createNewRenderer = (
		noMatchesTag: string,
		additionalLookupProps: any
	) => {
		return (
			<TextButton
				key="createNewTag"
				onClick={(e: any) => createNewTag(e, noMatchesTag)}
				className="block py-1">
				<p
					className={`text-sm text-left text-gray-500 dark:text-gray-400 hover:underline ${additionalLookupProps.resultsOptionStyling}`}>
					Create Tag: "{noMatchesTag}"
				</p>
			</TextButton>
		);
	};

	const onTagInput = useCallback(
		async (tagQuery: string | null) => {
			if (tagQuery) {
				const response: ApiResponse<any[]> = await callApi(
					searchService(queryCreator(tagQuery))
				);
				console.log(response);
				if (response.data) {
					setTagOptions(response.data);
				}
			}
		},
		[callApi, queryCreator, searchService]
	);

	useEffect(() => {
		onTagInput(debouncedQuery);
	}, [debouncedQuery, onTagInput]);

	useEffect(() => {
		if (showAdd) {
			inputRef.current?.focus();
		}
	}, [showAdd, inputRef]);

	return (
		<div className={'flex flex-wrap ' + (className ?? '')}>
			<label
				className={
					'text-sm text-gray-500 dark:text-gray-400 ' +
					(values?.length || isEditing
						? '-translate-y-5 scale-75 absolute'
						: '')
				}>
				{label}
			</label>
			<div className="pl-2 flex flex-wrap place-items-center">
				{values?.map((value: any) => (
					<div
						key={valueMapper(value)}
						className="text-sm text-gray-500 dark:text-gray-400 border border-gray-500 dark:border-gray-400 px-2 py-1 mr-1 mb-1.5 rounded-full bg-gray-200 dark:bg-gray-700 flex align-center w-max cursor-default active:bg-gray-300 transition duration-300 ease">
						{optionMapper(value)}
						{isEditing ? (
							<CloseButton
								onClick={(e: any) => onRemove(e, value)}
								className="ml-2"
							/>
						) : null}
					</div>
				))}
				{showAdd ? (
					<div className="flex-initial w-32">
						<Lookup
							ref={inputRef}
							name={internalFormName}
							placeholder={'Search'}
							onBlur={(e: any) => setShowAdd(false)}
							onInput={(event: any) => setValue(event.target.value)}
							onChange={onChangeWrapper}
							valueMapper={valueMapper}
							optionMapper={optionMapper}
							options={tagOptions}
							prefixSize="0"
							prefix={null}
							resultsContainerStyling="ml-0"
							className={lookupClassName}
							createNew={canCreate ? createNewRenderer : undefined}
							{...rest}
						/>
					</div>
				) : null}
				{isEditing ? (
					<div className="text-sm text-gray-500 dark:text-gray-400 border border-gray-500 dark:border-gray-400 px-2 py-1 mr-1 mb-1.5 rounded-full bg-gray-200 dark:bg-gray-700 flex align-center w-max cursor-default active:bg-gray-300 transition duration-300 ease">
						<AddButton onClick={() => setShowAdd(true)} className="" />
					</div>
				) : null}
			</div>
			{isEditing && helperInfo && (
				<HelperInfo
					helperInfo={helperInfo}
					className="relative ml-auto -mr-2 -mt-2"
				/>
			)}
			<select multiple hidden name={name} value={valueMapper(values)}>
				{values?.map((value: any) => (
					<option key={valueMapper(value)} value={valueMapper(value)}>
						{optionMapper(value)}
					</option>
				))}
			</select>
		</div>
	);
};

const MultiSelectLookup = (props: any) => {
	const { searchService, ...rest } = props;
	return <Tags canCreate={false} searchService={searchService} {...rest} />;
};

export {
	Input,
	DateInput,
	TextArea,
	Select,
	Checkbox,
	RadioFieldSet,
	PhotoUpload,
	EditableInlineContent,
	LabeledEditableContent,
	Lookup,
	Tags,
	MultiSelectLookup,
};
