import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';

export const InstallPwaContext = createContext({
	isInstallable: false,
	isAlreadyInstalled: false,
	installPwa: undefined,
} as { isInstallable: boolean; isAlreadyInstalled: boolean; installPwa?: any });

export const InstallPwaContextElement = ({
	children,
}: PropsWithChildren<any>) => {
	const [isInstallable, setIsInstallable] = useState(false);
	const [isAlreadyInstalled, setIsAlreadyInstalled] = useState(false);
	const [beforeInstallEvent, setBeforeInstallEvent] = useState<any | undefined>(
		undefined
	);

	const alert = useAlert();

	useEffect(() => {
		window.addEventListener('beforeinstallprompt', (e) => {
			// This was intended to prevent the mini-infobar from appearing on mobile
			// but I don't think that is used anymore and a simple menu item for install is shown, I'd like both to be available to users
			// e.preventDefault();
			// Stash the event so it can be triggered later.
			setBeforeInstallEvent(e);
			// Update UI notify the user they can install the PWA
			setIsInstallable(true);
		});

		window.addEventListener('appinstalled', () => {
			// Log install to analytics
			console.log('INSTALL: Success');
		});

		// Guessing that if we are in standalone mode we have already been installed
		if (window.matchMedia('(display-mode: standalone)').matches) {
			setIsAlreadyInstalled(true);
			console.log('INSTALL: Already installed');
		}
	}, []);

	const installPwa = (e: any) => {
		// Hide the app provided install promotion
		setIsInstallable(false);
		// Show the install prompt
		beforeInstallEvent?.prompt();
		// Wait for the user to respond to the prompt
		beforeInstallEvent?.userChoice.then((choiceResult: any) => {
			if (choiceResult.outcome === 'accepted') {
				console.log('User accepted the install prompt');
				alert.info(
					'Please open the app once installed and enable chat notifications in your account settings.'
				);
			} else {
				console.log('User dismissed the install prompt');
			}
		});
	};

	return (
		<InstallPwaContext.Provider
			value={{ isInstallable, isAlreadyInstalled, installPwa }}>
			{children}
		</InstallPwaContext.Provider>
	);
};
