import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
	ProjectService,
	ProjectSummaryDto,
	ProjectState,
	SearchType,
} from '../../api';
import { UserContext } from '../../contexts/UserContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import useApi from '../../services/useApi';
import UserSideNav from '../utilities/UserSideNav';
import { Button, Link, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import {
	DataGrid,
	GridColDef,
	GridValueGetterParams,
	GridRowParams,
	GridActionsCellItem,
	GridFooterContainer,
	GridPagination,
	GridRenderCellParams,
} from '@mui/x-data-grid';
import {
	Visibility as ViewIcon,
	Delete as DeleteIcon,
	Add as CreateIcon,
	Send as SubmitIcon,
	CheckCircle as CompleteIcon,
	Cancel as EndIcon,
} from '@mui/icons-material';

import { parseISO } from 'date-fns';
import { ActionDialog, ProjectDialog, StateType } from '../../Constants/Dialog';
import { getStateInfo, getStateName } from '../../services/helper';

const Projects = () => {
	const { user, isLoading: isUserLoading } = useContext(UserContext);
	const [items, setItems] = useState<ProjectSummaryDto[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const { callApi } = useApi();
	const navigateTo = useNavigate();

	const loadItems = useCallback(async () => {
		setIsLoading(true);
		const response = await callApi(ProjectService.getUserProjects());
		if (response.data) {
			setItems(response.data);
			setIsLoading(false);
		}
	}, [callApi]);

	useEffect(() => {
		// TODO: Switch all useEffects to properly handle unmounting with an ignore variable https://legacy.reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
		if (!isUserLoading && user) {
			loadItems();
		}
	}, [isUserLoading, user, callApi, loadItems]);

	const handleStateChange = async (
		id: string,
		state: ProjectState,
		dialog: string
	) => {
		// TODO: Change to a modal
		if (window.confirm(dialog) === true) {
			const response = await callApi(
				ProjectService.updateProjectState({
					id: id,
					state: state,
				})
			);
			if (response.data) {
				loadItems();
			}
		}
	};

	const columns: GridColDef<ProjectSummaryDto>[] = [
		{
			field: 'name',
			headerName: 'Name',
			flex: 1,
			renderCell: (params: GridRenderCellParams<ProjectSummaryDto>) => (
				<Link component={RouterLink} to={`/project/${params.row.id}`}>
					{params.value}
				</Link>
			),
		},
		{
			field: 'description',
			headerName: 'Description',
			flex: 1,
		},
		{
			field: 'startDate',
			headerName: 'Start Date',
			type: 'date',
			valueGetter: (params: GridValueGetterParams<ProjectSummaryDto>) =>
				params.row.startDate && parseISO(params.row.startDate),
			flex: 0.5,
		},
		{
			field: 'endDate',
			headerName: 'End Date',
			type: 'date',
			valueGetter: (params: GridValueGetterParams<ProjectSummaryDto>) =>
				params.row.endDate && parseISO(params.row.endDate),
			flex: 0.5,
		},
		{
			field: 'projectOwner',
			headerName: 'Project Owner?',
			valueGetter: (params: GridValueGetterParams<ProjectSummaryDto>) =>
				params.row.ownerBuyerProfileId === user?.buyerProfileId,
			renderCell: (params: GridRenderCellParams<ProjectSummaryDto>) => (
				<div>
					{params.row.ownerBuyerProfileId === user?.buyerProfileId
						? 'Yes'
						: 'No'}
				</div>
			),
			flex: 1,
		},
		{
			field: 'state',
			headerName: 'State',
			flex: 0.5,
			renderCell: (params: GridRenderCellParams<ProjectSummaryDto>) => (
				<Tooltip
					title={getStateInfo(
						params.value,
						StateType.Project,
						params.row.ownerBuyerProfileId === user?.buyerProfileId
					)}>
					<div>
						{getStateName(
							params.value,
							StateType.Project,
							params.row.ownerBuyerProfileId === user?.buyerProfileId
						)}
					</div>
				</Tooltip>
			),
		},
		{
			field: 'updatedAt',
			headerName: 'Last Updated',
			type: 'dateTime',
			valueGetter: (params: GridValueGetterParams<ProjectSummaryDto>) =>
				parseISO(params.row.updatedAt),
			flex: 1,
		},
		{
			field: 'actions',
			headerName: 'Actions',
			type: 'actions',
			headerAlign: 'left',
			align: 'left',
			getActions: (params: GridRowParams<ProjectSummaryDto>) => {
				const actions = [
					<GridActionsCellItem
						icon={
							<Tooltip title="View Details">
								<ViewIcon />
							</Tooltip>
						}
						label="View Details"
						onClick={() => navigateTo(`/project/${params.row.id}`)}
					/>,
				];
				const isProjectOwner =
					user?.buyerProfileId === params.row.ownerBuyerProfileId;
				// Only project owners have additional actions
				if (!isProjectOwner) return actions;

				switch (params.row.state) {
					case ProjectState.DRAFT:
						actions.push(
							<GridActionsCellItem
								icon={<DeleteIcon />}
								label="Delete Draft"
								onClick={() =>
									handleStateChange(
										params.row.id,
										ProjectState.REMOVED,
										ActionDialog.DeleteDraft
									)
								}
								showInMenu
							/>
						);
						actions.push(
							<GridActionsCellItem
								icon={<SubmitIcon />}
								label="Publish"
								onClick={() =>
									handleStateChange(
										params.row.id,
										ProjectState.BIDDING,
										ProjectDialog.Publish
									)
								}
								showInMenu
							/>
						);
						break;
					case ProjectState.ACTIVE:
						actions.push(
							<GridActionsCellItem
								icon={<CompleteIcon />}
								label="Complete"
								onClick={() =>
									handleStateChange(
										params.row.id,
										ProjectState.COMPLETED,
										ProjectDialog.Complete
									)
								}
								showInMenu
							/>
						);
						actions.push(
							<GridActionsCellItem
								icon={<EndIcon />}
								label="End"
								onClick={() =>
									handleStateChange(
										params.row.id,
										ProjectState.ENDED,
										ProjectDialog.End
									)
								}
								showInMenu
							/>
						);
						break;
					default:
						break;
				}
				return actions;
			},
		},
	];

	return (
		<UserSideNav>
			<h1 className="text-center pb-4">Projects</h1>
			<DataGrid
				initialState={{
					sorting: {
						sortModel: [{ field: 'updatedAt', sort: 'desc' }],
					},
					columns: {
						// If the user is both a buyer and talent show the project owner column by default
						columnVisibilityModel: {
							projectOwner: !!(user?.talentProfileId && user?.buyerProfileId),
						},
					},
				}}
				rows={items}
				columns={columns}
				autoHeight
				disableRowSelectionOnClick
				loading={isLoading}
				slots={{
					noRowsOverlay: () => (
						<div className="flex flex-col items-center justify-center m-4">
							<div className="pb-4">You currently have no projects.</div>
							<div className="flex flex-row items-center justify-center">
								{user?.buyerProfileId && (
									<Button
										className="mx-2"
										variant="outlined"
										onClick={() => navigateTo(`/project/${uuidv4()}`)}>
										Create Project
									</Button>
								)}
								{user?.talentProfileId && (
									<Button
										className="mx-2"
										variant="outlined"
										onClick={() => navigateTo(`/search/${SearchType.PROJECT}`)}>
										Find Projects
									</Button>
								)}
							</div>
						</div>
					),
					footer: () => (
						<GridFooterContainer>
							{user?.buyerProfileId && (
								<Button
									className="m-4"
									variant="contained"
									startIcon={<CreateIcon />}
									onClick={() => navigateTo(`/project/${uuidv4()}`)}>
									Create Project
								</Button>
							)}
							<GridPagination />
						</GridFooterContainer>
					),
				}}
			/>
		</UserSideNav>
	);
};

export default Projects;
