/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TeamSize {
	UNDECIDED = 'Undecided',
	SMALL = 'Small',
	MEDIUM = 'Medium',
	LARGE = 'Large',
}
