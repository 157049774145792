import React, {
	useEffect,
	useState,
	createContext,
	PropsWithChildren,
	useCallback,
} from 'react';
import { useApi } from '../services/useApi';
import { useAuth0, User } from '@auth0/auth0-react';
import { useSessionStorage } from 'usehooks-ts';
import { UserInfoDto, UserService } from '../api';

export const UserContext = createContext({
	isAuthenticated: false,
	user: null as AuthUser | null,
	isLoading: false || true,
	setSessionStorageUserData: ((
		user: UserInfoDto | null
	) => {}) as React.Dispatch<React.SetStateAction<UserInfoDto | null>>,
	referrerId: null as string | null,
});

export type AuthUser = User & UserInfoDto;

export const UserContextElement = ({ children }: PropsWithChildren<any>) => {
	const [apiUserData, setApiUserData] = useSessionStorage<UserInfoDto | null>(
		'user',
		null
	);
	const [isApiLoading, setApiIsLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const { callApi } = useApi();
	const {
		isAuthenticated,
		user: authUser,
		isLoading: isAuthLoading,
	} = useAuth0();
	const [areInternalDependenciesLoading, setAreInternalDependenciesLoading] =
		useState(isAuthLoading || isApiLoading);
	const [user, setUser] = useState<AuthUser | null>(null);
	const [referrerId] = useSessionStorage<string | null>(
		'referrerId',
		new URLSearchParams(window.location.search).get('referrerId')
	);

	const GetUserFromAuthProvider = useCallback(async () => {
		setApiIsLoading(true);
		const response = await callApi(UserService.getUserFromAuthProvider());
		if (response.data) {
			console.log('User Info found:', response.data);
			setApiUserData(response.data);
			setApiIsLoading(false);
		}
	}, [callApi, setApiUserData, setApiIsLoading]);

	useEffect(() => {
		if (!areInternalDependenciesLoading) {
			const sessionStoredUser = sessionStorage.getItem('user');
			if (
				authUser?.sub === apiUserData?.authProviderUserId &&
				apiUserData !== null
			) {
				setUser({ ...authUser!, ...apiUserData! });
				setIsLoading(false);
			} else if (
				authUser &&
				(!sessionStoredUser || sessionStoredUser === 'null')
			) {
				setIsLoading(true);
				GetUserFromAuthProvider();
			} else {
				if (!authUser && apiUserData) {
					setApiUserData(null);
				}
				setIsLoading(false);
			}
		}
	}, [
		areInternalDependenciesLoading,
		setIsLoading,
		authUser,
		apiUserData,
		GetUserFromAuthProvider,
		setApiUserData,
	]);

	useEffect(() => {
		setAreInternalDependenciesLoading(isApiLoading || isAuthLoading);
	}, [isApiLoading, isAuthLoading]);

	return (
		<UserContext.Provider
			value={{
				isAuthenticated,
				user,
				isLoading,
				setSessionStorageUserData: setApiUserData,
				referrerId,
			}}>
			{children}
		</UserContext.Provider>
	);
};
