import { useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CreateUserDto, SearchType, UserService, UserSummaryDto } from '../api';
import Button from '../components/utilities/Button';
import {
	Input,
	LabeledEditableContent,
} from '../components/utilities/FloatingLabelInput';
import PostalCodeLookup from '../components/utilities/PostalCodeLookup';
import { UserContext } from '../contexts/UserContext';
import { useApi } from '../services/useApi';
import {
	AutocompleteInput,
	DatePickerInput,
} from '../components/utilities/Input';
import { Box, Stack } from '@mui/material';
import { useAlert } from 'react-alert';
import {
	userOptionDisplayMapper,
	userOptionValueMapper,
} from '../services/helper';

export default function UserSetup(props: {
	message?: React.ReactNode | null;
	advanced: boolean;
	includeReferrer: boolean;
}) {
	const { message, advanced, includeReferrer } = props;
	const { callApi } = useApi();
	const { user, isLoading, setSessionStorageUserData, referrerId } =
		useContext(UserContext);
	const alert = useAlert();

	const {
		control,
		register,
		setValue,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm({
		defaultValues: {
			firstName: '' as string | undefined,
			lastName: '' as string | undefined,
			email: '' as string | undefined,
			countryCode: null as string | null,
			postalCode: null as string | null,
			hiatusStart: null as string | null,
			hiatusEnd: null as string | null,
			hasCompletedGettingStartedWalkthrough: false,
			hasCompletedDedicatedTeamWalkthrough: false,
			referrerId: referrerId as string | null,
			referrer: null as UserSummaryDto | null,
		} as CreateUserDto & {
			email: string | undefined;
			referrerId: string | null | undefined;
			referrer: UserSummaryDto | null | undefined;
		},
	});
	const watchReferrer = watch('referrer');

	useEffect(() => {
		if (!isLoading) {
			setValue('firstName', user?.firstName ?? user?.given_name ?? '');
			setValue('lastName', user?.lastName ?? user?.family_name ?? '');
			setValue('countryCode', user?.countryCode ?? null);
			setValue('postalCode', user?.postalCode ?? null);
			setValue('email', user?.email);
			setValue('hiatusStart', user?.hiatusStart);
			setValue('hiatusEnd', user?.hiatusEnd);
			setValue(
				'hasCompletedGettingStartedWalkthrough',
				user?.hasCompletedGettingStartedWalkthrough ?? false
			);
			setValue(
				'hasCompletedDedicatedTeamWalkthrough',
				user?.hasCompletedDedicatedTeamWalkthrough ?? false
			);
			setValue('referrerId', user?.referrerId ?? referrerId);
		}
	}, [isLoading, referrerId, setValue, user]);

	const submitData = useCallback(
		async (data: CreateUserDto) => {
			const response = await callApi(UserService.createUser(data));
			if (response.data) {
				alert.success('User created successfully');
				setSessionStorageUserData(response.data);
			}
		},
		[callApi, alert, setSessionStorageUserData]
	);

	useEffect(() => {
		const getReferrer = async (referrerId: string) => {
			const response = await callApi(UserService.getUserById(referrerId));
			setValue('referrer', response.data);
		};
		// If we have a referrerId but no  referrer object then we need to get the referrer
		if (referrerId && !watchReferrer) {
			getReferrer(referrerId);
		}
	}, [callApi, referrerId, setValue, watchReferrer]);

	const searchReferringUsers = useCallback(
		async (searchTerm?: string) => {
			const response = await callApi(
				UserService.searchUsers(searchTerm, SearchType.USER)
			);
			return response;
		},
		[callApi]
	);

	const handleReferrerChange = useCallback((result: UserSummaryDto) => {
		if (!result) {
			return;
		}
	}, []);

	if (isLoading) {
		return <div>Loading ...</div>;
	}

	const basicItemStyling = 'flex-initial basis-full md:basis-1/2 px-3';
	return (
		<div className="inline-flex flex-wrap">
			{message}

			<Input
				{...register('firstName', {
					required: 'First name is required',
					validate: (value) => (value?.trim().length ?? 0) > 0,
				})}
				className={basicItemStyling}
				label="First Name"
				errors={errors}
			/>
			<Input
				{...register('lastName', {
					required: 'Last name is required',
					validate: (value) => (value?.trim().length ?? 0) > 0,
				})}
				className={basicItemStyling}
				label="Last Name"
				errors={errors}
			/>
			{advanced ? (
				<Box className="basis-full w-full">
					<h5 className="text-center basis-full w-full font-bold mb-1">
						Looking to take a break from work for a bit? Set when you'd like to
						be on hiatus here:{' '}
					</h5>
					<Stack direction="row" className="px-3 mb-4 gap-4">
						<DatePickerInput
							label="Hiatus Start"
							name="hiatusStart"
							className="flex-initial basis-1/2 "
							control={control}
							maxDate={watch('hiatusEnd')}
						/>
						<DatePickerInput
							label="Hiatus End"
							name="hiatusEnd"
							className="flex-initial basis-1/2 "
							control={control}
							minDate={watch('hiatusStart')}
						/>
					</Stack>
				</Box>
			) : null}
			<div className={`${basicItemStyling} mt-[-.1rem]`}>
				<PostalCodeLookup
					defaultValue={user?.postalCode}
					onChange={(selectedPostalCode) => {
						setValue('countryCode', selectedPostalCode?.countryCode ?? null);
						setValue('postalCode', selectedPostalCode?.code ?? null);
					}}
				/>
			</div>
			<LabeledEditableContent
				{...register('email', { required: 'Email is required' })}
				errors={errors}
				disabled={true}
				className={`${basicItemStyling} text-center`}>
				<span>{user?.email || 'No email'}</span>
			</LabeledEditableContent>
			{includeReferrer ? (
				<AutocompleteInput
					name="referrer"
					valueName="referrerId"
					control={control}
					label="Referred by (optional)"
					optionApiService={searchReferringUsers}
					onChange={handleReferrerChange}
					optionDisplayMapper={userOptionDisplayMapper}
					optionValueMapper={userOptionValueMapper}
					apiFiltering
					className={basicItemStyling}
				/>
			) : null}
			<Button
				className={includeReferrer ? basicItemStyling : 'basis-1/2 ml-auto'}
				onClick={handleSubmit(submitData)}
				type="submit">
				Submit
			</Button>
		</div>
	);
}
