import { useEffect, useState, useContext, useCallback } from 'react';
import {
	PhotoUpload,
	LabeledEditableContent,
} from '../utilities/FloatingLabelInput';
import Button from '@mui/material/Button';
import StickyActionsBar from '../utilities/StickyActionsBar';
import { useForm, useWatch } from 'react-hook-form';
import TeamRoster from '../utilities/TeamRoster';
import { UserContext } from '../../contexts/UserContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useApi from '../../services/useApi';
import {
	DedicatedTeamDto,
	TagDto,
	TeamMemberDto,
	TeamMemberState,
	TeamService,
	TeamState,
	UserService,
} from '../../api';
import { useDebounce } from 'usehooks-ts';
import { StateIndicator } from '../utilities/StateIndicator';
import { flushSync } from 'react-dom';
import {
	recursiveTrim,
	setValueAsUserInputOptions,
	talentProfileToTeamMember,
} from '../../services/helper';
import { nanoid } from 'nanoid';
import dockerNames from 'docker-names';
import { StateType } from '../../Constants/Dialog';
import { Box, Stepper, Step, StepLabel, Paper } from '@mui/material';
import { HelperInfo } from '../utilities/IconButtons';
import { WalkthroughContext } from '../../contexts/WalkthroughContext';
import { Step as WalkthroughStep } from 'react-joyride';
import { TagInput } from '../utilities/Input';

export const stateSteps = {
	[TeamState.DRAFT]: {
		target: 'dedicated-team-steps-draft',
		label: 'Draft',
		description: (
			<div>
				Edit team details and add or remove team members. Only you can see your
				drafts until you click "Send Invite(s)".
			</div>
		),
	},
	[TeamState.PENDING]: {
		target: 'dedicated-team-steps-pending',
		label: 'Pending',
		description: (
			<div>
				At this stage team invites have been sent. All team members must accept
				their invites in order to publish. Other than the team creator, team
				members can only modify their own team member cards.
			</div>
		),
	},
	[TeamState.ACTIVE]: {
		target: 'dedicated-team-steps-active',
		label: 'Published',
		description: (
			<div>
				Once the team creator has published the team it will be publicly visible
				and the team is able to bid on and complete projects. Edits are
				automatically saved and published as they are made.
			</div>
		),
	},
};

export const additionalWalkthroughSteps: WalkthroughStep[] = [
	{
		placement: 'bottom',
		target: '.dedicated-team-state-indicator',
		content: <div>The state of your team is displayed here.</div>,
	},
	{
		placement: 'bottom',
		target: '.dedicated-team-name',
		content: <div>A unique name is required to publish your team.</div>,
	},
	{
		placement: 'bottom',
		target: '.dedicated-team-description',
		content: (
			<div>
				A short description will also be required to publish your team but
				doesn't need to be added until that point.
			</div>
		),
	},
	{
		placement: 'top',
		target: '.dedicated-team-add-member',
		content: (
			<div>
				Click here to search for and add a team member. They will not be
				notified until you explicitly click the 'Send Invite(s)' button below.
			</div>
		),
	},
	{
		placement: 'top',
		target: '.dedicated-team-member',
		content: (
			<div>
				Click here to edit your team member card, including your role,
				anticipated weekly hours, and/or pay.
			</div>
		),
	},
	{
		placement: 'bottom',
		target: '.dedicated-team-banner',
		content: <div>Upload a banner image for your team (optional).</div>,
	},
	{
		placement: 'bottom',
		target: '.dedicated-team-logo',
		content: <div>Upload a logo image for your team (optional).</div>,
	},
	{
		placement: 'top',
		target: '.dedicated-team-actions',
		content: (
			<div>
				These are the additional actions you can take on your team given its
				current state.
			</div>
		),
	},
];

const defaultValues: Omit<
	DedicatedTeamDto,
	'id' | 'updatedAt' | 'createdById'
> = {
	teamMembers: [] as TeamMemberDto[],
	state: TeamState.DRAFT,
	name: null,
	description: null,
	bannerId: null,
	bannerUrl: null,
	logoId: null,
	logoUrl: null,
	tags: [],
};

const DedicatedTeamComponent = (props: any) => {
	const {
		id,
		isEditing: startInEditingMode,
		talentProfilesToAddToTeam,
		team,
		onTeamMemberCardClick,
	}: {
		id: string;
		isEditing: boolean | null | undefined;
		talentProfilesToAddToTeam: any[] | null | undefined;
		team: DedicatedTeamDto | null | undefined;
		onTeamMemberCardClick: ((member: TeamMemberDto) => void) | undefined;
	} = props;
	const navigateTo = useNavigate();
	const { user, isLoading: isUserLoading } = useContext(UserContext);
	const { isLoading: isWalkthroughLoading, getWalkthrough } =
		useContext(WalkthroughContext);
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		setError,
		getValues,
		resetField,
		formState: { isDirty, errors },
		control,
	} = useForm({
		mode: 'onBlur',
		defaultValues: team ?? defaultValues,
	});
	const [searchParams] = useSearchParams();
	const [isEditing, setIsEditing] = useState(
		startInEditingMode || searchParams.get('edit') === 'true'
	);
	const [shouldAddReceivedTeamMembers, setShouldAddReceivedTeamMembers] =
		useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isUserTeamMember, setIsUserTeamMember] = useState(false);
	const [isLoadingTeam, setIsLoadingTeam] = useState(true);
	const [isCreatingTeam, setIsCreatingTeam] = useState(false);
	const [isTeamCreator, setIsTeamCreator] = useState(true);
	const watchAll = useDebounce(
		useWatch({ control, disabled: isLoadingTeam || !isEditing }),
		600
	);
	const { callApi } = useApi();

	const watchBanner = watch('bannerId');
	const [bannerUrl, setBannerUrl] = useState<string | null>();
	const watchLogo = watch('logoId');
	const [logoUrl, setLogoUrl] = useState<string | null>();
	const watchName = watch('name');
	const watchDescription = watch('description');
	const watchTeamMembers = watch('teamMembers');
	const watchState = watch('state');

	const addNewMember = useCallback(
		(
			teamMember: TeamMemberDto,
			setValueOptions = setValueAsUserInputOptions
		) => {
			const teamMembers = getValues('teamMembers');
			const existingMember = teamMembers?.find(
				(member: TeamMemberDto) =>
					member.talentProfileId === teamMember.talentProfileId
			);
			if (!existingMember) {
				setValue(
					'teamMembers',
					[...(teamMembers ?? []), teamMember],
					setValueOptions
				);
			}
		},
		[getValues, setValue]
	);
	const changeMember = (
		teamMember: TeamMemberDto,
		oldTeamMember: TeamMemberDto
	) => {
		setValue(
			'teamMembers',
			getValues('teamMembers').map((currentTeamMember: TeamMemberDto) =>
				currentTeamMember.talentProfileId === oldTeamMember.talentProfileId
					? teamMember
					: currentTeamMember
			),
			setValueAsUserInputOptions
		);
	};
	const removeMember = (teamMember: TeamMemberDto) => {
		const teamMembers = getValues('teamMembers');
		const teamMemberToMarkForRemoval = teamMembers.find(
			(currentTeamMember: TeamMemberDto) =>
				currentTeamMember.talentProfileId === teamMember.talentProfileId
		);
		if (teamMemberToMarkForRemoval) {
			teamMemberToMarkForRemoval.inviteState = TeamMemberState.REMOVED;
		}
		setValue('teamMembers', teamMembers, setValueAsUserInputOptions);
	};

	useEffect(() => {
		if (shouldAddReceivedTeamMembers) {
			for (const newTeamMember of talentProfilesToAddToTeam ?? []) {
				addNewMember(
					talentProfileToTeamMember(id, newTeamMember, TeamMemberState.DRAFT)
				);
			}
			setShouldAddReceivedTeamMembers(false);
		}
	}, [
		addNewMember,
		id,
		shouldAddReceivedTeamMembers,
		talentProfilesToAddToTeam,
	]);

	useEffect(() => {
		const loadTeamData = async () => {
			const response = await callApi(TeamService.getDedicatedTeam(id));
			console.log('loaded team', response.data);
			if (response.data) {
				setValue('name', response.data.name);
				setValue('description', response.data.description);
				setValue('logoId', response.data.logoId);
				setLogoUrl(response.data.logoUrl);
				setValue('bannerId', response.data.bannerId);
				setBannerUrl(response.data.bannerUrl);
				setValue('teamMembers', response.data.teamMembers);
				setValue('tags', response.data.tags ?? undefined);
				setValue('state', response.data.state);
				if (
					response.data.teamMembers.find(
						(member: TeamMemberDto) =>
							member.talentProfileId === user!.talentProfileId &&
							member.inviteState !== TeamMemberState.DRAFT
					)
				) {
					console.log('User is team member');
					setIsUserTeamMember(true);
				} else {
					setIsUserTeamMember(false);
				}

				if (response.data.createdById === user!.id) {
					console.log('User is creator');
					setIsTeamCreator(true);
					setIsUserTeamMember(true);
				} else {
					setIsTeamCreator(false);
				}
			} else if (response.error) {
				console.error(response.error);
			} else {
				console.log('No team found, we are creating a new team');
				setIsTeamCreator(true);
				setIsUserTeamMember(true);
				setIsCreatingTeam(true);
				setIsEditing(true);
			}
			setShouldAddReceivedTeamMembers(
				(talentProfilesToAddToTeam?.length ?? 0) > 0
			);
			setIsLoadingTeam(false);
		};
		if (id && !isUserLoading && user && !team) {
			setIsLoadingTeam(true);
			loadTeamData();
		}
	}, [
		id,
		setValue,
		callApi,
		user,
		isUserLoading,
		searchParams,
		setIsUserTeamMember,
		talentProfilesToAddToTeam,
		team,
	]);

	useEffect(() => {
		const addCurrentUserToTeam = async () => {
			const response = await callApi(
				UserService.getFullPublicTalentProfile(user!.talentProfileId!)
			);
			if (response.data) {
				const currentUserTalentProfile = response.data;
				const currentUserTeamMember = talentProfileToTeamMember(
					id,
					currentUserTalentProfile,
					TeamMemberState.DRAFT
				);
				console.log('add current user', currentUserTeamMember);
				// Pass setValueOptions to avoid setting isDirty, etc.
				addNewMember(currentUserTeamMember, {});
			}
		};

		const teamMembers = getValues('teamMembers');
		if (user && isCreatingTeam && teamMembers.length === 0) {
			console.log('full user data', user);
			addCurrentUserToTeam();
			setValue(
				'name',
				`Draft: ${dockerNames.getRandomName().split('_').join(' ')} ${nanoid(
					3
				)}`
			);
		}
	}, [isCreatingTeam, user, id, addNewMember, callApi, setValue, getValues]);

	const save = useCallback(
		async (
			data: Omit<DedicatedTeamDto, 'id' | 'updatedAt' | 'createdById'>
		) => {
			setIsSaving(true);
			console.log('saving team');
			const response = await callApi(
				TeamService.upsertDedicatedTeam(recursiveTrim({ ...data, id: id }))
			);
			console.log('save team response', response);
			if ([TeamState.REMOVED, TeamState.INACTIVE].includes(data.state)) {
				setIsEditing(false);
			}
			setIsSaving(false);
		},
		[callApi, id]
	);

	useEffect(() => {
		if (isEditing && !isLoadingTeam && isDirty) {
			const submitFunction = handleSubmit(save, (errors) =>
				console.error(errors)
			);
			submitFunction();
		}
	}, [watchAll, handleSubmit, save, isEditing, isLoadingTeam, isDirty]);

	const inviteTeam = () => {
		setValue('state', TeamState.PENDING, setValueAsUserInputOptions);

		const teamMembers = getValues('teamMembers');
		setValue(
			'teamMembers',
			teamMembers.map((teamMember: TeamMemberDto) => {
				if (teamMember.inviteState === TeamMemberState.DRAFT) {
					if (
						isTeamCreator &&
						teamMember.talentProfileId === user!.talentProfileId
					) {
						teamMember.inviteState = TeamMemberState.ACCEPTED;
					} else {
						teamMember.inviteState = TeamMemberState.UNREAD;
					}
					return teamMember;
				} else {
					return teamMember;
				}
			}),
			setValueAsUserInputOptions
		);
	};

	const isNameValidFor = (desiredState: TeamState) =>
		!(desiredState === TeamState.ACTIVE && (watchName?.length ?? 0) < 4);
	const isDescriptionValidFor = (desiredState: TeamState) =>
		!(desiredState === TeamState.ACTIVE && (watchDescription?.length ?? 0) < 4);

	const publishTeam = () => {
		const isNameValid = isNameValidFor(TeamState.ACTIVE);
		if (!isNameValid) {
			setError('name', {
				type: 'minNameLengthToPublish',
				message: 'A name must be 4+ characters to publish a team',
			});
		}
		const isDescriptionValid = isDescriptionValidFor(TeamState.ACTIVE);
		if (!isDescriptionValid) {
			setError('description', {
				type: 'minNameLengthToPublish',
				message: 'A description must be 4+ characters to publish a team',
			});
		}

		if (isNameValid && isDescriptionValid) {
			setValue('state', TeamState.ACTIVE, setValueAsUserInputOptions);
		}
	};

	const handleRemoved = () => {
		if (
			window.confirm('Are you sure you want to delete this draft?') === true
		) {
			setValue('state', TeamState.REMOVED, setValueAsUserInputOptions);
		}
	};

	const handleInactive = () => {
		if (
			window.confirm('Are you sure you want to disband this team?') === true
		) {
			setValue('state', TeamState.INACTIVE, setValueAsUserInputOptions);
		}
	};

	const updateTeamMemberState = (newState: TeamMemberState) => {
		let initialEditState = false;
		flushSync(() => {
			setIsEditing((currentEditState: boolean) => {
				initialEditState = currentEditState;
				return true;
			});
			const teamMembers = getValues('teamMembers');
			// Get logged in team member and update their state
			const loggedInTeamMember = teamMembers.find(
				(teamMember: TeamMemberDto) =>
					teamMember.talentProfileId === user!.talentProfileId
			);
			if (loggedInTeamMember) {
				loggedInTeamMember.inviteState = newState;
			}
			setValue('teamMembers', teamMembers, setValueAsUserInputOptions);
		});
		setIsEditing(initialEditState);
	};

	return (
		<>
			<div className="w-full flex flex-column flex-wrap justify-center">
				{
					// if current user has been invited to the team, show a message and a button to accept/reject/ignore the invitation
					watchTeamMembers.find(
						(teamMember: TeamMemberDto) =>
							teamMember.inviteState === TeamMemberState.UNREAD &&
							teamMember.talentProfileId === user!.talentProfileId
					) && (
						<div className="flex flex-col place-content-center place-items-center w-full">
							<div className="w-full text-center">
								<h2>You have been invited to join this team</h2>
							</div>
							<div className="flex flex-row place-content-evenly w-1/2">
								<Button
									variant="contained"
									onClick={() =>
										updateTeamMemberState(TeamMemberState.DECLINED)
									}>
									Decline
								</Button>
								<Button
									variant="contained"
									onClick={() =>
										updateTeamMemberState(TeamMemberState.ACCEPTED)
									}>
									Accept
								</Button>
							</div>
						</div>
					)
				}
				<StateIndicator
					show={true}
					displayState={isSaving ? 'Saving' : watchState}
					additionalElements={
						<input
							type="hidden"
							{...register('state', {
								validate: {
									minNameLengthToPublish: (desiredState) => {
										return isNameValidFor(desiredState);
									},
									minDescriptionLengthToPublish: (desiredState) => {
										return isDescriptionValidFor(desiredState);
									},
								},
							})}
							value={watchState}
						/>
					}
					stateType={StateType.Team}
					className="dedicated-team-state-indicator"
				/>
				{isEditing && Object.keys(stateSteps)?.length ? (
					<Paper className="w-full basis-full my-2 p-2">
						<Box sx={{ width: '100%' }}>
							<Stepper
								activeStep={Object.keys(stateSteps).findIndex(
									(stepState) => stepState === watchState
								)}
								alternativeLabel>
								{Object.entries(stateSteps).map(([stepState, step]) => (
									<Step key={stepState} className={step.target}>
										<StepLabel>
											{step.label}
											<HelperInfo
												helperInfo={
													<div className="p-2 max-w-[18rem]">
														{step.description}
													</div>
												}
												className="inline-flex flex-wrap scale-75 place-content-center ml-1"
											/>
										</StepLabel>
									</Step>
								))}
							</Stepper>
						</Box>
					</Paper>
				) : null}
				<PhotoUpload
					{...register('bannerId')}
					label="Team Banner"
					helperText="select a team banner"
					category="TeamBanner"
					value={watchBanner}
					url={bannerUrl}
					// Image Resizing Properties
					maxWidth={1250}
					maxHeight={500}
					minWidth={1240}
					minHeight={200}
					editing={isEditing && isTeamCreator}
					errors={errors}
					resetfield={resetField}
					className="w-full md:w-3/4 dedicated-team-banner"
					styling="h-44"
					imageStyle="object-contain"
				/>
				<div className="w-full md:w-3/4 justify-between flex flex-row place-items-center">
					<PhotoUpload
						{...register('logoId')}
						label="Team Logo"
						helperText="select a logo"
						category="TeamLogo"
						value={watchLogo}
						url={logoUrl}
						// Image Resizing Properties
						maxWidth={200}
						maxHeight={200}
						minWidth={100}
						minHeight={100}
						editing={isEditing && isTeamCreator}
						errors={errors}
						resetfield={resetField}
						className="mr-4 dedicated-team-logo"
						styling="h-24 w-24"
						imageStyle="object-cover"
					/>
					<LabeledEditableContent
						{...register('name')}
						errors={errors}
						disabled={!isEditing || !isTeamCreator}
						value={watchName}
						label="Team Name"
						className="dedicated-team-name">
						<h1 className="min-h-[1em]">{watchName}</h1>
					</LabeledEditableContent>
				</div>
				<div className="w-full md:w-3/4 justify-between flex flex-row place-items-center">
					<LabeledEditableContent
						{...register('description')}
						errors={errors}
						disabled={!isEditing || !isTeamCreator}
						value={watchDescription}
						label="Team Description"
						className="ml dedicated-team-description">
						<pre className="break-words whitespace-pre-wrap font-body">
							{watchDescription}
						</pre>
					</LabeledEditableContent>
				</div>
				<h3 className="w-full md:w-3/4 p-2 text-left">Team Members</h3>
				<TeamRoster
					className="w-full md:w-3/4 dedicated-team-members"
					teamId={id}
					editing={isEditing}
					isTeamCreator={isTeamCreator}
					teamMembers={watchTeamMembers}
					onAddNewMember={addNewMember}
					onChangeMember={changeMember}
					onRemoveMember={removeMember}
					onTeamMemberCardClick={onTeamMemberCardClick}></TeamRoster>
				{!watch('tags')?.length && !isEditing ? null : (
					<div className="w-full md:w-3/4 place-items-center mb-4">
						<>
							<TagInput
								name="tags"
								control={control}
								label="Related Tags"
								readOnly={!isEditing}
								rules={{
									validate: (value?: TagDto[]) => {
										return (
											value === null ||
											value === undefined ||
											value?.length <= 12 ||
											'You can only select up to 12 tags for your team.'
										);
									},
								}}
							/>
						</>
					</div>
				)}
				{((isEditing && isTeamCreator) ||
					(isEditing &&
						watchTeamMembers.find(
							(teamMember: TeamMemberDto) =>
								teamMember.inviteState === TeamMemberState.ACCEPTED &&
								teamMember.talentProfileId === user!.talentProfileId
						))) &&
				watchState !== TeamState.REMOVED &&
				watchState !== TeamState.INACTIVE ? (
					<StickyActionsBar className="dedicated-team-actions z-10">
						{isUserTeamMember && watchState !== TeamState.DRAFT && (
							<Button variant="contained" onClick={() => setIsEditing(false)}>
								Exit
							</Button>
						)}
						{isTeamCreator &&
							(watchState === TeamState.DRAFT ||
								watchState === TeamState.PENDING) && (
								<Button variant="contained" onClick={() => handleRemoved()}>
									Delete Team
								</Button>
							)}
						{isTeamCreator &&
							(watchState === TeamState.DRAFT ||
								watchTeamMembers.some(
									(tm) => tm.inviteState === TeamMemberState.DRAFT
								)) && (
								<Button variant="contained" onClick={inviteTeam}>
									Send Invite(s)
								</Button>
							)}
						{isTeamCreator &&
							watchState === TeamState.PENDING &&
							watchTeamMembers.every((tm) =>
								[
									TeamMemberState.ACCEPTED,
									TeamMemberState.DECLINED,
									TeamMemberState.REMOVED,
								].includes(tm.inviteState ?? '')
							) && (
								<Button variant="contained" onClick={publishTeam}>
									Publish
								</Button>
							)}
						{isTeamCreator && watchState === TeamState.ACTIVE && (
							<Button variant="contained" onClick={() => handleInactive()}>
								Disband Team
							</Button>
						)}
					</StickyActionsBar>
				) : null}

				{isUserTeamMember && !isEditing ? (
					<StickyActionsBar className="dedicated-team-actions z-10">
						{(watchState === TeamState.REMOVED ||
							watchState === TeamState.INACTIVE) && (
							<Button
								variant="contained"
								onClick={() => navigateTo('/dashboard')}>
								Preview
							</Button>
						)}
						{watchState === TeamState.REMOVED ||
							watchState === TeamState.INACTIVE || (
								<Button
									variant="contained"
									onClick={() => setIsEditing(!isEditing)}>
									Edit
								</Button>
							)}
					</StickyActionsBar>
				) : null}
			</div>
			{isEditing && !isWalkthroughLoading && getWalkthrough('dedicatedTeam')}
		</>
	);
};

export default DedicatedTeamComponent;
