import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { InvoiceSummaryDto, PaymentService } from '../../../api';
import { UserContext } from '../../../contexts/UserContext';
import { useContext, useEffect, useState } from 'react';
import useApi from '../../../services/useApi';
import { Link, Button, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import {
	DataGrid,
	GridColDef,
	GridValueGetterParams,
	GridRenderCellParams,
	GridRowParams,
	GridActionsCellItem,
	GridFooterContainer,
	GridPagination,
} from '@mui/x-data-grid';
import { Visibility as ViewIcon, Add as CreateIcon } from '@mui/icons-material';

import { parseISO } from 'date-fns';
import { getStateInfo, getStateName } from '../../../services/helper';
import { StateType } from '../../../Constants/Dialog';

const Invoices = () => {
	const { user, isLoading: isUserLoading } = useContext(UserContext);
	const [items, setItems] = useState<InvoiceSummaryDto[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const { callApi } = useApi();
	const navigateTo = useNavigate();

	useEffect(() => {
		const loadItems = async () => {
			const response = await callApi(PaymentService.getUserInvoices());
			if (response.data) {
				setItems(response.data);
				setIsLoading(false);
			}
		};
		if (!isUserLoading && user) {
			setIsLoading(true);
			loadItems();
		}
	}, [isUserLoading, user, callApi]);

	const columns: GridColDef<InvoiceSummaryDto>[] = [
		{
			field: 'title',
			headerName: 'Title',
			flex: 0.5,
			renderCell: (params: GridRenderCellParams<InvoiceSummaryDto>) => (
				<Link component={RouterLink} to={`/invoice/${params.row.id}`}>
					{params.value}
				</Link>
			),
		},
		{
			field: 'projectName',
			headerName: 'Project',
			valueGetter: (params: GridValueGetterParams<InvoiceSummaryDto>) =>
				params.row.contract.project.name,
			renderCell: (params: GridRenderCellParams<InvoiceSummaryDto>) => (
				<Link
					component={RouterLink}
					to={`/project/${params.row.contract.projectId}`}>
					{params.row.contract.project.name}
				</Link>
			),
			flex: 1,
		},
		{
			field: 'teamName',
			headerName: 'Team',
			valueGetter: (params: GridValueGetterParams<InvoiceSummaryDto>) =>
				params.row.contract.team.name,
			renderCell: (params: GridRenderCellParams<InvoiceSummaryDto>) => (
				<Link
					component={RouterLink}
					to={`/dedicated-team/${params.row.contract.teamId}`}>
					{params.row.contract.team.name}
				</Link>
			),
			flex: 1,
		},
		{
			field: 'description',
			headerName: 'Description',
			flex: 1,
		},
		{
			field: 'projectOwner',
			headerName: 'Project Owner?',
			valueGetter: (params: GridValueGetterParams<InvoiceSummaryDto>) =>
				params.row.contract.project.ownerBuyerProfileId ===
				user?.buyerProfileId,
			renderCell: (params: GridRenderCellParams<InvoiceSummaryDto>) => (
				<div>
					{params.row.contract.project.ownerBuyerProfileId ===
					user?.buyerProfileId
						? 'Yes'
						: 'No'}
				</div>
			),
			flex: 1,
		},
		{
			field: 'state',
			headerName: 'State',
			flex: 0.5,
			renderCell: (params: GridRenderCellParams<InvoiceSummaryDto>) => (
				<Tooltip
					title={getStateInfo(
						params.value,
						StateType.Invoice,
						params.row.contract.project.ownerBuyerProfileId ===
							user?.buyerProfileId
					)}>
					<div>
						{getStateName(
							params.value,
							StateType.Invoice,
							params.row.contract.project.ownerBuyerProfileId ===
								user?.buyerProfileId
						)}
					</div>
				</Tooltip>
			),
		},
		{
			field: 'updatedAt',
			headerName: 'Last Updated',
			type: 'dateTime',
			valueGetter: (params: GridValueGetterParams<InvoiceSummaryDto>) =>
				parseISO(params.row.updatedAt),
			flex: 0.5,
		},
		{
			field: 'actions',
			headerName: 'Actions',
			type: 'actions',
			headerAlign: 'left',
			align: 'left',
			getActions: (params: GridRowParams<InvoiceSummaryDto>) => {
				const actions = [
					<GridActionsCellItem
						icon={
							<Tooltip title="View Details">
								<ViewIcon />
							</Tooltip>
						}
						label="View Details"
						onClick={() => navigateTo(`/invoice/${params.row.id}`)}
					/>,
				];
				// TODO: Add quick actions once seperate api call available
				return actions;
			},
		},
	];

	return (
		<div>
			<h2 className="pb-4">Invoices</h2>
			<DataGrid
				initialState={{
					sorting: {
						sortModel: [{ field: 'updatedAt', sort: 'desc' }],
					},
					columns: {
						// If the user is both a buyer and talent show the project owner column by default
						columnVisibilityModel: {
							projectOwner: !!(user?.talentProfileId && user?.buyerProfileId),
						},
					},
				}}
				rows={items}
				columns={columns}
				autoHeight
				disableRowSelectionOnClick
				loading={isLoading}
				slots={{
					footer: () => (
						<GridFooterContainer>
							{user?.talentProfileId && (
								<Button
									className="m-4"
									variant="contained"
									startIcon={<CreateIcon />}
									onClick={() => navigateTo(`/invoice/${uuidv4()}`)}>
									Create Invoice
								</Button>
							)}
							<GridPagination />
						</GridFooterContainer>
					),
				}}
			/>
		</div>
	);
};

export default Invoices;
