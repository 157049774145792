import {
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import React, { useContext } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { ProjectSummaryDto, SearchType } from '../../api';
import { UserContext } from '../../contexts/UserContext';
import { v4 as uuidv4 } from 'uuid';

interface ProjectProps {
	className?: string;
	projects: ProjectSummaryDto[];
	loading: boolean;
}

const Projects = (props: ProjectProps) => {
	const navigateTo = useNavigate();
	const { user } = useContext(UserContext);

	const isBuyer = user?.buyerProfileId ? true : false;
	const isTalent = user?.talentProfileId ? true : false;
	const isBuyerAndTalent = isBuyer && isTalent;

	const buildList = () => {
		if (props.loading) {
			return <div className="pl-2">Loading...</div>;
		} else if (props.projects.length === 0) {
			return (
				<ListItem className="flex justify-between p-3 hover:bg-blue-600 hover:text-blue-200">
					<ListItemText>
						<h3 className="font-bold">
							You currently have no projects.{' '}
							{isBuyerAndTalent ? (
								<>
									<Link className="text-accent-400" to={`/project/${uuidv4()}`}>
										Create
									</Link>{' '}
									or{' '}
									<Link
										className="text-accent-400"
										to={`/search/${SearchType.PROJECT}`}>
										find
									</Link>{' '}
									a project to get started!
								</>
							) : isTalent ? (
								<>
									<Link
										className="text-accent-400"
										to={`/search/${SearchType.PROJECT}`}>
										Find projects
									</Link>{' '}
									to get started!
								</>
							) : (
								<>
									<Link className="text-accent-400" to={`/project/${uuidv4()}`}>
										Create
									</Link>{' '}
									a project to get started!
								</>
							)}
						</h3>
					</ListItemText>
				</ListItem>
			);
		} else {
			return props.projects.map((row: ProjectSummaryDto) => (
				<React.Fragment key={row.id}>
					<ListItemButton
						key={row.id}
						onClick={() => navigateTo(`/project/${row.id}`)}
						className="flex justify-between p-3 hover:bg-blue-600 hover:text-blue-200">
						<ListItemText primary={row.name} secondary={row.description} />
						<ListItemIcon>
							<FaArrowRight />
						</ListItemIcon>
					</ListItemButton>
					<Divider />
				</React.Fragment>
			));
		}
	};

	return (
		<div className="flex bg-white dark:bg-gray-900 rounded-md shadow-xl max-h-80">
			<div className="flex flex-col container mx-auto overflow-y-auto">
				<List>{buildList()}</List>
			</div>
		</div>
	);
};

export default Projects;
