import { DedicatedTeamDto } from '../../api';
import UserSideNav from '../utilities/UserSideNav';
import DedicatedTeamComponent from './DedicatedTeamComponent';

const DedicatedTeam = (props: any) => {
	const {
		id,
		isEditing: startInEditingMode,
		talentProfilesToAddToTeam,
		team,
	}: {
		id: string;
		isEditing: boolean | null | undefined;
		talentProfilesToAddToTeam: any[] | null | undefined;
		team: DedicatedTeamDto | null | undefined;
	} = props;
	return (
		<UserSideNav>
			<DedicatedTeamComponent
				id={id}
				isEditing={startInEditingMode}
				talentProfilesToAddToTeam={talentProfilesToAddToTeam}
				team={team}
			/>
		</UserSideNav>
	);
};

export default DedicatedTeam;
