import { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import Button from '@mui/material/Button';
import ChatContext from '../../contexts/ChatContext';
import StickyActionsBar from '../utilities/StickyActionsBar';
import { UserContext } from '../../contexts/UserContext';
import {
	BuyerProfilePublicDto,
	ChatService,
	ConnectionDto,
	ConnectionState,
	TagDto,
	UpsertBuyerProfileCommand,
	UserService,
} from '../../api';
import useApi from '../../services/useApi';
import { useDebounce } from 'usehooks-ts';
import UserSideNav from '../utilities/UserSideNav';
import BuyerCard from '../utilities/BuyerCard';
import { StateIndicator } from '../utilities/StateIndicator';
import { ConnectionDirection } from '../TalentProfile/TalentProfile';
import ConnectButton from '../utilities/ConnectButton';
import { TagInput, TextFieldInput } from '../utilities/Input';
import { Router } from '../../Router';

const defaultValues: BuyerProfilePublicDto = {
	tags: [] as TagDto[],
	id: undefined!,
	userId: undefined!,
	firstName: undefined!,
	lastName: undefined!,
};

const BuyerProfile = (props: any) => {
	const { id, isEditing: startInEditingMode } = props;
	const { user, isLoading: isUserLoading } = useContext(UserContext);
	const canEdit = id === user?.buyerProfileId;
	const [isEditing, setIsEditing] = useState(canEdit && startInEditingMode);
	const [isSaving, setIsSaving] = useState(false);
	const [connectionState, setConnectionState] =
		useState<ConnectionState | null>(null);
	const [connectionDirection, setConnectionDirection] =
		useState<ConnectionDirection | null>(null);
	const [profileConnection, setProfileConnection] = useState<ConnectionDto>();
	const { callApi } = useApi();
	const form = useForm({
		mode: 'onBlur',
		defaultValues: defaultValues,
	});
	const { handleSubmit, reset, setValue, watch, control } = form;
	const watchAll = useDebounce(
		useWatch({ control, disabled: isUserLoading || !isEditing }),
		600
	);
	const { createConversation, chatState } = useContext(ChatContext);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const loadBuyerProfile = async () => {
			const response = await callApi(UserService.getFullPublicBuyerProfile(id));
			if (response.data) {
				const profile = response.data;
				reset(profile);
				if (profile.connection) {
					setConnectionDirection(
						profile.connection.initiatorUserId === profile.userId
							? ConnectionDirection.Received
							: ConnectionDirection.Initiated
					);
					setConnectionState(profile.connection.state);
					setProfileConnection(profile.connection);
				}
			} else {
				// Currently this component is only used for updating an existing buyer profile
				Router.navigate('/unauthorized', {
					state: { message: 'Buyer Profile does not exist.' },
				});
			}
			setIsLoading(false);
		};
		if (!isUserLoading && user) {
			setIsLoading(true);
			loadBuyerProfile();
		}
	}, [id, isUserLoading, setValue, user, callApi, reset]);

	const save = useCallback(
		async (data: UpsertBuyerProfileCommand) => {
			setIsSaving(true);
			const payload = {
				...data,
			};
			await callApi(UserService.upsertBuyerProfile(payload));
			setIsSaving(false);
		},
		[callApi]
	);

	useEffect(() => {
		if (isEditing && !isLoading) {
			handleSubmit(save)();
		}
	}, [watchAll, handleSubmit, save, isEditing, isLoading]);

	const sendDirectMessage = async () => {
		if (chatState.conversationsReady) {
			const response = await callApi(ChatService.buyerParticipantSearch(id));
			if (response.data) {
				const buyerParticipant = response.data;
				await createConversation([buyerParticipant]);
			}
		}
	};

	return (
		<UserSideNav>
			<FormProvider {...form}>
				<div className="w-full flex flex-column flex-wrap">
					{!isLoading &&
					!canEdit &&
					connectionState === ConnectionState.PENDING &&
					connectionDirection === ConnectionDirection.Received ? (
						<div className="flex flex-col place-content-center place-items-center w-full">
							<div className="w-full text-center">
								<h2>You have been invited to connect by this user.</h2>
							</div>
							<ConnectButton
								userId={watch('userId')}
								connection={profileConnection}
								condensed={false}
							/>
						</div>
					) : null}
					<StateIndicator
						show={isEditing}
						displayState={isSaving ? 'Saving' : 'Saved'}
					/>
					<BuyerCard isEditing={isEditing} isLoading={isLoading} />
					<div className="w-full md:w-3/4 pr-0 md:pr-2 place-self-start">
						<h2 className="pb-2 invisible md:visible">
							{(watch('firstName') ?? '') + ' ' + (watch('lastName') ?? '')}
						</h2>
						{!watch('blurb') && !isEditing ? null : (
							<TextFieldInput
								name="blurb"
								control={control}
								label="Blurb"
								rules={{
									maxLength: {
										value: 300,
										message: "Oops, let's keep it under 300 characters",
									},
								}}
								readOnly={!isEditing}
								multiline
								maxRows={4}
								fullWidth
								className="my-2"
							/>
						)}
						{!watch('tags')?.length && !isEditing ? null : (
							<>
								<TagInput
									name="tags"
									control={control}
									label="Industry"
									readOnly={!isEditing}
									rules={{
										validate: (value?: TagDto[]) => {
											return (
												value === null ||
												value === undefined ||
												value?.length <= 5 ||
												'You can only select up to 5 skills'
											);
										},
									}}
								/>
							</>
						)}
					</div>
					<StickyActionsBar className="z-10">
						{canEdit ? (
							<Button
								variant="contained"
								onClick={() => setIsEditing(!isEditing)}>
								{isEditing ? 'Preview' : 'Edit'}
							</Button>
						) : null}
						{!canEdit && connectionState === null ? (
							<ConnectButton
								userId={watch('userId')}
								connection={profileConnection}
								condensed={true}
							/>
						) : null}
						{!canEdit ? (
							<Button variant="contained" onClick={() => sendDirectMessage()}>
								Message
							</Button>
						) : null}
					</StickyActionsBar>
				</div>
			</FormProvider>
		</UserSideNav>
	);
};

export default BuyerProfile;
