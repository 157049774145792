import { Box, Button } from '@mui/material';
import { FaPlus } from 'react-icons/fa';
import { OrganizationSummaryDto, OrganizationsService } from '../../api';
import { OrganizationModal } from '../Organizations/OrganizationModal';
import { Lookup } from './FloatingLabelInput';
import UrlFavIcon from './UrlFavIcon';
import { v4 as uuidv4 } from 'uuid';
import { useCallback, useState } from 'react';
import useApi from '../../services/useApi';

const OrganizationLookup = (props: {
	organization: OrganizationSummaryDto | null | undefined;
	isEditing: boolean;
	errors: any;
	onChange: (result: OrganizationSummaryDto | null | undefined) => any;
}) => {
	const { organization, isEditing, onChange, errors } = props;
	const { callApi, emptyResponse } = useApi();

	const [organizationSearchResults, setOrganizationSearchResults] = useState<
		OrganizationSummaryDto[]
	>([]);

	const searchOrganizations = useCallback(
		async (query?: string) => {
			if (query) {
				const response = await callApi(
					OrganizationsService.searchOrganizations(query, false)
				);
				setOrganizationSearchResults(response.data ?? []);
				return response;
			} else {
				setOrganizationSearchResults([]);
				return emptyResponse;
			}
		},
		[callApi, emptyResponse]
	);

	const [addOrganizationModalOpen, setAddOrganizationModalOpen] =
		useState(false);

	const handleAddOrganization = (newOrganization: OrganizationSummaryDto) => {
		setAddOrganizationModalOpen(false);
		onChange?.(newOrganization);
	};

	return (
		<Box
			display="flex"
			className="gap-4 justify-between m-2"
			alignItems="center">
			<Lookup
				prefix={
					<UrlFavIcon
						url={organization?.website}
						name={organization?.name}
						sx={{ width: 24, height: 24 }}
						className="w-6 h-6 block"
					/>
				}
				prefixSize="9"
				label="Organization"
				name="organization"
				defaultValue={organization?.name}
				disabled={!isEditing}
				onInput={(e: any) => searchOrganizations(e.target.value)}
				options={organizationSearchResults}
				OptionElement={({ option }: { option: OrganizationSummaryDto }) => (
					<div className="flex flex-row justify-between truncate">
						<UrlFavIcon
							url={option.website}
							name={option.name}
							sx={{ width: 24, height: 24 }}
						/>
						<div className="ml-2 truncate">{option.name}</div>
					</div>
				)}
				optionMapper={(option: OrganizationSummaryDto | null) => option?.name}
				onChange={(_: any, result: OrganizationSummaryDto | null | undefined) =>
					onChange(result)
				}
				errors={errors}
			/>
			{!isEditing ? null : (
				<>
					<Button
						className="flex-none"
						variant="outlined"
						color="primary"
						onClick={() => setAddOrganizationModalOpen(true)}>
						<FaPlus className="ml-0 mr-2" /> Org
					</Button>
					<OrganizationModal
						id={uuidv4()}
						organization={null}
						open={addOrganizationModalOpen}
						onCancel={() => setAddOrganizationModalOpen(false)}
						onOrganizationAdded={handleAddOrganization}
					/>
				</>
			)}
		</Box>
	);
};

export default OrganizationLookup;
