import React from 'react';
import { Link } from 'react-router-dom';
import FullPageCard from './components/utilities/FullPageCard';

export const NotFound: React.FC = () => {
	return (
		<FullPageCard className="px-20 py-10">
			<h1 className="font-bold text-accent-400 text-9xl">404</h1>

			<h6 className="mb-2 text-2xl font-bold text-center text-gray-800 dark:text-gray-200 md:text-3xl">
				<span className="text-primary-500">Oops!</span> Page not found
			</h6>

			<p className="mb-8 text-center text-gray-500 md:text-lg">
				The page you're looking for doesn't exist.
			</p>

			<Link
				to="/"
				className="px-6 py-2 text-sm font-semibold text-primary-800 bg-gray-200">
				Go home
			</Link>
		</FullPageCard>
	);
};
