/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PotentialTeamReview {
	UNSEEN = 'Unseen',
	PASS = 'Pass',
	INTERESTED = 'Interested',
	SAVE_TO_DRAFTS = 'SaveToDrafts',
}
