/* Wrapper button with text styling */

import React, {
	ButtonHTMLAttributes,
	DetailedHTMLProps,
	PropsWithChildren,
} from 'react';

export default function TextButton(
	props: DetailedHTMLProps<
		ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> &
		PropsWithChildren<{}>
) {
	return (
		<button
			{...props}
			className={
				'bg-transparent hover:text-accent-200 px-2 mx-2 ' +
				(props.className ?? '')
			}></button>
	);
}
