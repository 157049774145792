import { useEffect, useContext, useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import StickyActionsBar from '../utilities/StickyActionsBar';
import { useForm, useWatch } from 'react-hook-form';
import { UserContext } from '../../contexts/UserContext';
import {
	BuyerProfilePublicDto,
	ProjectDto,
	ProjectPlan,
	ProjectService,
	ProjectState,
	ProjectSummaryDto,
	StoredFileDto,
	TagDto,
	TeamSize,
	UpsertProjectCommand,
	UserService,
	WorkLocation,
} from '../../api';
import useApi from '../../services/useApi';
import { useDebounce } from 'usehooks-ts';
import UserSideNav from '../utilities/UserSideNav';
import BuyerCardViewOnly from '../utilities/BuyerCardViewOnly';
import { StateIndicator } from '../utilities/StateIndicator';
import {
	DatePickerInput,
	RadioGroupInput,
	TagInput,
	TextFieldInput,
} from '../utilities/Input';
import { Grid, Stack } from '@mui/material';
import {
	greaterThanZero,
	setValueAsUserInputOptions,
} from '../../services/helper';
import Attachments from '../utilities/Attachments';
import { ActionDialog, ProjectDialog, StateType } from '../../Constants/Dialog';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const defaultValues: Omit<
	ProjectDto,
	'id' | 'state' | 'ownerBuyerProfileId' | 'updatedAt'
> = {
	name: undefined!,
	description: undefined!,
	skills: [] as TagDto[],
	attachments: [] as StoredFileDto[],
	budgetPlan: ProjectPlan.UNDECIDED,
	timePlan: ProjectPlan.UNDECIDED,
	workLocation: WorkLocation.UNDECIDED,
	teamSize: TeamSize.UNDECIDED,
};

/* Radio Button Options*/
const budgetOptions = [
	{ value: ProjectPlan.UNDECIDED, label: 'Not sure yet' },
	{ value: ProjectPlan.FLEXIBLE, label: 'The budget is negotiable ' },
	{ value: ProjectPlan.SPECIFIED, label: 'I have a specific budget in mind' },
];

const timeframeOptions = [
	{ value: ProjectPlan.UNDECIDED, label: 'Not sure yet' },
	{ value: ProjectPlan.FLEXIBLE, label: 'I have a general range in mind' },
	{ value: ProjectPlan.SPECIFIED, label: 'I have a specific range in mind' },
];

const worklocationOptions = [
	{ value: WorkLocation.UNDECIDED, label: 'Not sure yet' },
	{ value: WorkLocation.ONSITE, label: 'Onsite' },
	{ value: WorkLocation.REMOTE, label: 'Remote' },
	{ value: WorkLocation.HYBRID, label: 'Hybrid' },
];

const teamOptions = [
	{ value: TeamSize.UNDECIDED, label: 'Not sure yet' },
	{ value: TeamSize.SMALL, label: 'Small (2-3)' },
	{ value: TeamSize.MEDIUM, label: 'Medium (4-7)' },
	{ value: TeamSize.LARGE, label: 'Large (8+)' },
];

const Project = (props: any) => {
	const { id } = props;
	const { user, isLoading: isUserLoading } = useContext(UserContext);
	const { handleSubmit, watch, setValue, getValues, control, reset, register } =
		useForm({
			mode: 'onBlur',
			defaultValues: defaultValues,
			shouldFocusError: false,
		});
	const [isEditing, setIsEditing] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isCreatingProject, setIsCreatingProject] = useState(false);
	const [isProjectOwner, setIsProjectOwner] = useState(true);
	const [projectOwner, setProjectOwner] =
		useState<BuyerProfilePublicDto | null>(null);
	const [updatedAt, setUpdatedAt] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const { callApi } = useApi();

	/* Form Values */
	const watchAll = useDebounce(
		useWatch({ control, disabled: isLoading || !isEditing }),
		600
	);

	const [state, setState] = useState<ProjectState>();
	const navigateTo = useNavigate();

	useEffect(() => {
		const loadProjectData = async () => {
			const response = await callApi(ProjectService.getProject(id));
			if (response.data) {
				const project = response.data;
				reset(project);
				setState(project.state);
				setProjectOwner(project.ownerBuyerProfile ?? null);
				if (project.ownerBuyerProfileId !== user!.buyerProfileId) {
					setIsProjectOwner(false);
				}
				setUpdatedAt(project.updatedAt);
				setIsEditing(false);
			} else if (!response?.error) {
				// No errors and no data means the user is trying to create a new project
				setIsEditing(true);
				setIsCreatingProject(true);
				setIsProjectOwner(true);
			}
			setIsLoading(false);
		};

		if (id && !isUserLoading && user) {
			setIsLoading(true);
			loadProjectData();
		}
	}, [id, user, isUserLoading, callApi, reset]);

	useEffect(() => {
		const pullBuyerProfile = async () => {
			const response = await callApi(
				UserService.getFullPublicBuyerProfile(user!.buyerProfileId!)
			);
			if (response.data) {
				setProjectOwner(response.data);
			}
		};

		if (user && isCreatingProject) {
			pullBuyerProfile();
		}
	}, [user, isCreatingProject, callApi, setValue]);

	const save = useCallback(
		async (
			data: Omit<
				ProjectDto,
				'id' | 'state' | 'ownerBuyerProfileId' | 'updatedAt'
			>
		) => {
			setIsSaving(true);
			const upsertCommand: UpsertProjectCommand = {
				...data,
				id,
				ownerBuyerProfileId: user!.buyerProfileId!,
			};
			// TODO: Add error handling if talent is trying to create a project
			const response = await callApi(
				ProjectService.upsertProject(upsertCommand)
			);
			if (response.data && !state) {
				setState(ProjectState.DRAFT);
			}
			setIsSaving(false);
		},
		[callApi, id, state, user]
	);

	useEffect(() => {
		if (isEditing && !isLoading) {
			handleSubmit(save)();
		}
	}, [watchAll, handleSubmit, save, isEditing, isLoading]);

	const handleStateChange = async (state: ProjectState, dialog: string) => {
		// TODO: Change to a modal
		if (window.confirm(dialog) === true) {
			const response = await callApi(
				ProjectService.updateProjectState({
					id: id!,
					state: state,
				})
			);
			if (response.data) {
				setIsEditing(false);
				setState(state);
			}
		}
	};

	const projectSummaryDtoForBids: ProjectSummaryDto = {
		...getValues(),
		id,
		state: state!,
		ownerBuyerProfileId: projectOwner?.id!,
		ownerBuyerProfile: projectOwner,
		updatedAt: updatedAt!,
	};

	return (
		<UserSideNav>
			<StateIndicator
				show={true}
				displayState={isSaving ? 'Saving' : state ?? 'Unsaved'}
				stateType={StateType.Project}
				isBuyer={isProjectOwner}
			/>
			<Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2 }}>
				<Grid
					container
					spacing={{ xs: 2 }}
					columns={{ xs: 4, sm: 8, md: 12 }}
					className="mb-4">
					<Grid item xs={12}>
						<TextFieldInput
							name="name"
							label="Project Name"
							control={control}
							rules={{
								minLength: {
									value: 3,
									message: 'Must be at least 3 characters',
								},
							}}
							readOnly={!isEditing}
							required
							fullWidth
						/>
						<TextFieldInput
							name="description"
							control={control}
							label="Description"
							rules={{
								minLength: {
									value: 3,
									message: 'Must be at least 3 characters',
								},
							}}
							readOnly={!isEditing}
							multiline
							maxRows={4}
							fullWidth
							required
						/>
					</Grid>
					<Grid item xs={6}>
						<RadioGroupInput
							name="budgetPlan"
							control={control}
							label="What's your budget plan?"
							readOnly={!isEditing}
							onChange={() =>
								setValue('budget', null, setValueAsUserInputOptions)
							}
							options={budgetOptions}
							required
						/>
					</Grid>

					<Grid item xs={6}>
						{watch('budgetPlan') === 'Specified' && (
							<TextFieldInput
								name={'budget'}
								label={'Budget'}
								rules={{ validate: { greaterThanZero } }}
								control={control}
								prefix="$"
								type="number"
								min="1"
								step="1"
								readOnly={!isEditing}
								required
							/>
						)}
					</Grid>
					<Grid item xs={6}>
						<RadioGroupInput
							name="timePlan"
							control={control}
							label="Expected Time Frame?"
							readOnly={!isEditing}
							onChange={() => {
								setValue('startDate', null, setValueAsUserInputOptions);
								setValue('endDate', null, setValueAsUserInputOptions);
							}}
							options={timeframeOptions}
							required
						/>
					</Grid>
					<Grid item xs={6}>
						{watch('timePlan') === 'Specified' ||
						watch('timePlan') === 'Flexible' ? (
							<Stack direction="row" spacing={{ xs: 2 }}>
								<DatePickerInput
									label="Start Date"
									name="startDate"
									rules={{
										deps: 'endDate',
										validate: (value) =>
											!!value ||
											!!watch('endDate') ||
											'Start Date is required if End Date is not provided',
									}}
									control={control}
									maxDate={watch('endDate')}
									readOnly={!isEditing}
								/>
								<DatePickerInput
									label="End Date"
									name="endDate"
									rules={{
										deps: 'startDate',
										validate: (value) =>
											!!value ||
											!!watch('startDate') ||
											'End Date is required if Start Date is not provided',
									}}
									control={control}
									minDate={watch('startDate')}
									readOnly={!isEditing}
								/>
							</Stack>
						) : null}
					</Grid>
					<Grid item xs={12}>
						<RadioGroupInput
							name="workLocation"
							control={control}
							label="Where is the work location?"
							readOnly={!isEditing}
							options={worklocationOptions}
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<RadioGroupInput
							name="teamSize"
							control={control}
							label="What size team are you looking for?"
							readOnly={!isEditing}
							options={teamOptions}
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<TagInput
							name="skills"
							control={control}
							label="Desired Skills"
							readOnly={!isEditing}
						/>
					</Grid>
				</Grid>
				<BuyerCardViewOnly
					isLoading={isLoading}
					buyerProfile={{
						...projectOwner,
						id: projectOwner?.id ?? '',
						userId: projectOwner?.userId ?? '',
						firstName: projectOwner?.firstName ?? '',
						lastName: projectOwner?.lastName ?? '',
						profilePicUrl: projectOwner?.profilePicUrl,
						organization: projectOwner?.organization,
						locationState: projectOwner?.locationState,
					}}
				/>
			</Stack>
			<div className="pt-4"></div>
			<Attachments
				control={control}
				register={register}
				isEditing={isEditing}
			/>
			<div className="pt-4"></div>
			<StickyActionsBar>
				{isProjectOwner && state === ProjectState.DRAFT && (
					<Button
						variant="contained"
						onClick={() =>
							handleStateChange(ProjectState.REMOVED, ActionDialog.DeleteDraft)
						}>
						Delete Draft
					</Button>
				)}
				{isProjectOwner && state === ProjectState.BIDDING && (
					<Button
						variant="contained"
						onClick={() =>
							handleStateChange(
								ProjectState.REMOVED,
								'Are you sure you want to delete this project? Any pending bids will be rejected.'
							)
						}>
						Delete
					</Button>
				)}
				{isProjectOwner &&
					state &&
					state !== ProjectState.REMOVED &&
					state !== ProjectState.ENDED &&
					state !== ProjectState.COMPLETED && (
						<Button
							variant="contained"
							onClick={() => setIsEditing(!isEditing)}>
							{isEditing ? 'Preview' : 'Edit'}
						</Button>
					)}
				{isProjectOwner && state === ProjectState.DRAFT && (
					<Button
						variant="contained"
						onClick={() =>
							handleStateChange(ProjectState.BIDDING, ProjectDialog.Publish)
						}>
						Publish
					</Button>
				)}
				{isProjectOwner &&
					state === ProjectState.ACTIVE && [
						<Button
							variant="contained"
							onClick={() =>
								handleStateChange(
									ProjectState.COMPLETED,
									ProjectDialog.Complete
								)
							}>
							Complete
						</Button>,
						<Button
							variant="contained"
							onClick={() =>
								handleStateChange(ProjectState.ENDED, ProjectDialog.End)
							}>
							End
						</Button>,
					]}
				{user?.talentProfileId && state === ProjectState.BIDDING && (
					<Button
						variant="contained"
						onClick={() =>
							navigateTo(`/contract/${uuidv4()}`, {
								state: { defaultProject: projectSummaryDtoForBids },
							})
						}>
						Create Bid
					</Button>
				)}
			</StickyActionsBar>
		</UserSideNav>
	);
};

export default Project;
