import React, { useContext, useState } from 'react';
import { TalentExperienceDto } from '../../api';
import { v4 as uuidv4 } from 'uuid';
import { UserContext } from '../../contexts/UserContext';
import { Box, Button, Typography, Paper } from '@mui/material';
import ExperienceModal from './ExperienceModal';
import UrlFavIcon from '../utilities/UrlFavIcon';
import { FaPlus } from 'react-icons/fa';

type ExperienceListProps = {
	experiences: TalentExperienceDto[] | null;
	onSave: (experience: TalentExperienceDto) => void;
	onDelete: (experience: TalentExperienceDto) => void;
	isEditing?: boolean;
};

const ExperienceList: React.FC<ExperienceListProps> = ({
	experiences,
	onSave,
	onDelete,
	isEditing,
}) => {
	const { user: currentUser } = useContext(UserContext);

	const [showModal, setShowModal] = useState(false);
	const [experienceToEdit, setEditingExperience] =
		useState<TalentExperienceDto | null>(null);

	const handleEdit = (experience: TalentExperienceDto | null) => {
		setEditingExperience(experience);
		setShowModal(true);
	};

	const handleSave = (experience: TalentExperienceDto) => {
		onSave(experience);
		setShowModal(false);
	};

	const handleDelete = (experience: TalentExperienceDto) => {
		experience.deleted = true;
		onDelete(experience);
	};

	return (
		<Paper className="p-4">
			<div>
				{experiences
					?.filter((experience) => !experience.deleted)
					.map((experience) => (
						<Box key={experience.id} mb={2}>
							<Typography variant="h6">{experience.title}</Typography>
							<Typography variant="subtitle1">
								<UrlFavIcon
									className="inline-flex align-middle"
									sx={{ width: 24, height: 24 }}
									url={experience.organization?.website}
									name={experience.organization?.name}
								/>{' '}
								{experience.organization?.name}
							</Typography>
							{experience.startDate || experience.endDate ? (
								<Typography variant="body2">
									{experience.startDate} - {experience.endDate || 'Present'}
								</Typography>
							) : null}
							<Typography variant="body2"> {experience.description}</Typography>
							{isEditing && (
								<Box>
									<Button
										className="text-nKipo"
										size="small"
										onClick={() => handleEdit(experience)}>
										Edit
									</Button>
									<Button
										className="text-nKipo"
										size="small"
										onClick={() => handleDelete(experience)}>
										Delete
									</Button>
								</Box>
							)}
						</Box>
					)) ?? null}
			</div>
			{isEditing && (
				<Box mt={2} textAlign="center">
					<Button variant="outlined" onClick={() => handleEdit(null)}>
						<FaPlus className="mr-2" />
						Add Experience
					</Button>
				</Box>
			)}
			{!showModal ? null : (
				<ExperienceModal
					id={experienceToEdit?.id ?? uuidv4()}
					talentProfileId={currentUser?.talentProfileId ?? ''}
					experience={experienceToEdit}
					visible={showModal}
					onSave={handleSave}
					onCancel={() => setShowModal(false)}
				/>
			)}
		</Paper>
	);
};

ExperienceList.defaultProps = {
	isEditing: false,
};

export default ExperienceList;
