/* Wrapper button with default styling */

import React, {
	ButtonHTMLAttributes,
	DetailedHTMLProps,
	PropsWithChildren,
} from 'react';
import SplitButton from './SplitButton';

export default function Button(
	props: DetailedHTMLProps<
		ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> &
		PropsWithChildren<{}>
) {
	const { children, onClick, ...rest } = props;
	return (
		<SplitButton
			className="mx-2"
			options={[
				{
					label: children,
					// This should be a safe cast because in the case that there is no other options the onClick should always be coming from a button
					handler: (event) => {
						onClick?.(event as React.MouseEvent<HTMLButtonElement, MouseEvent>);
					},
				},
			]}
			{...rest}></SplitButton>
	);
}
