import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	BsArrowBarRight,
	BsChevronDown,
	BsChevronUp,
	BsFillChatSquareQuoteFill,
} from 'react-icons/bs';
import { FaEdit } from 'react-icons/fa';
import { ChatParticipantDto, ChatService } from '../../api';
import ChatContext, { ChatStatus } from '../../contexts/ChatContext';
import { Input, MultiSelectLookup } from '../utilities/FloatingLabelInput';
import { UserContext } from '../../contexts/UserContext';
import Conversation from './Conversation';
import { Badge, ListItem } from '@mui/material';
import { isNumber } from '@mui/x-data-grid/internals';

const Chat = (props: any) => {
	const { isChatInFullscreen, activeConvoSid } = props;
	const { register } = useForm();
	const { user } = useContext(UserContext);
	const {
		createConversation,
		refreshToken,
		conversations,
		unreadMessageCounts,
		updateMessagesReadForConversation,
		activeConversationSid,
		changeActiveConversation,
		chatState,
		isChatActive,
		setIsChatActive,
		startConversation,
	} = useContext(ChatContext);
	const [participants, setParticipants] = useState([] as ChatParticipantDto[]);
	const [hasHadConversationSet, setHasHadConversationSet] = useState(false);

	useEffect(() => {
		if (isChatInFullscreen) {
			setIsChatActive(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (
			isChatInFullscreen &&
			chatState.conversationsReady &&
			!hasHadConversationSet
		) {
			if (activeConversationSid === activeConvoSid) {
				setHasHadConversationSet(true);
			} else {
				changeActiveConversation(activeConvoSid);
			}
		}
	}, [
		activeConversationSid,
		activeConvoSid,
		changeActiveConversation,
		chatState,
		hasHadConversationSet,
		isChatInFullscreen,
	]);

	const onUserFocus = useCallback(
		async (conversation: any) => {
			await conversation.setAllMessagesRead();
			await updateMessagesReadForConversation(conversation);
		},
		[updateMessagesReadForConversation]
	);

	const startConversationWithoutParticipants = useCallback(
		(event: any) => {
			startConversation();
			setParticipants([]);
		},
		[startConversation]
	);

	const titleBar = useCallback(() => {
		const maximized = isChatActive && chatState.conversationsReady;

		const toggleChat = () => {
			if (
				[ChatStatus.Disconnected, ChatStatus.Denied].includes(chatState.status)
			) {
				refreshToken();
			}
			setIsChatActive(!maximized);
		};

		let totalUnreadMessages = 0;
		unreadMessageCounts.forEach((v) => (totalUnreadMessages += v ? v : 0));
		const chatStatus = chatState.status;
		const chatIndicatorToDisplay = chatState.conversationsReady
			? totalUnreadMessages
			: chatStatus;
		const miniStatusIndicators: {
			[K in ChatStatus]: {
				color:
					| 'error'
					| 'warning'
					| 'success'
					| 'primary'
					| 'secondary'
					| 'default'
					| 'info';
				value: any;
			};
		} = {
			[ChatStatus.Disconnected]: { color: 'error', value: '!' },
			[ChatStatus.Denied]: { color: 'error', value: '!' },
			[ChatStatus.Connecting]: { color: 'warning', value: '' },
			[ChatStatus.Connected]: { color: 'success', value: '✓' },
			[ChatStatus.Disconnecting]: { color: 'warning', value: '' },
		};
		const miniChatIndicatorToDisplay = isNumber(chatIndicatorToDisplay)
			? { color: 'primary', value: chatIndicatorToDisplay }
			: miniStatusIndicators[chatIndicatorToDisplay];

		const styling =
			'h-10 bg-gray-200 dark:bg-gray-700 p-2 inline-flex cursor-pointer border-gray-300 dark:border-gray-600 ' +
			(maximized
				? 'w-full border-t-2 border-x-2'
				: 'fixed border-2 bottom-1 right-1 w-10 md:w-72 z-40');
		const ExpandCollapseButton = maximized ? BsChevronDown : BsChevronUp;
		return (
			<div className={styling}>
				<div
					className="w-8 h-full md:mr-2 place-self-center inline-flex"
					onClick={toggleChat}>
					<Badge
						badgeContent={miniChatIndicatorToDisplay.value}
						color={miniChatIndicatorToDisplay.color as unknown as any}
						// anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						overlap="circular"
						className="w-8 h-full md:mr-2 place-self-center inline-flex"
						sx={{
							'& .MuiBadge-badge': {
								transform: 'scale(0.66) translate(-15%, -66%)',
							},
						}}>
						<BsFillChatSquareQuoteFill className="w-8 h-full mr-2.5 place-self-center text-gray-500 dark:text-gray-400" />
					</Badge>
				</div>
				<div
					className={`${
						maximized ? 'flex' : 'hidden'
					} md:flex grow justify-center place-items-center text-gray-500 dark:text-gray-400 `}
					onClick={toggleChat}>
					Chat ({chatIndicatorToDisplay})
				</div>
				<FaEdit
					className={`${
						maximized ? '' : 'hidden'
					} md:[display:inherit] w-6 h-full ml-2 place-self-center text-gray-500 dark:text-gray-400 hover:text-nKipo dark:hover:text-nKipo `}
					onClick={startConversationWithoutParticipants}
				/>
				<ExpandCollapseButton
					className={`${
						maximized ? '' : 'hidden'
					} md:[display:inherit] w-8 h-full ml-2 place-self-center text-gray-500 dark:text-gray-400 hover:text-nKipo dark:hover:text-nKipo `}
					onClick={toggleChat}
				/>
			</div>
		);
	}, [
		chatState.conversationsReady,
		chatState.status,
		isChatActive,
		refreshToken,
		setIsChatActive,
		startConversationWithoutParticipants,
		unreadMessageCounts,
	]);

	const onParticipantSelection = (
		event: any,
		participantSelection: ChatParticipantDto | null | undefined
	) => {
		if (participantSelection) {
			setParticipants((currentParticipants) => [
				...currentParticipants,
				participantSelection,
			]);
		}
	};

	const onRemoveParticipant = (
		event: any,
		participantToRemove: ChatParticipantDto
	) => {
		console.log(event, participantToRemove);
		setParticipants((currentParticipants) =>
			currentParticipants.filter((cp: any) => cp.id !== participantToRemove.id)
		);
	};

	if (!user || !(user.talentProfileId || user.buyerProfileId)) {
		return null;
	}

	return isChatActive && chatState.conversationsReady ? (
		<div
			className={`w-full ${
				isChatInFullscreen
					? 'relative h-full'
					: 'fixed bottom-11 right-1 sm:w-1/2 md:w-1/3 h-1/3'
			} z-40 flex flex-wrap`}>
			{titleBar()}
			<div className="flex flex-col basis-full grow h-full max-h-[85dvh] min-h-[7rem]">
				<div className="flex basis-full grow h-full">
					<div className="basis-2/3 grow border-l-4 border-y-4 dark:border-gray-700 bg-white dark:bg-gray-900">
						{activeConversationSid ? (
							<Conversation
								conversationProxy={conversations.get(activeConversationSid)}
								myIdentity={user!.id}
								messageInput={(additionalProps: any) => (
									<Input
										{...register('newMessage')}
										placeholder="Your Message"
										{...additionalProps}
										suffix={
											<BsArrowBarRight className="h-6 w-6 cursor-pointer" />
										}
										className="mb-0 dark:border-gray-600 border-t-2"
									/>
								)}
								onUserFocus={onUserFocus}
							/>
						) : (
							<div className="flex flex-wrap">
								<div className="basis-3/4 grow">
									<MultiSelectLookup
										name="chatParticipants"
										searchService={ChatService.searchChatParticipants}
										placeholder="Add participants"
										optionMapper={(o: ChatParticipantDto | null | undefined) =>
											o ? `${o.name} (${o.type})` : ''
										}
										isEditing={true}
										onChange={onParticipantSelection}
										onRemove={onRemoveParticipant}
										values={participants}
										className="my-2 border-b-2 border-gray-300 dark:border-gray-600 ml-2"
										lookupClassName="px-2"
									/>
								</div>
								<div className="w-fit p-2 flex">
									<BsArrowBarRight
										onClick={() => createConversation(participants)}
										className="place-self-center h-6 w-6 cursor-pointer text-gray-400 hover:text-nKipo"
									/>
								</div>
							</div>
						)}
					</div>
					<div className="basis-1/3 md:w-72 border-4 dark:border-gray-700 bg-white dark:bg-gray-900 overflow-auto">
						{Array.from(conversations, ([conversationId, conversation]) => (
							<ListItem
								key={conversation.sid}
								onClick={() => changeActiveConversation(conversation)}
								className="border-b dark:border-gray-700 cursor-pointer">
								{conversation.channelState.friendlyName}
								<Badge
									badgeContent={unreadMessageCounts.get(conversationId) ?? 0}
									color="primary"
									sx={{
										'& .MuiBadge-badge': {
											transform: 'scale(0.8) translate(100%, -50%)',
										},
									}}
									className="mr-1.5 ml-auto"
								/>
							</ListItem>
						))}
						{/*
					// Consider special casing these types of chats in the future somehow:
					// DM
					// Groups
					// Teams
					// Groups of Teams
					// Buyer + Team
					// etc.
					*/}
					</div>
				</div>
			</div>
		</div>
	) : (
		titleBar()
	);
};

export default Chat;
