/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateBasicTagDto } from '../models/CreateBasicTagDto';
import type { TagDto } from '../models/TagDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TagService {

	/**
	 * Creates a new basic tag if it doesn't already exist
	 * Creates a new basic tag as long as the given ID and Name do not already exist.
	 * @param requestBody
	 * @returns TagDto Success
	 * @throws ApiError
	 */
	public static createBasicTag(
		requestBody: CreateBasicTagDto,
	): CancelablePromise<TagDto> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/Tag/BasicTag',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Searches basic tags by name matching the given query.
	 * Returns any basic tags matching the given query.
	 * @param query Applied against Name field
	 * @returns TagDto Success
	 * @throws ApiError
	 */
	public static searchBasicTags(
		query?: string,
	): CancelablePromise<Array<TagDto>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Tag/BasicTag',
			query: {
				'Query': query,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

}
