import React, { useContext, useEffect, useState } from 'react';
import { ThemeController } from '../../contexts/ThemeContext';
import { UserContext } from '../../contexts/UserContext';
import Button from '../utilities/Button';
import { useNavigate } from 'react-router-dom';
import useApi from '../../services/useApi';
import { UserService } from '../../api';
import { v4 as uuidv4 } from 'uuid';
import UserSetup from '../../onboarding/UserSetup';
import FullPageCard from '../utilities/FullPageCard';
import ChatContext from '../../contexts/ChatContext';

const UserAccount = () => {
	const { callApi } = useApi();
	const { user, isLoading, setSessionStorageUserData } =
		useContext(UserContext);
	const { refreshToken } = useContext(ChatContext);
	const navigateTo = useNavigate();
	const [newBuyerProfileId, setNewBuyerProfileId] = useState<string | null>();
	const [newTalentProfileId, setNewTalentProfileId] = useState<string | null>();

	useEffect(() => {
		if (!isLoading) {
			setNewBuyerProfileId(user?.buyerProfileId ?? uuidv4());
			setNewTalentProfileId(user?.talentProfileId ?? uuidv4());
		}
	}, [isLoading, user?.buyerProfileId, user?.talentProfileId]);

	const AccountType = () => {
		const isBuyer = user!.buyerProfileId;
		const isTalent = user!.talentProfileId;

		if (isBuyer && isTalent) {
			return <span>Talent/Buyer</span>;
		} else if (isBuyer) {
			return <span>Buyer</span>;
		} else if (isTalent) {
			return <span>Talent</span>;
		}
		return null;
	};

	const createBuyerProfile = async () => {
		const response = await callApi(
			UserService.upsertBuyerProfile({
				id: newBuyerProfileId!,
				userId: user!.id,
			})
		);
		if (response.data) {
			setSessionStorageUserData((currentUserData) => ({
				...currentUserData!,
				buyerProfileId: response.data,
			}));
			navigateTo(`/buyer-profile/${response.data}`, {
				state: { isEditing: true },
			});
		} else {
			console.error(response);
		}
	};
	const createTalentProfile = async () => {
		const response = await callApi(
			UserService.upsertTalentProfile(newTalentProfileId!, {
				id: newTalentProfileId!,
				userId: user!.id,
				primaryDescriptors: [],
			})
		);
		if (response.data) {
			setSessionStorageUserData((currentUserData) => ({
				...currentUserData!,
				talentProfileId: response.data,
			}));
			navigateTo(`/talent-profile/${response.data}`, {
				state: { isEditing: true },
			});
		} else {
			console.error(response);
		}
	};

	const BuyerSignUp = () => (
		<React.Fragment>
			Looking to hire too?
			<Button onClick={createBuyerProfile}>Create a Buyer Profile!</Button>
		</React.Fragment>
	);
	const TalentSignUp = () => (
		<React.Fragment>
			Looking to form a team?
			<Button onClick={createTalentProfile}>Create a Talent Profile!</Button>
		</React.Fragment>
	);

	if (isLoading) {
		return null;
	}

	return (
		<FullPageCard className="w-full md:w-1/2 place-self-center items-center">
			<UserSetup
				advanced={true}
				message={<h2 className="basis-full flex-0">User Info</h2>}
				includeReferrer={false}
			/>
			<h2 className="mt-8">Account Info</h2>
			<div className="flex flex-row justify-center justify-between">
				<div className="px-4 py-2 content-center"> Account Type: </div>
				<div className="px-4 py-2 content-center">
					<AccountType />
				</div>
			</div>
			{!user!.buyerProfileId && (
				<div className="flex flex-row justify-center">
					<BuyerSignUp />
				</div>
			)}
			{!user!.talentProfileId && (
				<div className="flex flex-row justify-center">
					<TalentSignUp />
				</div>
			)}
			<h2 className="mt-8">User Preferences</h2>
			<div className="flex flex-row justify-center justify-between">
				<div className="px-4 py-2 content-center"> Theme: </div>
				<ThemeController />
			</div>
			{Notification.permission !== 'granted' ? (
				<div className="flex flex-row justify-center justify-between">
					<div className="px-4 py-2 content-center"> Chat Notifications: </div>
					<Button
						onClick={() => {
							Notification.requestPermission().then((permission) => {
								if (permission === 'granted') {
									console.log('Notification permission granted.');
									refreshToken();
								}
							});
						}}>
						Enable
					</Button>
				</div>
			) : null}
		</FullPageCard>
	);
};

export default UserAccount;
