const defaultTheme = require('tailwindcss/defaultTheme');
const colors = require('tailwindcss/colors');

module.exports = {
	// Skips preflight, custom one is added in index.css to avoid invisible background buttons for MUI
	corePlugins: {
		preflight: false,
	},
	darkMode: 'class',
	content: ['./src/**/*.{js,jsx,ts,tsx}'],
	theme: {
		extend: {
			keyframes: (theme) => ({
				alert: {
					'0%, 10%': {
						transform: 'scale(1.05)',
						opacity: '100',
						backgroundColor: theme('colors.red.700'),
					},
				},
			}),
		},
		colors: {
			transparent: 'transparent',
			current: 'currentColor',
			black: colors.black,
			white: colors.white,
			gray: colors.zinc,
			red: colors.red,
			orange: colors.amber,
			darkBlue: '#1471B4',
			lightBlue: '#27B8E8',
			nKipo: '#E45A1F',
			primary: {
				default: '#FDD259',
				50: '#FEE9AE',
				100: '#FEE39A',
				200: '#FEDE86',
				300: '#FED872',
				400: '#FDD259',
				500: '#FDC835',
				600: '#FDBD0D',
				700: '#D19A03',
				800: '#A47426',
				900: '#6C4C19',
			},
			accent: {
				default: '#F77604',
				50: '#FDA85E',
				100: '#FC9D4A',
				200: '#FC9236',
				300: '#FC8822',
				400: '#F77604',
				500: '#DD6903',
				600: '#B55603',
				700: '#8D4302',
				800: '#643002',
				900: '#3C1D01',
			},
			neutral: {
				default: '#090619',
				50: '#fcfcfc',
				100: '#f8f7f7',
				200: '#ebeaeb',
				300: '#dfdddf',
				400: '#c7c2c6',
				500: '#a7a0a6',
				600: '#907f8e',
				700: '#766074',
				800: '#B8D20B',
				900: '#1b0e19',
			},
		},
		fontFamily: {
			headline: ['Josefin Sans', ...defaultTheme.fontFamily.sans],
			body: ['Open Sans', ...defaultTheme.fontFamily.sans],
		},
		container: true /* Automatically center container */,
	},
	plugins: [],
};
