/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ExperienceType {
	EMPLOYMENT = 'Employment',
	INTERNSHIP = 'Internship',
	VOLUNTEER = 'Volunteer',
	HACKATHON = 'Hackathon',
	RESEARCH = 'Research',
	FREELANCE = 'Freelance',
	WORKSHOP = 'Workshop',
	CONFERENCE = 'Conference',
	AWARD = 'Award',
	PUBLICATION = 'Publication',
	PATENT = 'Patent',
	OTHER = 'Other',
}
