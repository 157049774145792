import React, { useCallback, useEffect, useState } from 'react';
import LineChart from '../utilities/Revenue/LineChart';
import { MonthsAbrv } from '../../Constants/Months';
import { useAlert } from 'react-alert';
import { useParams } from 'react-router-dom';
import {
	PaymentService,
	TalentPaymentState,
	TalentPaymentSummaryDto,
	TalentProfileDto,
} from '../../api';
import useApi from '../../services/useApi';
import { Button, CircularProgress } from '@mui/material';

interface RevenueProps {
	isLoading?: boolean;
	talentProfile?: TalentProfileDto;
}

const Revenue = (props: RevenueProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const alert = useAlert();
	const { redirect } = useParams();
	const { callApi } = useApi();

	const onboardingRefreshRedirect = 'onboardRefresh';
	const onboardingReturnRedirect = 'onboardReturn';

	const paymentOnboardingOnClick = useCallback(async () => {
		// Strip any previous redirect parameters
		const baseUrl = window.location.href
			.replace('/' + onboardingRefreshRedirect, '')
			.replace('/' + onboardingReturnRedirect, '');
		const refreshUrl = baseUrl + '/' + onboardingRefreshRedirect;
		const returnUrl = baseUrl + '/' + onboardingReturnRedirect;

		setIsLoading(true);
		const response = await callApi(
			PaymentService.initiateTalentOnboarding({
				refreshUrl,
				returnUrl,
			})
		);

		setIsLoading(false);
		if (response.data) {
			// Redirect to Stripe onboarding
			window.location.replace(response.data);
		}
	}, [callApi]);

	useEffect(() => {
		if (redirect === onboardingRefreshRedirect) {
			// Session expired or user tried to refresh during onboarding, create a new link and redirect
			paymentOnboardingOnClick();
		} else if (redirect === onboardingReturnRedirect) {
			alert.success('Stripe Onboarding has been completed.');
		}
	}, [alert, redirect, paymentOnboardingOnClick]);

	const stripeLoginOnClick = async () => {
		setIsLoading(true);
		const response = await callApi(PaymentService.stripeLogin());
		setIsLoading(false);
		if (response.data) {
			// Open new tab to Stripe login
			window.open(response.data);
		}
	};

	const getRevenue = () => {
		const completePayments = props.talentProfile?.payments?.filter(
			(x: TalentPaymentSummaryDto) => x.state === TalentPaymentState.COMPLETE
		);
		const monthlyPayments = new Array(12).fill(0);
		completePayments?.forEach((payment: any) => {
			monthlyPayments[new Date(payment.updatedAt).getMonth()] += payment.amount;
		});
		return {
			labels: MonthsAbrv,
			datasets: [
				{
					label: props.talentProfile
						? 'Monthly Revenue for ' +
						  props.talentProfile.firstName +
						  ' ' +
						  props.talentProfile.lastName
						: 'Monthly Revenue',
					fill: false,
					lineTension: 0.5,
					backgroundColor: 'rgba(75,192,192,1)',
					borderColor: 'rgba(0,0,0,1)',
					borderWidth: 2,
					data: monthlyPayments,
				},
			],
		};
	};

	const getPendingPayment = (): string => {
		const pendingPayments = props.talentProfile?.payments?.filter(
			(x: TalentPaymentSummaryDto) => x.state === TalentPaymentState.PENDING
		);
		const paymentSum = pendingPayments?.reduce(
			(sum: any, payment: any) => sum + payment.amount,
			0
		);
		const currencyFormatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
		});
		return paymentSum ? currencyFormatter.format(paymentSum) : 'None';
	};

	if (isLoading || props.isLoading) {
		return <CircularProgress />;
	}
	return (
		<div>
			{!props?.talentProfile?.isStripeOnboardingComplete ? (
				<div>
					<p>
						We use Stripe to make sure you get paid on time and to keep your
						personal bank and details secure.
					</p>
					<p>
						Please setup Stripe in order to receive future payments and receive
						any currently failed payments.
					</p>
					<div className="py-5">
						<Button variant="contained" onClick={paymentOnboardingOnClick}>
							Setup Stripe
						</Button>
					</div>
				</div>
			) : (
				<div>
					<div className="py-5">
						<LineChart data={getRevenue()} />
						<div className="py-5">
							<h3>Total Pending Payment: {getPendingPayment()}</h3>
						</div>
						<Button variant="contained" onClick={stripeLoginOnClick}>
							Login to Stripe
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};

export default Revenue;
