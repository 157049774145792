/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StoredFileCategory } from '../models/StoredFileCategory';
import type { StoredFileDto } from '../models/StoredFileDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StorageService {

	/**
	 * Uploads a file to storage
	 * @param formData
	 * @returns StoredFileDto Success
	 * @throws ApiError
	 */
	public static createFile(
		formData?: {
			Id?: string;
			Category?: StoredFileCategory;
			File?: Blob;
		},
	): CancelablePromise<StoredFileDto> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/Storage/File',
			formData: formData,
			mediaType: 'multipart/form-data',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

}
