/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ProjectState {
	DRAFT = 'Draft',
	BIDDING = 'Bidding',
	ACTIVE = 'Active',
	REMOVED = 'Removed',
	COMPLETED = 'Completed',
	ENDED = 'Ended',
}
