import React, { PropsWithChildren, useContext } from 'react';
import { User } from '@auth0/auth0-spa-js';
import Grid from '@mui/material/Unstable_Grid2';

import FullPageCard from './FullPageCard';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { AuthUser, UserContext } from '../../contexts/UserContext';
import {
	Icon,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import {
	AccountBox,
	ConnectWithoutContact,
	Dashboard,
	Diversity2,
	ContentPaste,
} from '@mui/icons-material';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import MenuContext from '../../contexts/DrawerContext';
import { MdPersonSearch } from 'react-icons/md';
import { Loading } from '../../Loading';
import { Step } from 'react-joyride';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import GroupsIcon from '@mui/icons-material/Groups';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { SearchType } from '../../api';

interface SideNavProps {
	className?: string;
	user?: User;
	nav?: NavItem[];
}

export type NavItem = {
	name: string;
	targetClass: string;
	link: string;
	searchParams?: string;
	description: string;
	icon?: any;
	accessLevel: SideAccessEnum;
	walkthroughStep?: Step;
	walkthroughMeta?: { order: number; accessLevelOverride?: SideAccessEnum };
};

export enum SideAccessEnum {
	BuyerOrTalent,
	BuyerOnly,
	TalentOnly,
	BuyerAndTalent,
}

const displayNavItem = (navItem: NavItem, user: any): boolean => {
	let display: boolean = true;
	if (!user && navItem.accessLevel !== SideAccessEnum.BuyerOrTalent)
		display = false;
	if (user) {
		if (
			!user.buyerProfileId &&
			navItem.accessLevel === SideAccessEnum.BuyerOnly
		)
			display = false;
		if (
			!user.talentProfileId &&
			navItem.accessLevel === SideAccessEnum.TalentOnly
		)
			display = false;
		if (
			!(user.talentProfileId && user.buyerProfileId) &&
			navItem.accessLevel === SideAccessEnum.BuyerAndTalent
		)
			display = false;
	}
	return display;
};

export function getDefaultNavList(user: AuthUser | null) {
	const iconClass = 'dark:fill-white font-small';
	const baseItems: NavItem[] = [];
	const allItems: NavItem[] = [
		{
			name: 'Dashboard',
			targetClass: 'nav-dashboard',
			link: '/dashboard',
			description: 'Main Dashboard',
			icon: <Dashboard className={iconClass} />,
			accessLevel: SideAccessEnum.BuyerOrTalent,
		},
		{
			name: 'My Talent Profile',
			targetClass: 'nav-talent-profile',
			link: `/talent-profile/${user?.talentProfileId}`,
			description: 'Talent Profile View',
			icon: <AccountCircleIcon className={iconClass} />,
			accessLevel: SideAccessEnum.TalentOnly,
			walkthroughStep: {
				placement: 'right',
				target: '.nav-talent-profile',
				content: (
					<div>
						Your talent profile is where you showcase your skills, experience,
						and portfolio to attract potential clients and collaborators.
						<br />
						<br />
						Make sure to complete it to stand out from the crowd.
					</div>
				),
			},
			walkthroughMeta: { order: 1 },
		},
		{
			name: 'My Buyer Profile',
			targetClass: 'nav-buyer-profile',
			link: `/buyer-profile/${user?.buyerProfileId}`,
			description: 'Buyer Profile View',
			icon: <AccountBox className={iconClass} />,
			accessLevel: SideAccessEnum.BuyerOnly,
			walkthroughStep: {
				placement: 'right',
				target: '.nav-buyer-profile',
				content:
					'Your Buyer Profile is where you can showcase a bit about yourself and/or your organization to attract the best talent for your projects.',
			},
			walkthroughMeta: { order: 1 },
		},
		{
			name: 'My Connections',
			targetClass: 'nav-connections',
			link: `/dashboard/connections/${user?.id}`,
			description: 'Manage inter-personal connections',
			icon: <ConnectWithoutContact className={iconClass} />,
			accessLevel: SideAccessEnum.BuyerOrTalent,
		},
		{
			name: 'AI Team Generator',
			targetClass: 'team-generator',
			link: `/team-generator`,
			description:
				'Get ideas on what kind of teams you can form with your connections or others on nKipo',
			icon: <ModelTrainingIcon className={iconClass} />,
			accessLevel: SideAccessEnum.TalentOnly,
			walkthroughStep: {
				placement: 'right',
				target: '.team-generator',
				content: (
					<div>
						Try nKipo's AI to generate potential teams you could form based on
						your connections and the nKipo network.
						<br />
						<br />
						Make sure to complete your talent profile and invite your contacts
						for the best results!
					</div>
				),
			},
			walkthroughMeta: { order: 2 },
		},
		{
			name: 'Teams',
			targetClass: 'nav-teams',
			link: `/teams`,
			description: 'View Teams',
			icon: <Diversity2 className={iconClass} />,
			accessLevel: SideAccessEnum.TalentOnly,
			walkthroughStep: {
				placement: 'right',
				target: '.nav-teams',
				content: (
					<div>
						Here you can create and manage your freelance teams.
						<br />
						<br />
						Teams are like mini companies that offer services and take on
						projects.
						<br />
						<br />
						If you publish a team nKipo will actively work to bring you
						projects!
					</div>
				),
			},
			walkthroughMeta: { order: 0 },
		},
		{
			name: 'Projects',
			targetClass: 'nav-projects',
			link: `/projects`,
			description: 'View projects',
			icon: <ContentPaste className={iconClass} />,
			accessLevel: SideAccessEnum.BuyerOrTalent,
			walkthroughStep: {
				placement: 'right',
				target: '.nav-projects',
				content: (
					<div>
						Here you can create and manage the details of your projects.
						<br />
						<br />
						Once you create and publish a project, teams can submit bids, and
						nKipo will help you find the perfect team to deliver on your needs!
					</div>
				),
			},
			walkthroughMeta: {
				order: 0,
				accessLevelOverride: SideAccessEnum.BuyerOnly,
			},
		},
		{
			name: 'Bids',
			targetClass: 'nav-contracts',
			link: `/contracts`,
			description: 'View bids',
			icon: <RequestQuoteIcon className={iconClass} />,
			accessLevel: SideAccessEnum.BuyerOrTalent,
		},
		{
			name: 'Payments',
			targetClass: 'nav-payments',
			link: `/payments`,
			description: 'View payments',
			icon: <CurrencyExchangeIcon className={iconClass} />,
			accessLevel: SideAccessEnum.BuyerOrTalent,
		},
		{
			name: 'Find New Teammates',
			targetClass: 'nav-search-talent',
			link: `/search/${SearchType.TALENT}`,
			description: 'Search talent profiles to find new potential teammates',
			searchParams: createSearchParams({ query: '' }).toString(),
			icon: (
				<Icon>
					<MdPersonSearch className={iconClass} />
				</Icon>
			),
			accessLevel: SideAccessEnum.TalentOnly,
		},
		{
			name: 'Find New Projects',
			targetClass: 'nav-search-project',
			link: `/search/${SearchType.PROJECT}`,
			description: 'Search projects to find work for your teams',
			searchParams: createSearchParams({ query: '' }).toString(),
			icon: (
				<Icon>
					<ContentPasteSearchIcon className={iconClass} />
				</Icon>
			),
			accessLevel: SideAccessEnum.TalentOnly,
		},
		{
			name: 'Find Teams for Hire',
			targetClass: 'nav-search-team',
			link: `/search/${SearchType.TEAM}`,
			description: 'Search for teams to partner with on your project',
			searchParams: createSearchParams({ query: '' }).toString(),
			icon: (
				<Icon>
					<GroupsIcon className={iconClass} />
				</Icon>
			),
			accessLevel: SideAccessEnum.BuyerOnly,
		},
	];

	allItems.forEach((navItem: NavItem) => {
		if (displayNavItem(navItem, user)) {
			baseItems.push(navItem);
		}
	});
	return baseItems;
}

export default function UserSideNav(props: PropsWithChildren<SideNavProps>) {
	const { user, isLoading } = useContext(UserContext);
	const { isSideNavOpen: isOpen } = useContext(MenuContext);

	const navigateTo = useNavigate();
	const buildNav = () => {
		const listItemClassName =
			'flex flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in cursor-pointer duration-200 hover:text-gray-800 dark:hover:text-white';
		const navList = (props.nav ?? defaultNav).map((listItem: NavItem) => {
			return (
				<ListItem
					disableGutters
					alignItems="flex-start"
					className={`${listItem.targetClass} ${listItemClassName}`}
					key={listItem.name}
					disablePadding>
					<ListItemButton
						onClick={() =>
							navigateTo({
								pathname: listItem.link,
								search: listItem.searchParams,
							})
						}
						sx={{
							paddingX: { xs: 0, md: 2 },
						}}>
						<ListItemIcon>{listItem.icon}</ListItemIcon>
						<ListItemText
							primary={listItem.name}
							sx={{
								display: { sm: 'none', xs: 'none', md: 'block', lg: 'block' },
							}}
						/>
					</ListItemButton>
				</ListItem>
			);
		});
		return <ul className="flex px-4 flex-col">{navList}</ul>;
	};

	if (isLoading) {
		return (
			<div className="flex flex-col my-96 items-center w-full h-full">
				<Loading />
			</div>
		);
	}

	const defaultNav = getDefaultNavList(user);

	return (
		<div className="flex flex-col flex-auto">
			{isOpen ? (
				<Grid container spacing={0.5} className="w-full flex-1">
					<Grid xs={1} sm={1} md={3} lg={2}>
						<div className="h-full flex w-full flex-row">
							<List className="flex w-full shawdow-sm flex-col bg-gray-100 dark:bg-gray-900 overflow-hidden text-black dark:text-white">
								{buildNav()}
							</List>
						</div>
					</Grid>
					<Grid
						xs={11}
						sm={11}
						md={9}
						lg={10}
						sx={{ paddingRight: 0, marginTop: 0 }}
						container>
						<FullPageCard className={'nav-content ' + (props.className ?? '')}>
							{props.children}
						</FullPageCard>
					</Grid>
				</Grid>
			) : (
				<FullPageCard className={'nav-content ' + (props.className ?? '')}>
					{props.children}
				</FullPageCard>
			)}
		</div>
	);
}
