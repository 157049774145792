import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

export default function ChooseOnboardingType() {
	const navigateTo = useNavigate();

	const onBuyerSelection = () => {
		navigateTo('/start/buyer');
	};
	const onTalentSelection = async (justConnect: boolean) => {
		navigateTo('/start/talent', { state: { justConnect: justConnect } });
	};

	const basicButtonStyle =
		'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-default bg-accent-default transition duration-150 ease-in-out hover:bg-accent-600 lg:text-xl lg:font-bold rounded text-white px-4 sm:px-10 border border-accent-700 py-2 sm:py-4 text-sm';
	return (
		<div>
			<h2 className="text-xl text-center dark:text-neutral-100 font-black leading-7 md:leading-10">
				Are you looking to start hiring or to get hired?
			</h2>
			<div className="flex justify-center items-center pt-6">
				<button
					className={basicButtonStyle}
					onClick={() => onTalentSelection(false)}>
					Get Hired
					<FiArrowRight size={20} className="inline-block" />
				</button>
				<button
					className={`ml-4 ${basicButtonStyle}`}
					onClick={onBuyerSelection}>
					Start Hiring <FiArrowRight size={20} className="inline-block" />
				</button>
				<button
					className={`ml-4 ${basicButtonStyle}`}
					onClick={() => onTalentSelection(true)}>
					Just Connect
					<FiArrowRight size={20} className="inline-block" />
				</button>
			</div>
		</div>
	);
}
