import React from 'react';
import { Line } from 'react-chartjs-2';

/**
 *
 * @param props
 * @returns LineChart of Revenue
 */
const LineChart = (props: any) => {
	return (
		<div className="flex w-full h-full">
			<Line data={props.data} title={props.title ?? 'Revenue Over Time'} />
		</div>
	);
};

export default LineChart;
