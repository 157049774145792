/* ChatContext */
import {
	useEffect,
	createContext,
	useContext,
	PropsWithChildren,
	useState,
	useCallback,
} from 'react';
import { Client as ConversationsClient } from '@twilio/conversations';
import { UserContext } from './UserContext';
import { v4 as uuidv4 } from 'uuid';
import { useApi } from '../services/useApi';
import { ChatService } from '../api';
import { FirebaseContext } from './FirebaseContext';

export enum ChatStatus {
	Connecting = 'Connecting',
	Connected = 'Connected',
	Disconnecting = 'Disconnecting',
	Disconnected = 'Disconnected',
	Denied = 'Failed connecting',
}

const defaultChatState = {
	status: ChatStatus.Disconnected,
	conversationsReady: false,
};
const ChatContext = createContext({
	chatToken: null as string | null,
	refreshToken: () => {},
	chatState: defaultChatState,
	createConversation: async (otherParticipants: any) => {},
	conversations: new Map() as Map<string, any>,
	unreadMessageCounts: new Map() as Map<string, number | null>,
	updateMessagesReadForConversation: (conversationSid: string) => {},
	activeConversationSid: '',
	changeActiveConversation: (sidOrConversation: string | any) => {},
	isChatActive: false,
	setIsChatActive: (isActive: boolean) => {},
	startConversation: () => {},
});

const ChatContextElement = ({ children }: PropsWithChildren<any>) => {
	const chatContext = useChat();
	return (
		<ChatContext.Provider value={chatContext}>{children}</ChatContext.Provider>
	);
};

function useChat() {
	const { user } = useContext(UserContext);
	const { messaging, setOnMessage, getCurrentToken } =
		useContext(FirebaseContext);
	const { callApi } = useApi();
	const [token, setToken] = useState<string | null>(null);
	const [chatState, setChatState] = useState(defaultChatState);
	const [conversationsClient, setConversationsClient] = useState(null as any);
	const [conversations, setConversations] = useState(
		new Map() as Map<string, any>
	);
	const [unreadMessageCounts, setUnreadMessageCounts] = useState(
		new Map() as Map<string, number | null>
	);
	const [isChatActive, setIsChatActive] = useState(false);
	const [activeConversationSid, setActiveConversationSid] = useState('');
	const [refreshTokenToggle, setRefreshTokenToggle] = useState(false);
	const [lastRefreshTokenToggle, setLastRefreshTokenToggle] = useState<
		boolean | null
	>(null);

	const refreshToken = useCallback(
		() => setRefreshTokenToggle((currentState) => !currentState),
		[]
	);

	useEffect(() => {
		if (user?.id && refreshTokenToggle !== lastRefreshTokenToggle) {
			setLastRefreshTokenToggle(refreshTokenToggle);
			const retrieveToken = async () => {
				const tokenResponse = await callApi(ChatService.getChatToken());
				if (tokenResponse.data) {
					setToken(tokenResponse.data);
				}
			};
			retrieveToken();
		} else if (!user?.id) {
			conversationsClient?.shutdown();
			setToken(null);
		}
	}, [
		callApi,
		conversationsClient,
		user?.id,
		refreshTokenToggle,
		chatState.status,
		lastRefreshTokenToggle,
	]);

	const addConversation = useCallback(
		async (conversation: any) => {
			if (Notification.permission !== 'granted') {
				Notification.requestPermission().then((permission) => {
					if (permission === 'granted') {
						console.log('Notification permission granted.');
						refreshToken();
					}
				});
			}
			setConversations(
				(conversations) =>
					new Map(conversations.set(conversation.sid, conversation))
			);
			const count = await conversation.getUnreadMessagesCount();
			setUnreadMessageCounts(
				(unreadMessageCounts) =>
					new Map(unreadMessageCounts.set(conversation.sid, count))
			);
		},
		[refreshToken]
	);
	const removeConversation = useCallback((thisConversation: any) => {
		setConversations((conversations) => {
			conversations.delete(thisConversation.sid);
			return new Map(conversations);
		});
		setUnreadMessageCounts((unreadMessageCounts) => {
			unreadMessageCounts.delete(thisConversation.sid);
			return new Map(unreadMessageCounts);
		});
	}, []);

	useEffect(() => {
		if (token) {
			const client = new ConversationsClient(token);
			getCurrentToken()
				.then((fcmToken) => {
					client.setPushRegistrationId('fcm', fcmToken!);

					// registering event listener on new message from firebase to pass it to the Conversations SDK for parsing
					setOnMessage(messaging, (payload: any) => {
						client.handlePushNotification(payload);
					});
				})
				.catch((err: any) => {
					// can't get token
					console.error("Can't get token", err);
				});
			// setChatState(defaultChatState);
			//#region HandleConnectionStateChanges
			client.on('connectionStateChanged', (state: string) => {
				if (state === 'connecting') {
					setChatState({
						status: ChatStatus.Connecting,
						conversationsReady: false,
					});
				}
				if (state === 'connected') {
					setChatState({
						status: ChatStatus.Connected,
						conversationsReady: true,
					});
				}
				if (state === 'disconnecting')
					setChatState({
						status: ChatStatus.Disconnecting,
						conversationsReady: false,
					});
				if (state === 'disconnected') setChatState(defaultChatState);
				if (state === 'denied')
					setChatState({
						status: ChatStatus.Denied,
						conversationsReady: false,
					});
			});
			//#endregion

			client.on('conversationJoined', addConversation);
			client.on('conversationLeft', removeConversation);
			client.on('messageAdded', (message) => {
				const currentUserIdentity = client.user.identity;
				const messageAttributes: any = message?.attributes;
				if (messageAttributes?.senderIdentity !== currentUserIdentity) {
					message.conversation
						.getUnreadMessagesCount()
						.then((count: number | null) => {
							setUnreadMessageCounts(
								(unreadMessageCounts) =>
									new Map(
										unreadMessageCounts.set(message.conversation.sid, count)
									)
							);
						});
				}
			});

			setConversationsClient(client);
			console.log(conversationsClient);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	const createConversation = async (otherParticipants: any[]) => {
		const response = await callApi(
			ChatService.getOrCreateConversation({
				name: '',
				id: uuidv4(),
				otherParticipants,
			})
		);
		if (response.data) {
			console.log(`Successfully created conversation! ${response.data}`);
			setActiveConversationSid(response.data);
			setIsChatActive(true);
		}
	};

	const updateMessagesReadForConversation = async (conversation: any) => {
		const count = await conversation.getUnreadMessagesCount();
		setUnreadMessageCounts(
			(unreadMessageCounts) =>
				new Map(unreadMessageCounts.set(conversation.sid, count))
		);
	};

	const changeActiveConversation = useCallback(
		async (conversation: any) => {
			if (typeof conversation === 'string') {
				conversation = conversations.get(conversation);
				conversation = conversation ?? '';
			}
			setActiveConversationSid(
				conversation?.sid === activeConversationSid
					? undefined
					: conversation?.sid
			);
			if (conversation) {
				await conversation.setAllMessagesRead();
				await updateMessagesReadForConversation(conversation);
			}
		},
		[activeConversationSid, conversations]
	);

	const startConversation = useCallback(() => {
		if (
			[ChatStatus.Disconnected, ChatStatus.Denied].includes(chatState.status)
		) {
			refreshToken();
		}
		setIsChatActive(true);
		changeActiveConversation('');
		console.log('starting new conversation');
	}, [
		changeActiveConversation,
		chatState.status,
		refreshToken,
		setIsChatActive,
	]);

	return {
		chatToken: token,
		refreshToken,
		chatState,
		createConversation,
		conversations,
		unreadMessageCounts,
		updateMessagesReadForConversation,
		activeConversationSid,
		changeActiveConversation,
		isChatActive,
		setIsChatActive,
		startConversation,
	};
}

export default ChatContext;
export { ChatContextElement };
