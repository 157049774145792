/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProjectDto } from '../models/ProjectDto';
import type { ProjectSummaryDto } from '../models/ProjectSummaryDto';
import type { UpdateProjectStateCommand } from '../models/UpdateProjectStateCommand';
import type { UpsertProjectCommand } from '../models/UpsertProjectCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectService {

	/**
	 * Upserts a project
	 * Upserts a project
	 * @param requestBody
	 * @returns string Success
	 * @throws ApiError
	 */
	public static upsertProject(
		requestBody: UpsertProjectCommand,
	): CancelablePromise<string> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/Project/Project',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Searches existing projects.
	 * Returns any projects matching the given query.
	 * @param query Applied against title field
	 * @param skills Applied against ProjectSkills
	 * @param postalCode Applied against Project Owner Location
	 * @param pageNumber Determines which page of results will be returned
	 * @param pageSize Determines how many results will be returned per page
	 * @returns ProjectDto Success
	 * @throws ApiError
	 */
	public static searchProject(
		query?: string,
		skills?: Array<string>,
		postalCode?: string,
		pageNumber?: number,
		pageSize?: number,
	): CancelablePromise<Array<ProjectDto>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Project/Project',
			query: {
				'Query': query,
				'Skills': skills,
				'PostalCode': postalCode,
				'PageNumber': pageNumber,
				'PageSize': pageSize,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Updates a project's state
	 * @param requestBody
	 * @returns string Success
	 * @throws ApiError
	 */
	public static updateProjectState(
		requestBody: UpdateProjectStateCommand,
	): CancelablePromise<string> {
		return __request(OpenAPI, {
			method: 'POST',
			url: '/api/Project/ProjectState',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Retrieves project by Id
	 * Returns one project matching the given Id.
	 * @param id
	 * @returns ProjectDto Success
	 * @throws ApiError
	 */
	public static getProject(
		id: string,
	): CancelablePromise<ProjectDto> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Project/Project/{id}',
			path: {
				'id': id,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Retrieves project according to user skills
	 * Returns list of projects matching the given criteria.
	 * @returns ProjectDto Success
	 * @throws ApiError
	 */
	public static getRecommendedProjects(): CancelablePromise<Array<ProjectDto>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Project/RecommendedProjects',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

	/**
	 * Retrieves projects by talent membership or project ownership
	 * Curently used on Talent Dashboard
	 * @returns ProjectSummaryDto Success
	 * @throws ApiError
	 */
	public static getUserProjects(): CancelablePromise<Array<ProjectSummaryDto>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Project/UserProjects',
			errors: {
				401: `Unauthorized`,
			},
		});
	}

}
