import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { ComponentType } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Loading } from '../Loading';

interface ProtectedRouteProps {
	component: ComponentType;
	props?: any;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
	component,
	props,
}) => {
	const params = useParams();
	const { state } = useLocation();
	const [searchParams] = useSearchParams();
	const Component = withAuthenticationRequired(component, {
		onRedirecting: () => (
			<div className="flex flex-col my-96 items-center w-full h-full">
				<Loading />
			</div>
		),
		loginOptions: {
			appState: {
				...Object.fromEntries(searchParams.entries()),
				...params,
				...state,
				...props,
			},
		},
	});

	return (
		<Component
			{...Object.fromEntries(searchParams.entries())}
			{...params}
			{...(typeof state === 'object' ? state : { state })}
			{...props}
		/>
	);
};
