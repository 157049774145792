import { PortfolioItemDto } from '../../api';
import { TextFieldInput } from '../utilities/Input';
import { useForm } from 'react-hook-form';
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { PhotoUpload } from '../utilities/FloatingLabelInput';
import OrganizationLookup from '../utilities/OrganizationLookup';
import ExternalLink from '../utilities/ExternalLink';
import { IoOpenOutline } from 'react-icons/io5';

const defaultValues: Omit<PortfolioItemDto, 'id' | 'talentProfileId'> = {
	name: undefined!,
	description: undefined!,
	forOrganizationId: null!,
	forOrganization: null!,
	url: undefined!,
	fileId: undefined!,
	deleted: false,
};

const PortfolioItemDetails = (props: {
	portfolioItem: PortfolioItemDto;
	visible: boolean;
	onClose: any;
}) => {
	const { portfolioItem, visible, onClose } = props;
	const {
		register,
		resetField,
		formState: { errors },
		control,
	} = useForm({
		mode: 'onBlur',
		defaultValues: portfolioItem ?? defaultValues,
		shouldFocusError: false,
	});

	return (
		<>
			<Dialog open={visible} onClose={onClose}>
				<DialogTitle>{`${portfolioItem.name ?? 'Portfolio Item'}`}</DialogTitle>
				<DialogContent>
					<Grid container className="px-2" spacing={1} columns={8}>
						{portfolioItem.description ? (
							<Grid item xs={8}>
								<TextFieldInput
									name="description"
									variant="standard"
									control={control}
									label="Description"
									readOnly={true}
									fullWidth
								/>
							</Grid>
						) : null}
						{portfolioItem.forOrganizationId ? (
							<Grid item xs={8}>
								<OrganizationLookup
									organization={portfolioItem.forOrganization}
									isEditing={false}
									errors={[]}
									onChange={() => {}}
								/>
							</Grid>
						) : null}
						{portfolioItem.url ? (
							<Grid item xs={8}>
								<label>Linked Content: </label>
								<ExternalLink
									href={portfolioItem.url}
									className="text-accent-400">
									{portfolioItem.url}
									<IoOpenOutline className="inline scale-80 align-super" />
								</ExternalLink>
							</Grid>
						) : null}
						{portfolioItem.fileId ? (
							<Grid item xs={8} className="mt-4">
								<PhotoUpload
									{...register('fileId')}
									label="Image"
									helperText="select an image to add to your portfolio"
									category="PortfolioItem"
									value={portfolioItem.fileId}
									url={portfolioItem.fileUrl}
									// Image Resizing Properties
									editing={false}
									errors={errors}
									resetfield={resetField}
									className="mx-2"
									imageStyle="object-contain"
								/>
							</Grid>
						) : null}
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default PortfolioItemDetails;
