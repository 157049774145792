/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PostalCodeDto } from '../models/PostalCodeDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LocationService {

	/**
	 * Searches postal codes by code matching the given query.
	 * Returns any postal codes matching the given query.
	 * @param query Applied against Name field
	 * @returns PostalCodeDto Success
	 * @throws ApiError
	 */
	public static searchPostalCodes(
		query?: string,
	): CancelablePromise<Array<PostalCodeDto>> {
		return __request(OpenAPI, {
			method: 'GET',
			url: '/api/Location/PostalCode',
			query: {
				'Query': query,
			},
			errors: {
				401: `Unauthorized`,
			},
		});
	}

}
