import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
	Avatar,
	Divider,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
} from '@mui/material';

import { UserContext } from './contexts/UserContext';
import { NavItem, getDefaultNavList } from './components/utilities/UserSideNav';
import MenuContext from './contexts/DrawerContext';
import ExternalLink from './components/utilities/ExternalLink';
import { InstallPwaContext } from './contexts/InstallPwaContext';

const ProfileButton = (props: any) => {
	const anchorRef = React.useRef<HTMLAnchorElement>(null);
	const { logout } = useAuth0();
	const { setSessionStorageUserData } = useContext(UserContext);
	const { isSideNavOpen, isAccountMenuOpen, setIsAccountMenuOpen } =
		useContext(MenuContext);
	const { isInstallable, isAlreadyInstalled, installPwa } =
		useContext(InstallPwaContext);

	const handleUserLogOut = () => {
		setSessionStorageUserData(null);
		logout({ returnTo: window.location.origin });
	};

	const defaultNav: NavItem[] = getDefaultNavList(props.user);

	return (
		<>
			<Tooltip title="Open settings" className="account-button">
				<IconButton
					onClick={() => setIsAccountMenuOpen(true)}
					sx={{ p: 0 }}
					ref={anchorRef}
					component="a">
					<Avatar
						alt={props.user?.given_name}
						src={props.user?.picture || '/static/images/avatar/2.jpg'}
					/>
				</IconButton>
			</Tooltip>
			<Menu
				sx={{ mt: '45px' }}
				id="menu-appbar"
				anchorEl={anchorRef.current}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={isAccountMenuOpen}
				onClose={() => setIsAccountMenuOpen(false)}>
				<Link to="/user-account">
					<MenuItem key="Account">
						<Typography textAlign="center">Account</Typography>
					</MenuItem>
				</Link>
				{isSideNavOpen ? (
					<Link to="/dashboard">
						<MenuItem key="Dashboard">
							<Typography textAlign="center">Dashboard</Typography>
						</MenuItem>
					</Link>
				) : null}
				{!isAlreadyInstalled ? (
					isInstallable ? (
						<MenuItem key="InstallApp" onClick={installPwa}>
							<Typography textAlign="center">Install App</Typography>
						</MenuItem>
					) : (
						<Link to="/install-app">
							<MenuItem key="InstallApp">
								<Typography textAlign="center">Install App</Typography>
							</MenuItem>
						</Link>
					)
				) : null}
				{isSideNavOpen
					? null
					: defaultNav.map((item) => (
							<Link to={item.link} key={item.name}>
								<MenuItem key={item.name} className={item.targetClass}>
									<Typography textAlign="center">{item.name}</Typography>
								</MenuItem>
							</Link>
					  ))}
				<Divider className="darkblue" />
				<MenuItem key="FAQ" className="menu-help">
					<ExternalLink href="https://blog.nkipo.com/frequently-asked-questions/">
						<Typography textAlign="center">Help</Typography>
					</ExternalLink>
				</MenuItem>
				<MenuItem key="LogOut" onClick={handleUserLogOut}>
					<Typography textAlign="center">LogOut</Typography>
				</MenuItem>
			</Menu>
		</>
	);
};

export default ProfileButton;
