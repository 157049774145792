import { BuyerPaymentDto, PaymentService } from '../../api';
import { UserContext } from '../../contexts/UserContext';
import { useContext, useEffect, useState } from 'react';
import useApi from '../../services/useApi';
import { Link, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import {
	DataGrid,
	GridColDef,
	GridValueGetterParams,
	GridRenderCellParams,
} from '@mui/x-data-grid';

import { parseISO } from 'date-fns';
import {
	formatCurrency,
	getStateInfo,
	getStateName,
} from '../../services/helper';
import { StateType } from '../../Constants/Dialog';

const BuyerPayments = () => {
	const { user, isLoading: isUserLoading } = useContext(UserContext);
	const [items, setItems] = useState<BuyerPaymentDto[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const { callApi } = useApi();

	useEffect(() => {
		const loadItems = async () => {
			const response = await callApi(PaymentService.getUserBuyerPayments());
			if (response.data) {
				setItems(response.data);
				setIsLoading(false);
			}
		};
		if (!isUserLoading && user) {
			setIsLoading(true);
			loadItems();
		}
	}, [isUserLoading, user, callApi]);

	const columns: GridColDef<BuyerPaymentDto>[] = [
		{
			field: 'invoice',
			headerName: 'Invoice',
			valueGetter: (params: GridValueGetterParams<BuyerPaymentDto>) =>
				params.row.invoice.title,
			renderCell: (params: GridRenderCellParams<BuyerPaymentDto>) => (
				<Link component={RouterLink} to={`/invoice/${params.row.invoice.id}`}>
					{params.row.invoice.title}
				</Link>
			),
			flex: 1,
		},
		{
			field: 'stripePaymentIntentId',
			headerName: 'Stripe Id',
			flex: 1,
			valueFormatter: ({ value }) => value ?? 'N/A',
		},
		{
			field: 'amount',
			headerName: 'Amount',
			type: 'number',
			valueFormatter: ({ value }) => formatCurrency(Number(value)),
			flex: 1,
		},
		{
			field: 'state',
			headerName: 'State',
			flex: 1,
			renderCell: (params: GridRenderCellParams<BuyerPaymentDto>) => (
				<Tooltip title={getStateInfo(params.value, StateType.BuyerPayment)}>
					<div>{getStateName(params.value, StateType.BuyerPayment)}</div>
				</Tooltip>
			),
		},
		{
			field: 'updatedAt',
			headerName: 'Last Updated',
			type: 'dateTime',
			valueGetter: (params: GridValueGetterParams<BuyerPaymentDto>) =>
				parseISO(params.row.updatedAt),
			flex: 1,
		},
	];

	return (
		<div>
			<h2 className="pb-4">Payments Sent</h2>
			<DataGrid
				initialState={{
					columns: {
						columnVisibilityModel: {
							// Stripe id is hidden by default
							stripePaymentIntentId: false,
						},
					},
					sorting: {
						sortModel: [{ field: 'updatedAt', sort: 'desc' }],
					},
				}}
				rows={items}
				columns={columns}
				autoHeight
				disableRowSelectionOnClick
				loading={isLoading}
			/>
		</div>
	);
};

export default BuyerPayments;
