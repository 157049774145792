import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoMdPerson } from 'react-icons/io';
import TextButton from '../utilities/TextButton';
import {
	ChatService,
	ConnectionState,
	TeamSummaryDto,
	UserSummaryDto,
} from '../../api';
import ConnectButton from '../utilities/ConnectButton';
import ThemeContext from '../../contexts/ThemeContext';
import {
	defaultLightProfilePicUrl,
	defaultDarkProfilePicUrl,
} from '../../services/helper';
import { Stack, Button } from '@mui/material';
import ChatContext from '../../contexts/ChatContext';
import useApi from '../../services/useApi';
import AddToTeamButton from '../utilities/AddToTeamButton';

interface UserSearchResultProps {
	result: UserSummaryDto;
	currentUserTeamMemberships?: TeamSummaryDto[];
	onConnectionUpdated?: (userId: string, state: ConnectionState) => void;
}

const UserSearchResult = (props: UserSearchResultProps) => {
	const {
		result: userInfo,
		currentUserTeamMemberships,
		onConnectionUpdated,
	} = props;
	const { callApi } = useApi();
	const { theme } = useContext(ThemeContext);
	const { createConversation, chatState } = useContext(ChatContext);
	const navigateTo = useNavigate();
	const userFullName = userInfo.firstName + ' ' + userInfo.lastName;

	const isTalent = userInfo.talentProfileId ? true : false;
	const isBuyer = userInfo.buyerProfileId ? true : false;
	const isBoth = isTalent && isBuyer;

	const sendDirectMessage = async () => {
		if (chatState.conversationsReady) {
			const response = await callApi(
				isTalent
					? ChatService.talentParticipantSearch(userInfo.talentProfileId!)
					: ChatService.buyerParticipantSearch(userInfo.buyerProfileId!)
			);
			if (response.data) {
				const participant = response.data;
				await createConversation([participant]);
			}
		}
	};

	const profilePicToUse =
		userInfo.talentProfile?.profilePicUrl ?? userInfo.buyerProfilePicUrl;
	const profilePicToUseIsTalentPic =
		profilePicToUse === userInfo.talentProfile?.profilePicUrl ? true : false;

	return (
		<div className="flex flex-wrap md:flex-nowrap h-full w-full items-center gap-2 mb-4">
			<div className="flex flex-initial items-center gap-2 h-full text-left w-full">
				{profilePicToUse ? (
					<img
						className={`flex-none object-cover -left-10 w-24 h-24 min-w-24 basis-24 ${
							profilePicToUseIsTalentPic ? 'rounded-full' : ''
						} shadow-lg`}
						src={profilePicToUse}
						alt={'Profile picture of ' + userFullName}
						onError={(event: any) => {
							event.currentTarget.onerror = null;
							event.currentTarget.src =
								theme === 'light'
									? defaultLightProfilePicUrl
									: defaultDarkProfilePicUrl;
						}}
					/>
				) : isTalent ? (
					<div className="flex-none relative w-24 h-24 min-w-24 basis-24 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-700 shadow-lg">
						<IoMdPerson className="text-gray-400 dark:text-gray-500 relative w-28 h-28 -left-2" />
					</div>
				) : (
					<div className="flex-none relative w-24 h-24 min-w-24 basis-24 overflow-hidden bg-gray-100 dark:bg-gray-700 shadow-lg">
						<IoMdPerson className="text-gray-400 dark:text-gray-500 relative w-28 h-28 -left-2" />
					</div>
				)}

				{!isBoth && isTalent ? (
					<div className="flex flex-col h-full justify-center">
						<TextButton
							className="col-start-5 col-span-1 text-left ml-0 pl-0"
							onClick={() =>
								navigateTo(`/talent-profile/${userInfo.talentProfileId}`)
							}>
							<h3 className="font-bold text-accent-400 mb-1">{userFullName}</h3>
						</TextButton>
						{userInfo.locationState && (
							<p className="text-md font-bold text-gray-800 dark:text-gray-200">
								{userInfo.locationState}
							</p>
						)}
						{userInfo.talentProfile?.tagLine && (
							<p className="text-md font-bold text-gray-800 dark:text-gray-200 pl-2">
								{userInfo.talentProfile.tagLine}
							</p>
						)}
					</div>
				) : null}
				{!isBoth && isBuyer ? (
					<div className="flex flex-col h-full justify-center">
						<TextButton
							className="col-start-5 col-span-1"
							onClick={() =>
								navigateTo(`/buyer-profile/${userInfo.buyerProfileId}`)
							}>
							<h3 className="font-bold text-accent-400 mb-1">{userFullName}</h3>
						</TextButton>
						{userInfo.locationState && (
							<p className="text-md font-bold text-gray-800 dark:text-gray-200">
								{userInfo.locationState}
							</p>
						)}
						{(userInfo.buyerRole || userInfo.buyerOrgName) && (
							<p className="text-md font-bold text-gray-800 dark:text-gray-200 pl-2">
								{[userInfo.buyerRole, userInfo.buyerOrgName]
									.filter((s) => s)
									.join(', ')}
							</p>
						)}
					</div>
				) : null}
				{isBoth ? (
					<>
						<div className="flex flex-col h-full justify-center">
							<TextButton
								className="col-start-5 col-span-1 mx-0"
								onClick={() =>
									navigateTo(`/talent-profile/${userInfo.talentProfileId}`)
								}>
								<h3 className="font-bold text-accent-400 mb-1">
									{userFullName}
								</h3>
							</TextButton>
							{userInfo.locationState && (
								<p className="text-md font-bold text-gray-800 dark:text-gray-200 pl-2">
									{userInfo.locationState}
								</p>
							)}
							{userInfo.talentProfile?.tagLine && (
								<p className="text-md font-bold text-gray-800 dark:text-gray-200">
									Talent: {userInfo.talentProfile.tagLine}
								</p>
							)}
							{(userInfo.buyerRole || userInfo.buyerOrgName) && (
								<p className="text-md font-bold text-gray-800 dark:text-gray-200">
									Buyer:{' '}
									{[userInfo.buyerRole, userInfo.buyerOrgName]
										.filter((s) => s)
										.join(', ')}
								</p>
							)}
						</div>
						<div className="flex flex-col gap-4 h-full justify-center">
							<TextButton
								className="px-0 mx-0 scale-80"
								onClick={() =>
									navigateTo(`/buyer-profile/${userInfo.buyerProfileId}`)
								}>
								<h4 className="text-sm font-bold text-accent-400 mb-1">
									Buyer Profile
								</h4>
							</TextButton>
							<TextButton
								className="px-0 mx-0 scale-80"
								onClick={() =>
									navigateTo(`/talent-profile/${userInfo.talentProfileId}`)
								}>
								<h4 className="text-sm font-bold text-accent-400 mb-1">
									Talent Profile
								</h4>
							</TextButton>
						</div>
					</>
				) : null}
			</div>
			<Stack
				alignItems="right"
				spacing={1}
				className="w-full md:w-auto px-2 md:px-[inherit]">
				{currentUserTeamMemberships && !!userInfo.talentProfile && (
					<AddToTeamButton
						talentProfile={userInfo.talentProfile}
						currentUserTeamMemberships={currentUserTeamMemberships}
					/>
				)}
				<ConnectButton
					userId={userInfo.id}
					connection={userInfo.connection}
					condensed={true}
					onConnectionUpdated={onConnectionUpdated}
				/>
				<Button variant="contained" onClick={() => sendDirectMessage()}>
					Message
				</Button>
			</Stack>
		</div>
	);
};

export default UserSearchResult;
