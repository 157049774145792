import React, { useCallback, useEffect, useState } from 'react';
import {
	TalentExperienceDto,
	ExperienceType,
	OrganizationsService,
	OrganizationSummaryDto,
} from '../../api';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	MenuItem,
	Box,
} from '@mui/material';
import { AutocompleteInput, TextFieldInput } from '../utilities/Input';
import { useForm } from 'react-hook-form';
import useApi from '../../services/useApi';
import { OrganizationModal } from '../Organizations/OrganizationModal';
import { v4 as uuidv4 } from 'uuid';
import { FaPlus } from 'react-icons/fa';
import { setValueAsUserInputOptions } from '../../services/helper';

type ExperienceModalExperience = Omit<
	TalentExperienceDto,
	'id' | 'talentProfileId'
> & {
	organization?: OrganizationSummaryDto | null | undefined;
};

type ExperienceModalProps = {
	id: string;
	talentProfileId: string;
	experience: ExperienceModalExperience | null;
	visible: boolean;
	onSave: (experience: TalentExperienceDto) => void;
	onCancel: () => void;
};

const defaultValues: ExperienceModalExperience = {
	experienceType: ExperienceType.EMPLOYMENT,
	organizationId: undefined,
	organization: undefined,
	title: undefined,
	startDate: undefined,
	endDate: undefined,
	description: undefined,
	deleted: false,
};

const ExperienceModal: React.FC<ExperienceModalProps> = ({
	id,
	talentProfileId,
	experience,
	visible,
	onSave,
	onCancel,
}) => {
	const { control, setValue, handleSubmit, reset } = useForm({
		mode: 'onBlur',
		defaultValues: experience ?? defaultValues,
		shouldFocusError: false,
	});
	const { callApi, emptyResponse } = useApi();

	useEffect(() => {
		if (experience) {
			reset(experience);
		} else {
			reset(defaultValues);
		}
	}, [experience, reset]);

	const handleSave = (data: ExperienceModalExperience) => {
		onSave({
			...data,
			id,
			talentProfileId,
		});
	};

	const searchOrganizations = useCallback(
		async (query?: string) => {
			if (query) {
				const response = await callApi(
					OrganizationsService.searchOrganizations(query, false)
				);
				return response;
			} else {
				return emptyResponse;
			}
		},
		[callApi, emptyResponse]
	);

	const [addOrganizationModalOpen, setAddOrganizationModalOpen] =
		useState(false);

	const handleAddOrganization = (newOrganization: OrganizationSummaryDto) => {
		setAddOrganizationModalOpen(false);
		setValue('organizationId', newOrganization.id, setValueAsUserInputOptions);
		setValue('organization', newOrganization, setValueAsUserInputOptions);
	};

	return (
		<>
			<Dialog open={visible} onClose={onCancel}>
				<DialogTitle>
					{experience ? 'Edit Experience' : 'Add Experience'}
				</DialogTitle>
				<form onSubmit={handleSubmit(handleSave)}>
					<DialogContent>
						<TextFieldInput
							select
							margin="dense"
							name="experienceType"
							label="Experience Type"
							control={control}
							fullWidth>
							{Object.values(ExperienceType).map((type) => (
								<MenuItem key={type} value={type}>
									{type}
								</MenuItem>
							))}
						</TextFieldInput>
						<TextFieldInput
							autoFocus
							margin="dense"
							name="title"
							label="Title"
							type="text"
							fullWidth
							control={control}
						/>
						<Box
							display="flex"
							className="gap-4 justify-between mb-4"
							alignItems="center">
							<AutocompleteInput
								className="flex-auto"
								name="organization"
								valueName="organizationId"
								control={control}
								label="Organization"
								optionApiService={searchOrganizations}
								apiFiltering
							/>
							<Button
								className="flex-none"
								variant="outlined"
								color="primary"
								onClick={() => setAddOrganizationModalOpen(true)}>
								<FaPlus className="ml-0 mr-2" /> Add Org
							</Button>
						</Box>
						<TextFieldInput
							margin="dense"
							name="startDate"
							label="Start Date"
							type="date"
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							control={control}
						/>
						<TextFieldInput
							margin="dense"
							name="endDate"
							label="End Date"
							type="date"
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							control={control}
						/>
						<TextFieldInput
							margin="dense"
							name="description"
							label="Description"
							type="text"
							fullWidth
							multiline
							rows={4}
							control={control}
						/>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={onCancel}>
							Cancel
						</Button>
						<Button variant="outlined" type="submit" color="primary">
							{experience ? 'Update' : 'Add'}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
			<OrganizationModal
				id={uuidv4()}
				organization={null}
				open={addOrganizationModalOpen}
				onCancel={() => setAddOrganizationModalOpen(false)}
				onOrganizationAdded={handleAddOrganization}
			/>
		</>
	);
};

export default ExperienceModal;
