import { Stack } from '@mui/material';
import FullPageCard from '../utilities/FullPageCard';

const InstallApp = (props: any) => {
	return (
		<FullPageCard>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				spacing={2}>
				<h1>Install App</h1>
				{/* Instructions on how to install PWA on iPhone or any iOS device */}
				<h3>
					To install this app on your iOS device, please follow the steps below:
				</h3>
				<ol>
					<li>- In Safari click the share button.</li>
					<li>- Select "Add to Home Screen".</li>
					<li>- Click "Add" to confirm the name of the app.</li>
				</ol>
				<h3>To receive chat notifications on your device:</h3>
				<ol>
					<li>- Open the app you added to your home screen</li>
					<li>- Navigate to your nKipo account settings page</li>
					<li>- Click to enable chat notifications</li>
				</ol>
				<p>Please note, this must be done from within the home screen app.</p>
			</Stack>
		</FullPageCard>
	);
};

export default InstallApp;
