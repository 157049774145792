import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FiLogIn } from 'react-icons/fi';
import { useSearchParams } from 'react-router-dom';

const LoginButton = () => {
	const { loginWithRedirect } = useAuth0();
	const [searchParams] = useSearchParams();

	return (
		<button
			onClick={() =>
				loginWithRedirect({
					appState: {
						returnTo: '/dashboard',
						...Object.fromEntries(searchParams.entries()),
					},
				})
			}
			className="mx-2 text-black dark:text-white hover:text-accent-200 bg-transparent">
			Log In
			<FiLogIn size={20} className="ml-2 sm:inline-block hidden" />
		</button>
	);
};

export default LoginButton;
