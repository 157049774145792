/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ConnectionState {
	PENDING = 'Pending',
	ACTIVE = 'Active',
	DECLINED = 'Declined',
	IGNORED = 'Ignored',
	INACTIVE = 'Inactive',
}
