import React, { useContext, useMemo } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
} from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import CopyableLink from './CopyableLink';

type InviteToJoinModalProps = {
	currentUserId: string | null | undefined;
	linkToProfile: string;
	open: boolean;
	onCancel: () => void;
};

export const InviteToJoinModal: React.FC<InviteToJoinModalProps> = ({
	currentUserId,
	linkToProfile,
	open,
	onCancel,
}) => {
	const { user } = useContext(UserContext);
	const newLine = escape('\r\n');
	const referrerHref = useMemo(() => {
		const referrerSearchParam = currentUserId
			? new URLSearchParams([['reffererId', currentUserId]])
			: undefined;
		return window.location.origin + '?' + referrerSearchParam ?? '';
	}, [currentUserId]);
	const emailInvite = `mailto:
	?subject=Join me on nKipo!
	&body=Hey!
	${newLine}
	${newLine}
	I'm excited to let you know about nKipo, a new professional network that I recently joined. It helps you connect with other professionals, form freelance teams, find projects, and streamline your work.
	${newLine}
	${newLine}
	It works best when I'm connected to the people I know, so I was hoping you'd join and connect with me. Creating a profile is easy - just click here (${referrerHref}) to get started!
	${newLine}
	${newLine}
	You can find my profile here: ${linkToProfile}
	${newLine}
	${newLine}
	This is the beta version right now so there aren't many projects listed on the marketplace. However, if you create a team, the nKipo staff will help find projects for you! #freesalesrep
	${newLine}
	${newLine}
	nKipo believes freelance teams are the workforce of the future, and their goal is to merge the positive aspects of working for a business with those of being a freelancer to revolutionize the way we work for the better. I hope to see you on there!
	${newLine}
	${newLine}
	Thanks!
	${newLine}
	${user?.firstName ?? ''}`;

	return (
		<Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth>
			<DialogTitle>Invite Others To Join nKipo!</DialogTitle>
			<DialogContent>
				<Typography>
					<b>Inviting others is as simple as sending an email!</b>
					<br />
					<br />
					If you're looking to invite a lot of people to join nKipo, we
					recommend using the "Bcc" function in your email.
					<br />
					<br />
					Simply select "Get Started!" on this notification, and an email will
					pop up. Add the email addresses of everyone you want to invite in the
					"Bcc" section (you might need to click to expand the options from the
					"To" field). This way, each recipient will receive an individual email
					and any replies will only come back to you. Otherwise, feel free to
					send out this link:
				</Typography>
				<br />
				<CopyableLink text={referrerHref} />
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={onCancel} color="primary">
					Cancel
				</Button>
				<Button variant="outlined" color="primary">
					<a target="_blank" rel="noopener noreferrer" href={emailInvite}>
						Get Started!
					</a>
				</Button>
			</DialogActions>
		</Dialog>
	);
};
