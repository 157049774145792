/* Button with default action and dropdown of other options */
import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

export type SplitButtonOptions = {
	label: React.ReactNode;
	handler: (event: React.MouseEvent<Element, MouseEvent>) => any;
	disabled?: boolean;
};

export default function SplitButton(props: {
	className?: string | undefined;
	options: SplitButtonOptions[];
}) {
	const { className, options } = props;
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);
	const [selectedIndex, setSelectedIndex] = React.useState(0);

	const handleClick = (
		event: React.MouseEvent<Element, MouseEvent>,
		desiredIndex?: number | undefined
	) => {
		options[desiredIndex ?? selectedIndex].handler(event);
	};

	const handleMenuItemClick = (
		event: React.MouseEvent<HTMLLIElement, MouseEvent>,
		index: number
	) => {
		setSelectedIndex(index);
		setOpen(false);
		handleClick(event, index);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: Event) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}

		setOpen(false);
	};

	const buttonTextAndBackgroundColors =
		'text-white bg-accent-500 hover:bg-accent-600';
	return (
		<React.Fragment>
			<ButtonGroup
				variant="contained"
				ref={anchorRef}
				aria-label="split button"
				color="inherit"
				className={className}>
				<Button
					color="primary"
					className={`${buttonTextAndBackgroundColors} flex-auto`}
					sx={{ borderColor: 'transparent', whiteSpace: 'nowrap' }}
					onClick={(event) => handleClick(event)}>
					{options[selectedIndex].label}
				</Button>
				{options.length > 1 ? (
					<Button
						color="primary"
						className={`${buttonTextAndBackgroundColors}`}
						size="small"
						aria-controls={open ? 'split-button-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						aria-haspopup="menu"
						onClick={handleToggle}>
						<ArrowDropDownIcon />
					</Button>
				) : null}
			</ButtonGroup>
			<Popper
				sx={{
					zIndex: 1,
				}}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom' ? 'center top' : 'center bottom',
						}}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									{options.map((option, index) => (
										<MenuItem
											key={index}
											disabled={option.disabled}
											selected={index === selectedIndex}
											onClick={(event) => handleMenuItemClick(event, index)}>
											{option.label}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</React.Fragment>
	);
}
