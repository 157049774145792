import React, { useCallback, useContext, useEffect, useState } from 'react';
import useApi, { ApiResponse } from '../../services/useApi';
import {
	ConnectionState,
	TeamService,
	TeamSummaryDto,
	UserService,
	UserSummaryDto,
} from '../../api';
import {
	Card,
	CardContent,
	CircularProgress,
	Fab,
	List,
	Paper,
} from '@mui/material';
import UserSideNav from '../utilities/UserSideNav';
import { UserContext } from '../../contexts/UserContext';
import Button from '../utilities/Button';
import UserSearchResult from '../Search/UserSearchResult';
import AddIcon from '@mui/icons-material/Add';
import { InviteToJoinModal } from '../utilities/InviteToJoinModal';

const Connections = (props: any) => {
	const { id } = props;
	const { user } = useContext(UserContext);
	const [connections, setConnections] = useState<
		UserSummaryDto[] | undefined
	>();
	const [teamMemberships, setTeamMemberships] = useState<
		TeamSummaryDto[] | undefined
	>(undefined);
	const [isLoadingData, setIsLoadingData] = useState(true);
	const [inviteToJoinModalOpen, setInviteToJoinModalOpen] = useState(false);
	const { requestInParallel } = useApi();

	useEffect(() => {
		const loadTalentProfile = async () => {
			// TODO: reduce this to a single request. I believe we have all the data in the second request but it needs a little massaging
			const requests = [
				{
					request: UserService.getUserConnections(),
					handler: (response: ApiResponse<UserSummaryDto[]>) =>
						setConnections(response.data ?? []),
				},
				{
					request: TeamService.getUserDedicatedTeams(),
					handler: (response: ApiResponse<TeamSummaryDto[]>) =>
						setTeamMemberships(response.data ?? []),
				},
			];
			await requestInParallel(requests);
			setIsLoadingData(false);
		};
		setIsLoadingData(true);
		loadTalentProfile();
	}, [id, setIsLoadingData, requestInParallel]);

	const onConnectionUpdated = useCallback(
		(userId: string, state: ConnectionState) => {
			// Remove any non pending or active connections from list
			if (![ConnectionState.PENDING, ConnectionState.ACTIVE].includes(state)) {
				setConnections(connections?.filter((x) => x.id !== userId));
			}
		},
		[connections]
	);

	const currentUserProfileHref = `${window.location.origin}/${
		user?.buyerProfileId
			? `buyer-profile/${user?.buyerProfileId}`
			: `talent-profile/${user?.talentProfileId}`
	}`;

	return (
		<UserSideNav className="flex flex-col items-center">
			<Fab
				className="sticky top-4 right-0 place-self-end mb-4"
				variant="extended"
				color="primary"
				aria-label="add"
				onClick={() => setInviteToJoinModalOpen(true)}>
				<AddIcon className="text-white" />
				<span className="MuiFab-label text-white">Invite Others</span>
			</Fab>
			<Paper elevation={3} className="w-full 2xl:w-3/4">
				<Card>
					{isLoadingData && (
						<CardContent className="content-center">
							<CircularProgress />
						</CardContent>
					)}
					{!isLoadingData && !!connections?.length && (
						<CardContent>
							<List>
								{connections &&
									connections.map((connection: UserSummaryDto) => (
										<UserSearchResult
											key={connection.id}
											result={connection}
											currentUserTeamMemberships={teamMemberships ?? []}
											onConnectionUpdated={
												onConnectionUpdated
											}></UserSearchResult>
									))}
							</List>
						</CardContent>
					)}
					{!isLoadingData && !connections?.length && (
						<CardContent className="content-center">
							<div>
								It looks like you haven't connected with anyone yet. Connecting
								with others on nKipo helps determine project and team
								recommendations.
							</div>
							<div>
								You can invite others to join{' '}
								<Button
									className="accent-default"
									onClick={() => setInviteToJoinModalOpen(true)}>
									here
								</Button>
							</div>
						</CardContent>
					)}
				</Card>
			</Paper>
			<InviteToJoinModal
				currentUserId={user?.id}
				linkToProfile={currentUserProfileHref}
				open={inviteToJoinModalOpen}
				onCancel={() => setInviteToJoinModalOpen(false)}
			/>
		</UserSideNav>
	);
};

export default Connections;
