/* Icon based buttons */

import { Popover } from '@mui/material';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { FaInfoCircle, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';

const encodeSvg = (
	reactElement: React.ReactElement<
		any,
		string | React.JSXElementConstructor<any>
	>
) => {
	return (
		'data:image/svg+xml,' +
		ReactDOMServer.renderToStaticMarkup(reactElement)
			.replace(/#/g, '%23')
			.replace(/"/g, "'")
			.replace(/&/g, '&amp;')
	);
};

const CloseButton = (props: any) => {
	const { onClick, className, ...rest } = props;
	return (
		<FaTimesCircle
			{...rest}
			onClick={onClick}
			className={
				'h-5 w-5 cursor-pointer border-[1px] border-transparent rounded-full bg-gray-100 dark:bg-gray-400 text-gray-500 dark:text-gray-600 hover:text-nKipo dark:hover:text-nKipo dark:hover:bg-gray-200 ' +
				(className ?? '')
			}
		/>
	);
};

const AddButton = (props: any) => {
	const { onClick, className, ...rest } = props;
	return (
		<FaPlusCircle
			{...rest}
			onClick={onClick}
			className={
				'h-5 w-5 cursor-pointer border-[1px] border-transparent rounded-full bg-gray-100 dark:bg-gray-400 text-gray-500 dark:text-gray-600 hover:text-nKipo dark:hover:text-nKipo dark:hover:bg-gray-200 ' +
				(className ?? '')
			}
		/>
	);
};

const HelperInfo = (props: any) => {
	const { onClick, helperInfo, className, ...rest } = props;
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		onClick?.(event);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	return (
		<React.Fragment>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}>
				{helperInfo}
			</Popover>

			<div
				onClick={handlePopoverOpen}
				className={
					'h-5 w-5 cursor-pointer border-[1px] border-transparent rounded-full bg-gray-100 dark:bg-gray-400 text-gray-500 dark:text-gray-600 hover:text-nKipo dark:hover:text-nKipo dark:hover:bg-gray-200 ' +
					(className ?? '')
				}
				{...rest}>
				<FaInfoCircle />
			</div>
		</React.Fragment>
	);
};

export { encodeSvg, CloseButton, AddButton, HelperInfo };
