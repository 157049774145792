/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PostalCodeLocationAccuracy {
	UNKNOWN = 'Unknown',
	ESTIMATED = 'Estimated',
	GEO_NAME_ID = 'GeoNameId',
	CENTROID_OF_ADDRESSES = 'CentroidOfAddresses',
}
